import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
// @mui
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  ClickAwayListener,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import cssStyles from '../../../utils/cssStyles';
// components
import { useJsApiLoader } from '@react-google-maps/api';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import {
  IdentifierCode,
  PatientWrapper,
  WrappedPatient,
} from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { usePatients } from 'src/@nicheaim/fhir-react';
import { CreateFullPatientDto } from 'src/ccm/dto/create-full-patient.dto';
import { fullPatientService } from 'src/ccm/full-patient/services';
import { IS_CRS } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import useRouteHanderByTenant from 'src/hooks/useRouteHanderByTenant';
import {
  Address,
  Patient,
  PatientGender,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import AddAddress from 'src/sections/crs/referral/components/workflow-step/AddressChecklistItem/AddAddress';
import client from 'src/services/api/_client';
import usePatientTabsStore from 'src/stores/patient-tabs';
import { getIdentifier, getPrimaryPhone } from 'src/utils/fhir';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import {
  ACLValidationMode,
  checkAclValidation,
  permissionsAcls,
} from 'src/utils/permissions/permission.utils';
import { getUUID } from 'src/utils/url';
import Iconify from '../../../components/Iconify';
import getNavConfig, { Section } from '../navbar/NavConfig';
import MaxWorkspaceModal from './MaxWorkspaceModal';
import { MAX_ALLOWED_OPENED_PETIENTS } from './constants';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Yup from 'yup';
import moment from 'moment';
import useTenantConfigData from 'src/hooks/useTenantConfigData';
import { SimpleOrgI } from 'src/contexts/JWTContext';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));
const SearchbarResultsStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  alignItems: 'flex-start',
  backgroundColor: 'white',
  display: 'flex',
  left: 0,
  padding: '0 40px',
  position: 'absolute',
  width: '100%',
  zIndex: 99,
  boxShadow: '0px 5px 10px 0px #cdcdcd',
  marginTop: '30px',
}));

// ----------------------------------------------------------------------

interface AdvancedSearchResultsProps {
  patients: Patient[];
  showResults: boolean;
}

interface MPISearchResultsProps {
  patients: any;
  showResults: boolean;
  addPatient: () => void;
}

export default function Searchbar() {
  const { openPatientIds, openPatient } = usePatientTabsStore();
  const auth = useAuth();
  const { configurations } = useTenantConfigData();
  const { organizations: userOrganizations } = useAuth().getCurrentUser() || {};
  const [isPatientCreationLoading, setIsPatientCreationLoading] = useState<boolean>(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const [showCreatePatientFromMPIModal, setShowCreatePatientFromMPIModal] = useState(false);
  const [showMaxPatientAllowedModal, setShowMaxPatientAllowedModal] = useState(false);
  const [isOpenSimpleSearch, setOpenSimpleSearch] = useState(false);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [isOpenAdvancedSearch, setOpenAdvancedSearch] = useState(false);
  const [showAdvancedSearchResults, setShowAdvancedSearchResults] = useState(false);
  const [showMPISearchResults, setShowMPISearchResults] = useState(false);
  const [mpiSearchResult, setMPISearchResult] = useState<any>([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const [firstNameError, setFirstNameError] = useState<string | false>(false);
  const [lastNameError, setLastNameError] = useState<string | false>(false);
  const [dateOfBirthError, setDateOfBirthError] = useState<string | false>(false);
  const [genderError, setGenderError] = useState<string | false>(false);
  // const [plan, setPlan] = useState('');
  // const [lineOfBusiness, setLineOfBusiness] = useState('');
  // const [program, setProgram] = useState('');
  const [address, setAddress] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [isAddressDialogOpen, setIsAddressDialogOpen] = useState(false);
  const [fhirAddress, setFhirAddress] = useState<Address | null>(null);
  const isClosingAddressDialog: MutableRefObject<boolean> = useRef(false);
  const [gender, setGender] = useState<string | null>(null);
  const { i18n } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const routeHandlerByTenant = useRouteHanderByTenant();

  const selectedOrg = useMemo<SimpleOrgI>(() => {
    return auth.getSelectedOrg();
  }, [auth]);

  const JsOptions = useMemo<any>(
    () => ({
      id: 'google-map-script',
      googleMapsApiKey: configurations?.googleMapsApiKey || '',
      libraries: ['places'],
    }),
    []
  );
  const { isLoaded: isGoogleAPILoaded } = useJsApiLoader(JsOptions);
  const onCloseAddressDialog = useCallback(() => {
    isClosingAddressDialog.current = true;
    setIsAddressDialogOpen(false);
  }, []);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleOpenSimpleSearch = () => {
    setOpenSimpleSearch((prev) => !prev);
    setTooltipOpen(false);
  };

  const handleCloseSimpleSearch = () => {
    setName('');
    setOpenSimpleSearch(false);
  };

  const handleOpenAdvancedSearch = () => {
    setOpenAdvancedSearch(true);
    setTooltipOpen(false);
  };

  const handleCloseAdvancedSearch = () => {
    if (isAddressDialogOpen || isClosingAddressDialog.current) {
      isClosingAddressDialog.current = false;
      return;
    }
    setOpenAdvancedSearch(false);
    setShowAdvancedSearchResults(false);
  };

  const handleTiptoolToggle = () => {
    setTooltipOpen(!isTooltipOpen);
  };

  const handleTiptoolClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setTooltipOpen(false);
  };

  const toISODateString = (isoDateString: Date) => isoDateString?.toISOString().split('T')[0];
  const [, { create, find }] = usePatients({
    map: PatientWrapper,
    autofetch: false, //query && !isPatientCreationLoading ? true : false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [patients, setPatients] = useState<WrappedPatient[]>([]);

  const handleSimpleSearch = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (!userOrganizations || userOrganizations?.length === 0) return;

    const organizationQuery = userOrganizations.join(',');

    const { value } = event.target;
    setName(value);
    if (value.length < 3) {
      return;
    }

    const formNameQuery = (name: string) => {
      const nameParts = name.trim().split(' ');
      if (!value) {
        return { organization: organizationQuery };
      }
      if (nameParts.length === 1) {
        return { 'name:contains': name, organization: organizationQuery };
      } else {
        return { given: nameParts[0], family: nameParts[1], organization: organizationQuery };
      }
    };

    // attempt search by identifier
    setIsLoading(true);
    const resultByIdentifier = await find({
      identifier: value,
      organization: organizationQuery,
    });
    const resultByName = await find(formNameQuery(value) as any);
    setIsLoading(false);
    setPatients([...resultByIdentifier, ...resultByName]);
  };

  const schema = Yup.object({
    firstName: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required('First Name is required')
      .matches(/^[a-zA-ZÑñ\s]+$/, 'First Name cannot contain numbers or symbols')
      .max(25, 'First Name must be a maximum of 25 characters'),
    lastName: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required('Last Name is required')
      .matches(/^[a-zA-ZÑñ\s-]+$/, 'Last Name cannot contain numbers or symbols')
      .max(25, 'Last Name must be a maximum of 25 characters'),
    dateOfBirth: Yup.string()
      .nullable()
      .required('Date of Birth is required')
      .test('valid-format', 'Date of Birth is invalid', (value) => {
        if (!value) return true;
        return moment(value).isValid();
      }),
    gender: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required(`${i18n('patients.gender', 'crs')} is required`),
  });

  const validateFields = async () => {
    const validateFieldsSchema = await schema.validate({
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      gender,
      dateOfBirth
    }, { abortEarly: false }).catch((err) => err);

    if (validateFieldsSchema.inner) {
      const errors = validateFieldsSchema.inner.reduce((acc, error) => {
        acc[error.path] = error.message;
        return acc;
      }, {});
      
      setFirstNameError(errors.firstName || false);
      setLastNameError(errors.lastName || false);
      setDateOfBirthError(errors.dateOfBirth || false);
      setGenderError(errors.gender || false);

      return false;
    }

    setFirstNameError(false);
    setLastNameError(false);
    setDateOfBirthError(false);
    setGenderError(false);

    return true;
  };

  const handleAdvancedSearch = async () => {
    
    const validate = await validateFields();

    if(!validate) return;

    if (!userOrganizations || userOrganizations?.length === 0) return;

    const organizationQuery = userOrganizations.join(',');

    const query = { organization: organizationQuery } as any;
    if (firstName) {
      query['given:contains'] = firstName;
    }
    if (lastName) {
      query['family:contains'] = lastName;
    }
    if (dateOfBirth) {
      query.birthdate = toISODateString(dateOfBirth);
    }

    if (gender) query.gender = gender;

    setIsLoading(true);
    const results = await find(query);
    setIsLoading(false);
    setPatients(results);
    setShowAdvancedSearchResults(true);
    setShowMPISearchResults(false);
  };

  const handleMPISearch = async (createIfNotExist?: boolean): Promise<any> => {
    const payload = {
      name: [{ family: lastName, given: [firstName] }],
      birthDate: dateOfBirth ? toISODateString(dateOfBirth) : null,
      gender: gender || undefined,
      createIfNotExist,
    };

    const res = await client.post('/mpi/search', payload);
    const data = res?.data[0];

    if (isEmpty(data)) {
      setMPISearchResult([]);
    } else {
      const patient = {
        uuid: data?.uuid,
        firstName: data?.name?.[0]?.given?.[0],
        lastName: data?.name?.[0]?.family,
        birthDate: data?.birthDate,
        phone: getPrimaryPhone(data?.telecom)?.value,
      };
      setMPISearchResult([patient]);
    }
    setShowAdvancedSearchResults(false);
    setShowMPISearchResults(true);

    return data;
  };

  const searchModule = (pathname: string) =>
    getNavConfig().find((section: Section) =>
      section.items.find((item) => pathname.includes(item.path))
    );

  const currentModule = searchModule(location.pathname);

  const clearForm = () => {
    setFhirAddress(null);
    setFirstName('');
    setLastName('');
    setMiddleName('');
    setDateOfBirth(null);
    // setPlan('');
    // setLineOfBusiness('');
    // setProgram('');
    setAddress('');
    setGender('');
    setIdentifier('');
    setMPISearchResult([]);
    setShowAdvancedSearchResults(false);
    setShowMPISearchResults(false);
  };

  useEffect(() => {
    if (!isLoading && showAdvancedSearchResults && patients.length === 0) {
      handleMPISearch(false);
    }
  }, [showAdvancedSearchResults, patients, isLoading, isPatientCreationLoading]);

  const handleOpenPerson = (person: any) => {
    let shouldOpenPatient = true;
    
    const patientRoute = routeHandlerByTenant.redirectRouteAfterCreatePatient(
      person.id || '',
      location.pathname
    );
    navigate(patientRoute);
    
    if (openPatientIds.length >= MAX_ALLOWED_OPENED_PETIENTS) {
      shouldOpenPatient = false;
      setShowMaxPatientAllowedModal(true);
    }

    if (shouldOpenPatient) {
      openPatient(person.id);
      clearForm();
      handleCloseSimpleSearch();
      handleCloseAdvancedSearch();
    }
  };

  const handleAddPatient = async () => {
    try {
      if (!isEmpty(firstName) && !isEmpty(lastName) && dateOfBirth !== null) {
        setIsPatientCreationLoading(true);

        const result = await handleMPISearch(true);
        let fullPatient: CreateFullPatientDto | null = null;

        result?.identifier?.push({
          use: 'official',
          type: 'master_patient_uuid',
          value: result?.uuid,
          system: `${configurations?.mpi?.searchUrl}/patients`,
        });

        const mrn = getIdentifier(result, IdentifierCode.MEDICAL_RECORD_NUMBER.toLowerCase(), patientIdentifierTypes, "MR" );
        const master_person_uuid = getIdentifier(result, IdentifierCode.MASTER_PERSON_UUID.toLowerCase(), patientIdentifierTypes);
        const master_patient_uuid = getIdentifier(result, IdentifierCode.MASTER_PATIENT_UUID.toLowerCase(), patientIdentifierTypes);

        if (!isEmpty(mrn)) {
          const [fhirPatient] = await create({
            resourceType: 'Patient',
            active: true,
            identifier: [mrn, master_person_uuid, master_patient_uuid].filter(Boolean),
            gender: (gender ?? undefined) as PatientGender,
            name: [{ given: [firstName, middleName], family: lastName }],
            birthDate: dateOfBirth?.toISOString().split('T')[0],
            address: fhirAddress ? [fhirAddress] : undefined,
            managingOrganization: {
              display: selectedOrg?.name,
              reference: `Organization/${getUUID(selectedOrg?.fhir_uri)}`,
            },
          });

          if (!showCreatePatientFromMPIModal) {
            fullPatient = new CreateFullPatientDto();
            fullPatient.fhirRefUri = fhirPatient.id!;
            fullPatient.given = firstName;
            fullPatient.family = lastName;
            fullPatient.fullName = `${firstName} ${lastName}`;
            fullPatient.status = 'new';

            await fullPatientService.create(fullPatient);
          }

          handleCloseAdvancedSearch();
          const patientRoute = routeHandlerByTenant.redirectRouteAfterCreatePatient(
            fhirPatient.id || '',
            location.pathname
          );
          navigate(patientRoute);

          setIsPatientCreationLoading(false);
          setDisableButtons(false);
          onCloseCreatePatientFromMPIModal();
          handleOpenPerson(fhirPatient);
          handleCloseAdvancedSearch();
        } else {
          enqueueSnackbar('An error has ocurred.', { variant: 'error' });
          setIsPatientCreationLoading(false);
          setDisableButtons(false);
        }
      }
    } catch (e) {
      console.error(e);
      setIsPatientCreationLoading(false);
      setDisableButtons(false);
    }
  };

  const MPISearchResults = ({ showResults, patients, addPatient }: MPISearchResultsProps) => (
    <Stack spacing={2}>
      {showResults && patients.length ? (
        patients.map((patient: any) => (
          <Stack key={patient.uuid} direction="row" sx={{ alignItems: 'center' }}>
            <Box sx={{ cursor: 'pointer' }}>
              <Grid container spacing={2}>
                <Grid item>
                  <Avatar />
                </Grid>
                <Grid item sx={{ color: 'text.primary' }}>
                  <Stack>
                    <Typography variant="body1">
                      {patient?.firstName} {patient?.lastName}
                    </Typography>
                    <Typography variant="caption">
                      {patient?.phone?.[0]} / {patient?.birthDate}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            {isPatientCreationLoading && <CircularProgress size={24} sx={{ ml: '14px' }} />}
          </Stack>
        ))
      ) : (
        <Box>
          <Typography variant="body1" sx={{ color: '#637381', textTransform: 'uppercase' }}>
            {i18n('notFound', 'crs')}
          </Typography>
          <Stack direction="row" sx={{ alignItems: 'baseline' }}>
            <Typography variant="body1" sx={{ color: '#637381' }}>
              Do you want to{' '}
            </Typography>
            <Button variant="text" disabled={isPatientCreationLoading} onClick={addPatient}>
              {`Create a new ${i18n('patients.title', 'crs')}`}
            </Button>
          </Stack>
        </Box>
      )}
    </Stack>
  );

  const advancedSearchResults = ({ showResults, patients }: AdvancedSearchResultsProps) => {
    const handleOpenPerson = (person: any) => {
      let shouldOpenPatient = true;

      const patientRoute = routeHandlerByTenant.redirectRouteAfterCreatePatient(
        person.id || '',
        location.pathname
      );
      navigate(patientRoute);
      
      if (openPatientIds.length >= MAX_ALLOWED_OPENED_PETIENTS) {
        shouldOpenPatient = false;
      }
      
      if (shouldOpenPatient) {
        handleCloseAdvancedSearch();
        openPatient(person.id);
        clearForm();
      }
    };

    return (
      <Stack spacing={2}>
        {showResults && patients.length ? (
          patients.map((patient) => (
            <Box
              key={patient.id}
              onClick={() => handleOpenPerson(patient)}
              sx={{ cursor: 'pointer' }}
            >
              <Grid container spacing={2}>
                <Grid item>
                  <Avatar />
                </Grid>
                <Grid item sx={{ color: 'text.primary' }}>
                  <Stack>
                    <Typography variant="body1">
                      {patient?.name?.[0]?.given?.join(' ')} {patient?.name?.[0]?.family}
                    </Typography>
                    <Typography variant="caption">
                      {patient?.telecom?.filter((t) => t.system === 'phone')?.[0]?.value} /{' '}
                      {patient?.birthDate}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          ))
        ) : (
          <Box>
            <Typography variant="body1" sx={{ color: '#637381', textTransform: 'uppercase' }}>
              {i18n('notFound', 'crs')}
            </Typography>
          </Box>
        )}
      </Stack>
    );
  };

  const onCloseCreatePatientFromMPIModal = () => setShowCreatePatientFromMPIModal(false);

  const onCloseShowMaxPatientAllowedModal = () => setShowMaxPatientAllowedModal(false);

  const genders: [string, string][] = [
    ['', ''],
    ['female', i18n('patients.genderValues.female', 'crs')],
    ['male', i18n('patients.genderValues.male', 'crs')],
    ['other', i18n('patients.genderValues.other', 'crs')],
    ['unknown', i18n('patients.genderValues.unknown', 'crs')],
  ];

  const {
    valueSets: [patientIdentifierTypes]
  } = useValueSetsByIdentifiers(['patient-identifier-types']);

  const isAllowedToCRSSimpleSearch = checkAclValidation({
    acls: [crsAcls.CRS.PATIENT.SEARCH.SIMPLE],
  });

  const isAllowedToCRSAdvancedSearch = checkAclValidation({
    acls: [crsAcls.CRS.PATIENT.SEARCH.ADVANCED],
  });

  return (
    <>
      <ClickAwayListener onClickAway={handleCloseSimpleSearch}>
        <div>
          {/* CREATE PATIENT FROM MPI RESULT */}
          <Dialog open={showCreatePatientFromMPIModal} onClose={onCloseCreatePatientFromMPIModal}>
            <DialogTitle id="delete-note-modal-title">
              {'This patient does not exists on the database'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="delete-note-modal-description">
                {`Do you want to create this ${i18n('patients.title', 'crs')}?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disabled={disableButtons} onClick={onCloseCreatePatientFromMPIModal}>
                No, go back
              </Button>
              <Button
                disabled={disableButtons}
                onClick={() => {
                  setDisableButtons(true);
                  handleAddPatient();
                }}
              >
                Yes, create
              </Button>
            </DialogActions>
          </Dialog>
          {/* CCM PATIENT LIMIT WORKSPACE MODAL */}
          <MaxWorkspaceModal
            open={showMaxPatientAllowedModal}
            onClose={onCloseShowMaxPatientAllowedModal}
          />
          {!!(isAllowedToCRSAdvancedSearch || isAllowedToCRSSimpleSearch) && (
            <ButtonGroup sx={{ paddingRight: 3 }} ref={anchorRef}>
              <IconButton size="small" onClick={handleOpenSimpleSearch}>
                <Iconify icon={'eva:search-fill'} width={20} height={20} />
              </IconButton>
              <IconButton size="small" onClick={handleTiptoolToggle}>
                <Iconify icon={'eva:arrow-ios-downward-outline'} width={20} height={20} />
              </IconButton>
            </ButtonGroup>
          )}
          <Popper
            disablePortal
            anchorEl={anchorRef.current}
            open={isTooltipOpen}
            sx={{
              zIndex: 1,
            }}
            placement="bottom-start"
            onResize={undefined}
            onResizeCapture={undefined}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleTiptoolClose}>
                <MenuList autoFocusItem>
                  {!!(
                    isAllowedToCRSSimpleSearch ||
                    checkAclValidation(
                      {
                        acls: permissionsAcls.simpleSearch,
                      },
                      ACLValidationMode.EXCLUSIVE
                    )
                  ) && (
                    <MenuItem onClick={handleOpenSimpleSearch}>
                      <Iconify icon={'eva:search-fill'} width={20} height={20} />
                      {i18n('search', 'translations')}
                    </MenuItem>
                  )}
                  {!!(
                    isAllowedToCRSAdvancedSearch ||
                    checkAclValidation(
                      {
                        acls: permissionsAcls.advancedSearch,
                      },
                      ACLValidationMode.EXCLUSIVE
                    )
                  ) && (
                    <MenuItem onClick={handleOpenAdvancedSearch}>
                      <Iconify icon={'eva:maximize-outline'} width={20} height={20} />
                      {i18n('advancedSearch.title', 'translations')}
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>

          {isOpenSimpleSearch && (
            <Container>
              <SearchbarStyle>
                <Input
                  autoFocus
                  fullWidth
                  disableUnderline
                  placeholder="Search…"
                  sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify
                        icon={'eva:search-fill'}
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  }
                  onChange={handleSimpleSearch}
                />
                <Button variant="contained" onClick={handleCloseSimpleSearch}>
                  {i18n('search', 'translations')}
                </Button>
              </SearchbarStyle>
              <SearchbarResultsStyle>
                {patients.length && name.length >= 3 && (
                  <List
                    sx={{
                      color: 'text.primary',
                      width: '100%',
                      maxHeight: 600,
                      overflow: 'scroll',
                    }}
                  >
                    {patients?.map((patient) => (
                      <ListItem
                        key={patient.id}
                        alignItems="flex-start"
                        onClick={() => handleOpenPerson(patient)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <ListItemAvatar>
                          {/* //TODO: refactor intoname getter */}
                          <Avatar
                            alt={`${patient?.name?.[0]?.given?.join(' ')} ${
                              patient?.name?.[0]?.family
                            }`}
                            src="/static/images/avatar/1.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${patient?.name?.[0]?.given?.join(' ')} ${
                            patient?.name?.[0]?.family
                          }`}
                          secondary={
                            <>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                              >
                                {patient?.telecom?.filter((t) => t.system === 'phone')?.[0]?.value}{' '}
                                / {patient?.birthDate}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
                {!isLoading && patients.length === 0 && name.length > 0 && (
                  <List sx={{ color: 'text.primary' }}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={`No results were found for ${i18n(
                          'patients.title',
                          'crs'
                        )} ${name}`}
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </List>
                )}
              </SearchbarResultsStyle>
            </Container>
          )}

          {isOpenAdvancedSearch && (
            <ClickAwayListener onClickAway={handleCloseAdvancedSearch} mouseEvent={'onMouseUp'}>
              <SearchbarResultsStyle>
                <Container sx={{ padding: 2, overflowY: 'auto', maxHeight: '70vh' }}>
                  <Stack direction="row" spacing={4}>
                    <Stack spacing={2}>
                      <Stack direction="row" justifyContent="flex-end" spacing={2}>
                        <Button color="primary" variant="outlined" onClick={clearForm}>
                          Clear All
                        </Button>
                        <Button color="primary" variant="contained" onClick={handleAdvancedSearch}>
                          Search
                        </Button>
                      </Stack>

                      <Typography
                        variant="caption"
                        sx={{ color: '#637381', textTransform: 'uppercase' }}
                      >
                        {i18n('advancedSearch.title', 'translations')}
                      </Typography>

                      <TextField
                        label={`${i18n('patients.firstName', 'crs')}*`}
                        size="small"
                        value={firstName}
                        error={!!firstNameError}
                        helperText={firstNameError}
                        onChange={(ev) => {
                          setFirstName(ev.target.value);
                          if (showMPISearchResults) {
                            setShowMPISearchResults(false);
                          }
                        }}
                      />
                      <TextField
                        label={`${i18n('patients.lastName', 'crs')}*`}
                        size="small"
                        value={lastName}
                        error={!!lastNameError}
                        helperText={lastNameError}
                        onChange={(ev) => {
                          setLastName(ev.target.value);
                          if (showMPISearchResults) {
                            setShowMPISearchResults(false);
                          }
                        }}
                      />
                      <TextField
                        select
                        size="small"
                        label={`${i18n('patients.gender', 'crs')}*`}
                        value={gender}
                        error={!!genderError}
                        helperText={genderError}
                        SelectProps={{
                          displayEmpty: true,
                        }}
                        onChange={(event) => {
                          setGender(event.target.value);
                        }}
                      >
                        {genders.map(([value, label]) => (
                          <MenuItem key={`patient-menu-item-${value}`} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label={`${i18n('patients.dateOfBirth', 'crs')}*`}
                          value={dateOfBirth}
                          onChange={(date) => {
                            setDateOfBirth(date);
                            if (showMPISearchResults) {
                              setShowMPISearchResults(false);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              error={!!dateOfBirthError}
                              helperText={dateOfBirthError}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      
                      <Typography variant="caption">Other Details</Typography>
                      <TextField
                        label={i18n('patients.middleName', 'crs')}
                        size="small"
                        value={middleName}
                        onChange={(ev) => setMiddleName(ev.target.value)}
                      />
                      {/* <TextField
                        label={i18n('patients.plan', 'crs')}
                        size="small"
                        value={plan}
                        onChange={(ev) => setPlan(ev.target.value)}
                      />
                      <TextField
                        label={i18n('patients.lob', 'crs')}
                        size="small"
                        value={lineOfBusiness}
                        onChange={(ev) => setLineOfBusiness(ev.target.value)}
                      />
                      <TextField
                        label={i18n('patients.program', 'crs')}
                        size="small"
                        value={program}
                        onChange={(ev) => setProgram(ev.target.value)}
                      /> */}
                      <Accordion>
                        <AccordionSummary expandIcon={<Iconify icon={'eva:plus-outline'} />}>
                          {i18n('patients.identifiers', 'crs')}
                        </AccordionSummary>
                        <AccordionDetails>
                          <TextField
                            label={i18n('patients.identifiers', 'crs')}
                            size="small"
                            value={identifier}
                            onChange={(ev) => setIdentifier(ev.target.value)}
                          />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary expandIcon={<Iconify icon={'eva:plus-outline'} />}>
                          {i18n('patients.address', 'crs')}
                        </AccordionSummary>
                        <AccordionDetails>
                          <TextField
                            label={!IS_CRS ? i18n('patients.address', 'crs') : undefined}
                            placeholder={i18n('patients.address', 'crs')}
                            size="small"
                            value={address}
                            defaultValue={''}
                            InputProps={{
                              readOnly: IS_CRS,
                              onClick: () => {
                                if (!IS_CRS) return;
                                setIsAddressDialogOpen(true);
                              },
                            }}
                            onChange={(ev) => {
                              if (IS_CRS) return;
                              setAddress(ev.target.value);
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>

                      <Stack direction="row" justifyContent="flex-end" spacing={2}>
                        <Button color="primary" variant="outlined" onClick={clearForm}>
                          Clear All
                        </Button>
                        <Button color="primary" variant="contained" onClick={handleAdvancedSearch}>
                          Search
                        </Button>
                      </Stack>
                    </Stack>

                    <Stack spacing={2} paddingTop={6}>
                      <Typography
                        variant="caption"
                        sx={{ color: '#637381', textTransform: 'uppercase' }}
                      >
                        {`${i18n('patients.title', 'crs')}s`}
                      </Typography>
                      {showAdvancedSearchResults
                        ? advancedSearchResults({
                            patients,
                            showResults: showAdvancedSearchResults,
                          })
                        : null}
                      {showMPISearchResults ? (
                        <MPISearchResults
                          showResults={showMPISearchResults}
                          patients={mpiSearchResult}
                          addPatient={handleAddPatient}
                        />
                      ) : null}
                    </Stack>
                  </Stack>
                </Container>
              </SearchbarResultsStyle>
            </ClickAwayListener>
          )}
        </div>
      </ClickAwayListener>
      {!!isGoogleAPILoaded && (
        <Dialog
          disableEscapeKeyDown={false}
          open={isAddressDialogOpen}
          fullWidth
          maxWidth="md"
          onClose={onCloseAddressDialog}
        >
          <DialogTitle> Add {i18n('patients.address', 'crs')}</DialogTitle>
          <Card sx={{ p: 2, overflowY: 'auto' }}>
            <AddAddress
              externalAddress={fhirAddress}
              isEditable={true}
              handleClose={onCloseAddressDialog}
              handleSave={(address: Address) => {
                setFhirAddress(address);
                setAddress(formatAddress(address));
                onCloseAddressDialog();
              }}
            />
          </Card>
        </Dialog>
      )}
    </>
  );
}

const formatAddress = (address: Address): string => {
  const { line, city, state, postalCode, country } = address ?? {};

  let formattedAddressSegments: string[] = [];

  if (line && line.length > 0) formattedAddressSegments.push(line.join(' ').trim());
  if (city) formattedAddressSegments.push(city.trim());
  if (state || postalCode)
    formattedAddressSegments.push(`${state || ''} ${postalCode || ''}`.trim());
  if (country) formattedAddressSegments.push(country.trim());

  return formattedAddressSegments.join(', ').trim();
};
