import { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import useTable from '../../../hooks/useTable';
import { ReferralManager } from 'src/@types/crs/referral';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { ReferralStatics, ReferralTable, ReferralTableToolbar } from 'src/sections/crs/referral';
import { TableHeadCustom } from 'src/components/table';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { referralService } from 'src/crs/referral/services';
import { PaginateQuery } from 'src/api/pagination/dtos';
import { PaginatedReferralDto } from 'src/crs/referral/services/ReferralService';
import useObjectState from 'src/hooks/useObjectState';
import {
  GridViewModules,
  GridView,
  getGridViewsByModule,
  GridViewConverter,
} from 'src/services/api/gridView';
import { debounce, wait } from 'src/utils/timers';
import { TABLE_HEAD_REFERRAL_LIST } from '../common/table-head';
import { referralDtoToReferralManager } from '../common/common-utils';
import NoResultsTableRow from '../common/NoResultsTableRow';
import LoadingTableRow from '../../../components/table/LoadingTableRow';
import useLocales from 'src/hooks/useLocales';
import { translateTableHead } from '../helpers/common';
import useNotiMessage from 'src/hooks/useNotiMessage';
import { FilterList as FilterListIcon } from '@mui/icons-material';
import FilterDrawer from 'src/components/FilterDrawer';
import agent from 'src/api/agent';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

interface GridViewState {
  isGridViewsLoading: boolean;
  gridViews: GridView[] | null;
  gridViewSelected: number;
}

const defaultViewGrid: GridView = {
  id: 0,
  code: 'All',
  name: 'All',
  description: 'All',
  scope: 'GLOBAL',
  data: {},
  isDefault: false,
  module: GridViewModules.REFERRALLIST,
  isPredefined: true,
};

export default function ReferralList() {
  const { page, orderBy, rowsPerPage, onChangePage, onChangeRowsPerPage, setPage } = useTable({
    defaultRowsPerPage: 5,
    defaultOrderBy: 'createdOn',
    initialIndex: 1,
    defaultCurrentPage: 1,
  });

  const { i18n } = useLocales();
  const { configurations } = useTenantConfigData();

  const { themeStretch } = useSettings();

  const [tableData, setTableData] = useState<PaginatedReferralDto | undefined | null>(null);

  const [open, setOpen] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [assignedTo, setAssignedTo] = useState<any>([]);
  const [isOpenAplyFilters, setIsOpenAplyFilters] = useState<boolean>(false);

  const [filterMrn, setFilterMrn] = useState('');
  const [search, setSearch] = useState<string>('');
  const [{ gridViews, isGridViewsLoading, gridViewSelected }, updateState] =
    useObjectState<GridViewState>({
      isGridViewsLoading: true,
      gridViews: [],
      gridViewSelected: defaultViewGrid.id,
    });
  const { showErrorMessage } = useNotiMessage();

  const getPaginateQuery = (page: number, rowsPerPage: number, search: string, gridView:any, assignedTo: any) => {
    const paginateQuery: PaginateQuery = {
      page: page,
      limit: rowsPerPage,
      sortBy: [[orderBy, 'DESC']],
    };
    if (search) {
      paginateQuery.search = search;
    }

    paginateQuery.filter = {};

    if(gridView?.dataConverted?.filter?.indexedStatus) {
      paginateQuery.filter.indexedStatus = gridView.dataConverted.filter.indexedStatus;
    }

    if (assignedTo?.length) {
      paginateQuery.filter.workflowOwnedBy = `$in:${assignedTo.join(',')}`;
    }

    return paginateQuery;
  };

  const fetchReferralList = async (
    page: number,
    rowsPerPage: number,
    search: string,
    gridViewSelected: number,
    assignedTo: any
  ) => {
    setIsLoading(true);
    await wait();
    const gridView = gridViewSelected ? findGridViewById(gridViewSelected) : null;
    const referrals = await referralService.getAll(
      getPaginateQuery(page, rowsPerPage, search, gridView, assignedTo)
    );
    setIsLoading(false);
    if (!referrals) return showErrorMessage(i18n('error.referral.list'));

    setTableData(referrals);
  };

  const getReferralList = useCallback(debounce(fetchReferralList, 600), [gridViews]);

  const getAllUsers = async () => {
    const result = await agent.User.getAllUsers();
    setAllUsers(result);
  };

  useEffect(() => {
    const getReferralGridViews = async () => {
      const gridViews = await getGridViewsByModule(
        GridViewModules.REFERRALLIST,
        GridViewConverter.PaginateQuery
      );
      await wait();
      updateState({
        isGridViewsLoading: false,
        gridViews: [defaultViewGrid, ...(gridViews || [])],
      });
    };
    getReferralGridViews();
  }, [updateState]);

  useEffect(() => {
    getReferralList(page, rowsPerPage, search, gridViewSelected, assignedTo);
    getAllUsers();
  }, [page, rowsPerPage, filterMrn, search, gridViewSelected]);

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(event, newPage + 1);
  };

  const handleFilterMrn = (filterMrn: string) => {
    setFilterMrn(filterMrn);
    setPage(1);
  };

  const findGridViewById = (gridViewId: number): GridView | undefined =>
    gridViews?.find(({ id }) => id === gridViewId);

  const handleOnGridValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateState({
      gridViewSelected: Number(event.target.value),
    });
  };

  const dataFormatted = (): ReferralManager[] => {
    const data = tableData?.data ? tableData.data.map((r) => referralDtoToReferralManager(r)) : [];
    return data;
  };

  const rows = dataFormatted();

  return (
    <Page title={i18n('referral.list.title', 'crs')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          title={i18n('referral.list.title', 'crs')}
          action={
            <Stack direction="row" spacing={2}>
              <Button
                sx={{ float: 'right' }}
                variant="contained"
                onClick={() => {
                  getReferralList(page, rowsPerPage, search, gridViewSelected, assignedTo);
                }}
              >
                {i18n('referral.list.refresh', 'crs')}
              </Button>
              <Button
                sx={{ float: 'right' }}
                variant="contained"
                href={`${configurations?.mint?.referralSincronizar}`}
                target="_blank"
              >
                {i18n('referral.list.syncReferrals', 'crs')}
              </Button>
            </Stack>
          }
          heading=""
          links={[
            { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
            { name: `${i18n('breadcrumbs.title', 'crs')}`},
            { name: `${i18n('referral.list.title', 'crs')}`, href: PATH_DASHBOARD.crs.referral },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ReferralStatics isOpen={open} onOpen={() => setOpen(!open)} />
          </Grid>

          <Grid item xs={12}>
            <Card>
              <Stack direction="row" sx={{ py: 2, px: 2 }}>
                <Grid xs={6}>
                  <ReferralTableToolbar
                    isLoading={isGridViewsLoading}
                    filterMrn={filterMrn}
                    gridViewSelected={gridViewSelected}
                    onFilterMrn={handleFilterMrn}
                    onGridValueChange={handleOnGridValueChange}
                    options={gridViews ?? []}
                    search={search}
                    onSearch={setSearch}
                  />
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-end" xs={6}>
                  <Tooltip title="Filter">
                    {assignedTo?.length > 0 ? (
                      <IconButton
                        onClick={() => {
                          setIsOpenAplyFilters(true);
                        }}
                        sx={{ height: '40px', backgroundColor: '#7ac25b' }}
                      >
                        <FilterListIcon htmlColor="#087736" />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          setIsOpenAplyFilters(true);
                        }}
                        sx={{ height: '40px' }}
                      >
                        <FilterListIcon htmlColor="#8f9993" />
                      </IconButton>
                    )}
                  </Tooltip>
                </Grid>
              </Stack>
              <TableContainer>
                <Table>
                  <TableHeadCustom
                    headLabel={translateTableHead(TABLE_HEAD_REFERRAL_LIST, 'crs')}
                  />
                  <TableBody>
                    {!isLoading ? (
                      rows?.length ? (
                        rows.map((row) => <ReferralTable key={row.id} row={row} />)
                      ) : (
                        <NoResultsTableRow
                          colSpan={TABLE_HEAD_REFERRAL_LIST.length}
                          text={i18n('referral.list.listNotFound', 'crs')}
                        />
                      )
                    ) : (
                      <LoadingTableRow colSpan={TABLE_HEAD_REFERRAL_LIST.length} />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid xs={12}>
                <Stack justifyContent="flex-end">
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={tableData ? tableData.meta.totalItems : 0}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    sx={{ borderTop: 0 }}
                  />
                </Stack>
              </Grid>
            </Card>
          </Grid>
          <FilterDrawer
            onApplyButtonClick={() => {
              getReferralList(page, rowsPerPage, search, gridViewSelected, assignedTo);
              setIsOpenAplyFilters(false);
            }}
            title={i18n('referral.list.filterPopUp.title', 'crs')}
            onCloseIconButtonClick={() => {
              setIsOpenAplyFilters(false);
            }}
            onClearAllButtonClick={() => {
              setAssignedTo([]);
            }}
            anchor={'right'}
            open={isOpenAplyFilters}
          >
            <TaskFilterList
              setAssignedTo={setAssignedTo}
              assignedTo={assignedTo}
              users={allUsers}
              i18n={i18n}
            />
          </FilterDrawer>
        </Grid>
      </Container>
    </Page>
  );
}

interface TaskFilterListProps {
  setAssignedTo: any;
  assignedTo: any;
  users: any;
  i18n?: any;
}
const TaskFilterList = ({
  setAssignedTo,
  assignedTo,
  users,
  i18n,
}: TaskFilterListProps) => (
  <Box py={3}>
    <Grid item sx={{ marginTop: 1 }} xs={6}>
      <Typography fontSize={'1rem'} fontWeight={'bold'} marginBottom={1.4}>
        {i18n('referral.list.filterPopUp.assignedTo', 'crs')}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Stack spacing={2} sx={{ p: 2 }}>
        <Autocomplete
          value={assignedTo}
          multiple
          fullWidth
          onChange={(_: React.SyntheticEvent, taskStatus) => {
            setAssignedTo([...taskStatus]);
          }}
          options={users?.map((option: any) => option?.email)}
          getOptionLabel={(option: string) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label={i18n('referral.list.filterPopUp.assignedTo', 'crs')}
              variant="outlined"
            />
          )}
        />
      </Stack>
    </Grid>
  </Box>
);