import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isArray, isEmpty, startCase } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Observation } from 'src/@nicheaim/fhir-base/mappings/Observation';
import { WrappedObservation } from 'src/@nicheaim/fhir-base/wrappers/Observation';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { useValueSet, useValueSets } from 'src/@nicheaim/fhir-react';
import RegistryComponent, { ValueType } from 'src/components/RegistryComponent';
import useAddEntityRequestStates from 'src/hooks/useAddEntityRequestStates';
import useLocales from 'src/hooks/useLocales';
import useTenantConfigData from 'src/hooks/useTenantConfigData';
import { ValueSetComposeIncludeConcept } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { convertValueToValueSet } from 'src/sections/crs/common/common-utils';
import { getRegistries, RegistryValueType } from 'src/services/api/registry';
import InfoRibbon from '../../../../../../components/InfoRibbon';
import {
  InfoRibbonObservation,
  InfoRibbonPatient,
} from '../../../../../../components/InfoRibbonSections';
import AnnotationsList from '../../../../common/AnnotationsList';
import AnnotationsModal from '../../../../common/AnnotationsModal';

type Props = {
  patient: WrappedPatient | null | undefined;
  observation?: WrappedObservation | null;
  open: boolean;
  onClose: VoidFunction;
  handleObservation: (data: any) => Promise<any>;
};

export function ObservationForm({ patient, open, observation, onClose, handleObservation }: Props) {
  const { i18n } = useLocales();
  const { componentsData, configurations } = useTenantConfigData();

  const [annotations, setAnnotations] = useState<any[]>([]);
  const [annotationsOpen, setAnnotationsOpen] = useState(false);

  const [valueObservation, setValueObservation] = useState<{
    valueType: RegistryValueType | null;
    value: ValueType;
  }>({ valueType: null, value: null });
  const [typeObservation, setTypeObservation] = useState('');
  const [categoryObservation, setCategoryObservation] = useState('');

  const [valueSetType] = useValueSet('ph-type-observation', { map: ValueSetWrapper });
  const [valueSetCategory] = useValueSet('ph-category-observation', { map: ValueSetWrapper });
  const [observationsCatalog] = useValueSet('ph-observations-catalog', { map: ValueSetWrapper });
  const [initialObservationValue, setInitialObservationValue] = useState<ValueType>(null);

  const [{ error }, { setError }] = useAddEntityRequestStates();

  useEffect(() => {
    if (isEmpty(observation)) {
      setCategoryObservation(componentsData.observation?.defaultValues?.category?.code ?? '');
      setTypeObservation('');
      setAnnotations([]);
      return;
    }
    setCategoryObservation(observation?.category?.[0]?.coding?.[0]?.code ?? '');
    setTypeObservation(observation?.code?.coding?.[0].code ?? '');

    if (observation?.note && isArray(observation?.note)) {
      setAnnotations([...observation.note]);
    } else {
      setAnnotations([]);
    }
  }, [observation, componentsData, patient]);

  const getValue = () => {
    const value =
      typeof valueObservation.value === 'object'
        ? valueObservation.value?.code
        : valueObservation.value;
    if (!value) return null;
    switch (valueObservation?.valueType) {
      case RegistryValueType.CodeableConcept:
        if (valueObservation.value !== null && typeof valueObservation.value === 'object') {
          const { ...valueWithoutCategory } = valueObservation.value;

          return {
            valueCodeableConcept: {
              coding: [valueWithoutCategory],
              text: valueObservation.value?.display,
            },
          };
        }
        return null;
      case RegistryValueType.NUMBER: {
        const integer = parseInt(String(value));
        if (isNaN(integer)) return null;
        return { valueInteger: integer };
      }
      default: {
        return { valueString: value };
      }
    }
  };

  const { data: registries } = useQuery({
    queryKey: ['registries', { groupIdentifier: 'observations' }],
    queryFn: async () => {
      const registries = await getRegistries('observations');
      return registries.data;
    },
  });

  const valueSetsIds = useMemo(() => registries?.map((e) => e?.keyRegistry) ,[registries]);

  const [valueSets] = useValueSets({
    filter: {
      _id: valueSetsIds?.join(','),
    },
    map: ValueSetWrapper,
    autofetch: !!valueSetsIds
  });

  const validateRequiredFields = (value: any) => {
    if (isEmpty(typeObservation) || isEmpty(value) || isEmpty(categoryObservation)) {
      return false;
    }
    return true;
  };

  function getObservationValueBasedOnRegistry(
    valueType: RegistryValueType | null,
    resource: WrappedObservation | null | undefined
  ) {
    if (!valueType || !resource) {
      return null;
    }

    if (
      valueType === RegistryValueType.CodeableConcept &&
      resource.valueCodeableConcept &&
      resource.valueCodeableConcept.coding &&
      resource.valueCodeableConcept.coding.length > 0 &&
      resource.valueCodeableConcept.coding[0].code
    ) {
      return resource.valueCodeableConcept.coding[0].code;
    } else if (valueType === RegistryValueType.NUMBER && resource.valueInteger) {
      return resource.valueInteger;
    } else if (resource.valueString) {
      return resource.valueString;
    }

    return null;
  }

  function handleOnRegistryLoadForValue(valueType: RegistryValueType | null) {
    const value = getObservationValueBasedOnRegistry(valueType, observation);
    setInitialObservationValue(value);
  }

  const handleOnRegistryLoadForValueCallBack = useCallback(handleOnRegistryLoadForValue, [
    observation,
    setInitialObservationValue,
  ]);

  const mapObservation = (derivedFrom?: string) => {
    const value = getValue() ?? {};

    if (!validateRequiredFields(value)) return setError('Please, fill all required fields');
    const conceptList = valueSetType?.asListAll?.() ?? [];

    const valueSetConcept = conceptList.find(({ code }) => code === typeObservation);

    let observationRes: Observation = {
      resourceType: 'Observation',
      code: {
        coding: [
          {
            code: valueSetConcept?.code,
            display: valueSetConcept?.display,
            system: valueSetType?.getConceptSystem?.(),
          },
        ],
        text: valueSetConcept?.display,
      },
      effectiveDateTime: new Date().toISOString(),
      issued: new Date().toISOString(),
      status: 'preliminary',
      subject: {
        reference: `Patient/${patient?.id}`,
      },
      ...value,
      ...(derivedFrom && {
        derivedFrom: [
          {
            reference: `Observation/${derivedFrom}`,
            type: 'Observation',
          },
        ],
      }),
    };

    if (categoryObservation) {
      const catCode = convertValueToValueSet(categoryObservation, valueSetCategory);

      if (catCode) {
        observationRes.category = [
          {
            coding: [catCode],
            text: startCase(catCode?.display),
          },
        ];
      }
    }

    if (annotations && annotations.length > 0) {
      const note = annotations.map((x) => ({
        time: x?.time,
        text: x?.text,
        authorString: x?.authorString,
      }));
      observationRes.note = note;
    }

    return observationRes;
  };

  const save = () => {
    const edit = observation ? observation?.id : undefined;
    const mappedObservation = mapObservation(edit);
    handleObservation(mappedObservation);
  };

  const onSaveAnnotation = async (data: any) => {
    setAnnotations((originalAnnotations) => [...originalAnnotations, data]);
  };

  const handleViewAllAnnotation = () => {
    setAnnotationsOpen(true);
  };

  const handleHideAllAnnotation = () => {
    setAnnotationsOpen(false);
  };

  const annotationModalBreadcrumbs = [
    `${i18n('patients.details.tabs.health', 'crs')}`,
    `${i18n('patients.details.observations.title', 'crs')}`,
    `${i18n('annotations.details.title', 'crs')}`,
  ];

  const AnnotationsModalHeader = () =>
    !!patient ? (
      <InfoRibbon containerSx={{ height: '60px', marginTop: 0, flexWrap: 'wrap' }} xs={12}>
        <Grid container item xs={5} pl={3}>
          <InfoRibbonPatient patient={patient} configurations={configurations?.mpi}/>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mr: '-1px', borderStyle: 'dashed' }} />
        <Grid container item xs={7} pl={3}>
          <InfoRibbonObservation observation={observation} />
        </Grid>
      </InfoRibbon>
    ) : null;

  return (
    <>
      {annotationsOpen ? (
        <AnnotationsModal
          open={annotationsOpen}
          isLoading={false}
          handlerSave={onSaveAnnotation}
          annotations={annotations}
          breadcrumbs={annotationModalBreadcrumbs}
          onClose={handleHideAllAnnotation}
          patient={patient}
          modalHeader={<AnnotationsModalHeader />}
        />
      ) : null}
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogTitle>
          {!isEmpty(observation)
            ? `Edit ${i18n('patients.details.observations.title', 'crs')}`
            : `Add ${i18n('patients.details.observations.title', 'crs')}`}{' '}
        </DialogTitle>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <FormControl sx={{ mr: 2 }} fullWidth error={!!error}>
                  <InputLabel id="category">
                    {`${i18n('patients.details.observations.category', 'crs')}*`}
                  </InputLabel>
                  <Select
                    label="category"
                    value={categoryObservation}
                    fullWidth
                    onChange={(event) => {
                      setCategoryObservation(event.target.value);
                      setError(null);
                    }}
                  >
                    {(
                      valueSetCategory?.asListAll?.() ?? ([] as ValueSetComposeIncludeConcept[])
                    ).map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.display}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ mr: 2 }} fullWidth error={!!error}>
                  <InputLabel id="observation">
                    {`${i18n('patients.details.observations.observations', 'crs')}*`}
                  </InputLabel>
                  <Select
                    label="observation"
                    value={typeObservation}
                    fullWidth
                    onChange={(event) => {
                      setTypeObservation(event.target.value);
                      setError(null);
                    }}
                  >
                    {(valueSetType?.asListAll?.() ?? ([] as ValueSetComposeIncludeConcept[])).map(
                      (option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.display}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>

                <RegistryComponent
                  disabled={!typeObservation}
                  registries={registries ?? []}
                  initialValue={initialObservationValue}
                  valueSet={valueSets?.map((e) => e?.asListAll())?.flat() ?? []}
                  label={`${i18n('patients.details.observations.value', 'crs')}*`}
                  keyRegistry={typeObservation}
                  onValueChange={(value, valueType) => {
                    setValueObservation({ value, valueType });
                    setError(null);
                  }}
                  onRegistryLoad={handleOnRegistryLoadForValueCallBack}
                  error={error}
                />
                <AnnotationsList annotations={annotations} onViewAll={handleViewAllAnnotation} />
              </Stack>
            </Grid>
            {error && (
              <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                <FormHelperText sx={{ color: '#d50000' }}>{error}</FormHelperText>
              </Grid>
            )}
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="contained" color="info" onClick={onClose}>
                {i18n('cancel')}
              </Button>

              <Button variant="contained" color="info" type="submit" onClick={save}>
                {i18n('submit')}
              </Button>
            </DialogActions>
          </Stack>
        </Card>
      </Dialog>
    </>
  );
}
