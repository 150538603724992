import { has } from "lodash";
import { createWrapper } from "src/@nicheaim/fhir-react/base";
import { EpisodeOfCare, Identifier, Reference } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";

export enum IdentifierCode {
  MINT_PROGRAM_IDENTIFIER = 'MINT PROGRAM',
}

export const IDENTIFIER_MAP: Map<IdentifierCode, Identifier> = newIdentifierMap()
  .add(
    IdentifierCode.MINT_PROGRAM_IDENTIFIER,
    'MINT PROGRAM IDENTIFIER',
    process.env.REACT_APP_MINT_PROGRAM_IDENTIFIER
  )
  .getValue();

export const EpisodeOfCareWrapper = createWrapper('EpisodeOfCare', (episodeOfCare) => ({

  getMINT(systemUrl: string): Identifier | null {
    return getIdentifier(episodeOfCare, IdentifierCode.MINT_PROGRAM_IDENTIFIER, systemUrl);
  },
  getReferralRequestReference(): Reference[] {
    if(episodeOfCare?.referralRequest && episodeOfCare?.referralRequest?.length > 0){
      const referralRequest = episodeOfCare?.referralRequest
      .filter((s) => has(s, 'reference') && s?.reference)
      .map((s) => (s.reference)) as Reference[];
      return referralRequest;
    }
    return [];
  }
}));

function getIdentifier(
  episodeOfCare: EpisodeOfCare, 
  identifierCode: IdentifierCode, 
  systemUrl: string
): Identifier | null {
  let identifierType = IDENTIFIER_MAP.get(identifierCode);
  const system = systemUrl ? systemUrl : identifierType?.system;
  if (system) {
    const value = episodeOfCare.identifier?.find((identifier) => identifier?.system === system) || null;
    return value;
  }
  return null;
}

function newIdentifierMap() {
  const entries: Map<IdentifierCode, Identifier> = new Map();
  const map = {
    add: (code: IdentifierCode, display: string, system?: string) => {
      entries.set(code, {
        system,
        type: {
          coding: [
            {
              system,
              code,
              display,
            },
          ],
          text: display,
        },
      });
      return map;
    },
    getValue: () => entries,
  };
  return map;
}

export type WrappedEpisodeOfCare = ReturnType<typeof EpisodeOfCareWrapper>;