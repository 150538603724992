import { 
  Autocomplete, 
  Box, 
  Card, 
  CircularProgress, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Grid, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TextField, 
  Tooltip, 
  Typography 
} from "@mui/material";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import useAuth from "src/hooks/useAuth";
import { LoadingButton } from "@mui/lab";
import { Add } from "@mui/icons-material";
import { trimMultipleWhiteSpaces } from "src/utils/string";
import { practitionerService } from "src/crs/practitioner";
import CloseIconButton from "src/components/CloseIconButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { mapProviderDirectoryToFhirPractitioner } from "./helpers";
import { ValueSetWrapper } from "src/@nicheaim/fhir-base/wrappers/ValueSet";
import { usePractitionerRoles, usePractitioners, useValueSet } from "src/@nicheaim/fhir-react";
import { PractitionerWrapper, WrappedPractitioner } from "src/@nicheaim/fhir-base/wrappers/Practitioner";
import { DirectoryProvider, OnPractitionerRoleCreation, PractitionerRoleReference, Resource } from "src/@types/crs/providerDirectory";
import useTenantConfigData from "src/hooks/useTenantConfigData";

export interface CreatePractitionerRoleProps {
  practitioner: WrappedPractitioner | null;
  provider: DirectoryProvider | null;
  isVisible: boolean;
  onDialogClose: () => void;
  onPractitionerRoleCreation: OnPractitionerRoleCreation;
  onPractitionerCreation: (practitioner: WrappedPractitioner) => void;
}

const CreatePractitionerRole = ({
  isVisible,
  onDialogClose,
  provider,
  practitioner,
  onPractitionerRoleCreation,
  onPractitionerCreation,
}: CreatePractitionerRoleProps) => {
  const { user } = useAuth();
  const { configurations } = useTenantConfigData();
  const [resourceSearch, setResourceSearch] = useState("");
  const [
    selectedOrganization,
    setSelectedOrganization,
  ] = useState<Resource | null>(null);
  const [organizations, setOrganizations] = useState<Resource[]>([]);
  const [isLoadingOrganizations, setIsLoadingOrganizations] = useState(true);
  const [existingPractitionerRoles, setExistingPractitionerRoles] = useState<
    PractitionerRoleReference[]
  >([]);
  const [isPractitionerRolesLoading, setIsPractitionerRolesLoading] = useState(
    false
  );
  const [isCreating, setIsCreating] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [, { create: createPractitioner }] = usePractitioners({
    autofetch: false,
  });
  const [, { create: createPractitionerRole }] = usePractitionerRoles({
    autofetch: false,
  });
  const [ roles ] = useValueSet('ph-roles-codes',{
    map: ValueSetWrapper
  });

  const isPractitionerRoleWithOrganizationEnabled =
    configurations?.provider?.practitionerRoleWithOrgCreationFlag === 'true';

  const getExistingPractitionerRoles = useCallback(async () => {
    if (!practitioner || !isVisible) return;
    setExistingPractitionerRoles([]);
    setIsPractitionerRolesLoading(true);
    const practitionerRoles = await practitionerService.getPractitionerRolesByReference(
      configurations?.fhir?.pcpRoleCode,
      practitioner.id,
      selectedOrganization?.id,
    );
    setExistingPractitionerRoles(practitionerRoles);
    setIsPractitionerRolesLoading(false);
  }, [selectedOrganization, practitioner, isVisible]);

  useEffect(() => {
    getExistingPractitionerRoles();
  }, [getExistingPractitionerRoles]);

  const debouncedGetResources = useCallback(
    debounce(async (search: string) => {
      setOrganizations([]);
      setIsLoadingOrganizations(true);
      const resources = await practitionerService.getResource({
        resourceType: "Organization",
        search,
      });
      setOrganizations(resources.filter(({ name }) => name.trim()));
      setIsLoadingOrganizations(false);
    }, 500),
    []
  );

  useEffect(() => {
    if ((!provider && !practitioner) || !isVisible) return;
    debouncedGetResources(resourceSearch);
  }, [
    resourceSearch,
    provider,
    isVisible,
    debouncedGetResources,
    practitioner,
  ]);

  useEffect(() => {
    if (isVisible) return;
    setSelectedOrganization(null);
    setResourceSearch("");
    setExistingPractitionerRoles([]);
  }, [isVisible]);

  const pcpRole = useMemo(
    () => roles?.asList()?.find(({ code }) => code === configurations?.fhir?.pcpRoleCode),
    [roles]
  );

  const handlePractitionerCreation = useCallback(async () => {
    if (!provider) return;
    const providerDetails = await practitionerService.getProviderDetails(provider.uuid);
    if (!providerDetails) {
      enqueueSnackbar(
        "There was an error while trying to get Provider's details. Please try again",
        { variant: "error" }
      );
      return;
    }

    const {
      fhirIdentifierTypes,
      licenseCodeMapping,
    } = await practitionerService.getProviderDirectoryMappingData(configurations?.fhir?.terminologyUrl);

    if (!fhirIdentifierTypes || !licenseCodeMapping) {
      showPractitionerCreationError();
      return;
    }

    const practitionerPayload = mapProviderDirectoryToFhirPractitioner({
      provider: providerDetails,
      userName: user?.userName,
      fhirIdentifierTypes,
      licenseCodeMapping,
      configurations
    });

    const practitioner = await createPractitioner(practitionerPayload);

    if (!practitioner) {
      showPractitionerCreationError();
      return;
    }
    return PractitionerWrapper(practitioner?.[0]);
  }, [provider]);

  const showPractitionerCreationError = () => {
    enqueueSnackbar(
      "There was an error while trying to create practitioner. Please try again",
      { variant: "error" }
    );
  };

  const handleRoleCreation = useCallback(async () => {
    let selectedPractitioner:
      | WrappedPractitioner
      | null
      | undefined = practitioner;

    setIsCreating(true);
    if (!selectedPractitioner) {
      selectedPractitioner = await handlePractitionerCreation();
      if (selectedPractitioner) onPractitionerCreation(selectedPractitioner);
    }
    if (!selectedPractitioner) return setIsCreating(false);

    const practitionerRoleResource = await createPractitionerRole({
      resourceType: 'PractitionerRole',
      active: true,
      code: [
        {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: pcpRole?.code,
              display: pcpRole?.display,
            },
          ],
        },
      ],
      practitioner: {
        reference: `Practitioner/${selectedPractitioner.id}`,
        display: selectedPractitioner.getFullName() ?? undefined,
      },
      ...(selectedOrganization
        ? {
            organization: {
              reference: `Organization/${selectedOrganization?.id}`,
              display: selectedOrganization?.name,
            },
          }
        : {}),
    });
    if (!practitionerRoleResource) return setIsCreating(false);
    const practitionerRole = await practitionerService.getPractitionerRole(
      practitionerRoleResource?.[0]?.id!
    );

    if (!practitionerRole) return setIsCreating(false);

    setIsCreating(false);
    enqueueSnackbar("Practitioner Role Created", { variant: "success" });

    onPractitionerRoleCreation(practitionerRole);
  }, [
    practitioner,
    pcpRole,
    selectedOrganization,
    handlePractitionerCreation,
    onPractitionerRoleCreation,
    onPractitionerCreation,
  ]);

  const providerFullName = provider
    ? trimMultipleWhiteSpaces(
        `${provider?.first_name} ${provider?.middle_name} ${provider?.last_name}`
      ).trim()
    : practitioner?.getFullName?.() ?? "";

  const npi = provider?.npi ?? practitioner?.getNPI?.() ?? "";

  const pcpRoleName = pcpRole?.display ?? "PCP - Primary Care Physician";

  if (!provider && !practitioner) return null;

  return (
    <Dialog
      open={isVisible}
      onClose={onDialogClose}
      fullWidth
      maxWidth="sm"
      keepMounted={false}
    >
      <DialogTitle id="confirm-dialog-title">Create PCP Role</DialogTitle>
      <Card>
        <DialogContent sx={{ paddingTop: 2 }}>
          <>
            <Box sx={{ marginBottom: 1 }}>
              <Typography sx={{ fontWeight: "bold", fontSize: "0.90rem" }}>
                {providerFullName}
              </Typography>
              <Typography sx={{ fontSize: "0.90rem" }}>
                <span style={{ fontWeight: "bold" }}>NPI: </span> {npi}
              </Typography>
              <Typography sx={{ fontSize: "0.90rem" }}>
                <span style={{ fontWeight: "bold" }}>Role: </span> {pcpRoleName}
              </Typography>
            </Box>
            {isPractitionerRoleWithOrganizationEnabled && (
              <Box sx={{ marginTop: 2 }}>
                <Typography
                  sx={{
                    fontSize: "0.85rem",
                    marginBottom: 2,
                    fontWeight: "bold",
                  }}
                >
                  Select Organization
                </Typography>
              </Box>
            )}
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item xs={12}>
                {isPractitionerRoleWithOrganizationEnabled && (
                  <Autocomplete
                    value={selectedOrganization}
                    fullWidth
                    onChange={(_: React.SyntheticEvent, resource) => {
                      setSelectedOrganization(resource);
                    }}
                    onBlur={() => {
                      setResourceSearch("");
                    }}
                    options={organizations ?? []}
                    getOptionLabel={({ name }: Resource) => name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={resourceSearch}
                        label={"Organization"}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const text = event.target.value;
                          setResourceSearch(text);
                        }}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingOrganizations ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
            <Typography
              sx={{
                fontSize: "0.80rem",
                marginBottom: 2,
                marginTop: 3,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Existing Roles
            </Typography>
            <TableContainer sx={{ mt: 2, maxHeight: 300 }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Practitioner</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Organization</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isPractitionerRolesLoading ? (
                    existingPractitionerRoles.map(({ organization, id }) => (
                      <TableRow key={id}>
                        <TableCell>{providerFullName}</TableCell>
                        <TableCell>{pcpRoleName}</TableCell>
                        <TableCell>{organization?.display ?? ""}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <CircularProgress size={35} sx={{ marginTop: 2 }} />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </DialogContent>

        <DialogActions sx={{ flexDirection: "row", justifyContent: "center" }}>
          <Tooltip
            title={
              existingPractitionerRoles.length
                ? "Role already exist"
                : "Create Role"
            }
          >
            <span>
              <LoadingButton
                loading={isCreating}
                startIcon={<Add />}
                disabled={
                  !!(
                    isCreating ||
                    isPractitionerRolesLoading ||
                    existingPractitionerRoles.length
                  )
                }
                onClick={() => {
                  handleRoleCreation();
                }}
                variant="contained"
              >
                Create Role
              </LoadingButton>
            </span>
          </Tooltip>
          <CloseIconButton
            disabled={isCreating}
            onClick={onDialogClose}
            autoFocus
            sx={{ marginLeft: 1 }}
          />
        </DialogActions>
      </Card>
    </Dialog>
  );
};

export default CreatePractitionerRole;