import { Coding } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { ObservationComponent } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { createWrapper } from '../../fhir-react/base';
import { getCodeableConceptDisplayName } from 'src/sections/crs/helpers/common';

const findComponentByCode = (component: ObservationComponent[], code: string) =>
  component?.find((c) => c.code?.coding?.some((c) => c.code === code));

export const ObservationWrapper = createWrapper('Observation', (observation) => ({
  hasCodeValue(code: string) {
    return observation?.component?.find((c) => c.code?.coding?.[0].code === code);
  },

  hasVitalsValues() {
    return observation?.component?.find((c) =>
      c.code?.coding?.some(
        (c) =>
          c?.code === '8480-6' ||
          c?.code === '8310-5' ||
          c?.code === '8867-4' ||
          c?.code === '9279-1' ||
          c?.code === '2708-6'
      )
    );
  },

  hasHeightAndWeightValues() {
    return observation?.component?.find((c) =>
      c.code?.coding?.some(
        (c) => c?.code === '8302-2' || c?.code === '29463-7' || c?.code === '39156-5'
      )
    );
  },

  hasOrthostaticValues() {
    return observation?.component?.find((c) =>
      c.code?.coding?.some(
        (c) =>
          c?.code === '8455-8' ||
          c?.code === '8453-3' ||
          c?.code === '8454-1' ||
          c?.code === '8310-5'
      )
    );
  },

  getCategoryDisplay() {
    return observation.category?.map((cat) => getCodeableConceptDisplayName(cat)).join(', ');
  },

  getVitalsValue(code: string) {
    if (!observation?.component) return;
    const vitals = findComponentByCode(observation.component, code);
    return vitals?.valueQuantity?.value?.toString() ?? '-';
  },

  getVitalsValueWithUnits(code: string) {
    if (!observation?.component) return;
    const vitals = findComponentByCode(observation.component, code);
    return vitals ? `${vitals.valueQuantity?.value} ${vitals.valueQuantity?.unit}` : '-';
  },

  getFirstFiveVitals() {
    return [
      '8480-6',
      '8310-5',
      '8867-4',
      '9279-1',
      '2708-6',
      '8302-2',
      '29463-7',
      '39156-5',
      '8455-8',
      '8453-3',
      '8454-1',
    ]
      .map((code) => (this.hasCodeValue(code) ? code : null))
      .filter((c) => c)
      .slice(0, 5);
  },

  getValue() {
    if (observation?.valueInteger) {
      return observation?.valueInteger;
    }
    if (observation?.valueString) {
      return observation?.valueString;
    }
    return observation?.valueCodeableConcept?.coding?.[0].display;
  },

  isSil(serviceIntegrationLevelCode: string) {
    return (
      observation?.code?.coding?.[0]?.code ===
      serviceIntegrationLevelCode
    );
  },

  isPrs(preliminaryRiskScoreCode: string) {
    return (
      observation?.code?.coding?.[0]?.code ===
      preliminaryRiskScoreCode
    );
  },
  getSugestionCoding: (): Coding => {
    const coding = observation?.valueCodeableConcept?.coding?.[0];
    return {
      ...(coding ?? {}),
      ...(coding ? { display: coding.display ?? observation?.valueCodeableConcept?.text } : {}),
    };
  },

  getDisplayValueFromValueCodeableConcept() {
    const codeableConcept = observation?.valueCodeableConcept;

    if (!codeableConcept) {
      return '-';
    }

    if (codeableConcept.text) {
      return codeableConcept.text;
    }

    if (codeableConcept.coding) {
      const res = codeableConcept.coding.find((item) => item.display || item.code);

      if (res?.display) {
        return res?.display;
      } else if (res?.code) {
        return res.code;
      }
    }

    return '-';
  },
}));

export type WrappedObservation = ReturnType<typeof ObservationWrapper>;
