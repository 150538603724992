import {
  Box,
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import produce from 'immer';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import Iconify from 'src/components/Iconify';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import Phone from 'src/sections/crs/common/Phone';
import Email from 'src/sections/crs/common/Email';
import { usePatient, useValueSets } from 'src/@nicheaim/fhir-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider } from '../../../../../components/hook-form';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { PatientWrapper, WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import useLocales from 'src/hooks/useLocales';
import { ContactPoint } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { getTelecomsBySystem } from 'src/utils/fhir';
import { ContactDetailsPermissionProps } from 'src/sections/crs/types';
import { LoadingButton } from '@mui/lab';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { isTimeIncludedInDate } from 'src/utils/dates';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';

interface Telecom {
  use: string | undefined;
  value: string | undefined;
  system: string | undefined;
  period: { start: string | undefined; end: string | undefined } | undefined;
  areaCode: string | undefined;
}

type FormValue = {
  email: Telecom[] | undefined;
  phone: Telecom[] | undefined;
};

export type OnTelecomUpdate = (newTelecom: ContactPoint[]) => Promise<boolean>;

type EditModeProps = {
  open: boolean;
  onClose: VoidFunction;
  telecom: ContactPoint[];
  onTelecomUpdate: OnTelecomUpdate;
  emailPermissions: ContactDetailsPermissionProps;
  phonePermissions: ContactDetailsPermissionProps;
  isAllowedToEdit: boolean;
  valueSetsIds: string[];
};

export const EditMode = ({
  open,
  onClose,
  onTelecomUpdate,
  telecom,
  emailPermissions,
  phonePermissions,
  isAllowedToEdit,
  valueSetsIds
}: EditModeProps) => {
  const [isLoading, setisLoading] = useState(false);
  const { i18n } = useLocales();

  const [valueSets] = useValueSets({
    filter: {
      _id: valueSetsIds.join(),
    },
    map: ValueSetWrapper,
  });

  const [contactUseEmailVS, contactUsePhoneVS] = useMemo(() => {
    if (!valueSets?.length) return [];

    return valueSetsIds.map((valueSetId) => valueSets.find(({ id }) => id === valueSetId) ?? null);
  }, [valueSets]);

  const EventSchema = Yup.object().shape({
    email: Yup.array(
      Yup.object({
        value: Yup.string()
          .required('Email is required')
          .email('Email must be a valid email address'),
        use: Yup.string()
          .required('Use is required'),
        period: Yup.object().shape({
          start: Yup.date()
            .nullable()
            .transform((curr, orig) => (moment(orig).isValid() ? curr : null))
            .nullable(),
          end: Yup.date()
            .nullable()
            .transform((curr, orig) => (moment(orig).isValid() ? curr : null))
            .test('is-greater', 'End date must be greater than start date', function (value) {
              const startDate = moment(this.parent.start);
              const endDate = moment(value);
              const validation = startDate.isSameOrBefore(
                endDate,
                isTimeIncludedInDate(endDate) && isTimeIncludedInDate(startDate) ? undefined : 'date'
              );

              if(startDate.isValid() && endDate.isValid()){
                return validation;
              }
              return true;
            })
            .nullable(),
        })
      })
    ),
    phone: Yup.array(
      Yup.object({
        areaCode: Yup.string()
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .matches(/^[(]?[0-9]{3}[)]$/, 'Area Code must be 3 numbers')
          .when('value', (value, schema) => {
            if (value) return schema.required('Area Code is required');
          }),
        value: Yup.string()
          .required('Phone is required')
          .matches(/^[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Phone Number must be 7 numbers'),
        use: Yup.string()
          .required('Use is required'),
        period: Yup.object().shape({
          start: Yup.date()
            .nullable()
            .transform((curr, orig) => (moment(orig).isValid() ? curr : null))
            .nullable(),
          end: Yup.date()
            .nullable()
            .transform((curr, orig) => (moment(orig).isValid() ? curr : null))
            .test('is-greater', 'End date must be greater than start date', function (value) {
              const startDate = moment(this.parent.start);
              const endDate = moment(value);
              const validation = startDate.isSameOrBefore(
                endDate,
                isTimeIncludedInDate(endDate) && isTimeIncludedInDate(startDate) ? undefined : 'date'
              );

              if(startDate.isValid() && endDate.isValid()){
                return validation;
              }
              return true;
            })
            .nullable(),
        })
      })
    ),
  });

  const defaultValues = {
    email: getTelecomsBySystem(telecom, 'email'),
    phone: getTelecomsBySystem(telecom, 'phone').map((p) => {
      let valueClean: any = p?.value?.replace(/[^0-9]/g, '');
      const phoneModified = {
        ...p,
        areaCode: `(${valueClean?.substring(0, 3)})`,
        value: valueClean?.substring(3, 10),
      };
      return phoneModified;
    }),
  };

  const methods = useForm<FormValue>({
    resolver: yupResolver(EventSchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    reset,
    handleSubmit
  } = methods;

  const mapFormToPatientTelecom = (data: Telecom[] | undefined): any => {
    const telecom = data?.map((item, index) => ({
      rank: index + 1,
      use: item.use,
      system: item.system,
      ...(item.areaCode ? { value: `${item.areaCode} ${item.value}` } : { value: `${item.value}` }),
      ...((item.period?.start || item.period?.end) && {
        period: {
          ...(item.period?.start && { start: item.period?.start }),
          ...(item.period?.end && { end: item.period?.end }),
        },
      }),
    }));

    return telecom;
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const onSubmit = async (data: FormValue) => {
    setisLoading(true);
    const emails = mapFormToPatientTelecom(data.email);
    const phones = mapFormToPatientTelecom(data.phone);
    const wasUpdated = await onTelecomUpdate([...emails, ...phones]);
    setisLoading(false);
    if (!wasUpdated) reset(defaultValues);
    onClose();
  };

  const values = watch();

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) return;

    const list = source.droppableId === 'phone' ? values.phone : values.email;

    const items = reorder(list, result.source.index, result?.destination?.index);

    //@ts-ignore
    setValue(source.droppableId, items);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="lg">
      <DialogTitle>{i18n('patients.details.contactDetails.title', 'crs')}</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Grid item xs={6}>
                <Droppable droppableId="email">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <Email {...emailPermissions} valueSet={contactUseEmailVS} />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Grid>
              <Grid item xs={6}>
                <Droppable droppableId="phone">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <Phone {...phonePermissions} valueSet={contactUsePhoneVS}/>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Grid>
            </DragDropContext>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="contained" color="info" onClick={handleClose}>
                {i18n('cancel')}
              </Button>
              {isAllowedToEdit && (
                <LoadingButton
                  variant="contained"
                  color="info"
                  type="submit"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  {i18n('submit')}
                </LoadingButton>
              )}
            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
};

type DisplayModeProps = {
  patient: WrappedPatient;
  i18n: any;
};

const DisplayMode = ({ i18n, patient }: DisplayModeProps) => {
  const emails = patient.getEmails();
  const phones = patient.getPhoneNumbers();

  return (
    <Grid container spacing={3} sx={{ p: 1 }}>
      <Grid item xs={6}>
        <Typography variant="body2" sx={{ py: 1, color: 'text.secondary', fontWeight: 'bold' }}>
          {i18n('patients.email', 'crs')}
        </Typography>
        {emails.map((item) => (
          <Stack key={`em-${item.id}`} direction="row" sx={{ mb: 2 }}>
            <Typography variant="body2">{item.use}</Typography>

            <Box sx={{ flexGrow: 1, ml: 1 }}>
              <Typography variant="body2">{item.value}</Typography>
              <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                {item.period?.start && `${format(new Date(item.period?.start), 'MMM yyyy')} to `}
                {item.period?.end && format(new Date(item.period?.end), 'MMM yyyy')}
              </Typography>
            </Box>
          </Stack>
        ))}
      </Grid>

      <Grid item xs={6}>
        <Typography variant="body2" sx={{ py: 1, color: 'text.secondary', fontWeight: 'bold' }}>
          {i18n('patients.phone', 'crs')}
        </Typography>
        {phones.map((item) => (
          <Stack key={`ph-${item.id}`} direction="row" sx={{ mb: 2 }}>
            <Typography variant="body2">{item.use}</Typography>

            <Box sx={{ flexGrow: 1, ml: 1 }}>
              <Typography variant="body2">{item.value}</Typography>
              <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                {item.period?.start && `${format(new Date(item.period?.start), 'MMM yyyy')} to `}
                {item.period?.end && format(new Date(item.period?.end), 'MMM yyyy')}
              </Typography>
            </Box>
          </Stack>
        ))}
      </Grid>
    </Grid>
  );
};

type Props = {
  patient: WrappedPatient;
};

export default function ContactDetails({ patient }: Props) {
  const { i18n } = useLocales();

  const [editContact, setEditContact] = useState(false);

  const [, { update }] = usePatient(patient.id!, { map: PatientWrapper });

  const { enqueueSnackbar } = useSnackbar();

  const handleCloseEditContact = () => {
    setEditContact(false);
  };

  const handleTelecomUpdate: OnTelecomUpdate = async (telecom) => {
    try {
      await update(
        produce(patient!, (draft) => {
          draft.telecom = [...telecom];
        })
      );
      enqueueSnackbar('Patient was updated.');
      return true;
    } catch {
      enqueueSnackbar('Patient was not updated.', { variant: 'error' });
      return false;
    }
  };

  const isAllowedToAdd = checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CONTACT_DETAILS.ADD] });
  const isAllowedToDelete = checkAclValidation({
    acls: [crsAcls.CRS.PATIENT.CONTACT_DETAILS.DELETE],
  });

  return (
    <>
      <Stack direction="row" style={{ display: 'flex' }}>
        <Typography variant="button" sx={{ textTransform: 'uppercase', mt: 1 }}>
          {i18n('patients.details.contactDetails.title', 'crs')}
        </Typography>
        {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CONTACT_DETAILS.EDIT] }) && (
          <IconButton onClick={() => setEditContact(true)} sx={{ p: 0.5, ml: 1 }}>
            <Iconify icon={editContact ? 'eva:save-outline' : 'eva:edit-outline'} />
          </IconButton>
        )}
      </Stack>
      <DisplayMode i18n={i18n} patient={patient} />
      <EditMode
        emailPermissions={{
          isAllowedToAdd,
          isAllowedToDelete,
        }}
        phonePermissions={{
          isAllowedToAdd,
          isAllowedToDelete,
        }}
        isAllowedToEdit={checkAclValidation({
          acls: [crsAcls.CRS.PATIENT.CONTACT_DETAILS.EDIT],
        })}
        onTelecomUpdate={handleTelecomUpdate}
        telecom={patient?.telecom ?? []}
        open={editContact}
        onClose={handleCloseEditContact}
        valueSetsIds={['patient-contact-use-email','patient-contact-use-phone']}
      />
    </>
  );
}
