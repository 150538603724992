import {
  Box,
  Card,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import produce from 'immer';
import _, { get, isArray, isEmpty } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { CareTeamWrapper } from 'src/@nicheaim/fhir-base/wrappers/CareTeam';
import { ConsentWrapper, WrappedConsent } from 'src/@nicheaim/fhir-base/wrappers/Consent';
import { DocumentReferenceWrapper } from 'src/@nicheaim/fhir-base/wrappers/DocumentReference';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { PractitionerRoleWrapper } from 'src/@nicheaim/fhir-base/wrappers/PractitionerRole';
import { RelatedPersonWrapper } from 'src/@nicheaim/fhir-base/wrappers/RelatedPerson';
import { ServiceRequestWrapper } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { TaskWrapper } from 'src/@nicheaim/fhir-base/wrappers/Task';
import {
  useCareTeams,
  useConsents,
  useDocumentReferences,
  usePatient,
  usePractitionerRoles,
  useRelatedPersons,
  useServiceRequests,
  useTasks,
} from 'src/@nicheaim/fhir-react';
import { CareTeamMemberWithEditableData } from 'src/@types/crs/case';
import agent from 'src/api/agent';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import { TableCustom } from 'src/components/TableCustom';
import ExpandableCard from 'src/components/common/ExpandableCard';
import ComplementaryInfo from 'src/components/complementary-info/ComplementaryInfo';
import Workflow from 'src/components/workflow/Workflow';
import ButtonType from 'src/components/workflow/common/components/Button';
import CustomSelectType from 'src/components/workflow/common/components/CustomSelect';
import DispositionType from 'src/components/workflow/common/components/Disposition';
import EditType from 'src/components/workflow/common/components/Edit';
import Input from 'src/components/workflow/common/components/Input';
import SelectType from 'src/components/workflow/common/components/Select';
import { referralService } from 'src/crs/referral/services';
import { patientService } from 'src/engagement/patient';
import { PatientEngagementResponse } from 'src/engagement/patient/PatientService';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import useTenantConfigData from 'src/hooks/useTenantConfigData';
import { PATH_DASHBOARD } from 'src/routes/paths';
import CustomLink from 'src/sections/crs/common/CustomLink';
import GeneralPractitionerList from 'src/sections/crs/patient/components/GeneralPractitionerList';
import { RelatedPersons } from 'src/sections/crs/patient/components/PatientRelatedPersons';
import { DocumentList } from 'src/sections/crs/patient/components/patientDocuments/DocumentList';
import PatientCase from 'src/sections/crs/patient/components/patientReferralCases/patientCase';
import {
  PatientEngagementPermissions,
  ResourceWithIncludedResources,
} from 'src/sections/crs/types';
import { getRegistries } from 'src/services/api/registry';
import { formattedDateTimeZone } from 'src/utils/dates';
import { getValuesFromReference, setSILDisplay } from 'src/utils/fhir';
import { dateYearFormatWithAge } from 'src/utils/formatTime';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import { getParseColumn, mapDataAndDataSchemaWorkflowInstance } from 'src/utils/utilities';
import KeyValueGrid from '../../../components/KeyValueGrid';
import CreateTask from '../../nat/task/CreateTask';
import NotesList from '../common/notes/NotesLists';
import { TABLE_HEAD_CONTACT } from '../common/table-head';
import AssessmentFromChecklistData from '../intake/components/workflow-step/AssessmentFromChecklistData';
import ConsentInformation, {
  getSelectOptionCustomButton as getConsentOption,
} from 'src/sections/crs/workflow/components/step/ConsentInformation';
import ProviderInformation, {
  getSelectOptionCustomButton,
  PayloadProvider,
  workflowInstanceDataProvider,
} from '../intake/components/workflow-step/ProviderInformation';
import ReferralInformation, {
  getDisplay,
  getReferred,
} from '../intake/components/workflow-step/ReferralInformation';
import ConfirmCMOptInItem from './components/workflow-step/ConfirmCMOptInItem';
import ConfirmContact from './components/workflow-step/ConfirmContact';
import ConfirmGuarantorInfo from './components/workflow-step/ConfirmGuarantorInfo';
import ConfirmMemberInfo from './components/workflow-step/ConfirmMemberInfo';
import ConfirmNatOptIn from './components/workflow-step/ConfirmNatOptIn';
import TasksList from './components/workflow-step/ListTasks';
import ReviewNatReportItem from './components/workflow-step/ReviewNatReportItem';
import ScheduleAppointment from './components/workflow-step/ScheduleAppointment';
import { mapContactAttempts } from './map';
import { getConsents, handlerGenericStepWorkflow } from 'src/sections/crs/helpers/workflow';
import PatientEngagementInformation from './components/PatientEngagementInformation';

export default function PatientEngagementDetails() {
  const { id: patientEngagementId = null } = useParams();
  const user = useAuth();
  const userEmail = user?.getCurrentUser?.()?.email;
  const organization = useAuth().getCurrentUser().organization;
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const { i18n } = useLocales();
  const { componentsData, configurations } = useTenantConfigData();
  const { showOptInStatus } = componentsData ?? {};

  const engagementType = configurations?.engagementWorkflow === 'INTAKE_ENGAGEMENT' ? 'INTAKE' : 'ENGAGEMENT';

  const [patientId, setPatientId] = useState('');
  const [patientEngagement, setPatientEngagement] = useState<
    PatientEngagementResponse | undefined
  >();
  const [contactAttempts, setContactAttempts] = useState<any>([]);
  const [allUsers, setAllUsers] = useState([]);

  const [workflowStage, setWorkflowStage] = useState(undefined);
  const [workflowInstance, setWorkflowInstance] = useState<any>();
  const [checklistData, setChecklistData] = useState<any>([]);
  const [reject, setReject] = useState(false);
  const [disposition, setDisposition] = useState<any>(false);
  const [externalRefreshWorkFlow, setExternalRefreshWorkFlow] = useState(false);
  const [intakeOption, setIntakeOption] = useState<any>([]);
  const [consents, setConsents] = useState<ResourceWithIncludedResources<WrappedConsent>[]>([]);
  const [refreshConsents, setRefreshConsents] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [confirmOptInItem, setConfirmOptInItem] = useState<any>(false);
  const [reviewNatReportItem, setReviewNatReportItem] = useState<any>(false);
  const [showTasksList, setShowTasksList] = useState<boolean>(false);
  const [confirmNatOptInModalOpen, setConfirmNatOptInModalOpen] = useState(false);
  const [scheduleAppointmentModalOpen, setScheduleAppointmentModalOpen] = useState(false);
  const [confirmContactModalOpen, setConfirmContactModalOpen] = useState(false);
  const [createTask, setCreateTask] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmGuarantorModalOpen, setConfirmGuarantorModalOpen] = useState(false);

  const [referralInfoModalOpen, setReferralInfoModalOpen] = useState(false);
  const [consentsModalOpen, setConsentsModalOpen] = useState(false);
  const [provideInformationModalOpen, setProvideInformationModalOpen] = useState(false);
  const [screeningInterviewModalOpen, setScreeningInterviewModalOpen] = useState(false);
  const [chartReviewModalOpen, setChartReviewModalOpen] = useState(false);
  const [confirmScreeningResponsesModalOpen, setConfirmScreeningResponsesModalOpen] =
    useState(false);

  const [isOpenReAssign, setIsOpenReAssign] = useState(false);

  const [referredSR, setReferredSR] = useState('');

  const [relatedPersonsOpen, setRelatedPersonsOpen] = useState(false);

  useEffect(() => {
    if (patientEngagement) {
      const patientIdFhir = patientEngagement.patientFhirUUID;
      if (patientIdFhir) {
        setPatientId(patientIdFhir);
      }
    }
  }, [patientEngagement]);

  const [
    patient,
    { isFetching: isPatientFetching, refresh: refreshPatient, update: updatePatient },
  ] = usePatient(patientId, {
    map: PatientWrapper,
    autofetch: !!patientId,
  });

  const [tasks, { refresh: refreshTask }] = useTasks({
    filter: {
      patient: patient?.id,
      code: 'completed-nat-assessment',
    },
    autofetch: !!patient?.id,
    map: TaskWrapper,
  });

  const [consent] = useConsents({
    filter: {
      patient: patient?.id,
    },
    autofetch: !!patient?.id,
    map: ConsentWrapper,
  });

  const [
    relatedPersons,
    { create: createRelatedPerson, update: updateRelatedPerson, refresh: refreshRelatedPerson },
  ] = useRelatedPersons({
    filter: {
      patient: patient?.id,
    },
    autofetch: !!patient,
    map: RelatedPersonWrapper,
  });

  const [serviceRequest, { refresh: refreshServiceRequest }] = useServiceRequests({
    filter: {
      category: 'intake-referral',
      code: 'enroll-to-sbha-program',
      subject: patient?.id,
    },
    map: ServiceRequestWrapper,
    autofetch: !!patientId,
  });

  const [
    careTeams,
    { isFetching: isCareTeamFetching, update: updateCareTeams, refresh: refreshCareTeams },
  ] = useCareTeams({
    filter: {
      subject: patient?.id,
    },
    map: CareTeamWrapper,
    autofetch: !!patient,
  });

  const practitionerRolesId = useMemo(
    () =>
      patient?.generalPractitioner
        ?.filter(({ reference }) => reference?.includes('PractitionerRole'))
        .map(({ reference }) => {
          const [_, id] = getValuesFromReference(reference);
          return id;
        }),
    [patient]
  );

  const [
    practitionerRoles,
    { isFetching: isPractitionerRoleFetching, refresh: refreshPractitionerRoles },
  ] = usePractitionerRoles({
    filter: {
      _id: practitionerRolesId?.join(','),
      active: true,
    },
    map: PractitionerRoleWrapper,
    autofetch: !!practitionerRolesId?.length,
  });

  const filteredPractitionerRoles = useMemo(() => {
    if (
      practitionerRoles &&
      isArray(practitionerRoles) &&
      practitionerRoles.length > 0 &&
      practitionerRolesId &&
      isArray(practitionerRolesId) &&
      practitionerRolesId.length > 0 &&
      patient?.generalPractitioner &&
      isArray(patient?.generalPractitioner) &&
      patient?.generalPractitioner.length > 0
    ) {
      const filtered = practitionerRoles.filter((x) => x?.id && practitionerRolesId.includes(x.id));
      return filtered;
    }
    return [];
  }, [practitionerRoles, practitionerRolesId, patient]);

  const [documentReferences, { refresh: refreshDocumentReference }] = useDocumentReferences({
    filter: { subject: patient?.id },
    map: DocumentReferenceWrapper,
    autofetch: !!patient,
  });

  const getPatientEngagementDetails = async (id: any) => {
    try {
      const resp = await patientService.patientApiClient.getOne(id);
      setPatientEngagement(resp);
    } catch (e) {
      console.log(`Something failed get patient engagement details ${e}`);
    }
  };

  useEffect(() => {
    getPatientEngagementDetails(patientEngagementId);

    return () => {
      setPatientEngagement(undefined);
    };
  }, [patientEngagementId]);

  const fetchStageFromWorkflow = async () => {
    try {
      const result = await agent.Workflow.getInstance(
        patientEngagement?.patientEngagementId,
        engagementType
      );
      setWorkflowInstance(result);
      setWorkflowStage(result?.stage?.id);
    } catch (error) {
      console.log('error', error);
    }
  };

  const fetchContactAttempt = async (patientEngagementId: string) => {
    const result: any = await patientService.getContactAttempt(patientEngagementId);
    setContactAttempts(result);
  };

  const fetchAllUsers = async () => {
    if (!workflowInstance || !patient) {
      console.log('fetchAllUsers->waiting for workflow instance to fetch users');
      return;
    }
    try {
      //get allowed roles from registry for the specific patient engagement workflow
      console.log('fetchAllUsers->workflow scope', workflowInstance.scope);
      const roles = (
        componentsData?.workflow?.patientEngagement[workflowInstance.scope]
          ?.ownerAllowedRoleCodes || ''
      ).split(',');
      //get all usrs from the organization of the patient with the specific roles.
      const managingOrgId = patient.managingOrganization?.reference?.split('/')[1];
      const getAllUsers = await agent.User.getUsersByOrgAndRole(
        managingOrgId ? [managingOrgId || ''] : [],
        roles
      );
      setAllUsers(
        getAllUsers
          .sort((_a: any, _b: any) => {
            const a = (_a.firstName + ' ' + _a.lastName).toLowerCase(),
              b = (_b.firstName + ' ' + _b.lastName).toLowerCase();

            if (a < b) return -1;

            if (a > b) return 1;

            return 0;
          })
          .filter((e: any) => e.status === 'Active')
      );
    } catch (err) {
      console.log('error on fetching all users', err);
    }
  };

  useEffect(() => {
    fetchStageFromWorkflow();
    fetchContactAttempt(patientEngagement?.patientEngagementId || '');
  }, [patientEngagement, engagementType]);

  useEffect(() => {
    fetchAllUsers();
  }, [workflowInstance]);

  useEffect(() => {
    const getReferredFromServiceRequest = async () => {
      const { referred } = await getReferred(serviceRequest[0]);
      const stringReferred = referred ? getDisplay(referred) : '';
      setReferredSR(stringReferred);
    };

    getReferredFromServiceRequest();
  }, [serviceRequest]);

  const referralInfoItemData = useMemo(() => {
    return [
      {
        title: i18n('patientEngagement.details.workflow.workflowHandler.referred', 'engagement'),
        value: referredSR,
      },
      {
        title: i18n('patientEngagement.details.workflow.workflowHandler.cause', 'engagement'),
        value: serviceRequest?.[0]?.reasonCode?.[0]?.coding?.[0]?.display,
      },
    ];
  }, [referredSR]);

  const getIntakeOption = async () => {
    setIsLoading(true);
    const { data } = await getRegistries('intake', 'intake-option');
    const dataKeyValue = data?.[0]?.keyValue ? JSON.parse(data?.[0]?.keyValue) : null;
    setIntakeOption(dataKeyValue);
    setIsLoading(false);
  };

  useEffect(() => {
    getIntakeOption();
  }, []);

  const relatedProviders = useMemo(() => {
    const combinedResources: CareTeamMemberWithEditableData[] = [
      ...(careTeams
        ? careTeams.map((careTeam) => ({
            id: careTeam.id ?? '',
            associatedOrg: 'N/A',
            associatedOrgName: '',
            associatedOrgId: null,
            role: '',
            roleName: 'Care Team',
            roleId: 'care-team',
            name: careTeam.name ?? '',
            identifier: '',
            identifierType: '',
            memberType: careTeam.resourceType,
            startDate: '',
            endDate: '',
            startDateObj: null,
            endDateObj: null,
            versionId: careTeam.meta?.versionId ?? '',
          }))
        : []),
      ...(filteredPractitionerRoles
        ? filteredPractitionerRoles.map((practitionerRole) => ({
            id: practitionerRole.id ?? '',
            associatedOrg: '',
            associatedOrgName: '',
            associatedOrgId: null,
            role: practitionerRole?.code?.[0]?.coding?.[0]?.display ?? '',
            roleName: practitionerRole?.code?.[0]?.coding?.[0]?.display ?? '',
            roleId: practitionerRole?.code?.[0]?.coding?.[0]?.code ?? '',
            name: practitionerRole?.practitioner?.display ?? '',
            identifier: '',
            identifierType: '',
            memberType: practitionerRole.resourceType,
            startDate: '',
            endDate: '',
            startDateObj: null,
            endDateObj: null,
            versionId: practitionerRole.meta?.versionId ?? '',
          }))
        : []),
    ];
    return combinedResources;
  }, [careTeams, filteredPractitionerRoles]);

  useEffect(() => {
    const getConsentsAsync = async () => {
      const consents = await getConsents(patient);
      setConsents(consents);
      setRefreshConsents(false);
    };
    getConsentsAsync();
  }, [patient,refreshConsents]);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms || 300));
  }

  async function resetExternalRefreshWorkflow() {
    await sleep(1000);
    setExternalRefreshWorkFlow(false);
  }

  useEffect(() => {
    if (externalRefreshWorkFlow) {
      resetExternalRefreshWorkflow();
    }
  }, [externalRefreshWorkFlow]);

  const engagementWorkflowData = useMemo(
    () => ({
      id: 1,
      recordId: patientEngagement?.patientEngagementId || '',
      recordReference: engagementType,
      stage: {
        id: workflowStage,
      },
      referralRecord: patientEngagement,
      workflowInstance,
    }),
    [workflowStage, workflowInstance]
  );

  const WorkflowComponentMapping: any = {
    Button: ButtonType,
    CustomSelect: CustomSelectType,
    Disposition: DispositionType,
    Edit: EditType,
    Input,
    Select: SelectType,
  };

  const WorkflowData: any = {
    disposition,
    reject,
  };

  const handlerEngage = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setConfirmContactModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmMemberInfo = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setConfirmModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationGuarantorInfo = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setConfirmGuarantorModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationNatOptIn = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setConfirmNatOptInModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationScheduleAppointment = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setScheduleAppointmentModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationReviewNat = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setReviewNatReportItem(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationCmOptIn = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setConfirmOptInItem(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationInitiateNatRequest = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setCreateTask(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmCompleteNatInterview = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setShowTasksList(true);
    setChecklistData(checklistItem);
  };

  const providerInfoItemData = useMemo(() => {
    const { providers } = intakeOption;
    const workflowInstanceDataProviders = workflowInstanceDataProvider(
      getParseColumn(workflowInstance, 'data'),
      'notApplicable'
    );

    const data = getSelectOptionCustomButton(relatedProviders ?? [], providers ?? []);

    return data?.map((e) => {
      const workflowProvider = workflowInstanceDataProviders?.find(
        (provider) => provider.role === e.role
      );

      return {
        title: e?.sub,
        value: workflowProvider ? workflowProvider.title : e?.title ?? '',
      };
    });
  }, [relatedProviders, intakeOption, workflowInstance]);

  const consentInfoItemData = useMemo(() => {
    const { consents: consentRegistry } = intakeOption;
    const data = getConsentOption(consents, consentRegistry);
    return data?.map((e) => ({
      title: e?.title,
      value: e?.sub,
    }));
  }, [consents, intakeOption]);

  const handlerAssign = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    const payloadForUpdateWorkflowinstance = {
      item: checklistItem,
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
      value: more.selectedUser,
      attribute_name: 'ownedBy',
    };

    try {
      const response = await agent.Record.updateRecordByAttribute(payloadForUpdateWorkflowinstance);

      const payloadForSetItemCompleted = {
        item: checklistItem,
        record: {
          ...response,
        },
      };

      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      refreshChecklistHandler();
    } catch (err) {
      enqueueSnackbar('An error has occurred', { variant: 'error' });
      console.log('error on trying to update patient engagement record');
    } finally {
      setIsOpenReAssign(false);
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }
    setExternalRefreshWorkFlow(false);
  };

  const handleReAssingOwned = async (ownedSelected: any) => {
    try {
      const payload = {
        record: {
          ...workflowInstance,
          assigned: {
            selectedUser: ownedSelected?.more?.selectedUser,
          },
        },
      };

      const result = await agent.Workflow.reAssignOwner(payload);
      if (result) {
        enqueueSnackbar('User was assigned');
      }
    } catch (err) {
      enqueueSnackbar('An error has occurred', { variant: 'error' });
      console.log('error on handleReAssingOwned');
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }
    setIsOpenReAssign(false);
    setExternalRefreshWorkFlow(false);
  };

  const handlerConfirmGeneric = async (data?: any) => {
    const modifiedFields = {
      ...data
    };

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType
    };

    try {
      await handlerGenericStepWorkflow(
        workflowInstance,
        patientEngagement,
        payloadForUpdateWorkflowinstance,
        checklistData,
        userEmail,
        modifiedFields
      )
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }
    setExternalRefreshWorkFlow(false);
  };

  const handlerConfirmNATOptIn = async (data: any) => {
    const modifiedFields = {
      opt_out_decision: data.opt_out_decision,
      method: data.method,
    };

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType
    };

    try {
      await handlerGenericStepWorkflow(
        workflowInstance,
        patientEngagement,
        payloadForUpdateWorkflowinstance,
        checklistData,
        userEmail,
        modifiedFields
      )
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }
    setExternalRefreshWorkFlow(false);
  };

  const handlerConfirmGuarantor = async (data: any) => {
    try {
      let result;
      if (!data?.skip) {
        if (data?.id) {
          result = await updateRelatedPerson(data);
        } else {
          result = await createRelatedPerson(data);
        }
      }

      const modifiedFields = {
        fhirReference: `${result?.[0]?.resourceType}/${result?.[0]?.id}${
          result?.[0].meta?.versionId ? `|${result?.[0].meta?.versionId}` : ''
        }`,
        source: 'workflow',
      };

      const payloadForUpdateWorkflowinstance = {
        referenceId: patientEngagement?.patientEngagementId,
        referenceName: engagementType
      };

      await handlerGenericStepWorkflow(
        workflowInstance,
        patientEngagement,
        payloadForUpdateWorkflowinstance,
        checklistData,
        userEmail,
        modifiedFields
      )
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
    refreshRelatedPerson();
  };

  const handlerConfirmScheduleAppointment = async (data: any) => {
    const modifiedFields = {
      date_hour: data.date_hour && new Date(data.date_hour).toISOString(),
      location: data.location,
    };

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType
    };

    try {
      await handlerGenericStepWorkflow(
        workflowInstance,
        patientEngagement,
        payloadForUpdateWorkflowinstance,
        checklistData,
        userEmail,
        modifiedFields
      )
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
  };

  const handlerConfirmContactAttempt = async (data: any) => {
    let resultContactAttempt: any = {};

    const workflowOutcomeDisposition: string | undefined =
      configurations?.workflowOutcomeDisposition;
    const outcomeDisposition: string[] = workflowOutcomeDisposition
      ? workflowOutcomeDisposition.split(',')
      : [];

    if (outcomeDisposition?.find((item: string) => item === data?.outcome)) {
      setReject(true);
      return;
    }

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType
    };

    try {
      resultContactAttempt = await patientService.createContactAttempt(data);
      if (!_.isEmpty(resultContactAttempt)) {

        const workflowInstanceData = getParseColumn(workflowInstance, 'data');
        const workflowInstanceDataStep =
          workflowInstanceData && Object.keys(workflowInstanceData).length > 0
          ? get(workflowInstanceData, 
            'scope.checklistItem.CONTACT_INTAKE_ITEM.modifiedFields.contacts', '')
          : '';
        const workflowDataScope = !isEmpty(workflowInstanceDataStep) ? 
          workflowInstanceDataStep : [];

        workflowDataScope.push({
          outcome: resultContactAttempt?.outcome,
          contactOn: resultContactAttempt?.contactOn,
          nextContactOn: resultContactAttempt?.nextContactOn,
          reasonNote: data?.reasonNote ?? ""
        });

        const modifiedFields = {
          contacts: workflowDataScope
        };
        
        await handlerGenericStepWorkflow(
          workflowInstance,
          patientEngagement,
          payloadForUpdateWorkflowinstance,
          checklistData,
          userEmail,
          modifiedFields
        )
        fetchContactAttempt(patientEngagement?.patientEngagementId || '');
      }
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);

    return resultContactAttempt;
  };

  const handlerInitiateHJRequest = async (data: any) => {
    const additionalParams = (patient?.id && data) ? 
      { patientId: patient.id, orderId: data } : null;

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType
    };

    try {
      if (!_.isEmpty(data)) {
        await handlerGenericStepWorkflow(
          workflowInstance,
          patientEngagement,
          payloadForUpdateWorkflowinstance,
          checklistData,
          userEmail,
          null,
          additionalParams
        )
      }
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
    refreshTask();
  };

  const handlerCompleteHJRequest = async () => {
    setExternalRefreshWorkFlow(true);
    getPatientEngagementDetails(patientEngagementId);
    setExternalRefreshWorkFlow(false);
  };

  const handlerConfirmCMOptIN = async (data: any) => {
    const modifiedFields = {
      opt_out_decision: data.opt_out_decision,
      type: data.type,
      notes: data.notes,
    };

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType
    };

    try {
      await handlerGenericStepWorkflow(
        workflowInstance,
        patientEngagement,
        payloadForUpdateWorkflowinstance,
        checklistData,
        userEmail,
        modifiedFields
      );
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
  };

  const handlerComplementaryInfo = async (store: any) => {
    const payloadForUpdateWorkflowinstance = {
      referenceId: workflowInstance?.referenceId,
      referenceName: workflowInstance?.referenceName,
      data: JSON.stringify({
        scope: {
          checklistItem: {
            ...store,
          },
        },
      }),
    };

    try {
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error on handleComplementaryInfo', err);
    } finally {
      setExternalRefreshWorkFlow(true);
    }

    setExternalRefreshWorkFlow(false);
  };

  const handlerReferralInfo = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setReferralInfoModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConsents = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    itemCode,
    more,
  }: any) => {
    setConsentsModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerProviderInformation = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setProvideInformationModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerScreeningInterview = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setScreeningInterviewModalOpen(true);
  };

  const handlerChartReview = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setChartReviewModalOpen(true);
  };

  const handlerConfirmScreeningResponses = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setConfirmScreeningResponsesModalOpen(true);
  };

  const handlerReferralInformation = async (data: any) => {
    setIsLoading(true);
    try {
      const referral = await referralService.createChildReferral(data);

      const modifiedFields = {
        referral: {
          fhirReference: `${referral.resourceType}/${referral?.id}${
            referral?.meta?.versionId ? `|${referral?.meta?.versionId}` : ''
          }`,
          createdOn: moment().toISOString(),
          createdBy: userEmail,
          source: 'workflow-referral',
        },
      };

      const additionalParams = patient?.id ? { patientId: patient?.id } : null;

      const payloadForUpdateWorkflowinstance = {
        referenceId: patientEngagement?.patientEngagementId,
        referenceName: engagementType
      };

      await handlerGenericStepWorkflow(
        workflowInstance,
        patientEngagement,
        payloadForUpdateWorkflowinstance,
        checklistData,
        userEmail,
        modifiedFields,
        additionalParams
      )
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
      setIsLoading(false);
    }
    setExternalRefreshWorkFlow(false);
    refreshServiceRequest();
  };

  const handlerProvidersInformation = async (data: PayloadProvider) => {
    setIsLoading(true);
    try {
      const { generalPractitioner, addCareTeam, removeCareTeam, workflowDataScope } = data;
      if (generalPractitioner && generalPractitioner.length > 0) {
        await updatePatient(
          produce(patient!, (draft) => {
            draft.generalPractitioner = generalPractitioner.map((e) => ({ reference: e }));
          })
        );
      }

      if (addCareTeam || removeCareTeam) {
        const careTeamsArray = [...addCareTeam, ...removeCareTeam];
        const wrapper = careTeamsArray?.map((e) => ({
          resourceType: e?.resourceType,
          id: e?.id,
          category: e?.category,
          encounter: e?.encounter,
          identifier: e?.identifier,
          managingOrganization: e?.managingOrganization,
          name: e?.name,
          note: e?.note,
          participant: e?.participant,
          period: e?.period,
          reasonCode: e?.reasonCode,
          reasonReference: e?.reasonReference,
          status: e?.status,
          subject: e?.subject,
          telecom: e?.telecom,
        }));
        await updateCareTeams(wrapper);
      }

      const modifiedFields = {
        providers: workflowDataScope,
      };

      const additionalParams = patient ? { patientId: patient?.id } : null;

      const payloadForUpdateWorkflowinstance = {
        referenceId: patientEngagement?.patientEngagementId,
        referenceName: engagementType
      };

      await handlerGenericStepWorkflow(
        workflowInstance,
        patientEngagement,
        payloadForUpdateWorkflowinstance,
        checklistData,
        userEmail,
        modifiedFields,
        additionalParams
      )
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
      setIsLoading(false);
    }
    setExternalRefreshWorkFlow(false);
    refreshCareTeams();
    refreshPractitionerRoles();
    refreshPatient();
  };

  const handlerConsentInformation = async (data: any) => {
    setRefreshConsents(true);

    const modifiedFields = {
      consents: data,
    };

    const additionalParams = patient ? { patientId: patient?.id } : null;

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType
    };

    try {
      await handlerGenericStepWorkflow(
        workflowInstance,
        patientEngagement,
        payloadForUpdateWorkflowinstance,
        checklistData,
        userEmail,
        modifiedFields,
        additionalParams
      )
    } catch (err) {
      console.log('error', err);
      setRefreshConsents(false);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }
    setExternalRefreshWorkFlow(false);
  };

  const WorkflowHandler: any = {
    ASSIGN_OWNER_INITAL_PATIENT_ITEM: {
      type: 'payload',
      handler: handlerAssign,
      data: allUsers,
    },
    CONTACT_INITIAL_PATIENT_ITEM: {
      type: 'payload',
      handler: handlerEngage,
      data: true,
    },
    CONFIRM_MEMBER_INFO_INITIAL_PATIENT_ITEM: {
      type: 'confirm',
      handler: handlerConfirmMemberInfo,
      data: true,
    },
    CONFIRM_GUARANTOR_INFO_INITIAL_PATIENT_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationGuarantorInfo,
      data: true,
    },
    CONFIRM_NAT_OPTIN_INITIAL_PATIENT_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationNatOptIn,
      data: true,
    },
    SCHEDULE_NAT_SESSION_INITIAL_PATIENT_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationScheduleAppointment,
      data: true,
    },
    REVIEW_NAT_REPORT_INITIAL_PATIENT_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationReviewNat,
      data: true,
    },
    CONFIRM_CM_OPTIN_INITIAL_PATIENT_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationCmOptIn,
      data: true,
    },
    INITIATE_NAT_REQUEST_INITIAL_PATIENT_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationInitiateNatRequest,
      data: true,
    },
    COMPLETE_NAT_INTERVIEW_INITIAL_PATIENT_ITEM: {
      type: 'confirm',
      handler: handlerConfirmCompleteNatInterview,
      data: true,
    },
    ASSIGN_OWNER_INTAKE_ITEM: {
      type: 'payload',
      handler: handlerAssign,
      data: allUsers,
    },
    REFERRAL_INFORMATION_INTAKE_ITEM: {
      type: 'payload',
      handler: handlerReferralInfo,
      data: serviceRequest ? referralInfoItemData : true,
    },
    CONFIRM_MEMBER_INFO_INTAKE_ITEM: {
      type: 'confirm',
      handler: handlerConfirmMemberInfo,
      data: true,
    },
    CONFIRM_GUARANTOR_INFO_INTAKE_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationGuarantorInfo,
      data: true,
    },
    CONSENT_INFORMATION_INTAKE_ITEM: {
      type: 'payload',
      handler: handlerConsents,
      data: consentInfoItemData ? consentInfoItemData : true,
    },
    PROVIDER_INFORMATION_INTAKE_ITEM: {
      type: 'payload',
      handler: handlerProviderInformation,
      data: providerInfoItemData ? providerInfoItemData : true,
    },
    SCHEDULE_NAT_SESSION_INTAKE_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationScheduleAppointment,
      data: true,
    },
    SCREENING_INTERVIEW_INTAKE_ITEM: {
      type: 'payload',
      handler: handlerScreeningInterview,
      data: true,
    },
    CHART_REVIEW_INTAKE_ITEM: {
      type: 'payload',
      handler: handlerChartReview,
      data: true,
    },
    CONTACT_INTAKE_ITEM: {
      type: 'payload',
      handler: handlerEngage,
      data: true,
    },
    CONFIRM_SCREENING_RESPONSES_INTAKE_ITEM: {
      type: 'payload',
      handler: handlerConfirmScreeningResponses,
      data: true,
    },
    setDisposition: {
      type: 'confirm',
      handler: () => setDisposition(true),
      data: false,
    },
    setReject: {
      type: 'confirm',
      handler: (open: boolean = true) => setReject(open),
      data: false,
    },
  };

  const defaultStructure = useMemo(() => {
    const checklistItem = getParseColumn(workflowInstance, 'data')?.scope?.checklistItem;

    return {
      ...(checklistItem?.ASSIGN_OWNER_INTAKE_ITEM && {
        ASSIGN_OWNER_INTAKE_ITEM: {
          ...(checklistItem?.ASSIGN_OWNER_INTAKE_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(
              checklistItem?.ASSIGN_OWNER_INTAKE_ITEM?.validatedOn
            ),
          }),
        },
      }),
      ...(checklistItem?.CONFIRM_MEMBER_INFO_ITEM && {
        CONFIRM_MEMBER_INFO_ITEM: {
          ...(checklistItem?.CONFIRM_MEMBER_INFO_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(
              checklistItem?.CONFIRM_MEMBER_INFO_ITEM?.validatedOn
            ),
          }),
        },
      }),
      ...(checklistItem?.CONFIRM_NAT_OPTIN_ITEM && {
        CONFIRM_NAT_OPTIN_ITEM: {
          ...(checklistItem?.CONFIRM_NAT_OPTIN_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(checklistItem?.CONFIRM_NAT_OPTIN_ITEM?.validatedOn),
          }),
        },
      }),
      ...(checklistItem?.CONFIRM_GUARANTOR_INFO_ITEM && {
        CONFIRM_GUARANTOR_INFO_ITEM: {
          ...(checklistItem?.CONFIRM_GUARANTOR_INFO_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(
              checklistItem?.CONFIRM_GUARANTOR_INFO_ITEM?.validatedOn
            ),
          }),
        },
      }),
      ...(checklistItem?.SCHEDULE_NAT_SESSION_ITEM && {
        SCHEDULE_NAT_SESSION_ITEM: {
          ...(checklistItem?.SCHEDULE_NAT_SESSION_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(
              checklistItem?.SCHEDULE_NAT_SESSION_ITEM?.validatedOn
            ),
          }),
          modifiedFields: {
            ...(checklistItem?.SCHEDULE_NAT_SESSION_ITEM?.modifiedFields?.date_hour && {
              date_hour: formattedDateTimeZone(
                checklistItem?.SCHEDULE_NAT_SESSION_ITEM?.modifiedFields?.date_hour
              ),
            }),
          },
        },
      }),
      ...(checklistItem?.INITIATE_NAT_REQUEST_ITEM && {
        INITIATE_NAT_REQUEST_ITEM: {
          ...(checklistItem?.INITIATE_NAT_REQUEST_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(
              checklistItem?.INITIATE_NAT_REQUEST_ITEM?.validatedOn
            ),
          }),
        },
      }),
      ...(checklistItem?.REVIEW_NAT_REPORT_ITEM && {
        REVIEW_NAT_REPORT_ITEM: {
          ...(checklistItem?.REVIEW_NAT_REPORT_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(checklistItem?.REVIEW_NAT_REPORT_ITEM?.validatedOn),
          }),
        },
      }),
      ...(checklistItem?.CONFIRM_CM_OPTIN_ITEM && {
        CONFIRM_CM_OPTIN_ITEM: {
          ...(checklistItem?.CONFIRM_CM_OPTIN_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(checklistItem?.CONFIRM_CM_OPTIN_ITEM?.validatedOn),
          }),
        },
      }),
      ...(checklistItem?.REFERRAL_INFORMATION_INTAKE_ITEM && {
        REFERRAL_INFORMATION_INTAKE_ITEM: {
          ...(checklistItem?.REFERRAL_INFORMATION_INTAKE_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(
              checklistItem?.REFERRAL_INFORMATION_INTAKE_ITEM?.validatedOn
            ),
          }),
        },
      }),
      ...(checklistItem?.CONFIRM_MEMBER_INFO_INTAKE_ITEM && {
        CONFIRM_MEMBER_INFO_INTAKE_ITEM: {
          ...(checklistItem?.CONFIRM_MEMBER_INFO_INTAKE_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(
              checklistItem?.CONFIRM_MEMBER_INFO_INTAKE_ITEM?.validatedOn
            ),
          }),
        },
      }),
      ...(checklistItem?.CONFIRM_GUARANTOR_INFO_INTAKE_ITEM && {
        CONFIRM_GUARANTOR_INFO_INTAKE_ITEM: {
          ...(checklistItem?.CONFIRM_GUARANTOR_INFO_INTAKE_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(
              checklistItem?.CONFIRM_GUARANTOR_INFO_INTAKE_ITEM?.validatedOn
            ),
          }),
        },
      }),
      ...(checklistItem?.CONSENT_INFORMATION_INTAKE_ITEM && {
        CONSENT_INFORMATION_INTAKE_ITEM: {
          ...(checklistItem?.CONSENT_INFORMATION_INTAKE_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(
              checklistItem?.CONSENT_INFORMATION_INTAKE_ITEM?.validatedOn
            ),
          }),
        },
      }),
      ...(checklistItem?.SCHEDULE_NAT_SESSION_INTAKE_ITEM && {
        SCHEDULE_NAT_SESSION_INTAKE_ITEM: {
          ...(checklistItem?.SCHEDULE_NAT_SESSION_INTAKE_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(
              checklistItem?.SCHEDULE_NAT_SESSION_INTAKE_ITEM?.validatedOn
            ),
          }),
        },
      }),
      ...(checklistItem?.PROVIDER_INFORMATION_INTAKE_ITEM && {
        PROVIDER_INFORMATION_INTAKE_ITEM: {
          ...(checklistItem?.PROVIDER_INFORMATION_INTAKE_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(
              checklistItem?.PROVIDER_INFORMATION_INTAKE_ITEM?.validatedOn
            ),
          }),
        },
      }),
      ...(checklistItem?.SCREENING_INTERVIEW_INTAKE_ITEM && {
        SCREENING_INTERVIEW_INTAKE_ITEM: {
          ...(checklistItem?.SCREENING_INTERVIEW_INTAKE_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(
              checklistItem?.SCREENING_INTERVIEW_INTAKE_ITEM?.validatedOn
            ),
          }),
        },
      }),
      ...(checklistItem?.CHART_REVIEW_ITEM && {
        SCREENING_INTERVIEW_ITEM: {
          ...(checklistItem?.SCREENING_INTERVIEW_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(checklistItem?.SCREENING_INTERVIEW_ITEM?.validatedOn),
          }),
        },
      }),
      ...(checklistItem?.CONFIRM_SCREENING_RESPONSES_INTAKE_ITEM && {
        CONFIRM_SCREENING_RESPONSES_INTAKE_ITEM: {
          ...(checklistItem?.CONFIRM_SCREENING_RESPONSES_INTAKE_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(
              checklistItem?.CONFIRM_SCREENING_RESPONSES_INTAKE_ITEM?.validatedOn
            ),
          }),
        },
      }),
      ...(checklistItem?.CONTACT_INTAKE_ITEM && {
        CONTACT_INTAKE_ITEM: {
          ...(checklistItem?.CONTACT_INTAKE_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(checklistItem?.CONTACT_INTAKE_ITEM?.validatedOn),
          }),
        },
      })
    };
  }, [workflowInstance]);

  const patientEngagementPermissions: PatientEngagementPermissions = useMemo(
    () => ({
      isAllowedToEdit: checkAclValidation({
        acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.EDIT],
      }),
      notes: {
        isAllowedToAdd: checkAclValidation({
          acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.NOTES.ADD],
        }),
        isAllowedToView: checkAclValidation({
          acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.VIEW],
        }),
      },
    }),
    []
  );

  const isAllLoading =
    isPatientFetching ||
    isCareTeamFetching ||
    isPractitionerRoleFetching ||
    isLoading ||
    externalRefreshWorkFlow ||
    refreshConsents;

  return (
    <Page title={i18n('patientEngagement.details.title', 'engagement')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          title={i18n('patientEngagement.details.title', 'engagement')}
          heading=""
          links={[
            { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
            { name: `${i18n('title', 'engagement')}` },
            {
              name: `${i18n('patientEngagement.title', 'engagement')}`,
              href: PATH_DASHBOARD.engagementHub.patientEngagement,
            },
            { name: `${i18n('patientEngagement.details.title', 'engagement')}` },
          ]}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Workflow
              data={engagementWorkflowData}
              refreshRecord={fetchStageFromWorkflow}
              initialWorkflow={configurations?.engagementWorkflow}
              workflowHandler={WorkflowHandler}
              workflowData={WorkflowData}
              componentMapping={WorkflowComponentMapping}
              refreshWorkFlowExternal={externalRefreshWorkFlow}
              i18Path="patientEngagement.details.workflow"
              namespace="engagement"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <ExpandableCard title={`${i18n('patients.title', 'crs')} Engagement`}>
              <PatientEngagementInformation 
                workflowInstance={workflowInstance}
                patientLanguage={patientEngagement?.patientLanguage}
                patientEngagementPermissions={patientEngagementPermissions}
                onOpenAssigned={() => setIsOpenReAssign(true)}
                showOptInStatus={showOptInStatus}
                tasks={tasks}
                consent={consent}
              />
            </ExpandableCard>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <ExpandableCard title={i18n('patients.title', 'crs')}>
              <Box sx={{ px: 3, pb: 2 }}>
                {patient?.getMRN(configurations?.mpi?.systemMrn)?.value && (
                  <KeyValueGrid
                    label="MRN"
                    value=""
                    children={
                      <CustomLink
                        to={PATH_DASHBOARD.crs.patient.details.forId(patient?.id!)}
                        target="_blank"
                      >
                        <Typography noWrap variant="subtitle2">
                          {patient?.getMRN(configurations?.mpi?.systemMrn)?.value}
                        </Typography>
                      </CustomLink>
                    }
                  />
                )}
                <KeyValueGrid label="Given Name" value={patientEngagement?.patientGiven || ''} />
                <KeyValueGrid label="Family Name" value={patientEngagement?.patientFamily || ''} />
                <KeyValueGrid
                  label="DOB"
                  value={
                    (patientEngagement?.patientDOB &&
                      dateYearFormatWithAge(patientEngagement?.patientDOB)) ||
                    ''
                  }
                />
                {!_.isEmpty(patientEngagement?.patientLastSIL) && (
                  <KeyValueGrid
                    label="SIL"
                    value={
                      patientEngagement?.patientLastSIL
                        ? setSILDisplay(patientEngagement?.patientLastSIL)
                        : ''
                    }
                  />
                )}
              </Box>
            </ExpandableCard>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <ExpandableCard title="Complementary Info">
              <ComplementaryInfo
                dataSchema={getParseColumn(workflowInstance, 'dataSchema')}
                data={getParseColumn(workflowInstance, 'data')}
                defaultStructure={defaultStructure}
                handleComplementaryInfo={handlerComplementaryInfo}
                isAllowedToEdit={patientEngagementPermissions.isAllowedToEdit}
              />
            </ExpandableCard>
          </Grid>

          <Grid item xs={12}>
            <ExpandableCard title={i18n('patients.details.cases.title', 'crs')}>
              <PatientCase patientId={patientId} disableActions />
            </ExpandableCard>
          </Grid>

          <Grid item xs={12}>
            <ExpandableCard title="Contact Attempts">
              <TableCustom
                data={Array.isArray(contactAttempts) && mapContactAttempts(contactAttempts)}
                tableHead={TABLE_HEAD_CONTACT}
              />
            </ExpandableCard>
          </Grid>

          {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.GENERAL_PRACTITIONER.VIEW] }) && (
            <Grid item xs={12}>
              <ExpandableCard title={i18n('patients.generalPractitioners.title', 'crs')}>
                <GeneralPractitionerList patient={patient} />
              </ExpandableCard>
            </Grid>
          )}

          {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.RELATED_PERSONS.VIEW] }) && (
            <Grid item xs={12}>
              <ExpandableCard title={i18n('patients.details.relatedPersons.title', 'crs')}>
                <RelatedPersons
                  patient={patient}
                  open={relatedPersonsOpen}
                  setOpen={setRelatedPersonsOpen}
                />
              </ExpandableCard>
            </Grid>
          )}

          {patientEngagementPermissions?.notes?.isAllowedToView && (
            <Grid item xs={12}>
              <ExpandableCard title="Notes">
                {patientEngagementId && (
                  <NotesList
                    engagementWorkflowData={engagementWorkflowData}
                    isAllowedToAdd={patientEngagementPermissions?.notes?.isAllowedToAdd}
                  />
                )}
              </ExpandableCard>
            </Grid>
          )}

          {checkAclValidation({
            acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.ATTACHMENTS.VIEW],
          }) && (
            <Grid item xs={12}>
              <ExpandableCard title={i18n('patients.details.attachments.title', 'crs')}>
                <Card style={{ boxShadow: 'none', position: 'static' }}>
                  <DocumentList
                    permissions={{
                      isAllowedToAdd: checkAclValidation({
                        acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.ATTACHMENTS.ADD],
                      }),
                      isAllowedToEdit: checkAclValidation({
                        acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.ATTACHMENTS.EDIT],
                      }),
                    }}
                    patient={patient}
                    documentReferences={documentReferences}
                    refreshDocumentReference={refreshDocumentReference}
                    openCollapseExternal={true}
                    handleUpdateResource={null}
                    showFilters={true}
                  />
                </Card>
              </ExpandableCard>
            </Grid>
          )}

          <ConfirmMemberInfo
            isOpen={confirmModalOpen}
            handlerIsOpen={setConfirmModalOpen}
            patient={patient}
            handlePatient={updatePatient}
            handleConfirmMember={handlerConfirmGeneric}
            refreshPatient={refreshPatient}
          />

          <ConfirmGuarantorInfo
            patient={patient}
            isOpen={confirmGuarantorModalOpen}
            handlerIsOpen={setConfirmGuarantorModalOpen}
            relatedPerson={relatedPersons?.length ? relatedPersons[0] : null}
            handleRelatedPerson={handlerConfirmGuarantor}
          />

          <ConfirmNatOptIn
            isOpen={confirmNatOptInModalOpen}
            handlerIsOpen={setConfirmNatOptInModalOpen}
            handlerConfirmNATOptIn={handlerConfirmNATOptIn}
          />

          <ScheduleAppointment
            isOpen={scheduleAppointmentModalOpen}
            handlerIsOpen={setScheduleAppointmentModalOpen}
            handlerScheduleAppointment={handlerConfirmScheduleAppointment}
          />

          <ConfirmContact
            patient={patient}
            patientEngagementId={patientEngagementId || ''}
            isOpen={confirmContactModalOpen}
            handlerIsOpen={setConfirmContactModalOpen}
            handlePatient={updatePatient}
            refreshPatient={refreshPatient}
            handleConfirmContactAttempt={handlerConfirmContactAttempt}
            checkListData={checklistData}
          />

          <CreateTask
            isOpen={createTask}
            patient={patient}
            relatedPersons={relatedPersons}
            handlerIsOpen={setCreateTask}
            handleInitiateHJRequest={handlerInitiateHJRequest}
          />

          <TasksList
            patient={patient}
            tasks={tasks}
            refreshTasks={refreshTask}
            isOpen={showTasksList}
            handlerIsOpen={setShowTasksList}
            handleCompleteHJRequest={handlerCompleteHJRequest}
          />

          <ReviewNatReportItem
            patients={patient}
            isOpen={reviewNatReportItem}
            handlerIsOpen={setReviewNatReportItem}
            handleReviewHJRequest={handlerConfirmGeneric}
          />

          <ConfirmCMOptInItem
            isOpen={confirmOptInItem}
            handlerIsOpen={setConfirmOptInItem}
            handlerConfirmCMOptIN={handlerConfirmCMOptIN}
          />

          <ReferralInformation
            patient={patient}
            serviceRequest={serviceRequest[0]}
            open={referralInfoModalOpen}
            isLoading={isAllLoading}
            onClose={() => setReferralInfoModalOpen(false)}
            handlerSave={handlerReferralInformation}
            relatedPersonOpen={relatedPersonsOpen}
            setRelatedPersonOpen={setRelatedPersonsOpen}
          />

          <AssessmentFromChecklistData
            patient={patient}
            checkListData={checklistData}
            open={
              screeningInterviewModalOpen ||
              confirmScreeningResponsesModalOpen ||
              chartReviewModalOpen
            }
            displayStartRelatedAssessmentFirst={confirmScreeningResponsesModalOpen}
            onClose={() => {
              setExternalRefreshWorkFlow(true);

              if (screeningInterviewModalOpen) {
                setScreeningInterviewModalOpen(false);
              } else if (confirmScreeningResponsesModalOpen) {
                setConfirmScreeningResponsesModalOpen(false);
              } else if (chartReviewModalOpen) {
                setChartReviewModalOpen(false);
              }
            }}
            handlerSave={handlerConfirmGeneric}
          />

          <ProviderInformation
            patient={patient}
            careTeams={careTeams}
            relatedProviders={relatedProviders}
            providerOption={intakeOption.providers}
            open={provideInformationModalOpen}
            isLoading={isAllLoading}
            workflowInstanceData={getParseColumn(workflowInstance, 'data')}
            onClose={() => setProvideInformationModalOpen(false)}
            handlerSave={handlerProvidersInformation}
          />

          <ConsentInformation
            patient={patient}
            isLoading={isAllLoading}
            workflowInstanceData={getParseColumn(workflowInstance, 'data')}
            handlerSave={handlerConsentInformation}
            open={consentsModalOpen}
            consentOption={intakeOption.consents}
            consents={consents}
            breadcrumbs={[
              `${i18n('patientEngagement.details.title', 'engagement')}`,
              `${i18n('patientEngagement.details.workflow.step.intake', 'engagement')}`
            ]}
            onClose={() => setConsentsModalOpen(false)}
          />
        </Grid>

        <Dialog
          open={isOpenReAssign}
          onClose={() => setIsOpenReAssign(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: {
              minHeight: '400px',
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">Re-Assign To</DialogTitle>
          <DialogContent>
            <SelectType
              others={{
                data: allUsers,
                patient: patientEngagement,
                handler: handleReAssingOwned,
                checklistItem: {},
              }}
            />
          </DialogContent>
        </Dialog>
      </Container>
    </Page>
  );
}