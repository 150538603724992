import { useEffect, useState } from 'react';
import axiosInstance from '../utils/axios';
import useTenantConfigData from './useTenantConfigData';

export interface ILaunchDto {
  [key: string]: string | undefined;
  launch?: string;
  fhir_user?: string;
  iam_user?: string;
  patient?: string;
}

export interface ContextSmartLauncher {
  [key: string]: string;
}

const createLaunchContext = async (
  userId: string,
  fhirUserId: string,
  patientId: string,
  smartLaunchUrl: string,
  otherContext?: ContextSmartLauncher,
): Promise<string | undefined> => {
  console.log('DEBUG config.smartLaunchUrl: ', smartLaunchUrl);

  if (smartLaunchUrl === undefined) return undefined;

  let launchBody: ILaunchDto = {
    fhir_user: fhirUserId,
    iam_user: userId,
    patient: patientId,
  };

  if (otherContext) {
    launchBody = {
      ...otherContext,
      ...launchBody,
    };
  }

  const response = await axiosInstance.post<ILaunchDto>(smartLaunchUrl, launchBody);
  if (response.data.launch) return response.data.launch;

  return undefined;
};

export interface IUseSmartLauncherProps {
  smartAppBaseLaunchUrl: string | undefined;
  fhirUserId: string;
  iamUserId: string;
  patientId: string | null;
  otherContext?: ContextSmartLauncher;
}

export default function useSmartLauncher({
  iamUserId,
  fhirUserId,
  patientId,
  smartAppBaseLaunchUrl,
  otherContext,
}: IUseSmartLauncherProps) {
  const { configurations } =useTenantConfigData();
  const [isDone, setIsDone] = useState<boolean>(false);
  const [launchUrl, setLaunchUrl] = useState<string | undefined>();
  const [errorMsg, setErrorMsg] = useState<string | undefined>();

  async function processFn() {
    console.log('DEBUG userId: ', iamUserId);
    console.log('DEBUG patientId: ', patientId);
    console.log('DEBUG smartAppBaseLaunchUrl: ', smartAppBaseLaunchUrl);
    console.log('DEBUG config.fhirServerUrl: ', configurations?.fhir?.baseUrl);

    if (!iamUserId || !fhirUserId || !patientId || !smartAppBaseLaunchUrl) {
      setIsDone(false);
      setErrorMsg(undefined);
      setLaunchUrl(undefined);
      return;
    }

    let launchId;

    try {
      launchId = await createLaunchContext(iamUserId, fhirUserId, patientId, configurations?.smartLaunchUrl, otherContext);
    } catch (e) {
      setErrorMsg('Error while creating launch context');
      console.log(e);
    }

    if (launchId === undefined) return;

    let buildedLaunch = '';

    if (smartAppBaseLaunchUrl.includes('?')) {
      buildedLaunch = `${smartAppBaseLaunchUrl}&iss=${configurations?.fhir?.baseUrl}&launch=${launchId}`;
    } else {
      buildedLaunch = `${smartAppBaseLaunchUrl}?iss=${configurations?.fhir?.baseUrl}&launch=${launchId}`;
    }

    setLaunchUrl(buildedLaunch);
    setIsDone(true);
  }

  useEffect(() => {
    processFn();
  }, [iamUserId, patientId, smartAppBaseLaunchUrl]);

  return { isDone, launchUrl, errorMsg };
}
