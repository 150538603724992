import { Identifier } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { createWrapper } from '../../fhir-react/base';

export const HealthcareServiceWrapper = createWrapper('HealthcareService', (healthCareService) => ({
  getMINT: (programIdentifier: string): Identifier | undefined =>
    healthCareService?.identifier?.find?.(
      ({ system }) => system === programIdentifier
    ),
}));
export type WrappedHealthcareService = ReturnType<typeof HealthcareServiceWrapper>;
