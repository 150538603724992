import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  collection,
  query,
  getDocs,
  Query,
  DocumentData,
  QuerySnapshot,
  QueryConstraint,
} from 'firebase/firestore';
import { FirebaseCollectionData } from '../types';
import { getFirebaseCollectioPath } from '../utils/helpers';
import { db } from 'src/crs/firebase/firebase-init';

export interface UseFirebaseCollectionProps {
  collectionName: string;
  configurations: any;
  queryConstraints?: QueryConstraint[];
  queryProps?: UseQueryOptions<any>;
}

const useFirebaseCollection = <T = DocumentData>({
  collectionName,
  queryConstraints = [],
  queryProps = {},
  configurations
}: UseFirebaseCollectionProps): UseQueryResult<FirebaseCollectionData<T>> => {
  const fetchData = async (): Promise<FirebaseCollectionData<T>> => {
    let baseQuery: Query<T> = collection(db, getFirebaseCollectioPath(
      collectionName,  configurations?.defaultCollectionPath)
    ) as Query<T>;

    if (queryConstraints) baseQuery = query(baseQuery, ...queryConstraints);

    const snapshot: QuerySnapshot<T> = await getDocs(baseQuery);

    return [snapshot.docs.map((doc) => doc.data()), snapshot.docs];
  };

  const collectionFetching = useQuery<FirebaseCollectionData<T>>({
    queryKey: ['firebaseCollection', collectionName, queryConstraints],
    queryFn: fetchData,
    refetchOnMount: 'always',
    ...queryProps,
  });

  return collectionFetching;
};

export default useFirebaseCollection;
