// @ts-nocheck
import { MouseEvent, useEffect, useState, useMemo, useRef } from 'react';
import produce from 'immer';
import { alpha, styled } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  Card,
  Grid,
  IconButton,
  Link,
  Popper,
  Stack,
  Typography,
  TableBody,
  TableRow,
  TextField,
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import { useEventBus, SHOW_FAMILY_CONTACTS, ADD_PERSON, SWITCH_ACTIVE_PERSON } from 'src/event-bus';
import { mockPersons } from 'src/_mock/_careflow';
import { imageFromAttachment } from 'src/utils/formatPhoto';
import ForesightStats from './ForesightStats';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import useAuth from 'src/hooks/useAuth';
import { usePatient, useGroups, useOrganization, useCareTeams } from 'src/@nicheaim/fhir-react';
import { StatusChip } from 'src/sections/careflow/common';
import { getFhirIdFromReferenceString } from 'src/sections/careflow/tasks-activities/components/Activities/activities-utils';
import { Group } from 'src/@nicheaim/fhir-base/mappings/Group';
import { Organization } from 'src/@nicheaim/fhir-base/mappings/Organization';
import './styles.css';
import ProviderWindow from './ProviderWindow';
import fhirSystem from 'src/fhir/system';
import { Button } from '@mui/material';
import moment from 'moment';
import AuthTokenGuard from 'src/sections/careflow/common/AuthTokenGuard';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
}));

const UnderlinedText = ({ children, ...props }: any) => (
  <Link component="button" underline="always" variant="body2" {...props}>
    {children}
  </Link>
);

const FamilyPreviewTooltip = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const showFamilyTooltip = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const hideFamilyTooltip = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? 'family-popper' : undefined;

  const { dispatchEvent } = useEventBus();
  const handleClick = () => dispatchEvent(SHOW_FAMILY_CONTACTS);
  const openPerson = (person: any) => {
    dispatchEvent(ADD_PERSON, { data: person });
    dispatchEvent(SWITCH_ACTIVE_PERSON, person);
  };

  const familyGuys = mockPersons.filter((person) => person.familyId);
  return (
    <Box onMouseEnter={showFamilyTooltip} onMouseLeave={hideFamilyTooltip}>
      <IconButton size="small" onClick={handleClick}>
        <Iconify
          icon={'eva:people-fill'}
          sx={{
            alignItems: 'flex-end',
            cursor: 'pointer',
            width: 25,
            height: 25,
            mr: -0.6,
            color: 'rgb(92, 179, 148)',
          }}
        />
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <Card sx={{ p: 2 }}>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Typography variant="subtitle2">Family ID: {familyGuys[0].familyId}</Typography>
            <IconButton onClick={() => openPerson(familyGuys)}>
              <Iconify icon="eva:people-fill" sx={{ width: 18, height: 18 }} />
            </IconButton>
          </Stack>
          <Stack>
            {familyGuys.map((familyPerson) => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                key={familyPerson.id}
              >
                <Typography variant="caption">{familyPerson.name}</Typography>
                <IconButton onClick={() => openPerson(familyPerson)}>
                  <Iconify icon="eva:person-fill" sx={{ width: 18, height: 18 }} />
                </IconButton>
              </Stack>
            ))}
          </Stack>
        </Card>
      </Popper>
    </Box>
  );
};

interface IPatientInfoProps {
  hideForesightStats?: boolean;
  onShowMorePrograms?: () => void;
  onShowMorePlanIdentifiers?: () => void;
  patient?: {
    id: string;
  }
}

const PatientInfo = ({
  hideForesightStats,
  onShowMorePrograms,
  onShowMorePlanIdentifiers,
  patient: propPatient,
}: IPatientInfoProps) => {
  const { configurations } = useTenantConfigData();
  const [userId, setUserId] = useState<string>();
  const selectedPatient = useCurrentPatient();
  let currentPatient: any = selectedPatient;

  if (propPatient) {
    currentPatient = propPatient;
  }
  const [fhirUserId, setFhirUserId] = useState<string>();
  const [hideCamera, setHideCamera] = useState(true);

  const [patient, { update, refresh: refreshPatient }] = usePatient(currentPatient?.id, {
    map: PatientWrapper,
  });

  const input = useRef<HTMLElement>();
  const user = useAuth();
  const [careTeams] = useCareTeams({
    filter: {
      patient: `${patient?.id}`,
    },
    autofetch: patient ? true : false,
  });

  const practitioner = useMemo(() => {
    const pcp = careTeams?.[0]?.participant?.find((p) => p.role?.find((r) => r.text === 'PCP'));
    return pcp;
  }, [careTeams]);
  const [groups] = useGroups<Group | Organization>({
    filter: {
      member: patient?.id,
      identifier: fhirSystem.program.enrolledPatients.forCode(''),
      _include: 'Group:managing-entity',
    },
    autofetch: !!patient?.id,
  });

  // programs
  const enrolledPrograms = useMemo(
    () =>
      groups
        .filter((resource): resource is Group => resource.resourceType === 'Group')
        // only get new and active programs
        .filter((group) => {
          const membership = group.member?.find((member) => member.id === patient?.id);

          return !membership?.inactive;
        })
        .sort((a, b) => {
          // sort by most recent enrollment
          const aMembership = a.member?.find((member) => member.id === patient?.id);
          const bMembership = b.member?.find((member) => member.id === patient?.id);
          const aEnrollmentDate = aMembership?.period?.start
            ? Date.parse(aMembership.period.start)
            : 0;
          const bEnrollmentDate = bMembership?.period?.start
            ? Date.parse(bMembership.period.start)
            : 0;

          return bEnrollmentDate - aEnrollmentDate;
        }),
    [groups]
  );
  const mostRecentProgram = enrolledPrograms.at(0);

  // LOB
  const mostRecentLOB = useMemo(
    () =>
      groups
        .filter((resource): resource is Organization => resource.resourceType === 'Organization')
        .find(
          (organization) =>
            organization.id ===
            getFhirIdFromReferenceString(mostRecentProgram?.managingEntity?.reference || '')
        ),
    [mostRecentProgram]
  );

  const [imagecontent, setImageContent] = useState('');
  // health plan
  const [mostRecentPlan] = useOrganization(
    getFhirIdFromReferenceString(mostRecentLOB?.partOf?.reference ?? '')
  );

  const planEnrollmentIds = useMemo(
    () =>
      patient?.identifier?.filter((identifier) =>
        identifier.system?.startsWith(fhirSystem.healthPlan.withId('').asString())
      ) || [],
    [patient]
  );

  const mostRecentPlanEnrollmentId = useMemo(
    () =>
      planEnrollmentIds.find(
        (identifier) =>
          identifier.system === fhirSystem.healthPlan.withId(mostRecentPlan?.id ?? '').asString()
      )?.value,
    [mostRecentPlan, patient]
  );

  useEffect(() => {
    const currentUser = user.getCurrentUser();
    if (currentUser.id === undefined) return;
    setUserId(currentUser.id);
    if (currentUser.user_fhir_uri === undefined) return;
    setFhirUserId(currentUser.user_fhir_uri);
  }, [user]);

  const patientMrn = useMemo(() => {
    if (!patient) {
      return undefined;
    }

    const tempMrn = patient.getMRN(configurations?.mpi?.systemMrn)?.value;
    if (!tempMrn) {
      return undefined;
    }

    return tempMrn;
  }, [patient]);

  useEffect(() => {
    if (userId) {
      // console.log('DEBUG userId: ', userId);
    }
  }, [userId]);

  useEffect(() => {
    if (fhirUserId) {
      // console.log('DEBUG : ', fhirUserId);
    }
  }, [fhirUserId]);

  useEffect(() => {
    if (patientMrn) {
      // console.log('DEBUG : ', patientMrn);
    }
  }, [patientMrn]);

  // FIXME: Add Optimism
  if (!patient) return null;

  const gender = patient.getGender();

  const onInputFileChange = (e: any) => {
    console.log('on change', e.target.files);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const content = reader.result?.toString().split(',')[1];
      update(
        produce(patient!, (draft) => {
          const photo = [
            {
              contentType: file.type,
              size: file.size,
              creation: new Date().toISOString(),
              data: content,
              title: 'Profile picture',
            },
          ];
          draft.photo = photo;
        })
      )
        .then(() => {
          refreshPatient();
        })
        .catch((err) => console.log('========== ERR', err));
    };

    reader.onerror = (error) => {
      console.log('============= READ ERROR', error);
    };

    reader.readAsDataURL(file);
  };

  const selectFile = () => {
    input.current?.click();
  };

  return (
    <Grid container p={2} spacing={4}>
      <Grid
        item
        alignItems="center"
        xl={2}
        sm={12}
        xs={12}
        onMouseEnter={() => setHideCamera(false)}
        onMouseLeave={() => setHideCamera(true)}
        textAlign={'center'}
      >
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          invisible={hideCamera}
          badgeContent={
            <IconButton onClick={selectFile}>
              <Iconify icon="eva:camera-fill" height={24} width={24} marginLeft={8} />
            </IconButton>
          }
        >
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={
              <Iconify
                icon={
                  gender === 'female'
                    ? 'material-symbols:female'
                    : gender === 'male'
                    ? 'material-symbols:male'
                    : ''
                }
                height={24}
                width={24}
                marginTop={'50px'}
                marginLeft={7}
              />
            }
          >
            <Avatar
              alt={patient.getFullName() || undefined}
              src={imageFromAttachment(patient.photo?.[0])}
              sx={{ width: 100, height: 100, left: 30, marginTop: '30px' }}
            />
          </Badge>
        </Badge>
      </Grid>

      <Grid item xl={2} sm={4} xs={5} alignItems="center">
        <Stack spacing={1}>
          <table>
            <TableBody>
              <TableRow>
                <td className="patient-header-table-th">
                  <FamilyPreviewTooltip />
                </td>
                <td className="patient-header-table-td">
                  <Typography fontWeight="bold" fontSize={15}>
                    {patient.getFullName()}
                  </Typography>
                </td>
              </TableRow>
              <TableRow>
                <td className="patient-header-table-th">
                  <Iconify
                    icon={'fa6-solid:id-card'}
                    sx={{ color: '#637381', width: 25, height: 25 }}
                  />
                </td>
                <td className="patient-header-table-td">
                  <Typography fontWeight="bold" fontSize={15}>
                    {typeof mostRecentPlanEnrollmentId === 'string'
                      ? mostRecentPlanEnrollmentId || 'N/A'
                      : 'N/A'}{' '}
                    {planEnrollmentIds.length > 1 && (
                      <Button size="small" variant="text" onClick={onShowMorePlanIdentifiers}>
                        ({`${planEnrollmentIds.length - 1} more`})
                      </Button>
                    )}
                  </Typography>
                </td>
              </TableRow>
              <TableRow>
                <td className="patient-header-table-th">
                  <Typography fontSize={13}>Status</Typography>
                </td>
                <td className="patient-header-table-td">
                  <StatusChip label="New" color="success" size="small" />
                </td>
              </TableRow>
              <TableRow>
                <td className="patient-header-table-th">
                  <Typography fontSize={13}>PCP</Typography>
                </td>
                <td className="patient-header-table-td">
                  <Typography fontWeight="bold" fontSize={15}>
                    {practitioner?.member?.display ?? 'N/A'}
                  </Typography>
                </td>
              </TableRow>
            </TableBody>
          </table>
          {/* <Typography alignItems="center" display="flex" variant="subtitle2">
            <Iconify icon={'eva:phone-fill'} sx={{ width: 18, height: 18, mr: 1.5 }} />
            {patient.getPrimaryPhone()?.value}{' '}
            {patient.getPhoneNumbers().length > 1 && (
              <UnderlinedText>({patient.getPhoneNumbers().length - 1} more)</UnderlinedText>
            )}
          </Typography> */}
        </Stack>
      </Grid>

      {imagecontent.replace(/^data:(.*,)?/, '')}

      <Grid
        item
        xl={4}
        sm={4}
        xs={7}
        alignItems="center"
        style={{
          paddingLeft: '70px',
        }}
      >
        <Stack spacing={1}>
          <table>
            <TableBody>
              <TableRow>
                <td className="patient-header-table-th">
                  <Iconify
                    icon={'eva:gift-fill'}
                    sx={{ color: '#637381', width: 25, height: 25 }}
                  />
                </td>
                <td className="patient-header-table-td">
                  <Typography fontWeight="bold" fontSize={15}>
                    {patient.birthDate
                      ? `${moment(patient.birthDate).format(
                          'MMM D Y'
                        )} (${patient.getAgeInYears()})`
                      : 'N/A'}
                  </Typography>
                </td>
              </TableRow>
              <TableRow>
                <td className="patient-header-table-th">
                  <Iconify icon={'eva:pin-fill'} sx={{ color: '#637381', width: 25, height: 25 }} />
                </td>
                <td className="patient-header-table-td">
                  <Typography fontWeight="bold" fontSize={15}>
                    {patient.getPrimaryAddressFormatted() ?? 'No address'}
                  </Typography>
                </td>
              </TableRow>
              <TableRow>
                <td className="patient-header-table-th">
                  <Typography fontSize={13}>Plan</Typography>
                </td>
                <td className="patient-header-table-td">
                  <Typography fontWeight="bold" fontSize={15}>
                    {mostRecentPlan?.name ?? 'N/A'}{' '}
                    {mostRecentLOB && `(${mostRecentLOB?.name ?? 'N/A'})`}
                  </Typography>
                </td>
              </TableRow>
              <TableRow>
                <td className="patient-header-table-th">
                  <Typography fontSize={13}>Program</Typography>
                </td>
                <td className="patient-header-table-td">
                  <Typography fontWeight="bold" fontSize={15}>
                    {mostRecentProgram?.name ??
                      mostRecentProgram?.code?.coding?.[0]?.display ??
                      'N/A'}{' '}
                    {enrolledPrograms.length > 1 && (
                      <Button size="small" variant="text" onClick={onShowMorePrograms}>
                        ({`${enrolledPrograms.length - 1} more`})
                      </Button>
                    )}
                  </Typography>
                </td>
              </TableRow>
            </TableBody>
          </table>
        </Stack>
      </Grid>

      <Grid
        item
        xl={4}
        sm={4}
        xs={12}
        textAlign="right"
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <AuthTokenGuard>
          {!hideForesightStats &&
          patient.id !== undefined &&
          userId !== undefined &&
          fhirUserId !== undefined &&
          patientMrn !== undefined ? (
            <ForesightStats
              iamUserId={userId}
              fhirUserId={fhirUserId}
              patientId={patient.id}
              otherContext={{ mrn: patientMrn }}
            />
          ) : (
            <>The patient doesnt have enough data to show stats</>
          )}
        </AuthTokenGuard>
      </Grid>
      <input ref={input} type="file" hidden onChange={onInputFileChange} />

      <Grid item xl={4} sm={4} xs={12}>
        {patient.id !== undefined && userId && fhirUserId !== undefined && patientMrn && (
          <ProviderWindow userId={userId} fhirUserId={fhirUserId} patientId={patientMrn} />
        )}
      </Grid>
    </Grid>
  );
};

export default PatientInfo;
