import { Coding } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

export const NOTAPPLICABLE = 'N/A';

export const TASK_TYPES = {
  ASSESSMENT: 'assessment',
  NAT: 'completed-nat-assessment',
  REFERRAL: 'referral',
  PROGRAM: 'program'
};

export const PROVIDER_LINK_IDENTIFIER = (configurations: any) => ({
  system: configurations?.practitionerProviderDirLinkIdentifierSystem,
  type: {
    coding: [
      {
        code: configurations?.practitionerProviderDirLinlIdentifierTypeCode,
        display: 'PatientHolistics Providers Directory',
        system: configurations?.practitionerProviderDirLinkIdentifierTypeSytem,
      },
    ],
    text: 'PatientHolistics Providers Directory',
  },
});

export const NPI_PROVIDER_IDENTIFIER_CODE = 'NPI';
export const NPI_IDENTIFIER_SYSTEM = 'http://hl7.org/fhir/sid/us-npi';

export const SSN_PROVIDER_IDENTIFIER_CODE = 'SSN';
export const SSN_IDENTIFIER_SYSTEM = 'http://hl7.org/fhir/sid/us-ssn';

export const MEDICARE_PROVIDER_IDENTIFIER_CODE = 'MEDICARE';
export const MEDICARE_IDENTIFIER_SYSTEM = 'http://hl7.org/fhir/sid/us-medicare';

export const LOOKUP_ERROR_MESSAGE_CODES = {
  INVALID_VALUE_SET: 'INVALID_VALUE_SET',
};

export const existingPractitionerVerificationErrorMessage =
  'There was an error while trying to verify existing Practitioners';

export const linkingPractitionerErrorMessage =
  'There was an error while trying to link Practitioner. Please try again';

export const linkingPractitionerSuccessMessage = 'Practitioner Succesfully linked';

export const unlinkingPractitionerErrorMessage =
  'There was an error while trying to unlink Practitioner. Please try again';

export const unlinkingPractitionerSuccessMessage = 'Practitioner Succesfully Unlinked';

export const IDENTIFIER_CODE_SYSTEM_MAPPING: {
  code: string;
  system: string;
}[] = [
  {
    code: NPI_PROVIDER_IDENTIFIER_CODE,
    system: NPI_IDENTIFIER_SYSTEM,
  },
  {
    code: SSN_PROVIDER_IDENTIFIER_CODE,
    system: SSN_IDENTIFIER_SYSTEM,
  },
  {
    code: MEDICARE_PROVIDER_IDENTIFIER_CODE,
    system: MEDICARE_IDENTIFIER_SYSTEM,
  },
];

export const validTelecomSystems = ['phone', 'fax', 'email', 'pager', 'url', 'sms', 'other'];

export const defaultDateFormat = 'MM/DD/YYYY';
export const defaultTimeFormat = 'hh:mm A';
export const defaultDateTimeFormat = `${defaultDateFormat} ${defaultTimeFormat}`;
export const defaultMonthPickerFormat = 'MMMM';
export const defaultYearPickerFormat = 'YYYY';

export const isActiveCondition = { active: 'true' };

export const defaultRowsPerPageOptions: number[] = [5, 10, 25, 50, 100];

export const GOAL_RELATIONSHIP_EXTENSION_URL =
  'http://hl7.org/fhir/StructureDefinition/goal-relationship';

export const GOAL_RELATIONSHIP_TYPE_SYSTEM =
  'http://terminology.hl7.org/CodeSystem/goal-relationship-type';

export const PARENT_GOAL_SUCCESSOR_RELATIONSHIP_CODING: Coding = {
  system: GOAL_RELATIONSHIP_TYPE_SYSTEM,
  code: 'successor',
  display: 'Successor',
};

export const iconGreyFillColor = '#919eab';
