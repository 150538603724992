import {
  DocumentData,
  QueryConstraint,
  startAfter,
  limit,
  orderBy,
  collection,
  getCountFromServer,
  Query,
  query,
} from 'firebase/firestore';
import { FirebaseCollectionData, PaginationOptions } from '../types';
import useFirebaseCollection from './useFirebaseCollection';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { getFirebaseCollectioPath } from '../utils/helpers';
import { db } from 'src/crs/firebase/firebase-init';

const useFirebaseCollectionList = <T extends DocumentData>(
  collectionName: string,
  configurations: any,
  paginationOptions?: PaginationOptions,
  queryConstraints: QueryConstraint[] = [],
): UseQueryResult<FirebaseCollectionData<T>> & { count: number | null } => {
  const paginationConstraints: QueryConstraint[] = [];

  if (paginationOptions?.orderBy)
    paginationConstraints.push(
      orderBy(paginationOptions?.orderBy, paginationOptions?.orderDirection ?? 'asc')
    );

  if (paginationOptions?.limit) paginationConstraints.push(limit(paginationOptions?.limit));

  if (paginationOptions?.startAfter)
    paginationConstraints.push(startAfter(paginationOptions.startAfter));

  const collectionQueryInfo = useFirebaseCollection<T>({
    collectionName,
    queryConstraints: [...paginationConstraints, ...queryConstraints],
    configurations
  });

  const fetchCount = async (): Promise<number> => {
    let baseQuery = collection(db, getFirebaseCollectioPath(collectionName, configurations?.defaultCollectionPath)) as Query<T>;
    if (queryConstraints?.length) baseQuery = query(baseQuery, ...queryConstraints);
    const snapshot = await getCountFromServer(baseQuery);
    return snapshot.data().count;
  };

  const countFetching = useQuery<number>({
    queryKey: [
      'firebaseCollectionCountFetching',
      collectionName,
      paginationOptions,
      queryConstraints,
    ],
    queryFn: fetchCount,
    refetchOnMount: 'always',
  });
  return {
    ...collectionQueryInfo,
    isFetching: countFetching.isFetching || collectionQueryInfo.isFetching,
    count: countFetching.data ?? null,
  };
};

export default useFirebaseCollectionList;
