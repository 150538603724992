import { Edit, Lock, LockOpen, PreviewOutlined} from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';

import moment from 'moment';
import { WrappedConsent } from 'src/@nicheaim/fhir-base/wrappers/Consent';
import { DocumentReferenceWrapper } from 'src/@nicheaim/fhir-base/wrappers/DocumentReference';
import SeverityStatus from 'src/components/SeverityStatus';
import useLocales from 'src/hooks/useLocales';
import {
  DocumentReference,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import PreviewDetailDrawer, {
  InfoRow,
  InfoRowValue,
  InfoRowValueList,
  PreviewDetailDrawerProps,
  SectionInfo,
} from 'src/sections/crs/common/PreviewDetailDrawer';
import { ConsentPermissions, ResourceWithIncludedResources } from 'src/sections/crs/types';
import { formatDate, getTimeDiff } from 'src/utils/dates';
import { getReferenceId } from 'src/utils/fhir';
import mime from 'mime';
import CustomModal from 'src/components/CustomModal';
import Viewer from 'src/sections/crs/common/Viewer';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { useState } from 'react';
import usePermissionsContext from 'src/hooks/usePermissionsContext';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

export interface ConsentDetailPreviewProps extends PreviewDetailDrawerProps {
  data: ResourceWithIncludedResources<WrappedConsent> | null;
  consentStatusConcepts: ValueSetComposeIncludeConcept[];
  patient: WrappedPatient | null;
  onEdit: VoidFunction;
}

const ConsentDetailPreview = ({
  data,
  consentStatusConcepts,
  patient,
  onEdit,
  ...drawerProps
}: ConsentDetailPreviewProps) => {
  const { i18n } = useLocales();
  const { configurations } = useTenantConfigData();
  const consentInternalNumberIdentifierSystem = configurations?.fhir?.consentInternalNumberIdentifierSystem;
  const [isDocumentViewerOpen, setIsDocumentViewerOpen] = useState(false);
  const consent = data?.resource;
  const handleCloseDocumentViewer = () => {
    setIsDocumentViewerOpen(false);
  };
  const { isAllowedToEdit } = usePermissionsContext<ConsentPermissions>() ?? {};
  if (!consent) return null;
  const includedResources = data?.includedResources;
  const unWrappedDocumentReference = includedResources.find(
    ({ id }) => id === getReferenceId(consent?.sourceReference?.reference)
  ) as DocumentReference;
  const documentReference = unWrappedDocumentReference
    ? DocumentReferenceWrapper(unWrappedDocumentReference)
    : null;
  const start = moment(consent?.provision?.period?.start ?? null);
  const end = moment(consent?.provision?.period?.end ?? null);
  const timeDiff = start.isValid() && end.isValid() ? getTimeDiff(start, end)?.diffText : null;
  const statusDisplay = consentStatusConcepts.find(({ code }) => code === consent.status)?.display;
  const severity = consent.getStatusSeverity();

  const provisionType = consent.getProvisionType();
  const LockIcon = provisionType?.code ? (provisionType.code === 'permit' ? LockOpen : Lock) : null;
  const documentContentType = documentReference?.content?.[0]?.attachment?.contentType;

  return (
    <>
      <PreviewDetailDrawer
        title={i18n('patients.details.consents.info', 'crs')}
        headerChildren={
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ pl: 20 }}>
            {isAllowedToEdit && (
               <Tooltip title={i18n('patients.details.consents.edit', 'crs')}>
                <IconButton onClick={onEdit}>
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
            {!!documentReference && (
              <Tooltip title={i18n('selectAttachment.previewDocument', 'crs')}>
                <IconButton onClick={() => setIsDocumentViewerOpen(true)}>
                  <PreviewOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        }
        {...drawerProps}
      >
        <SectionInfo title={i18n('patients.details.consents.general', 'crs')}>
          <InfoRow
            label={i18n('patients.details.consents.consentId', 'crs')}
            value={consent.getInternalNumber(consentInternalNumberIdentifierSystem)}
          />
          <InfoRow
            label={i18n('patients.details.consents.grantee', 'crs')}
            value={consent.getFirstPerformerName(includedResources)}
          />
          <InfoRow
            label={i18n('patients.details.consents.controller', 'crs')}
            value={consent.getFirstOrganizationName(includedResources)}
          />
          <InfoRow
            label={i18n('patients.details.consents.createdOn', 'crs')}
            value={formatDate(moment(consent?.dateTime ?? null))}
          />
          <InfoRowValueList
            showPreferredIcon={false}
            label={i18n('patients.details.consents.period', 'crs')}
            values={[
              ...(start.isValid() ? [formatDate(start)] : []),
              ...(end.isValid() ? [`${formatDate(end)}${timeDiff ? ` (${timeDiff})` : ''}`] : []),
            ]}
          />
          <InfoRow
            label={i18n('patients.details.consents.status', 'crs')}
            value={
              <SeverityStatus
                sx={{ maxWidth: 90 }}
                status={{
                  message: statusDisplay,
                  severity,
                }}
              />
            }
          />
          <InfoRow
            label={i18n('patients.details.consents.consentType', 'crs')}
            value={consent.getFirstCategory()?.display}
          />
          <InfoRow
            label={i18n('patients.details.consents.consentDecision', 'crs')}
            value={
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {LockIcon ? (
                  <LockIcon htmlColor="#637381" sx={{ height: 15, width: 15, marginRight: 1 }} />
                ) : null}
                <InfoRowValue sx={{ width: 'auto' }} value={provisionType?.display} />
              </Box>
            }
          />
        </SectionInfo>
        {!!documentReference && (
          <SectionInfo title={i18n('patients.details.consents.documentProperties', 'crs')}>
            <InfoRow
              label={i18n('patients.details.consents.author', 'crs')}
              value={documentReference?.author?.[0]?.display}
            />
            <InfoRow
              label={i18n('patients.details.consents.type', 'crs')}
              value={
                documentContentType
                  ? mime.getExtension(documentContentType)
                  : i18n('patients.details.consents.unkown', 'crs')
              }
              valueSx={{ textTransform: 'uppercase' }}
            />
            <InfoRow
              label={i18n('patients.details.consents.lastModifiedOn', 'crs')}
              value={formatDate(moment(documentReference?.meta?.lastUpdated ?? null))}
            />
            <InfoRow
              label={i18n('patients.details.consents.createdOn', 'crs')}
              value={formatDate(moment(documentReference?.date ?? null))}
            />
          </SectionInfo>
        )}
      </PreviewDetailDrawer>
      <CustomModal
        title={i18n('selectAttachment.previewDocument', 'crs')}
        open={isDocumentViewerOpen}
        breadcrumbs={[]}
        showSaveButton={false}
        onClose={handleCloseDocumentViewer}
        onCancel={handleCloseDocumentViewer}
      >
        <Viewer
          documentReference={documentReference}
          typeResource="documentReference"
          patientId={patient?.id}
        />
      </CustomModal>
    </>
  );
};

export default ConsentDetailPreview;
