import moment from "moment";
import { capitalCase } from "change-case";
import { WrappedTask } from "src/@nicheaim/fhir-base/wrappers/Task";
import { PractitionerWrapper } from "src/@nicheaim/fhir-base/wrappers/Practitioner";
import { OrganizationWrapper } from "src/@nicheaim/fhir-base/wrappers/Organization";
import { RelatedPersonWrapper } from "src/@nicheaim/fhir-base/wrappers/RelatedPerson";
import { WrappedPlanDefinition } from "src/@nicheaim/fhir-base/wrappers/PlanDefinition";
import { WrappedServiceRequest } from "src/@nicheaim/fhir-base/wrappers/ServiceRequest";
import { PatientWrapper, WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { MeasureReport } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";
import { i18nFunction } from "src/hooks/useLocales";

export const HJAssessment = (
  serviceRequest: WrappedServiceRequest | null, 
  planDefinition: WrappedPlanDefinition | null,
  task: WrappedTask | undefined,
  measureReport: MeasureReport | undefined,
  ownerTask: any,
  configurations: any,
  i18n: i18nFunction
) => {
  const taskNumber = task 
    ? task?.getInternalNumber?.(configurations?.taskInternalNumberIdentifierSystem) 
      ?? task?.getTaskNumberNAT(configurations?.taskNATIdenitifierType) 
      ?? ""
    : "";

  return {
    reference: {
      show: false,
      [i18n('nat.request.requestId', 'translations')]: serviceRequest?.id,
    },
    serviceRequestId: {
      show: false,
      [i18n('nat.request.requestNumber', 'translations')]: serviceRequest?.identifier?.[0]?.value,
    },
    orderOn: {
      show: false,
      [i18n('nat.request.requestOrderOn', 'translations')]: moment(serviceRequest?.authoredOn).format('ddd MMM D YYYY')
    },
    [i18n('nat.request.requestDate', 'translations')]: moment(serviceRequest?.authoredOn).format('ddd MMM D YYYY'),
    [i18n('nat.request.requestStatus', 'translations')]: capitalCase(serviceRequest?.status ?? ''),
    serviceRequestIntent: {
      show: false,
      [i18n('nat.request.requestIntent', 'translations')]: serviceRequest?.intent,
    },
    planDefinition: {
      show: false,
      [i18n('nat.request.planId', 'translations')]: planDefinition?.id,
    },
    [i18n('nat.request.requestPlan', 'translations')]: planDefinition?.title,
    taskId: {
      show: false,
      [i18n('nat.request.taskId', 'translations')]: task?.id
    },
    [i18n('nat.request.taskNo', 'translations')]: taskNumber,
    [i18n('nat.request.taskDate', 'translations')]: moment(task?.authoredOn).format('ddd MMM D YYYY'),
    [i18n('nat.request.taskStatus', 'translations')]: capitalCase(task?.status ?? ''),
    [i18n('nat.request.taskPriority', 'translations')]: capitalCase(task?.priority ?? ''),
    [i18n('nat.request.measureReportDateEnd', 'translations')]: moment(measureReport?.period?.end).format('ddd MMM D YYYY'), 
    reportName: {
      show: false,
      [i18n('nat.request.reportName', 'translations')]: measureReport ? measureReport?.measure : "Not Available",
    },
    ...setOwnerType(ownerTask, i18n)
  }
};

export const memberDetails = (patient: WrappedPatient | undefined, configurations: any) => {
  const wrapperPatient = patient && PatientWrapper(patient);
  const mrn = wrapperPatient?.getMRN(configurations?.fhir?.systemMRNNAT)?.value ?
    wrapperPatient?.getMRN(configurations?.fhir?.systemMRNNAT)?.value :
    wrapperPatient?.getMRN(configurations?.mpi?.systemMrn)?.value;

  return {
    reference: {
      show: false,
      ID: wrapperPatient?.id,
    },
    Name: wrapperPatient?.getFullName(),
    DoB: moment(wrapperPatient?.birthDate).format('MMM D Y'),
    Age: wrapperPatient?.getAgeInYears(),
    MRN: mrn ?? "",
    Phone: wrapperPatient?.getPrimaryPhone()?.value,
    Email: wrapperPatient?.getPrimaryEmail()?.value,
    Race: wrapperPatient?.getRace(configurations?.fhir)?.display,
    Ethnicity: wrapperPatient?.getEthnicity(configurations?.fhir)?.display,
    Language: wrapperPatient?.getLanguage()?.[0]?.value,
    "Mgmt Org": wrapperPatient?.managingOrganization?.display
  }
};

const setOwnerType = (ownerTask: any, i18n: i18nFunction) => {

 const setOwner = ownerTask && ownerTask?.resourceType === 'Patient' ?  
    PatientWrapper(ownerTask) : RelatedPersonWrapper(ownerTask);

  return {
    referenceOwner: {
      show: false,
      [i18n('nat.request.ownerId', 'translations')]: setOwner?.id,
    },
    [i18n('nat.request.ownerType', 'translations')]: setOwner?.resourceType === 'Patient' ? 
      i18n('nat.request.ownerTypePatient', 'translations') : i18n('nat.request.ownerTypeGuarantor', 'translations'),
    [i18n('nat.request.ownerName', 'translations')]: setOwner?.getFullName(),
    [i18n('nat.request.ownerPhone', 'translations')]: setOwner?.getPrimaryPhone()?.value,
    [i18n('nat.request.ownerEmail', 'translations')]: setOwner?.getPrimaryEmail()?.value
  }
};

export const mapRequester = (requesterTask: any, configurations: any) => {

  let mapRequester: any;

  const setRequester = requesterTask?.resource && requesterTask?.resource?.resourceType === 'Practitioner' ?  
     PractitionerWrapper(requesterTask?.resource) : OrganizationWrapper(requesterTask?.resource);

  if(setRequester?.resourceType === 'Organization'){
    mapRequester = {
      referenceRequester: {
        show: false,
        "ID Requester": setRequester?.id,
      },
      type: {
        show: false,
        type: setRequester?.type?.[0]?.coding?.[0].display,
      },
      Name: requesterTask?.name,
      Email: requesterTask?.email
    }
  }else{
    mapRequester = {
      referenceRequester: {
        show: false,
        "ID Requester": setRequester?.id,
      },
      NPI: setRequester?.getIdentifierBySystem(configurations?.fhir?.systemNpi),
      Name: setRequester?.getFullName(),
      Phone: setRequester?.getPrimaryPhone()?.value,
      Email: setRequester?.getPrimaryEmail()?.value
    }
  }
 
   return mapRequester;
 };