import { createWrapper } from '../../fhir-react/base';
import {
  Coding,
  DocumentReference,
  FhirResource,
  Organization,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { AlertSeverity } from 'src/components/SeverityStatus';
import { getReferenceDisplayByResource, getReferenceId } from 'src/utils/fhir';
import { wrapResource } from 'src/utils/fhirSystem';

export const consentProvisionTypes: Coding[] = [
  {
    code: 'permit',
    display: 'Opt-In',
  },
  {
    code: 'deny',
    display: 'Opt-Out',
  },
];

export enum ConsentStatus {
  DRAFT = 'draft',
  PROPOSED = 'proposed',
  ACTIVE = 'active',
  REJECTED = 'rejected',
  INACTIVE = 'inactive',
  ENTEREDINERROR = 'entered-in-error',
}

export const ConsentWrapper = createWrapper('Consent', (consent) => ({
  getOptInStatus(): string {
    const policyRuleCode = consent?.policyRule?.coding?.[0]?.code;
    let optInStatus: string = '';

    switch (policyRuleCode) {
      case 'OIC':
        optInStatus = 'Opt In';
        break;
      case 'OIS':
        optInStatus = 'Opt In';
        break;
      case 'OOC':
        optInStatus = 'Opt Out';
        break;
      case 'OOS':
        optInStatus = 'Opt Out';
        break;
      default:
        optInStatus = 'Not available';
    }

    return optInStatus;
  },
  getInternalNumber: (CONSENT_INTERNAL_NUMBER_IDENTIFIER_SYSTEM: string): string | null =>
    consent?.identifier?.find?.(
      ({ system }) => system === CONSENT_INTERNAL_NUMBER_IDENTIFIER_SYSTEM
    )?.value ?? null,

  getProvisionType: () =>
    consentProvisionTypes.find(({ code }) => code === consent?.provision?.type),

  getFirstCategory: () => consent.category[0]?.coding?.[0],

  getStatusSeverity: (): AlertSeverity => {
    const statusSeverityMap: Record<ConsentStatus, AlertSeverity> = {
      [ConsentStatus.DRAFT]: AlertSeverity.WARNING,
      [ConsentStatus.PROPOSED]: AlertSeverity.WARNING,
      [ConsentStatus.ACTIVE]: AlertSeverity.SUCCESS,
      [ConsentStatus.REJECTED]: AlertSeverity.ERROR,
      [ConsentStatus.INACTIVE]: AlertSeverity.DEFAULT,
      [ConsentStatus.ENTEREDINERROR]: AlertSeverity.ERROR,
    };
    return statusSeverityMap?.[consent.status as ConsentStatus];
  },
  getDocumentTitle: (includedResources: FhirResource[]) => {
    const documentReference = includedResources.find(
      ({ id }) => id === getReferenceId(consent?.sourceReference?.reference)
    ) as DocumentReference;
    return documentReference?.content?.[0]?.attachment?.title;
  },
  getFirstPerformerName: (includedResources?: FhirResource[]) => {
    if (!includedResources?.length) return consent?.performer?.[0]?.display;
    const grantee = includedResources?.find?.(
      ({ id }) => id === getReferenceId(consent?.performer?.[0]?.reference)
    );
    if (!grantee) return consent?.performer?.[0]?.display;

    return getReferenceDisplayByResource(
      wrapResource(grantee),
      includedResources.map((resource) => wrapResource(resource))
    );
  },
  getFirstOrganizationName: (includedResources?: FhirResource[]) => {
    if (!includedResources?.length) return consent?.organization?.[0]?.display;
    const organization = includedResources.find(
      ({ id }) => id === getReferenceId(consent?.organization?.[0]?.reference)
    ) as Organization;
    if (!organization) return consent?.organization?.[0]?.display;
    return organization?.name;
  },
}));

export type WrappedConsent = ReturnType<typeof ConsentWrapper>;
