import { getStorage, ref, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';

const storage = getStorage();

export const extractFileNameFromURL = (url: string | null | undefined): string | null => {
  if (!url) return null;
  try {
    const urlDecoded = decodeURIComponent(url);
    const lastSlashIndex = urlDecoded.lastIndexOf('/');
    const queryParamsIndex = urlDecoded.indexOf('?', lastSlashIndex);
    const endIndex = queryParamsIndex !== -1 ? queryParamsIndex : urlDecoded.length;

    return urlDecoded.substring(lastSlashIndex + 1, endIndex);
  } catch (error) {
    return null;
  }
};

export const uploadFile = async (imagePath: string, file: File): Promise<string | null> => {
  try {
    const imageRef = ref(storage, imagePath);
    const response = await uploadBytes(imageRef, file);
    const downloadUrl = await getDownloadURL(response.ref);
    return downloadUrl;
  } catch (error) {
    console.log('Error uploading file to firebase storage: ', error);
    return null;
  }
};

export const deleteFile = async (imagePath: string): Promise<boolean> => {
  try {
    const imageRef = ref(storage, imagePath);
    await deleteObject(imageRef);
    return true;
  } catch (error) {
    console.log('Error deleting file from firebase storage: ', error);
    return false;
  }
};

export const getProfileImagePath = (personId: string, filename: string, storageProfileImagePath: string) =>
  `${storageProfileImagePath}${personId}/${filename}`;
