import { Sorting } from '../types';

// export const defaultCollectionsPath = YOCARE_FIREBASE_DEFAULT_COLLECTION_PATH.split(',');

export const defaultSorting: Sorting = {
  orderBy: 'createdOn',
  orderDirection: 'desc',
};

export const defaultSort = 'asc';
export const highCodeChar = '\uf8ff';

export const dateRangeErrorMessage = "Start Date can't be greater than End Date ";

export const collectionNames = {
  USERS: 'Users',
  PERSONS: 'Persons',
};

export const COLLECTION_PATH_SEPARATOR = '/';

export const genders = ['male', 'female', 'other'];
