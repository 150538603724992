import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { useDocumentReferences } from 'src/@nicheaim/fhir-react';
import { fhirClient } from 'src/App';
import { TableHeadCustom } from 'src/components/table';
import useLocales from 'src/hooks/useLocales';
import useTable from 'src/hooks/useTable';
import {
  Bundle,
  DocumentReference,
  Practitioner,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { AttachmentAddForm } from 'src/sections/crs/common';
import { TABLE_HEAD_DOCUMENTS_PATIENT } from 'src/sections/crs/common/table-head';
import { translateTableHead } from 'src/sections/crs/helpers/common';
import { parseShortFormat } from 'src/utils/formatTime';
import DocumentRow from './DocumentRow';
import DocumentsFilters from './DocumentsFilters';
import DocumentsTagFiltered from './DocumentsTagFiltered';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

type Props = {
  patient: WrappedPatient | null;
  documentReferences: DocumentReference[];
  resource?: any;
  showFilters?: boolean;
  refreshDocumentReference: any;
  openCollapseExternal: boolean;
  handleUpdateResource: any;
  permissions?: {
    isAllowedToAdd: boolean;
    isAllowedToEdit: boolean;
  };
};

export function DocumentList({
  patient,
  documentReferences,
  resource,
  showFilters,
  refreshDocumentReference,
  openCollapseExternal,
  handleUpdateResource,
  permissions,
}: Props) {
  const { configurations } = useTenantConfigData();
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});

  const [, { create: createDocumentReference }] = useDocumentReferences({ autofetch: false });

  const [filterName, setFilterName] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterDate, setFilterDate] = useState<Date | null>(null);
  const [filterCustodian, setFilterCustodian] = useState('');
  const [filterCategory, setFilteCategory] = useState<string[]>([]);
  const [filterAuthor, setFilteAuthor] = useState<string[]>([]);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(showFilters);
  const { i18n } = useLocales();
  const [wrappedPractionersIndex, setWrappedPractionersIndex] = useState({});

  useEffect(() => {
    setOpenCollapse(openCollapseExternal);
  }, [openCollapseExternal]);

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
  };

  const handleFilterType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterType(event.target.value);
  };

  const handleFilterCustodian = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterCustodian(event.target.value);
  };

  const handleFilterCategory = (filterCategory: string[]) => {
    setFilteCategory(filterCategory);
  };

  const handleFilterAuthor = (filterAuthor: string[]) => {
    setFilteAuthor(filterAuthor);
  };

  const handleCloseAddAttachment = () => {
    setOpenAttachment(false);
  };

  const handleClearAll = () => {
    setFilterName('');
    setFilterDate(null);
    setFilterType('');
    setFilterCustodian('');
    setFilteAuthor([]);
    setFilteCategory([]);
  };

  const filtersFiltered = {
    filterName,
    filterDate,
    filterType,
    filterCustodian,
    filterAuthor,
    filterCategory,
  };

  const filteredDocuments = applyFilter(documentReferences, filtersFiltered);

  async function getPractitionersFromDocumentReferenceAuthor(resouces: DocumentReference[]) {
    let practitionerIds: string[] = [];

    resouces.forEach((resource) => {
      if (resource.author) {
        resource.author.forEach((ref) => {
          if (ref.reference && ref.reference.includes('Practitioner/')) {
            const splitRef = ref.reference.split('/');
            const practitionerId = splitRef[splitRef.length - 1];
            practitionerIds.push(practitionerId);
          }
        });
      }
    });

    if (practitionerIds.length === 0) {
      return;
    }

    const practitionerResponse = await fhirClient.get<Bundle<Practitioner>>(
      `Practitioner?_id=${practitionerIds.join()}`
    );

    let practitioners =
      practitionerResponse?.entry?.reduce?.<{}>((resources, { resource }) => {
        if (!resource) return resources;

        if (resource.id) {
          resources[resource.id] = PractitionerWrapper(resource);
        }

        return resources;
      }, {}) ?? {};

    setWrappedPractionersIndex(practitioners);
  }

  useEffect(() => {
    getPractitionersFromDocumentReferenceAuthor(filteredDocuments);
  }, [filteredDocuments]);

  const editAttachment = !!permissions?.isAllowedToEdit;
  const columns = editAttachment
    ? translateTableHead(TABLE_HEAD_DOCUMENTS_PATIENT, 'crs')
    : translateTableHead(TABLE_HEAD_DOCUMENTS_PATIENT, 'crs').filter((e) => e.id !== 'actions');

  return (
    <Card style={{ boxShadow: 'none', position: 'static' }}>
      <Grid container display={'flex'} alignItems={'flex-end'} paddingRight={2}>
        <Grid
          item
          xs={6}
          display={'flex'}
          style={{ position: 'absolute', top: '25px', right: '200px' }}
        ></Grid>

        {!!permissions?.isAllowedToAdd && (
          <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
            <Button
              style={{ position: 'absolute', top: '25px', right: '25px' }}
              size="small"
              sx={{ height: '36px' }}
              startIcon={<Add />}
              onClick={() => setOpenAttachment(true)}
            >
              {i18n('patients.details.attachments.button', 'crs')}
            </Button>
          </Grid>
        )}
      </Grid>
      {showFilters && (
        <Stack direction="row" justifyContent="right" sx={{ m: 2, mt: 0 }}>
          <DocumentsTagFiltered filters={filtersFiltered} onClearAll={handleClearAll} />
          <DocumentsFilters
            filterName={filterName}
            filterType={filterType}
            filterDate={filterDate}
            filterCustodian={filterCustodian}
            onFilterName={handleFilterName}
            onFilterType={handleFilterType}
            onFilterDate={(newValue) => {
              setFilterDate(newValue);
            }}
            onFilterCustodian={handleFilterCustodian}
            onFilterCategory={handleFilterCategory}
            onFilterAuthor={handleFilterAuthor}
            onClearAll={handleClearAll}
          />
        </Stack>
      )}
      <TableContainer>
        <Table size="small" sx={{ mb: 2 }}>
          <TableHeadCustom headLabel={columns} />
          <TableBody>
            {!isEmpty(filteredDocuments) && wrappedPractionersIndex ? (
              filteredDocuments
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <DocumentRow
                    key={`document-row-${index}`}
                    row={row}
                    patientId={patient?.getMRN(configurations?.mpi?.systemMrn)?.value}
                    editAttachment={editAttachment}
                    refreshDocumentReference={refreshDocumentReference}
                    wrappedPractionersIndex={wrappedPractionersIndex}
                  />
                ))
            ) : (
              <TableCell colSpan={TABLE_HEAD_DOCUMENTS_PATIENT?.length}>
                <Typography variant="body2" align="center">
                  No rows
                </Typography>
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredDocuments ? filteredDocuments.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
      <AttachmentAddForm
        open={openAttachment}
        patient={patient}
        resource={resource}
        onCancel={handleCloseAddAttachment}
        createDocumentReference={createDocumentReference}
        refreshDocumentReference={refreshDocumentReference}
        handleUpdateResource={handleUpdateResource}
      />
    </Card>
  );
}

function applyFilter(documents: DocumentReference[], filtersFiltered: any) {
  if (filtersFiltered.filterName) {
    documents = documents.filter((document) =>
      document.content?.[0].attachment.title?.includes(filtersFiltered.filterName)
    );
  }

  if (filtersFiltered.filterDate) {
    const dateFormated = parseShortFormat(filtersFiltered.filterDate.toString());
    documents = documents.filter((document) =>
      parseShortFormat(document.date || '').includes(dateFormated)
    );
  }

  if (filtersFiltered.filterType) {
    documents = documents.filter((document) =>
      document.type?.coding?.[0].display?.includes(filtersFiltered.filterType)
    );
  }

  if (filtersFiltered.filterCustodian) {
    documents = documents.filter((document) =>
      document.custodian?.display?.includes(filtersFiltered.filterCustodian)
    );
  }

  if (filtersFiltered.filterAuthor.length > 0) {
    documents = documents.filter((document) =>
      document.author?.some((e) => filtersFiltered.filterAuthor.includes(e.display))
    );
  }

  if (filtersFiltered.filterCategory.length > 0) {
    documents = documents.filter((document) =>
      document.category?.some((e) => filtersFiltered.filterCategory.includes(e.coding?.[0].display))
    );
  }

  return documents;
}
