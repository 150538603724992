//@ts-nocheck
import { capitalize } from 'lodash';
import {
  Address,
  ContactPoint,
  Identifier,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { createWrapper } from '../../fhir-react/base';
import { getPractitionerProviderDirectoryId, getNPIFromIdentifier } from 'src/utils/fhir';
import { dateYearFormatWithAge } from 'src/utils/formatTime';
import { formatUSAddress, verifyIfPractitionerIsVerified } from 'src/utils/fhir';
import {
  MEDICARE_IDENTIFIER_SYSTEM,
  PROVIDER_LINK_IDENTIFIER,
  SSN_IDENTIFIER_SYSTEM,
} from 'src/sections/crs/constants';
import moment from 'moment';
import { getSimpleLicenses, getSimpleQualifications } from 'src/sections/crs/provider/helpers';

export const PractitionerWrapper = createWrapper('Practitioner', (practitioner) => ({
  getIdAsReference(): string {
    return `Practitioner/${practitioner?.id}`;
  },

  getFullName(): string | null {
    let fullName = null;
    if (practitioner.name?.[0]?.given?.[0] && practitioner.name?.[0]?.family) {
      fullName = `${practitioner.name?.[0]?.given?.[0]} ${practitioner.name?.[0]?.family}`;
    }
    return fullName;
  },

  getFullNameWithTitles(): string | null {
    if (!practitioner.name) return null;
    const { prefix, suffix, given, family } = practitioner.name[0];
    return [prefix?.[0], given?.[0], given?.[1]?.[0], family, suffix].filter((n) => n).join(' ');
  },

  getStatus(): string {
    return practitioner.active ? 'active' : 'inactive';
  },

  getPrimaryAddress(): Address | null {
    let mainAddress = null;
    if (practitioner.address && practitioner.address?.length > 0) {
      mainAddress = practitioner.address[0];
    }
    return mainAddress;
  },

  getPrimaryAddressFormatted(): string | null {
    const mainAddress = this.getPrimaryAddress();

    if (!mainAddress) return null;

    const addr = [];
    if (mainAddress?.line) addr.push(mainAddress.line.filter((l) => l).join(' '));
    if (mainAddress?.city) addr.push(mainAddress.city);
    if (mainAddress?.state) addr.push(`${mainAddress.state},`);
    if (mainAddress?.postalCode) addr.push(mainAddress.postalCode);
    if (mainAddress?.use) addr.push(`(${capitalize(mainAddress.use)})`);

    return addr.join(' ');
  },

  getPrimaryPhone(): ContactPoint | null {
    let mainPhone = null;
    if (practitioner.telecom && practitioner.telecom?.length > 0) {
      const phones = practitioner.telecom.filter((t) => t?.system === 'phone');
      if (phones && phones?.length > 0) {
        mainPhone = phones[0];
      }
    }
    return mainPhone;
  },

  getPhones(): ContactPoint[] | null {
    return practitioner?.telecom?.filter((t) => t?.system === 'phone') || null;
  },

  getPrimaryEmail(): ContactPoint | null {
    let mainPhone = null;
    if (practitioner.telecom && practitioner.telecom?.length > 0) {
      const phones = practitioner.telecom.filter((t) => t?.system === 'email');
      if (phones && phones?.length > 0) {
        mainPhone = phones[0];
      }
    }
    return mainPhone;
  },

  getEmails(): ContactPoint[] | null {
    return practitioner?.telecom?.filter((t) => t?.system === 'email') || null;
  },
  getIdentifier(): { identifier: string | null; type: string | null } {
    return {
      identifier: practitioner?.identifier?.[0]?.value ?? null,
      type: practitioner?.identifier?.[0]?.system ?? null,
    };
  },
  getIdentifierBySystem(system: string | undefined): string | undefined {
    const identifier = practitioner?.identifier?.find((item) => item?.system === system)?.value;
    return identifier;
  },
  getProviderId: (configurations: any): string | null => getPractitionerProviderDirectoryId(practitioner, configurations),
  getFirstName: (): string => practitioner?.name?.[0]?.given?.[0] ?? '',
  getMiddleName: (): string => practitioner?.name?.[0]?.given?.[1] ?? '',
  getLastName: (): string => practitioner?.name?.[0]?.family ?? '',
  getNPI: (): string => getNPIFromIdentifier(practitioner?.identifier ?? []),
  getInternalIdenfitier: (configurations: any): string => 
    practitioner?.identifier?.find?.(({ system }) => system === configurations?.practitionerInternalNumberIdentifierSystem)?.value ?? '',
  getSSN: (): string =>
    practitioner?.identifier?.find?.(({ system }) => system === SSN_IDENTIFIER_SYSTEM)?.value ?? '',

  getMedicare: (): string =>
    practitioner?.identifier?.find?.(({ system }) => system === MEDICARE_IDENTIFIER_SYSTEM)
      ?.value ?? '',
  getBirthDate: (): string => {
    const date = moment(practitioner?.birthDate ?? null);
    return dateYearFormatWithAge(date);
  },
  getFormattedAddresses: (): string[] =>
    practitioner?.address?.map?.((address) => formatUSAddress(address)) ?? [],
  isVerified: (configurations: any): boolean => verifyIfPractitionerIsVerified(practitioner, configurations),
  getGenderIdentityCodeableConcept: (configurations: any): ValueSetComposeIncludeConcept | null => {
    const URLS_GENDER_IDENTITY = [
      'ValueSet/us-core-birthsex', 
      configurations?.genderIdentityExtensionDefinitionUrl, 
      configurations?.genderIdentityCodeSystemUrl
    ];
    const genderIdentityExtension = practitioner?.extension?.find?.(({ url }) =>
      URLS_GENDER_IDENTITY.some((e: string) => e === url)
    );
    if (!genderIdentityExtension) return null;
    const genderIdentityCoding = genderIdentityExtension?.valueCodeableConcept;

    return {
      code: genderIdentityCoding?.coding?.[0]?.code ?? '',
      display: genderIdentityCoding?.coding?.[0]?.display
        ? genderIdentityCoding.coding[0].display
        : genderIdentityCoding?.text
        ? genderIdentityCoding.text
        : genderIdentityCoding?.coding?.[0]?.code
        ? genderIdentityCoding.coding[0].code.replace('-', ' ')
        : '',
      system: genderIdentityCoding?.coding?.[0]?.system,
    };
  },
  getSimpleQualificationsWithoutLicenses: (medicalLicenseCodes: string[], configurations): SimpleQualification[] =>
    getSimpleQualifications(
      practitioner?.qualification?.filter?.(
        ({ identifier }) =>
          !identifier?.find?.(
            ({ type }) =>
              !!type?.coding?.find?.(({ code }) =>
                [...medicalLicenseCodes, configurations?.providerLicenseIdentifierCodingCode].includes(
                  code ?? ''
                )
              )
          )
      ) ?? [],
      configurations
    ),
  getSimpleLicenses: (medicalLicenseCodes: string[]) => {
    const identifiers =
      practitioner?.identifier?.filter?.(
        ({ type }) =>
          !!type?.coding?.find?.(({ code }) => [...medicalLicenseCodes].includes(code ?? ''))
      ) ?? [];

    const qualificationIdentifiers: Identifier[] = [];

    practitioner?.qualification?.map?.(({ identifier: identifiers }) => {
      const licenseIdentifier = identifiers?.find?.(
        ({ type }) =>
          !!type?.coding?.find?.(({ code }) => [...medicalLicenseCodes].includes(code ?? ''))
      );
      if (licenseIdentifier) qualificationIdentifiers.push(licenseIdentifier);
    });

    return getSimpleLicenses([...identifiers, ...qualificationIdentifiers]);
  },
  getHiddenIdentifiers: (medicalLicenseCodes: string[], configurations: any): Identifier[] => [
    ...(practitioner?.identifier?.filter?.(
      ({ type }) =>
        !!type?.coding?.find(({ code }) =>
          [
            ...medicalLicenseCodes,
            configurations?.providerLicenseIdentifierCodingCode,
            PROVIDER_LINK_IDENTIFIER(configurations)?.type.coding[0].code,
          ].includes(code ?? '')
        )
    ) ?? []),
  ],
}));

export type WrappedPractitioner = ReturnType<typeof PractitionerWrapper>;
