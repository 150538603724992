import { Grid, Stack, SxProps, Typography } from '@mui/material';
import React from 'react';
import { InfoTypography } from './InfoRibbon';
import CustomLink from '../sections/crs/common/CustomLink';
import { PATH_DASHBOARD } from '../routes/paths';
import LaunchIcon from '@mui/icons-material/Launch';
import { WrappedPatient } from '../@nicheaim/fhir-base/wrappers/Patient';
import BadgeIcon from '@mui/icons-material/Badge';
import CakeIcon from '@mui/icons-material/Cake';
import { WrappedServiceRequest } from '../@nicheaim/fhir-base/wrappers/ServiceRequest';
import { spreadSxProp } from 'src/utils/cssStyles';
import { DATETIME_LIST_FORMAT, getFormattedDate } from '../sections/crs/common/annotation-utils';
import { WrappedCondition } from '../@nicheaim/fhir-base/wrappers/Condition';
import { WrappedObservation } from '../@nicheaim/fhir-base/wrappers/Observation';
import { isString } from 'lodash';

export type InfoRibbonPatientProps = {
  configurations: any;
  patient?: WrappedPatient | null;
};

export type InfoRibbonServiceRequestProps = {
  serviceRequest?: WrappedServiceRequest | null;
};

export type InfoRibbonConditionProps = {
  condition?: WrappedCondition | null;
};

export type InfoRibbonObservationProps = {
  observation?: WrappedObservation | null;
};

const getCodeFromResource = (resource: any) => {
  const coding = resource?.code?.coding?.[0];
  return coding?.code ?? '';
};

const getDisplayFromResource = (resource: any) => {
  const coding = resource?.code?.coding?.[0];
  return coding?.display ?? '';
};

const getCategoryTextFromResource = (resource: any) => resource?.category?.[0]?.text ?? '';

const smallFontSize = '0.9rem';
const defaultFontSize = '0.9rem';
const bigFontSize = '1.2rem';

const smallStyle = { fontSize: smallFontSize };
const defaultStyle = { fontSize: defaultFontSize };
const bigStyle = { fontSize: bigFontSize };

export const PrimaryTypography = ({ text }) => (
  <InfoTypography
    containerSx={{
      ...defaultStyle,
      // @ts-ignore
      color: (theme) => theme.palette.grey[500],
    }}
  >
    {text}
  </InfoTypography>
);

export const SecondaryTypography = ({ text }) => (
  <InfoTypography
    containerSx={{
      ...defaultStyle,
      // @ts-ignore
      color: (theme) => theme.palette.grey[900],
    }}
  >
    {text}
  </InfoTypography>
);

export const InfoRibbonPatient = ({ patient, configurations }: InfoRibbonPatientProps) => (
  <>
    <Grid container item xs={12}>
      <Stack direction="row" alignItems="center" gap={1}>
        <InfoTypography
          containerSx={{
            ...bigStyle,
            color: (theme) =>
              // @ts-ignore
              theme.palette.grey[600],
          }}
        >
          {`${patient?.getFullName?.()} ` ?? ' '}
        </InfoTypography>
        <CustomLink to={PATH_DASHBOARD.crs.patient.details.forId(patient?.id!)} target="_blank">
          <Typography noWrap variant="subtitle2">
            <LaunchIcon color="primary" />
          </Typography>
        </CustomLink>
      </Stack>
    </Grid>

    <Grid container item xs={12}>
      <Stack direction="row" alignItems="center" gap={3}>
        {!!patient?.getMRN?.(configurations?.systemMrn)?.value && (
          <Stack direction="row" alignItems="center" gap={1}>
            <BadgeIcon sx={{ color: (theme) => theme.palette.grey[600] }} />
            <InfoTypography containerSx={defaultStyle}>{patient.getMRN(configurations?.systemMrn)?.value}</InfoTypography>
          </Stack>
        )}
        {!!patient?.birthDate && (
          <Stack direction="row" alignItems="center" gap={1}>
            <CakeIcon sx={{ color: (theme) => theme.palette.grey[600] }} />
            <InfoTypography containerSx={{ ...defaultStyle, marginLeft: 'auto' }}>
              {patient?.getBirthDateForDisplay?.()} ({patient?.getAgeInYears?.()} years)
            </InfoTypography>
          </Stack>
        )}
      </Stack>
    </Grid>
  </>
);

export const InfoRibbonServiceRequest = ({ serviceRequest }: InfoRibbonServiceRequestProps) => (
  <>
    <Grid container item xs={3}>
      <Grid item xs={12} alignContent={'center'}>
        <Stack direction="row" alignItems="center" gap={0}>
          <PrimaryTypography text={'Service Request'} />
        </Stack>
      </Grid>
    </Grid>

    <Grid container item xs={4}>
      <Grid container item xs={12}>
        <Stack direction="row" alignItems="center" gap={1}>
          <PrimaryTypography text={'From'} />
          <SecondaryTypography text={serviceRequest ? serviceRequest?.getRequesterDisplay() : ''} />
        </Stack>
      </Grid>
      <Grid container item xs={12}>
        <Stack direction="row" alignItems="center" gap={1}>
          <PrimaryTypography text={'To'} />
          <SecondaryTypography
            text={
              serviceRequest
                ? serviceRequest?.getPerformersByReferenceType?.('Practitioner')?.[0]?.display
                : ''
            }
          />
        </Stack>
      </Grid>
    </Grid>

    <Grid container item xs={5}>
      <Grid container item xs={12}>
        <Stack direction="row" alignItems="center" gap={1}>
          <PrimaryTypography text={'Created'} />
          <SecondaryTypography
            text={
              serviceRequest?.authoredOn
                ? getFormattedDate(serviceRequest?.authoredOn, DATETIME_LIST_FORMAT)
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid container item xs={12}>
        <Stack direction="row" alignItems="center" gap={1}>
          <PrimaryTypography text={'Service'} />
          <SecondaryTypography
            text={
              serviceRequest
                ? serviceRequest?.getPerformersByReferenceType?.('HealthcareService')?.[0]?.display
                : ''
            }
          />
        </Stack>
      </Grid>
    </Grid>
  </>
);

export const InfoRibbonObservation = ({ observation }: InfoRibbonObservationProps) => (
  <>
    <Grid container item xs={3}>
      <Grid item xs={12} alignContent={'center'}>
        <Stack direction="row" alignItems="center" gap={0}>
          <PrimaryTypography text={'Observation'} />
        </Stack>
      </Grid>
    </Grid>

    <Grid container item xs={9}>
      <Grid container item xs={12}>
        <Stack direction="row" alignItems="center" gap={1}>
          <PrimaryTypography text={'Category'} />
          <SecondaryTypography text={observation ? getCategoryTextFromResource(observation) : ''} />
        </Stack>
      </Grid>
      <Grid container item xs={12}>
        <Stack direction="row" alignItems="center" gap={1}>
          <PrimaryTypography text={'Type'} />
          <SecondaryTypography text={observation ? getDisplayFromResource(observation) : ''} />
        </Stack>
      </Grid>
    </Grid>
  </>
);

export const InfoRibbonCondition = ({ condition }: InfoRibbonConditionProps) => (
  <>
    <Grid container item xs={3}>
      <Grid item xs={12} alignContent={'center'}>
        <Stack direction="row" alignItems="center" gap={0}>
          <PrimaryTypography text={'Condition'} />
        </Stack>
      </Grid>
    </Grid>

    <Grid container item xs={9}>
      <Grid container item xs={12}>
        <Stack direction="row" alignItems="center" gap={1}>
          <PrimaryTypography text={'Code'} />
          <SecondaryTypography text={condition ? getCodeFromResource(condition) : ''} />
        </Stack>
      </Grid>
      <Grid container item xs={12}>
        <Stack direction="row" alignItems="center" gap={1}>
          <PrimaryTypography text={'RecordedDate'} />
          <SecondaryTypography
            text={
              condition && isString(condition?.recordedDate)
                ? getFormattedDate(condition?.recordedDate, DATETIME_LIST_FORMAT)
                : ''
            }
          />
        </Stack>
      </Grid>
    </Grid>
  </>
);
