import { useMemo, useRef, useState } from 'react';
import { Button, Link, Menu, MenuItem } from '@mui/material';
import useRegistry from 'src/hooks/useRegistry';
import { AssessmentLinks } from '../../types';
import { Add, OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { getDefaultAssessmentsQueryParams } from '../../helpers/assessments';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

interface StartAssessmentsButtonProps {
  keyRegistry: string;
  patientId: string;
  additionalQueryParams?: string;
}

const StartAssessmentsButton = ({
  keyRegistry,
  patientId,
  additionalQueryParams,
}: StartAssessmentsButtonProps) => {
  const user = useAuth();
  const { theme, configurations } = useTenantConfigData();
  const anchorElement = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { data: registry } = useRegistry({ filter: { keyRegistry: keyRegistry } });
  const { i18n } = useLocales();

  const assessmentsLinks: AssessmentLinks[] = useMemo(() => {
    if (!registry) return [];
    try {
      const links = JSON.parse(registry[0].keyValue);
      if (!links.length && links?.link) return [links];
      return links;
    } catch (error) {
      return [];
    }
  }, [registry]);
  if (!patientId) return null;
  return (
    <>
      {assessmentsLinks?.length > 0 && (
        <>
          <Button
            ref={anchorElement}
            size="small"
            sx={{ height: '36px' }}
            startIcon={<Add />}
            onClick={() => {
              setIsMenuOpen(true);
            }}
            style={{ position: 'absolute', top: '25px', right: '25px' }}
          >
            {i18n('patients.details.assessments.button', 'crs')}
          </Button>
          <Menu
            anchorEl={anchorElement.current}
            open={isMenuOpen}
            onClose={() => {
              setIsMenuOpen(false);
            }}
          >
            {assessmentsLinks.map(({ name, link, label }) => {
              const url = `${link}${getDefaultAssessmentsQueryParams(
                patientId, 
                user,
                { tenantInfoName: theme?.tenantName, nodeEnv: configurations?.nodeEnv}
              )}${additionalQueryParams ?? ''
                }`;

              return (
                <MenuItem sx={{ textTransform: 'capitalize' }} key={name + '' + link}>
                  <Link
                    href={url}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    color="inherit"
                    display={'flex'}
                  >
                    {label}
                    <OpenInNewIcon sx={{ marginLeft: 2 }} />
                  </Link>
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )}
    </>
  );
};

export default StartAssessmentsButton;
