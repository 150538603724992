import { useEffect, useState } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Person } from '../../types';
import useFirebaseCollection from '../../hooks/useFirebaseCollection';
import { WrappedPerson, wrapPerson } from '../../utils/wrappers/Person';
import PageContainer from '../../components/PageContainer';
import LoadingComponent from '../../components/LoadingComponent';
import { filterByStringExactMatch } from '../../utils/firestore';
import useLocales from 'src/hooks/useLocales';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

const PersonDetails: any = () => {
  const { id } = useParams();
  const { i18n } = useLocales();
  const { configurations } = useTenantConfigData();
  const { data, isFetching: isLoading } = useFirebaseCollection<Person>({
    collectionName: `Persons`,
    queryConstraints: filterByStringExactMatch('id', id),
    configurations: configurations?.yocare
  });
  const [person, setPerson] = useState<WrappedPerson | null>(null);
  useEffect(() => {
    setPerson(wrapPerson(data?.[0]?.[0] ?? null));
  }, [data]);

  return (
    // <Box sx={{ width: '100%' }}>
    //   <Page title="YoCare User List">
    //     <Container maxWidth={themeStretch ? false : 'xl'}>
    //       <HeaderBreadcrumbs
    //         title=""
    //         heading=""
    //         links={[
    //           { name: 'Home', href: PATH_DASHBOARD.root },
    //           { name: 'YoCare' },
    //           { name: 'Persons List', href: PATH_DASHBOARD.yoCare.persons },
    //           { name: `${id}` },
    //         ]}
    //       />
    //     </Container>
    //   </Page>
    // </Box>
    <PageContainer
      pageTitle={i18n('yocare.personDetails.title')}
      title={i18n('yocare.personDetails.title')}
      breadCrumbsLinks={[
        { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
        { name: `${i18n('yocare.userList.yocareTitle')}` },
        { name: `${i18n('yocare.personsList.title')}`, href: PATH_DASHBOARD.yoCare.persons },
        { name: `${i18n('yocare.personDetails.title')}`},
        { name: `${id}` },
      ]}
    >
      <Card sx={{ padding: 3, marginTop: 3 }}>
        {!isLoading ? (
          <Grid>
            <Typography>hola</Typography>
          </Grid>
        ) : (
          <LoadingComponent size={60} />
        )}
      </Card>
    </PageContainer>
  );
};

export default PersonDetails;
