import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActionArea,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form';
import { FormProvider } from 'src/components/hook-form';
import { Reference } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { WrappedHealthcareService } from 'src/@nicheaim/fhir-base/wrappers/HealthcareService';
import useLocales from 'src/hooks/useLocales';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  height: 160,
  color: theme.palette.text.disabled,
  border: `solid 1px ${theme.palette.grey[500_12]}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
}));

type EditModeProps = {
  open: boolean;
  healthCareServiceRelated: Reference[] | null;
  healthCareServices?: WrappedHealthcareService[] | null;
  handleProgramAssigment: (data: any) => Promise<any>;
  onCancel: VoidFunction;
  edit?: boolean;
};

const getIdFromReference = (reference: string): string => reference.split('/')?.[1];

export default function Program({
  open,
  healthCareServiceRelated,
  healthCareServices,
  handleProgramAssigment,
  onCancel,
  edit
}: EditModeProps) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { i18n } = useLocales();
  const { configurations } = useTenantConfigData();

  const methods = useForm<{ programs: string[] }>();

  const { control, handleSubmit, setValue } = methods;

  useEffect(() => {
    const programsRelated =
      healthCareServiceRelated
        ?.filter?.(({ reference }) => !!reference?.includes?.('HealthcareService'))
        ?.map?.((p: Reference) => getIdFromReference(p?.reference || '')) || [];
    if (!isEmpty(programsRelated)) {
      setValue('programs', programsRelated);
    } else {
      setValue('programs', []);
    }
  }, [healthCareServiceRelated, open]);

  const onSubmit = async (data: any) => {
    setOpenBackdrop(true);

    const healthCareServiceReferences = data.programs.map((p: Reference) => ({
      reference: `HealthcareService/${p}`,
    }));
    await handleProgramAssigment(healthCareServiceReferences);

    onCancel();
    setOpenBackdrop(false);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="md">
      <DialogTitle>{i18n('referral.details.referralSummary.programs', 'crs')}</DialogTitle>
      <Card sx={{ m: 2 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {!isEmpty(healthCareServices) ? (
            <>
              <Grid dir="ltr" container spacing={1} sx={{ p: 1 }}>
                {healthCareServices?.map((value) => (
                  <Grid key={value?.id} item xs={4}>
                    <Controller
                      name="programs"
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        const selectedItems = (field.value || []) as string[];
                        const isSelected = (item: any) => selectedItems.includes(item.id);

                        const handleToggle = (item: any) => () => {
                          const newSelection = isSelected(item)
                            ? selectedItems.filter((selectedItem) => selectedItem !== item.id)
                            : [...selectedItems, item.id];
                          field.onChange(newSelection);
                        };

                        return (
                          <BoxStyle sx={{ p: 2 }} onClick={handleToggle(value)} disabled={!!edit}>
                            <Paper
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                bgcolor: 'background.default',
                              }}
                            >
                              <Checkbox edge="start" disableRipple checked={isSelected(value)} />
                              <Typography variant="subtitle1">
                                {value.program?.[0].coding?.[0].code?.toUpperCase()}
                              </Typography>
                            </Paper>
                            <Box
                              display={'grid'}
                              sx={{ height: 80, maxWidth: 400, width: '100%', p: 1 }}
                            >
                              <Typography variant="caption">
                                {value.program?.[0].coding?.[0].display}
                              </Typography>
                              {/* 
                                Healthcare does not have period property
                                <Typography variant="caption">
                                  {value?.period?.start &&
                                    `Enrollment Day: ${format(
                                      new Date(value.period.start),
                                      'MMM dd, yyyy'
                                    )}`}
                                </Typography> */}
                              <Typography variant="caption">
                                {value?.getMINT?.(configurations?.mint?.programIdentifier)?.value &&
                                  `MiNT Identifier: ${value.getMINT(configurations?.mint?.programIdentifier)?.value}`}
                              </Typography>
                            </Box>
                          </BoxStyle>
                        );
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              <Stack spacing={2} alignItems="center">
                <DialogActions>
                  <Button variant="outlined" color="info" onClick={onCancel}>
                    {i18n('cancel')}
                  </Button>
                  {!edit &&
                    <Button
                      variant="outlined"
                      color="info"
                      type="submit"
                      disabled={isEmpty(healthCareServices)}
                    >
                      {i18n('submit')}
                    </Button>
                  }
                </DialogActions>
              </Stack>
            </>
          ) : (
            <Stack spacing={2} alignItems="center" sx={{ p: 2 }}>
              <Typography>{`${i18n('patients.title', 'crs')} is not enrolled in any program.`}</Typography>
              <Button variant="outlined" color="info" onClick={onCancel}>
                {i18n('cancel')}
              </Button>
            </Stack>
          )}
        </FormProvider>
      </Card>
    </Dialog>
  );
}
