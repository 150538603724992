import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import agent from 'src/api/agent';
import { useSnackbar } from 'notistack';
import { capitalCase } from 'capital-case';
import { MobileDatePicker } from '@mui/lab';
import { caseService } from 'src/crs/case/service';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CaseStatus } from 'src/crs/dto/case/case.dto';
import { ReferralStatus } from 'src/crs/dto/referral.dto';
import { referralService } from 'src/crs/referral/services';
import { UpdateCaseDto } from 'src/crs/dto/case/update-case-dto';
import { UpdateReferralDto } from 'src/crs/dto/update-referral.dto';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';

type FormValue = {
  number: string;
  create: string;
  status: string;
  owner: string;
  scope: string;
  step: number;
};

type Props = {
  data: any | null;
  open: boolean;
  onClose: VoidFunction;
  handleRefresh: VoidFunction;
};

export default function Edit({ data, open, onClose, handleRefresh }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [allUsers, setAllUsers] = useState([]);
  const [workflow, setWorkflow] = useState<any>(null);
  const [transitionList, setTransitionList] = useState<any>([]);

  useEffect(() => {
    if (open) {
      reset(defaultValues);
      getAllUsers();
    }
  }, [open]);

  useEffect(() => {
    fetchTransitions();
  }, [workflow]);

  useEffect(() => {
    fetchWorkFlow();
  }, [data]);

  const getAllUsers = async () => {
    try{
      const result = await agent.User.getAllUsers();
      setAllUsers(result.filter((e: any) => e.status === 'Active'));
    }catch(err){
      console.log('error on fetching all users', err);
    }
  };

  const fetchTransitions = async () => {
    const getTransitions = await agent.Workflow.getTransitions(workflow?.id);
    setTransitionList(getTransitions);
  };

  const fetchWorkFlow = async () => {
    const getWorkflow = await agent.Workflow.getWorkflowByCode(data?.scope);
    setWorkflow(getWorkflow);
  };
  const defaultValues = useMemo(
    () =>
      ({
        number: data?.internalNumber || '',
        create: data?.createdOnFormatted || '',
        status: data?.statusIndexed || '',
        owner: data?.owner || '',
        scope: data?.scope || '',
        step: data?.stepId || '',
      } as FormValue),
    [data]
  );

  const methods = useForm({ defaultValues });

  const { reset, control, handleSubmit } = methods;

  const onSubmit = async (dataForm: FormValue) => {
    const { status, owner, scope } = dataForm;
    try {
      if (data?.scope !== 'CASES_WORKFLOW') {
        const update = await updateCustomReferral({
          status: status as ReferralStatus,
          scope: data?.scope,
          assignedTo: owner,
        });
      } else if (data?.scope === 'CASES_WORKFLOW') {
        const update = await updateCustomCase({
          status: status as CaseStatus,
          scope: data?.scope,
          assignedTo: owner,
        });
      }

      if (data.step !== dataForm.step) {
        const updateSteps = await updateStep(dataForm.step);
      }

      handleRefresh();
      enqueueSnackbar(`${data?.scope} was updated.`);
      handleClose();
    } catch (e) {
      enqueueSnackbar(`${data?.scope} was not updated.`, { variant: 'error' });
    }
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const updateCustomReferral = async (dataForm: UpdateReferralDto) => {
    const { id } = data;
    const update = referralService.update(id, dataForm);
    return update;
  };

  const updateCustomCase = async (dataForm: UpdateCaseDto) => {
    const { id } = data;
    const update = caseService.update(id, dataForm);
    return update;
  };

  const updateStep = async (idStepForm: any) => {
    try {
      const getTransitionId = transitionList.filter(
        (i: any) => Number(i.fromStagesId) === Number(idStepForm) && i.name === 'Next'
      );

      await agent.Transition.updateStage({
        reference_name: data?.scope,
        reference_id: data?.id,
        record: { id: 1 },
        stage: { id: idStepForm },
        workflow: { id: workflow?.id },
        transition: { id: getTransitionId?.[0]?.id },
      });
    } catch (error) {
      console.log('error updateStep', error);
    }
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm">
      <DialogTitle>Edit {capitalCase(data?.scope || '')}</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <Typography variant="body2">Number</Typography>
                <RHFTextField  disabled name="number"/>

                <Typography variant="body2">Created</Typography>
                <Controller
                  name="create"
                  control={control}
                  render={({ field }) => (
                    <MobileDatePicker
                      disabled
                      {...field}
                      label=""
                      inputFormat="MMM dd, yyyy hh:mm"
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  )}
                />

                <Typography variant="body2">Status</Typography>

                {data?.scope !== 'CASES_WORKFLOW' && (
                  <RHFSelect name="status" label="">
                    <MenuItem disabled></MenuItem>
                    {Object.entries(ReferralStatus).map((option) => (
                      <MenuItem key={option[0]} value={option[1]}>
                        {capitalCase(option[1].replace('.', ' '))}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                )}

                {data?.scope === 'CASES_WORKFLOW' && (
                  <RHFSelect name="status" label="">
                    <MenuItem disabled></MenuItem>
                    {Object.entries(CaseStatus).map((option) => (
                      <MenuItem key={option[0]} value={option[1]}>
                        {capitalCase(option[1].replace('.', ' '))}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                )}

                <Typography variant="body2">Owner</Typography>
                <RHFSelect name="owner" label="">
                  <MenuItem disabled></MenuItem>
                  {allUsers?.map((option: any) => (
                    <MenuItem key={option.uuid} value={option.userName}>
                      {`${option.firstName} ${option.lastName}`}
                    </MenuItem>
                  ))}
                </RHFSelect>

                <Typography variant="body2">Scope</Typography>
                <RHFSelect name="scope" label="">
                  <MenuItem disabled></MenuItem>
                  {[
                    { id: 1, value: 'CovidCare' },
                    { id: 2, value: 'RPM' },
                  ]?.map((option: any) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </RHFSelect>

                <Typography variant="body2">Step</Typography>
                <RHFSelect name="step" label="">
                  <MenuItem disabled></MenuItem>
                  {workflow?.stages?.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />

              <Button variant="contained" color="info" onClick={handleClose}>
                Cancel
              </Button>

              <Button variant="contained" color="info" type="submit">
                Submit
              </Button>
            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
}
