import { PATH_DASHBOARD } from 'src/routes/paths';
import useTenantConfigData from './useTenantConfigData';

const useRouteHanderByTenant = () => {
  const { componentsData } = useTenantConfigData();
  const { patientLandingPageRoute } = componentsData;

  function redirectRouteAfterCreatePatient(fhirPatientId: string, locationPathName?: string) {
    const setPatientLandingPageRoute = locationPathName ? 
      patientLandingPageRoute?.[locationPathName] ?? null : null;

    if (setPatientLandingPageRoute && setPatientLandingPageRoute !== '') {
      return setPatientLandingPageRoute.replace(`:patientId`, fhirPatientId);
    }

    return PATH_DASHBOARD.crs.patient.details.forId(fhirPatientId || '');
  }

  return {
    redirectRouteAfterCreatePatient,
  };
};

export default useRouteHanderByTenant;
