import { Theme } from "@mui/material/styles";
import { withStyles } from '@mui/styles';
// ----------------------------------------------------------------------

const MeasureStyle = withStyles((theme: Theme) => ({
  "@global": {
    ".measure-report-background-indicator-indeterminate-risk": {
      backgroundColor: "#9c27b0",
      minHeight: "40px",
      borderRadius: 4,
    },
    ".measure-report-indicator-indeterminate-risk": {
      backgroundColor: "#9c27b0",
      color: "#ffffff",
      borderRadius: 4,
    },
    ".measure-report-background-indicator-none": {
      backgroundColor: "#f57c00",
      minHeight: "40px",
      borderRadius: 4,
    },
    ".measure-report-indicator-none": {
      backgroundColor: "#f57c00",
      color: "#000000",
      borderRadius: 4,
    },
    ".measure-report-background-indicator-low-risk": {
      backgroundColor: "#229a16",
      minHeight: "40px",
      borderRadius: 4,
    },
    ".measure-report-indicator-low-risk": {
      backgroundColor: "#3dab2b",
      color: "#ffffff",
      borderRadius: 4,
    },
    ".measure-report-background-indicator-medium-risk": {
      backgroundColor: "#ffc107",
      minHeight: "40px",
      borderRadius: 4,
    },
    ".measure-report-indicator-medium-risk": {
      backgroundColor: "#ffcb2f",
      color: "#000000",
      borderRadius: 4,
    },
    ".measure-report-background-indicator-high-risk": {
      backgroundColor: "#ff4842",
      minHeight: "40px",
      borderRadius: 4,
    },
    ".measure-report-indicator-high-risk": {
      backgroundColor: "#ff6860",
      color: "#ffffff",
      borderRadius: 4,
    },
    ".BOLD": {
      color: "red",
      fontWeight: "bold"
    }
  },
}))(() => null);

export default MeasureStyle;
