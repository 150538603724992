import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import CustomModal, {
  CustomModalBasicProps,
  GridItem,
  GridSection,
} from 'src/components/CustomModal';
import useLocales from 'src/hooks/useLocales';
import useObjectState from 'src/hooks/useObjectState';
import {
  TaskSelectedMemberOption,
  TaskSelectedMemberValue,
} from 'src/sections/crs/case/components/TasksGrid/TaskModal';
import { GroupRemove, PersonSearch, HowToReg } from '@mui/icons-material';
import { useEffect, useMemo } from 'react';
import { StatusChip } from 'src/sections/careflow/common';
import { useTheme } from '@mui/styles';
import { WrappedCareTeam } from 'src/@nicheaim/fhir-base/wrappers/CareTeam';
import { getValuesFromReference } from 'src/utils/fhir';
import { CareTeamMemberWithEditableData } from 'src/@types/crs/case';
import { Reference } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { has, get, isEmpty } from 'lodash';
import uuidv4 from 'src/utils/uuidv4';
import { CustomLoadingButton } from 'src/sections/crs/common/CustomButton';
import CancelButton from 'src/sections/crs/common/CancelButton';
import moment from 'moment';
import useAuth from 'src/hooks/useAuth';
import SearchMember, { 
  getRowsMapping, 
  GridRow, 
  MemberResourceTypes, 
  MemberWrappedResourceTypes, 
  SearchMemberProps 
} from 'src/sections/crs/case/components/SearchMember/SearchMember';
import { PermissionsProvider } from 'src/contexts/PermissionsContext';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

export interface PayloadProvider {
  generalPractitioner: string[];
  removeCareTeam: WrappedCareTeam[];
  addCareTeam: WrappedCareTeam[];
  workflowDataScope: any;
}

export interface ProviderOption<T = MemberResourceTypes> {
  code: string;
  label: string;
  value: T;
}

interface ProviderInformationFormState {
  providers: GridRow[] | null;
}

export type LabelColor = 'error' | 'success' | 'default' | 'progress';

export interface selectedOptionCustomizedButton {
  title: string | null;
  sub: string;
  role: string;
  icon: JSX.Element;
  label: string;
  labelColor: LabelColor;
}

interface ProviderSelectedMemberState {
  isSearchMemberOpen: boolean;
  selectedProviderRole: TaskSelectedMemberOption | TaskSelectedMemberValue | null;
  selectedOptions: ProviderOption[] | null;
  selectedFilterRole: string | null;
  selectedOptionCustomizedButton: selectedOptionCustomizedButton[];
}

interface Props extends CustomModalBasicProps {
  patient: WrappedPatient | null;
  careTeams: WrappedCareTeam[] | null;
  open: boolean;
  isLoading: boolean;
  relatedProviders: CareTeamMemberWithEditableData[];
  providerOption: ProviderOption[] | null;
  workflowInstanceData: any;
  handlerSave: (data: any) => Promise<any>;
}

export default function ProviderInformation({
  patient,
  careTeams,
  relatedProviders,
  providerOption,
  open,
  isLoading,
  workflowInstanceData,
  onClose,
  handlerSave,
}: Props) {
  const theme = useTheme();
  const { i18n } = useLocales();
  const { userName } = useAuth()?.getCurrentUser();
  const { configurations } = useTenantConfigData();

  const title = [
    `${i18n('patientEngagement.details.title', 'engagement')}`,
    `${i18n('patientEngagement.details.workflow.step.intake', 'engagement')}`,
    `${i18n(
      'patientEngagement.details.workflow.checklist.providerInformation.title',
      'engagement'
    )}`,
  ];

  const [{ providers }, updateState] = useObjectState<ProviderInformationFormState>({
    providers: null,
  });

  const [
    {
      isSearchMemberOpen,
      selectedProviderRole,
      selectedOptions,
      selectedFilterRole,
      selectedOptionCustomizedButton,
    },
    updateSelectedMember,
  ] = useObjectState<ProviderSelectedMemberState>(
    getInitialSelectedState(relatedProviders, null, false)
  );

  const notApplicableExternal = useMemo(() => 
    workflowInstanceDataProvider(workflowInstanceData, 'notApplicable'), 
  [workflowInstanceData]);

  useEffect(() => {
    if (!open) {
      updateSelectedMember({ isSearchMemberOpen: false });
      return;
    }

    updateSelectedMember(
      getInitialSelectedState(
        relatedProviders,
        providerOption,
        isSearchMemberOpen,
        notApplicableExternal
      )
    );
    updateState({ providers: relatedProviders });
  }, [open, relatedProviders, providerOption, notApplicableExternal]);

  useEffect(() => {
    updateSelectedMember({
      ...getInitialSelectedState(
        providers,
        providerOption,
        isSearchMemberOpen,
        notApplicableExternal ?? []
      ),
      selectedFilterRole
    });
  }, [providers, providerOption, selectedFilterRole]);

  const handleOnSave = async () => {
    try {
      let payload: PayloadProvider;

      const providersToReference =
        (providers?.map((e: GridRow) => ({
          reference: `${e?.memberType}/${e?.id}`,
        })) as Reference[]) ?? [];

      let [, , practitionerRoleIds] = getRelatedIds(providersToReference);

      let [organizationIds, practitionerIds, practitionerRoleIdsPatient] = getRelatedIds(
        patient?.generalPractitioner ?? []
      );

      const removeCareTeam = careTeams
        ?.filter(
          (c) =>
            !providers?.some(
              (p) =>
                p.memberType === 'CareTeam' &&
                `${p.memberType}/${p.id}` === `${c?.resourceType}/${c?.id}`
            )
        )
        ?.map((r) => {
          delete r.subject;
          return r;
        });

      const addCareTeam = providers
        ?.filter(
          (p) =>
            p.memberType === 'CareTeam' &&
            has(p, 'resource') &&
            !careTeams?.some((c) => `${c?.resourceType}/${c?.id}` === `${p.memberType}/${p.id}`)
        )
        ?.map((r: any) => {
          if (r?.resource === undefined) return;
          return {
            ...r?.resource,
            subject: {
              display: patient?.getFullName(),
              reference: `${patient?.resourceType}/${patient?.id}`,
            },
          };
        });

      const workflowDataScope = workflowInstanceDataProvider(workflowInstanceData, 'both');
        
      selectedOptionCustomizedButton?.forEach(provider => {
        const isTitleNA = provider?.title === 'N/A';
        const providerIndex = workflowDataScope.findIndex(existingProvider =>
          existingProvider.role === provider.role
        );
      
        const selectedProvider = providers?.find(e => e?.roleName === provider.role);
        if (!isTitleNA && !selectedProvider) return;
      
        const providerData = {
          role: provider.role,
          fhirReference: isTitleNA ? null : getReferenceCanonical(selectedProvider),
          assignedDate: moment().toISOString(),
          assignedBy: userName,
          source: "workflow-provider"
        };
      
        if (providerIndex !== -1) {
          const existingProvider = workflowDataScope[providerIndex];
      
          const shouldUpdate = isTitleNA
            ? existingProvider.assignedBy !== userName || existingProvider.fhirReference !== null
            : existingProvider.fhirReference !== providerData.fhirReference;
      
          if (shouldUpdate) {
            workflowDataScope[providerIndex] = { ...existingProvider, ...providerData };
          }
        } else {
          workflowDataScope.push(providerData);
        }
      });

      payload = {
        generalPractitioner: [...organizationIds, ...practitionerIds, ...practitionerRoleIds],
        removeCareTeam: removeCareTeam ?? [],
        addCareTeam: addCareTeam ?? [],
        workflowDataScope,
      };

      updateSelectedMember({ isSearchMemberOpen: false });
      await handlerSave(payload);
    } catch (error) {}
  };

  const setNotApplicable = () => {
    const label = selectedOptions?.find((e) => e?.code === selectedFilterRole)?.label;

    const notApplicable = selectedOptionCustomizedButton.map((item) => {
      if (item.role === label) {
        return notApplicableButton(item.role);
      }
      return item;
    });

    const updateProviders = providers?.filter((e) => e?.roleName !== label) ?? null;

    updateSelectedMember({
      ...getInitialSelectedState(
        updateProviders,
        providerOption,
        isSearchMemberOpen,
        notApplicable
      ), 
      selectedFilterRole
    });
  };
  
  const disabledButton = useMemo(
    () => {
      const label = selectedOptions?.find((e) => e?.code === selectedFilterRole)?.label;
      return selectedOptionCustomizedButton?.find((e) => e?.label === 'Confirmed' && e?.role === label)
    },
    [selectedOptionCustomizedButton, selectedFilterRole]
  );

  const careTeamPermissions = useMemo(
    () => ({
      isAllowedToAdd: checkAclValidation({
        acls: [crsAcls.CRS.CASE.CARE_TEAMS.ADD],
      }),
      isAllowedToEdit: checkAclValidation({
        acls: [crsAcls.CRS.CASE.CARE_TEAMS.EDIT],
      }),
      members: {
        isAllowedToAdd: checkAclValidation({
          acls: [crsAcls.CRS.CASE.CARE_TEAMS.MEMBERS.ADD],
        }),
        isAllowedToDelete: checkAclValidation({
          acls: [crsAcls.CRS.CASE.CARE_TEAMS.MEMBERS.DELETE],
        }),
        isAllowedToEdit: checkAclValidation({
          acls: [crsAcls.CRS.CASE.CARE_TEAMS.MEMBERS.EDIT],
        }),
      },
    }),
    []
  );

  return (
    <CustomModal
      keepMounted
      open={open}
      title={title[2]}
      breadcrumbs={title}
      showSaveButton={false}
      showCancelButton={false}
      onCancel={onClose as Function}
      onClose={onClose}
      isLoading={isLoading}
      containerSx={[{ overflow: 'scroll', pb: 0 }, isSearchMemberOpen ? { width: '94vw' } : {}]}
    >
      <>
        <Backdrop
            sx={{ background: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container my={3} justifyContent={'space-between'}>
          <Grid item xs={isSearchMemberOpen ? 5 : 12}>
            <GridSection mt={0}>
              <Typography variant="caption" sx={{ color: 'gray' }}>
                {`${i18n(
                  'patientEngagement.details.workflow.checklist.providerInformation.comment',
                  'engagement'
                )}`}
              </Typography>
            </GridSection>
            <GridSection>
              <GridItem xs={12}>
                <Stack display="flex" spacing={2}>
                  {selectedOptions &&
                    selectedOptions.map((option: any) => (
                      <CustomizedButton
                        key={uuidv4()}
                        customizedButton={selectedOptionCustomizedButton}
                        role={option.label}
                        handler={() => {
                          const optionAsValue = option as TaskSelectedMemberValue;
                          const optionAsOption = option as TaskSelectedMemberOption;
                          if (!optionAsValue?.resourceType && optionAsOption) {
                            updateSelectedMember({
                              isSearchMemberOpen: true,
                              selectedProviderRole: optionAsOption,
                              selectedFilterRole: option.code,
                            });
                            updateState({ providers: relatedProviders })
                            return;
                          }

                          updateSelectedMember({
                            selectedProviderRole: null,
                            selectedFilterRole: null,
                          });
                        }}
                      />
                    ))}
                </Stack>
              </GridItem>
            </GridSection>
          </Grid>
          {isSearchMemberOpen && (
            <Grid item xs={6.9}>
              <PermissionsProvider permissions={careTeamPermissions}>
                <SearchMember
                  patient={patient as WrappedPatient}
                  onSelectResource={(resource, includedResources, row: any) => {
                    if (!row) return;

                    const rowMap = resource
                      ? row
                      : {
                          ...(getRowsMapping?.[row.resourceType as MemberResourceTypes]?.(
                            { resource: row } as any as MemberWrappedResourceTypes
                          ) || {}),
                          isSelected: row.isSelected,
                        };
                  
                    if (!rowMap) return;
                  
                    updateState((prevState) => {
                      const currentProviders = prevState.providers || [];
                      const isSelected = rowMap.isSelected;
                  
                      return {
                        providers: isSelected
                          ? currentProviders.filter((provider) => provider.id !== rowMap.id)
                          : [...currentProviders, rowMap],
                      };
                    });
                  }}
                  onlyCareTeam={true}
                  careTeamMembers={relatedProviders}
                  filterRole={selectedFilterRole}
                  onClear={() => {
                    updateSelectedMember({
                      selectedProviderRole: null,
                      isSearchMemberOpen: false,
                      selectedFilterRole: null,
                    });
                    updateState({ providers: null });
                  }}
                  externalResourceType={(() => {
                    const selectedOption = selectedProviderRole as ProviderOption;
                    return selectedOption?.value as SearchMemberProps['externalResourceType'];
                  })()}
                />
              </PermissionsProvider>
              <Button
                onClick={() => setNotApplicable()}
                variant="contained"
                disableElevation
                color="inherit"
                sx={{
                  position: 'absolute',
                  marginTop: '32px',
                  color: 'common.white',
                  bgcolor: theme.palette.common.black,
                  '&:hover': {
                    backgroundColor: theme.palette.common.black,
                  },
                }}
                disabled={isLoading}
              >
                Not Applicable
              </Button>
            </Grid>
          )}
          <Grid item xs={12} sx={{ mt: '32px', display: 'flex', justifyContent: 'flex-end' }}>
            <CancelButton
              onClick={() => {
                onClose?.({}, 'backdropClick');
              }}
            />
            {isSearchMemberOpen && (
              <CustomLoadingButton
                disabled={!disabledButton || isLoading}
                loading={isLoading}
                onClick={handleOnSave}
                variant="contained"
              >
                {i18n('patientEngagement.details.confirm', 'engagement')}
              </CustomLoadingButton>
            )}
          </Grid>
        </Grid>
      </>
    </CustomModal>
  );
}

interface CustomizedButtonProps {
  customizedButton: selectedOptionCustomizedButton[];
  role: string;
  handler: (option: any) => any;
}

function CustomizedButton({ customizedButton, role, handler }: CustomizedButtonProps) {
  const getCustomButtonProperties = customizedButton.filter((e) => e.role === role);

  const { icon, title, sub, label, labelColor } = getCustomButtonProperties[0];

  return (
    <ListItemButton
      sx={{
        pl: 0,
        height: 48,
        typography: 'body2',
        color: 'text.secondary',
        textTransform: 'capitalize',
        border: `1px dashed #e0e0e0`,
        borderRadius: 1,
        bgcolor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 7,
        },
      }}
      disableTouchRipple
      alignItems="center"
      onClick={handler}
    >
      <ListItemIcon sx={{ mx: 1 }}>{icon}</ListItemIcon>
      <ListItemText
        disableTypography
        primary={title}
        sx={{ fontWeight: 'bold' }}
        secondary={
          <Typography
            sx={{ display: 'inline', ...(title && { pl: 1 }) }}
            variant="body2"
            color="text.secomdary"
          >
            {sub}
          </Typography>
        }
      />
      <StatusChip label={label} color={labelColor} size="small" />
    </ListItemButton>
  );
}

const getInitialSelectedState = (
  providers: GridRow[] | null,
  providerOption: ProviderOption[] | null,
  isSearchMemberOpen: boolean,
  existingOptions: any = []
) => {
  const options = existingOptions?.filter((e: any) => e?.title === 'N/A');
  const getOptions = getSelectOptionCustomButton(providers ?? [], providerOption ?? []);

  const updatedArray = (getOptions ?? [])
    .map((item1: any) => {
      const item2 = (options ?? []).find((item: any) => item.role === item1.role);
      if (!item1.title && item2?.title === 'N/A') {
        return item2;
      }
      return item1;
    })
    .filter((item) => item !== undefined);

  return {
    isSearchMemberOpen,
    selectedProviderRole: null,
    selectedOptions: providerOption,
    selectedFilterRole: null,
    selectedOptionCustomizedButton: [...(updatedArray ?? [])],
  };
};

const getCustomButton = (resource: GridRow[], role: string) => {
  if (resource.length > 1) {
    return {
      title: null,
      sub: `Multiple ${resource?.[0]?.roleName} Detected`,
      role: resource?.[0]?.roleName ?? '',
      icon: <GroupRemove htmlColor="red" />,
      label: 'Fix',
      labelColor: 'error' as LabelColor,
    };
  } else if (resource.length === 1) {
    return {
      title: resource?.[0]?.name ?? '',
      sub: resource?.[0]?.roleName ?? '',
      role: role ?? '',
      icon: <HowToReg htmlColor="#00ab55" />,
      label: 'Confirmed',
      labelColor: 'success' as LabelColor,
    };
  } else {
    return {
      title: null,
      sub: role ?? '',
      role: role ?? '',
      icon: <PersonSearch />,
      label: 'Select',
      labelColor: 'default' as LabelColor,
    };
  }
};

export const getSelectOptionCustomButton = (
  resource: GridRow[],
  providerOption: ProviderOption[]
) => {
  try {
    const resourceArray = resource
    ? Array.isArray(resource)
      ? resource
      : [resource]
    : [];
  
    const customButtonArray = providerOption
    ?.map((option: ProviderOption) => {
      const resourceByRole = resourceArray?.filter(
        (item: GridRow) =>
          item?.roleId === option?.code || item?.roleName === option?.value
      );
      return getCustomButton(resourceByRole, option.label);
    })
    .flat();
  return customButtonArray;
  } catch (error) {
    return [];
  }
};

const getRelatedIds = (references: Reference[]) => {
  return (
    references?.reduce<[string[], string[], string[]]>(
      ([organizations, practitioners, practitionerRoles], reference) => {
        const [resourceType, resourceId] = getValuesFromReference(reference.reference);

        if (!resourceId) return [organizations, practitioners, practitionerRoles];
        switch (resourceType) {
          case 'Practitioner':
            practitioners.push(`${resourceType}/${resourceId}`);
            break;
          case 'Organization':
            organizations.push(`${resourceType}/${resourceId}`);
            break;
          case 'PractitionerRole':
            practitionerRoles.push(`${resourceType}/${resourceId}`);
            break;
        }
        return [organizations, practitioners, practitionerRoles];
      },
      [[], [], []]
    ) ?? []
  );
};

const notApplicableButton = (role: string) => {
  return {
    title: 'N/A',
    sub: role,
    role: role,
    icon: <HowToReg htmlColor="#00ab55" />,
    label: 'Confirmed',
    labelColor: 'success' as LabelColor,
  }
};

export const workflowInstanceDataProvider = (workflowInstanceData: any, option: string) => {
  const workflowInstanceDataStep =
    workflowInstanceData && Object.keys(workflowInstanceData).length > 0
    ? get(workflowInstanceData, 
      'scope.checklistItem.PROVIDER_INFORMATION_INTAKE_ITEM.modifiedFields.providers', '')
    : '';
    
  const getDataProvider = !isEmpty(workflowInstanceDataStep) ? 
    workflowInstanceDataStep : [];
  
  const result = getDataProvider.reduce((acc, e) => {
    switch(option){
      case 'notApplicable':
        if (!e?.fhirReference && e?.role) {
          acc.push(notApplicableButton(e.role));
        }
        break;
      case 'applicable':
        if (e?.fhirReference && e?.role) {
          acc.push(e.role);
        }
        break;
      case 'both':
        if (e?.role) {
          acc.push(e);
        }
        break;
    }
    return acc;
  }, []);
  
  return result;
}

const getReferenceCanonical = (provider: any) => 
  `${provider?.memberType}/${provider?.id}${provider?.versionId ? `|${provider.versionId}` : ''}`;