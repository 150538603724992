import { useEffect } from 'react';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import {
  Cake as CakeIcon,
  ContactMail as ContactMailIcon,
  ContactPhone as ContactPhoneIcon,
  Person as PersonIcon,
  Place as PlaceIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Person, PersonStatus } from '../../types';
import useGridState from '../../hooks/useGridState';
import useFirebaseCollectionList from '../../hooks/useFirebaseCollectionList';
import { dateRangeErrorMessage, defaultSorting } from '../../utils/constants';
import PersonsListFilter, { PersonFilters } from './PersonsListFilter';
import CellRow from '../../components/CellRow';
import { capitalizeWords } from 'src/sections/careflow/permissions/permissions.helpers';
import { trimMultipleWhiteSpaces } from 'src/utils/string';
import { formatDate, getAge } from 'src/utils/dates';
import { getTelecomBySystem } from 'src/utils/fhir';
import { formatUSAddress } from 'src/utils/address';
import ListContainer from '../../components/ListContainer';
import CustomDataGrid from '../../components/CustomDataGrid';
import { getCreatedOnGridColDef, getUpdatedOnGridColDef } from '../../utils/UI/gridColumns';
import SeverityStatus, { AlertSeverity } from 'src/components/SeverityStatus';
import { QueryConstraint, where } from 'firebase/firestore';
import { wrapPerson } from '../../utils/wrappers/Person';
import { filterByDateRange, filterByString, filterByStringExactMatch } from '../../utils/firestore';
import useLocales from 'src/hooks/useLocales';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

const PersonsList = () => {
  const { i18n } = useLocales();
  const { configurations } = useTenantConfigData();
  const [
    {
      orderBy,
      orderDirection,
      rowsPerPage,
      page,
      startAfter,
      rowsTotalCount,
      isFilterDrawerOpen,
      filtersForm,
      queryFilters,
      selectedFilterName,
    },
    {
      handleChangeRowsPerPage,
      handlePageChange,
      handleSortModelChange,
      onStartAfterInternalChange,
      onRowsTotalCountChange,
      setQueryFilters,
      setSelectedFilterName,
      setIsFilterDrawerOpen,
    },
  ] = useGridState<PersonFilters>(defaultSorting, {
    id: '',
    status: [],
    createdBy: '',
    createdOn: null,
    updatedBy: '',
    updatedOn: null,
  });

  const {
    data,
    isFetching: isLoading,
    count: totalPersonsCount,
  } = useFirebaseCollectionList<Person>(
    'Persons',
    configurations?.yocare,
    {
      orderBy,
      orderDirection,
      startAfter,
      limit: rowsPerPage,
    },
    queryFilters
  );
  const [persons, personsDocs] = data ?? [];

  useEffect(() => {
    onStartAfterInternalChange(personsDocs?.[personsDocs?.length - 1]);
  }, [personsDocs]);

  useEffect(() => {
    if (!totalPersonsCount) return;
    if (totalPersonsCount === rowsTotalCount) return;
    onRowsTotalCountChange(totalPersonsCount);
  }, [totalPersonsCount]);

  const handleFiltering = () => {
    let queryFilters: QueryConstraint[] = [];

    switch (selectedFilterName) {
      case 'id':
        queryFilters = filterByStringExactMatch('id', filtersForm.values.id);
        break;
      case 'createdBy':
      case 'updatedBy':
        queryFilters = filterByString(selectedFilterName, filtersForm.values?.[selectedFilterName]);
        break;
      case 'createdOn':
      case 'updatedOn':
        const filter = filterByDateRange(
          selectedFilterName,
          filtersForm.values?.[selectedFilterName]?.start,
          filtersForm.values?.[selectedFilterName]?.end
        );
        if (!filter) {
          filtersForm.setFieldError('createdOn', dateRangeErrorMessage);
          return;
        }
        queryFilters = filter;
        break;
      default:
        queryFilters = [];
        break;
    }
    if (filtersForm.values.status.length)
      queryFilters.push(where('status', 'in', filtersForm.values.status));
    if (queryFilters.length) handleSortModelChange([{ field: '', sort: null }]);
    setQueryFilters(queryFilters);
    setIsFilterDrawerOpen(false);
  };

  return (
    <>
      <ListContainer
        pageTitle={i18n('yocare.personsList.title')}
        listTitle={i18n('yocare.personsList.title')}
        breadCrumbsLinks={[
          { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
          { name: `${i18n('yocare.userList.yocareTitle')}` },
          { name: `${i18n('yocare.personsList.title')}`, href: PATH_DASHBOARD.yoCare.persons },
        ]}
        onFilterIconPress={() => {
          setIsFilterDrawerOpen(true);
        }}
        isFiltering={!!queryFilters.length}
      >
        <CustomDataGrid
          rows={persons ?? []}
          getRowId={({ id }: Person) => id}
          onRowDoubleClick={(params) =>
            window.open(`/dashboard/yocare/personDetails/${params.id}`, '_blank')
          }
          getRowHeight={() => 160}
          autoHeight={true}
          columns={personsGridColumns}
          loading={isLoading}
          rowCount={rowsTotalCount}
          page={page - 1}
          sortModel={orderBy ? [{ field: orderBy, sort: orderDirection ?? 'asc' }] : []}
          onSortModelChange={handleSortModelChange}
          pageSize={rowsPerPage}
          onPageSizeChange={handleChangeRowsPerPage}
          onPageChange={handlePageChange}
        />
      </ListContainer>
      <PersonsListFilter
        selectedFilterName={selectedFilterName}
        onSetSelectedFilterName={setSelectedFilterName}
        containerSx={{ width: '23vw' }}
        open={isFilterDrawerOpen}
        onCloseIconButtonClick={() => {
          setIsFilterDrawerOpen(false);
        }}
        onApplyButtonClick={() => {
          handleFiltering();
        }}
        formik={filtersForm}
        onClearAllButtonClick={() => {
          setSelectedFilterName(null);
          filtersForm.resetForm();
        }}
      />
    </>
  );
};

const personsGridColumns: GridColDef[] = (
  [
    {
      flex: 1.4,
      field: 'id',
      headerName: 'Id',
      renderCell: (params) => {
        const { id } = params.row as Person;
        return <CellRow title={id} />;
      },
    },
    {
      flex: 2,
      field: 'personalInfo',
      headerName: 'Personal Info',
      renderCell: (params) => {
        const person = wrapPerson(params.row as Person);
        const { name: names, birthDate, gender } = person;
        const name = names?.[0];
        const formattedName = capitalizeWords(
          trimMultipleWhiteSpaces(`${name?.given?.join?.(' ') ?? ''} ${name?.family ?? ''}`).trim()
        );
        const date = moment(birthDate ? birthDate : null);
        let formattedBirthDate = formatDate(date);
        if (!date.isValid()) formattedBirthDate = '';

        return (
          <Box>
            <CellRow
              title={`${formattedName} ${gender ? `(${capitalizeWords(gender)})` : ''}`.trim()}
              tooltipTitle="Name"
              Icon={PersonIcon}
            />

            <CellRow
              title={formattedBirthDate ? `${formattedBirthDate} (${getAge(date)} yr)` : ''}
              tooltipTitle="Birth Date"
              Icon={CakeIcon}
            />
          </Box>
        );
      },
    },
    {
      flex: 2,
      field: 'contactInfo',
      headerName: 'Contact Info',
      renderCell: (params) => {
        const { telecom, address: addresses } = params.row as Person;
        const email = getTelecomBySystem(telecom, 'email')?.value;
        const phone = getTelecomBySystem(telecom, 'phone')?.value;
        const address = formatUSAddress(addresses?.[0]);

        return (
          <Box>
            <CellRow title={email} tooltipTitle="Email" Icon={ContactMailIcon} />
            <CellRow title={phone} tooltipTitle="Phone" Icon={ContactPhoneIcon} />
            <CellRow
              titleSx={{ whiteSpace: 'pre-line' }}
              title={address}
              tooltipTitle="Address"
              Icon={PlaceIcon}
            />
          </Box>
        );
      },
    },
    {
      flex: 2,
      field: 'dataSystems',
      headerName: 'Data Systems',
      valueGetter: (params) => {
        const { data_systems } = params.row as Person;
        const dataSystems = (data_systems?.map?.(({ name }) => name)?.filter?.((name) => !!name) ??
          []) as string[];
        return dataSystems.join(',');
      },
    },
    {
      flex: 1.4,
      field: 'status',
      headerName: 'Status',
      renderCell: (params) => {
        const { status } = params.row as Person;
        let severity: AlertSeverity = AlertSeverity.INFO;
        switch (status) {
          case PersonStatus.AUTHORIZED:
            severity = AlertSeverity.SUCCESS;
            break;
          case PersonStatus.PENDING:
            severity = AlertSeverity.WARNING;
            break;
          default:
            severity = AlertSeverity.INFO;
            break;
        }

        return (
          <SeverityStatus
            status={{
              message: capitalizeWords(status ?? ''),
              severity,
            }}
          />
        );
      },
    },
    getCreatedOnGridColDef({ flex: 2 }),
    getUpdatedOnGridColDef({ flex: 2 }),
  ] as GridColDef[]
).map((row) => ({ ...row, headerAlign: 'left' }));

export default PersonsList;
