import SummaryTable from './SummaryTable';
import useTable from 'src/hooks/useTable';
import { useEffect, useState } from 'react';
import { caseService } from 'src/crs/case/service';
import { PaginateQuery } from 'src/api/pagination/dtos';
import { caseDtoToCaseManager } from 'src/sections/crs/common/common-utils';
import { TABLE_HEAD_CASE_PATIENT } from 'src/sections/crs/common/table-head';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { CaseForm } from './CaseForm';
import { PatientWrapper } from '../../../../../@nicheaim/fhir-base/wrappers/Patient';
import { usePatient } from '../../../../../@nicheaim/fhir-react';
import { useSnackbar } from 'notistack';
import useAuth from '../../../../../hooks/useAuth';
import { translateTableHead } from 'src/sections/crs/helpers/common';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

export default function PatientCase({ patientId, disableActions }: any) {
  const [caseData, setCaseData] = useState<any | undefined | null>(null);
  const [refresh, setRefresh] = useState(false);
  const [add, setOpenAdd] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const authUser = useAuth().getCurrentUser();
  const { configurations } = useTenantConfigData();

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultRowsPerPage: 5,
    defaultOrderBy: 'id',
    initialIndex: 1,
    defaultCurrentPage: 1,
  });

  const [patient] = usePatient(patientId, { map: PatientWrapper });

  const getPaginateQuery = () => {
    const paginationQuery: PaginateQuery = { page: page, limit: rowsPerPage };
    paginationQuery.filter = { indexedSubjectFhirRefUri: `${configurations?.fhir?.baseUrl}/${patientId}` };
    return paginationQuery;
  };

  const getCaseList = async () => {
    const cases = await caseService.getAll(getPaginateQuery());
    if (cases) {
      setCaseData(cases);
    }
  };

  useEffect(() => {
    getCaseList();
  }, [page, rowsPerPage, patientId]);

  useEffect(() => {
    if (refresh) {
      getCaseList();
      setRefresh(false);
    }
  }, [refresh, caseData]);

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(event, newPage + 1);
  };

  const handleRefresh = () => {
    setRefresh(true);
  };

  const handleCase = async (data: any) => {
    try {
      const payload = { ...data, assignedTo: authUser?.email };
      const result = await caseService.create(payload);
      handleRefresh();
      enqueueSnackbar('Case Created Succesfully', {
        variant: 'success',
      });
    } catch (error) {
      console.log('DEBUG handleCase data: ', data);
      enqueueSnackbar('There was an error trying to create case. Please try again', {
        variant: 'error',
      });
    }
  };

  const data = caseData?.data ? caseData.data.map((r: any) => caseDtoToCaseManager(r)) : [];

  const actionButtons = (
    <Button
      size="small"
      sx={{ height: '36px' }}
      startIcon={<Add />}
      onClick={() => setOpenAdd(true)}
    >
      Add
    </Button>
  );

  const permissions = checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CASES.EDIT] });

  return (
    <>
      <SummaryTable
        title="Cases"
        columns={translateTableHead(TABLE_HEAD_CASE_PATIENT, 'crs')}
        data={data}
        page={page}
        count={caseData ? caseData.meta.totalItems : 0}
        rowsPerPage={rowsPerPage}
        workflowType={'CASES_WORKFLOW'}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={onChangeRowsPerPage}
        handleRefresh={handleRefresh}
        actions={permissions && !disableActions ? actionButtons : null}
      />
      <CaseForm
        patient={patient}
        carePlan={null}
        open={add}
        onClose={() => setOpenAdd(false)}
        handleCase={handleCase}
      />
    </>
  );
}
