import { NestedMenuItem } from 'mui-nested-menu';
import { TaskWrapper, WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import ActionButton from 'src/components/ActionButton';
import {
  Edit as EditIcon,
  ChevronRight as ChevronRightIcon,
  Check as CheckIcon,
  AccessTime as AccessTimeIcon,
  Person as PersonIcon,
  CalendarToday as CalendarTodayIcon,
  CopyAllRounded as CopyTaskIcon,
  NoteAddOutlined as NoteIcon,
  Link as LinkIcon,
  OpenInBrowser,
  OpenInNew,
} from '@mui/icons-material';
import { Box, MenuItem, SxProps } from '@mui/material';
import { TaskStatus, getTaskOwnerByReference, getOwnerDisplayLabel } from './TaskModal';
import useObjectState from 'src/hooks/useObjectState';
import { getValueSetConceptValue } from 'src/sections/crs/helpers/common';
import { useEffect, useMemo, useState } from 'react';
import { onSuccess } from 'src/@types/crs/case';
import { updateTask } from 'src/services/api/case';
import { useSnackbar } from 'notistack';
import SearchTextField from 'src/components/SearchTextField';
import moment from 'moment';
import { getSeverityDueDateData } from 'src/sections/crs/helpers/getSeverityDueDateData';
import DatePickerMoment from 'src/components/DatePickerMoment';
import { Task } from 'src/@nicheaim/fhir-base/mappings/Task';
import useTaskContext from 'src/hooks/useTaskContext';
import { itsNatTask } from 'src/nat/helpers/itstasknat';
import usePermissionsContext from 'src/hooks/usePermissionsContext';
import { taskHandlerMap, TaskPermissions } from 'src/sections/crs/types';
import useLocales from 'src/hooks/useLocales';
import { isValidURL } from 'src/utils/url';
import { useTask } from '../../../../../@nicheaim/fhir-react';
import Iconify from 'src/components/Iconify';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

export type onResourceAction = (task: WrappedTask) => void;

export interface UrlData {
  url: string | undefined;
  label: string | undefined;
}

export type OnInternalUrlClick = (urlData: UrlData) => void;

export interface TaskActionMenuProps {
  onEditTask: onResourceAction;
  // task: WrappedTask;
  taskExternal?: WrappedTask | null;
  taskId?: string;
  isNestedGrid?: boolean;
  onSuccessfulEdit: onSuccess;
  onSearchMemberOpen: onResourceAction;
  onCopyTask: onResourceAction;
  onAddNoteToTask: onResourceAction;
  taskStatuses: TaskStatus[];
  onInternalUrlClick?: OnInternalUrlClick;
  onSubTask?: onResourceAction;
  isLoading?: boolean;
  taskHandlerMap?: taskHandlerMap[];
}

interface TaskActionState {
  status: string | null;
  endDate: moment.Moment | null;
  taskStatusOptions: TaskStatus[];
}

const TaskActionMenu = ({
  // task,
  taskExternal,
  taskId,
  isNestedGrid,
  onEditTask,
  onSuccessfulEdit,
  onSearchMemberOpen,
  onCopyTask,
  onAddNoteToTask,
  taskStatuses,
  onInternalUrlClick,
  onSubTask,
  isLoading,
  taskHandlerMap
}: TaskActionMenuProps) => {
  const [verifiedNatTaskBoolean, setVerifiedNatTaskBoolean] = useState(false);

  const [taskRecord, { refresh: refreshTask } ] = useTask(taskId, {
    map: TaskWrapper,
    autofetch: !!taskId,
  });

  useEffect(() => { 
    if(isLoading){
      refreshTask();
    }
  },[isLoading]);

  const task = useMemo(() => {
    if (taskExternal) return taskExternal;
    if (taskRecord) return taskRecord;
    return null;
  }, [taskRecord, taskExternal]);

  const [{ status, endDate }, updateState] = useObjectState<TaskActionState>({
    status: task?.status ?? null,
    taskStatusOptions: [],
    endDate: null,
  });

  const { i18n } = useLocales();
  const { menuTaskHandlers } = useTaskContext();
  const { notes } = usePermissionsContext<TaskPermissions>() ?? {};

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const selectedStatus = getValueSetConceptValue(taskStatuses, task?.status);
    updateState({
      status: selectedStatus?.code ?? null,
      endDate: moment(task?.getDueDate() ?? null).isValid() ? moment(task?.getDueDate()) : null,
      taskStatusOptions: [
        ...taskStatuses,
        ...(selectedStatus && !taskStatuses.find(({ code }) => code === selectedStatus?.code)
          ? [selectedStatus]
          : []),
      ],
    });
    if (task) {
      const verifiedNatTask = itsNatTask(task);
      setVerifiedNatTaskBoolean(verifiedNatTask);
    }
  }, [task, taskStatuses]);

  const taskUrlResources = task?.getURLResources?.() ?? [];

  return (
    <ActionButton
      renderChildren={(isMenuOpen, setIsMenuOpen) => (
        <Box>
          {task ? (
            <>
              <NestedMenuItem
                sx={nestedMenuItemStyles}
                onClick={() => {
                  setIsMenuOpen(false);
                  onEditTask(task);
                }}
                leftIcon={<EditIcon htmlColor="#00ab55" />}
                rightIcon={null}
                label="Edit"
                parentMenuOpen={isMenuOpen}
              />
              <NestedMenuItem
                disabled={verifiedNatTaskBoolean ? 
                  !checkAclValidation({ acls: [crsAcls.NAT.TASKS.EDIT] }) : false
                }
                sx={nestedMenuItemStyles}
                leftIcon={<AccessTimeIcon />}
                rightIcon={<ChevronRightIcon />}
                label="Status"
                parentMenuOpen={isMenuOpen}
              >
                {taskStatuses.map((taskStatus) => (
                  <NestedMenuItem
                    onClick={async () => {
                      if (task?.status) {
                        task.status = taskStatus.code as Task['status'];
                      }
                      updateState({ status: taskStatus.code });
                      const response = await updateTask(
                        { status: taskStatus.code },
                        task?.id as string
                      );
                      if (!response) {
                        enqueueSnackbar('Unable to update Task Status. Please Try Again', {
                          variant: 'error',
                        });
                        return;
                      }
                      onSuccessfulEdit(response);
                    }}
                    key={taskStatus.code}
                    sx={nestedMenuItemStyles}
                    leftIcon={taskStatus.code === status ? <CheckIcon /> : null}
                    rightIcon={null}
                    label={taskStatus.display}
                    parentMenuOpen={isMenuOpen}
                  />
                ))}
              </NestedMenuItem>
              <NestedMenuItem
                disabled={verifiedNatTaskBoolean ? 
                  !checkAclValidation({ acls: [crsAcls.NAT.TASKS.EDIT] }) : false
                }
                sx={nestedMenuItemStyles}
                leftIcon={<PersonIcon />}
                rightIcon={<ChevronRightIcon />}
                label="Owner"
                parentMenuOpen={isMenuOpen}
              >
                <NestedMenuItem
                  sx={nestedMenuItemStyles}
                  leftIcon={
                    getOwnerDisplayLabel(getTaskOwnerByReference(task?.owner)) ? (
                      <CheckIcon />
                    ) : null
                  }
                  rightIcon={null}
                  label={getOwnerDisplayLabel(getTaskOwnerByReference(task?.owner))}
                  parentMenuOpen={isMenuOpen}
                />
                <MenuItem>
                  <SearchTextField
                    fullWidth
                    onFocus={() => {
                      onSearchMemberOpen(task);
                      setIsMenuOpen(false);
                    }}
                  />
                </MenuItem>
              </NestedMenuItem>
              <NestedMenuItem
                disabled={verifiedNatTaskBoolean ? 
                  !checkAclValidation({ acls: [crsAcls.NAT.TASKS.EDIT] }) : false
                }
                sx={nestedMenuItemStyles}
                leftIcon={<CalendarTodayIcon />}
                rightIcon={<ChevronRightIcon />}
                label="Due Date"
                parentMenuOpen={isMenuOpen}
              >
                <NestedMenuItem
                  sx={nestedMenuItemStyles}
                  leftIcon={<CheckIcon />}
                  rightIcon={null}
                  label={
                    getSeverityDueDateData(moment(endDate?.toISOString() ?? null))
                      ?.formattedDueDate ?? ''
                  }
                  parentMenuOpen={isMenuOpen}
                />
                <MenuItem>
                  <DatePickerMoment
                    value={endDate}
                    label={'Select New Due Date'}
                    onChange={async (dueDate) => {
                      updateState({ endDate: dueDate });
                      if (!dueDate?.isValid?.()) return;
                      if (!task?.executionPeriod) task.executionPeriod = { end: undefined };
                      task.executionPeriod.end = dueDate?.toISOString();
                      const response = await updateTask(
                        { endDate: dueDate.toISOString() },
                        task?.id as string
                      );
                      if (!response) {
                        enqueueSnackbar('Unable to update Task Status. Please Try Again', {
                          variant: 'error',
                        });
                        return;
                      }
                      setIsMenuOpen(false);
                      onSuccessfulEdit(response);
                    }}
                  />
                </MenuItem>
              </NestedMenuItem>
              <NestedMenuItem
                sx={nestedMenuItemStyles}
                onClick={() => {
                  setIsMenuOpen(false);
                  onCopyTask(task);
                }}
                leftIcon={<CopyTaskIcon />}
                rightIcon={null}
                label="Copy"
                parentMenuOpen={isMenuOpen}
              />
              {verifiedNatTaskBoolean 
                ? checkAclValidation({ acls: [crsAcls.NAT.TASKS.NOTES.VIEW] }) 
                : notes?.isAllowedToView && (
                  <NestedMenuItem
                    sx={nestedMenuItemStyles}
                    onClick={() => {
                      setIsMenuOpen(false);
                      onAddNoteToTask(task);
                    }}
                    leftIcon={<NoteIcon />}
                    rightIcon={null}
                    label="Notes"
                    parentMenuOpen={isMenuOpen}
                  />
              )}
              {menuTaskHandlers?.map?.(
                ({ icon, label, taskType, handler, conditionToShow }, index) => {
                  const getHandler = taskHandlerMap?.find((e) => e?.taskType === taskType);
                  if (!conditionToShow(task, getHandler?.permission)) return null;
                  return (
                    <NestedMenuItem
                      key={`task-menu-item-${index}`}
                      sx={nestedMenuItemStyles}
                      onClick={() => {
                        setIsMenuOpen(false);
                        handler?.(task, getHandler?.handler);
                      }}
                      leftIcon={icon}
                      rightIcon={null}
                      label={label}
                      parentMenuOpen={isMenuOpen}
                    />
                  );
                }
              )}
              {!!taskUrlResources.length && (
                <NestedMenuItem
                  sx={nestedMenuItemStyles}
                  leftIcon={<LinkIcon />}
                  rightIcon={<ChevronRightIcon />}
                  label={i18n('tasks.resources', 'crs')}
                  parentMenuOpen={isMenuOpen}
                >
                  {taskUrlResources.map((input, index) => {
                    const isInternal = input?.type?.coding?.[0]?.code?.includes?.('internal');
                    const label = input?.type?.text;
                    return (
                      <NestedMenuItem
                        key={`url-resource-${input?.type?.coding?.[0]?.code}-${index}`}
                        onClick={() => {
                          const url = input?.valueUrl;

                          if (!isValidURL(url ?? '')) return;
                          if (!isInternal) return window.open(url, '_blank');

                          onInternalUrlClick?.({ url, label });
                        }}
                        sx={nestedMenuItemStyles}
                        leftIcon={isInternal ? <OpenInBrowser /> : <OpenInNew />}
                        rightIcon={null}
                        label={label}
                        parentMenuOpen={isMenuOpen}
                      />
                    );
                  })}
                </NestedMenuItem>
              )}
              {!isNestedGrid && onSubTask && (
                <NestedMenuItem
                  disabled={verifiedNatTaskBoolean}
                  sx={nestedMenuItemStyles}
                  onClick={() => {
                    setIsMenuOpen(false);
                    onSubTask?.(task);
                  }}
                  leftIcon={<Iconify icon="mdi:subtasks" height={20} width={20}/>}
                  rightIcon={null}
                  label="Add Sub Task"
                  parentMenuOpen={isMenuOpen}
                />
              )}
            </>
          ) : null}
        </Box>
      )}
    />
  );
};

const nestedMenuItemStyles: SxProps = {
  paddingX: 3,
};

export default TaskActionMenu;
