import { HealthcareService } from 'src/@nicheaim/fhir-base/mappings/HealthcareService';
import { Location } from 'src/@nicheaim/fhir-base/mappings/Location';
import { Organization } from 'src/@nicheaim/fhir-base/mappings/Organization';
import { WrappedValueSet } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { IApiClient } from 'src/api/clients/api_client';
import { PaginateQuery, paginateQueryToURLParams } from 'src/api/pagination/dtos';
import { fhirClient } from 'src/App';
import { ContactAttemptDto } from 'src/crs/dto/contact-attempt.dto';
import { CreateReferralDto } from 'src/crs/dto/create-referral.dto';
import { ReferralDto } from 'src/crs/dto/referral.dto';
import { UpdateReferralDto } from 'src/crs/dto/update-referral.dto';
import { ActivityDefinition, Bundle, PlanDefinition } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { getFhirIdFromReferenceString } from 'src/sections/careflow/tasks-activities/components/Activities/activities-utils';
import { getValueSetConceptValue } from 'src/sections/crs/helpers/common';
import { ReferralStatuses } from 'src/sections/crs/referral/components/child-referral/constants';
import { getRegistries } from 'src/services/api/registry';
import { getDynamicValueExpressionByPath } from 'src/utils/fhir';
import { convertFilterToPaginateQueryParams } from 'src/utils/gridView';

export interface ReferralResponse {
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
  referralId: string;
  referralUUID: string;
  fhirId: string;
  workflowInstanceId: number;
  workflowStageId: string;
  workflowStageName: string;
  workflowStageOrder: number;
  workflowStatus: string;
  workflowScope: string;
  workflowSubstatus: string;
  workflowDispositionReason: string;
  workflowDispositionNote: string;
  workflowDispositionOn: string;
  workflowDispositionAt: string;
  workflowOwnedBy: string;
  indexedFhirRefUri: string;
  indexedMintReferralId: string;
  indexedsubjectMrn: string;
  indexedsubjectName: string;
  indexedsubjectPhone: string;
  indexedsubjectEmail: string;
  indexedsubjectDob: string;
  indexedSubjectGender: string;
  indexedSubjectRace: string;
  indexedSubjectEthnicity: string;
  indexedRegistrationDate: string;
  indexedStatus: string;
  indexedRequesterFhirRefUri: string;
  indexedRequesterName: string;
  indexedPerformerName: string;
  internalNumber: string;
  createdOnFormatted: string;
  subjectDOBFormatted: string;
  subjectAge: string;
}

export interface PaginatedReferralResponse {
  data: ReferralResponse[];
  links: {
    current: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export interface PaginatedReferralDto {
  data: ReferralDto[];
  links: {
    current: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export interface ReferralContactAttemptResponse {
  referralId: string;
  fhirId: string;
  referralContactAttempId: string;
  referralContactAttempUuid: string;
  referralContactAttempOutcome: string;
  referralContactAttempContactOn: string;
  referralContactAttempNextContactOn: string;
  referralContactAttempNoteId: string;
  noteId: string;
  noteUuid: string;
  noteType: string;
  noteText: string;
}
export interface AssessmentsFormsResponse {
  generic_id: number;
  survey_id: any;
  response_id: any;
  start_language: any;
  survey_type: any;
  submit_date: any;
}

export interface ServiceByFiltersResponse {
  healthcareService?: HealthcareService;
  organization?: Organization;
  location?: Location;
}

export class ReferralApiClient {
  apiBase: string;

  referralApiClient: IApiClient;

  constructor(referralApiClient: IApiClient) {
    this.apiBase = 'crs/referral';
    this.referralApiClient = referralApiClient;
  }

  async getAll(queryParams: PaginateQuery): Promise<PaginatedReferralResponse | undefined> {
    const urlParams = paginateQueryToURLParams(queryParams);

    const filters = convertFilterToPaginateQueryParams(queryParams?.filter ?? {});

    const url = `${this.apiBase}/?${urlParams.toString()}${filters}`;

    try {
      const response = await this.referralApiClient.get<PaginatedReferralResponse | undefined>(url);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async getOne(fhirId: string): Promise<ReferralResponse | undefined> {
    try {
      const response = await this.referralApiClient.get<ReferralResponse | undefined>(
        `${this.apiBase}/${fhirId}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async assessments(surveyId: any, reference: any): Promise<any> {
    try {
      const response = await this.referralApiClient.get<any | undefined>(
        `crs/assessments/${surveyId}?reference=${reference}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async surveyDetail(responseId: any) {
    try {
      const response = await this.referralApiClient.get<any | undefined>(
        `crs/assessments-template/${responseId}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async assessmentsByPatient(fhirPatientId: any): Promise<any> {
    try {
      const response = await this.referralApiClient.get<any | undefined>(
        `crs/assessments-patient/${fhirPatientId}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async create(payload: CreateReferralDto): Promise<ReferralResponse | undefined> {
    try {
      const response = await this.referralApiClient.post<
        CreateReferralDto,
        ReferralResponse | undefined
      >(`${this.apiBase}/`, payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async update(fhirId: string, payload: UpdateReferralDto): Promise<ReferralResponse | undefined> {
    try {
      const response = await this.referralApiClient.put<
        UpdateReferralDto,
        ReferralResponse | undefined
      >(`${this.apiBase}/${fhirId}`, payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async patch(fhirId: string, payload: UpdateReferralDto): Promise<ReferralResponse | undefined> {
    try {
      const response = await this.referralApiClient.patch<
        UpdateReferralDto,
        ReferralResponse | undefined
      >(`${this.apiBase}/${fhirId}`, payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async getAllContactAttempt(
    fhirId: string
  ): Promise<ReferralContactAttemptResponse[] | undefined> {
    try {
      const response = await this.referralApiClient.get<
        ReferralContactAttemptResponse[] | undefined
      >(`${this.apiBase}/contact-attempt/${fhirId}`);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async createContactAttempt(payload: ContactAttemptDto): Promise<any | undefined> {
    try {
      const response = await this.referralApiClient.post<ContactAttemptDto, any | undefined>(
        `${this.apiBase}/contact-attempt`,
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async getServiceRequestsBasedOnResource(resource: string): Promise<ReferralResponse[]> {
    try {
      const response = await this.referralApiClient.get<ReferralResponse[]>(
        `${this.apiBase}/basedon/${resource}`
      );
      return response;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async createChildReferral(payload: any): Promise<any | undefined> {
    try {
      const response = await this.referralApiClient.post<any, undefined>(
        `${this.apiBase}/child-referral`,
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async updateChildReferral(fhirId: string, payload: any): Promise<any | undefined> {
    try {
      const response = await this.referralApiClient.put<any, undefined>(
        `${this.apiBase}/child-referral/${fhirId}`,
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async getServicesByFilters(filters: any) {
    try {
      const nonEmptyFilters = Object.entries(filters).reduce<Record<string, string>>(
        (filters, [key, value]) => {
          if (!value) return filters;
          filters[key] = value as string;
          return filters;
        },
        {}
      );

      const queryParams = new URLSearchParams(nonEmptyFilters).toString();

      let backUrl = `${this.apiBase}/child-referral/service${queryParams ? `?${queryParams}` : ''}`;

      const response = await this.referralApiClient.get<ServiceByFiltersResponse[] | undefined>(
        backUrl
      );

      return response;
    } catch (error) {
      console.error(error);
    }
  }
}

export default class ReferralService {
  referralApiClient: ReferralApiClient;

  constructor(referralApiClient: ReferralApiClient) {
    this.referralApiClient = referralApiClient;
  }

  async getAll(queryParams: PaginateQuery): Promise<PaginatedReferralDto | undefined> {
    const referrals = await this.referralApiClient.getAll(queryParams);
    if (!referrals) return undefined;
    const dtos = referrals.data.map((r) => {
      const dto = new ReferralDto();
      dto.fromReferralResponse(r);
      return dto;
    });
    const mapped: PaginatedReferralDto = { ...referrals, data: dtos };
    return mapped;
  }

  async create(payload: CreateReferralDto): Promise<ReferralDto | undefined> {
    const referral = await this.referralApiClient.create(payload);
    if (!referral) return undefined;
    const dto = new ReferralDto();
    dto.fromReferralResponse(referral);
    return dto;
  }

  async update(fhirId: string, payload: UpdateReferralDto): Promise<ReferralDto | undefined> {
    const referral = await this.referralApiClient.update(fhirId, payload);
    if (!referral) return undefined;
    const dto = new ReferralDto();
    dto.fromReferralResponse(referral);
    return dto;
  }

  async getContactAttempt(fhirId: string): Promise<ReferralContactAttemptResponse[] | undefined> {
    const contactAttempts = await this.referralApiClient.getAllContactAttempt(fhirId);
    if (!contactAttempts) return undefined;
    return contactAttempts;
  }

  async createContactAttempt(payload: ContactAttemptDto): Promise<any | undefined> {
    const contactAttempt = await this.referralApiClient.createContactAttempt(payload);
    if (!contactAttempt) return undefined;
    return contactAttempt;
  }

  async getServiceRequestsBasedOnResource(
    resourceType: string,
    fhirId: string
  ): Promise<ReferralResponse[]> {
    const serviceRequests = await this.referralApiClient.getServiceRequestsBasedOnResource(
      `${resourceType}/${fhirId}`
    );
    return serviceRequests;
  }

  async createChildReferral(payload: any): Promise<any | undefined> {
    const response = await this.referralApiClient.createChildReferral(payload);
    if (!response) return undefined;
    return response;
  }

  async updateChildReferral(fhirId: string, payload: any): Promise<any | undefined> {
    const response = await this.referralApiClient.updateChildReferral(fhirId, payload);
    if (!response) return undefined;
    return response;
  }

  async getServicesByFilters(data: any): Promise<ServiceByFiltersResponse[] | undefined> {
    const response = await this.referralApiClient.getServicesByFilters(data);
    if (!response) return undefined;
    return response;
  }

  async getPlanActivityDefinition(requestIntentVS: WrappedValueSet | null, referralServicesInternal: WrappedValueSet | null){
    try {
      const registry = await getRegistries(undefined, 'nat-plan-definition');
      const natPlanDefinitionNames: string[] = JSON.parse(registry?.data?.[0]?.keyValue);
      const planDefinitionResourcesOther = await fhirClient.get<Bundle>(`PlanDefinition?name=${natPlanDefinitionNames?.join(",")}`);
      const planDefinitionResources = await fhirClient.get<Bundle>(`PlanDefinition?topic=outbound-referral`);

      if (!planDefinitionResources?.entry?.length || !planDefinitionResourcesOther?.entry?.length) return [];
      const entries = [...planDefinitionResources?.entry, ...planDefinitionResourcesOther?.entry]
      const entrieResourcePD = entries.reduce<(PlanDefinition)[]>((resources, { resource }) => {
        if (!resource) return resources;
        return [...resources, resource as PlanDefinition];
      }, []);

      const activityDefinitionIds: string[] = entrieResourcePD?.reduce((acc, e) => {
        const definitions = e?.action
          ?.map((s) => getFhirIdFromReferenceString(s?.definitionCanonical || ''))
          .filter((item): item is string => item !== undefined) ?? [];
        return acc.concat(definitions);
      }, [] as string[]) ?? [];

      const activityDefinitionResources = await fhirClient.get<Bundle>(`ActivityDefinition?_id=${activityDefinitionIds?.join(',')}`);
      if (!activityDefinitionResources?.entry?.length) return [];
      const entrieResourceAD = activityDefinitionResources.entry.reduce<(ActivityDefinition)[]>((resources, { resource }) => {
        if (!resource) return resources;
        return [...resources, resource as ActivityDefinition];
      }, []);

      const mapPlanActivityDefinition = entrieResourcePD?.map((planDefinition) => {
        const relatedActivityDefinition = entrieResourceAD?.find((e) => 
          e?.id === getFhirIdFromReferenceString(planDefinition?.action?.[0]?.definitionCanonical || '')
        );

        const serviceRequestIntent = getValueSetConceptValue(
          requestIntentVS?.asListAll?.() ?? [],
          getDynamicValueExpressionByPath(relatedActivityDefinition?.dynamicValue, 'serviceRequest.intent')
        );

        const serviceRequestStatus = getValueSetConceptValue(
          Object.entries(ReferralStatuses).map((current) => ({
            display: current[1],
            code: current[0],
          })) ?? [],
          getDynamicValueExpressionByPath(relatedActivityDefinition?.dynamicValue, 'serviceRequest.status')
        );

        const serviceRequestCode = getValueSetConceptValue(
          referralServicesInternal?.asListAll() ?? [],
          getDynamicValueExpressionByPath(relatedActivityDefinition?.dynamicValue, 'serviceRequest.code')
        );

        const planDefinitionType = planDefinition?.topic?.map((e) => e?.coding?.[0]?.code);
        const isNAT = natPlanDefinitionNames?.find((e) => e === planDefinition?.name) ? true : false;
        
        return {
          reference: `PlanDefinition/${planDefinition?.id}`,
          title: planDefinition?.title,
          topic: planDefinition?.topic,
          isNAT,
          planDefinitionType,
          serviceRequestIntent,
          serviceRequestStatus,
          serviceRequestCode : serviceRequestCode ? { 
            label: serviceRequestCode?.display, 
            value: {
              healthcareService: {
                "coding": [{...serviceRequestCode}],
                "text": serviceRequestCode?.display
              }
            }
          } : null
        }
      });
      return mapPlanActivityDefinition;
    } catch (error) {
      return [];
    }
  };
}
