//@ts-nocheck
import { useState } from 'react';
import produce from 'immer';
import { Box, Grid, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { PatternFormat } from 'react-number-format';
import Iconify from 'src/components/Iconify';
import { PatientMapper } from 'src/application/adapters/in/ui/model/mappers';
import { PatientWrapper, WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { usePatient } from 'src/@nicheaim/fhir-react';
import PhoneField from './personal-details/PhoneField';
import EmailField from './personal-details/EmailField';
import AddressField from './personal-details/AddressField';
import SexualOrientationSelector from './personal-details/components/SexualOrientationSelector';
import {
  Coding,
  Patient,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import RaceSelector from './personal-details/components/RaceSelector';
import NotificationDialog, {
  INotificationDialogData,
} from 'src/sections/careflow/common/NotificationDialog';
import { normalizeAddresses, normalizePhones } from './utils';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

const MAPPER = new PatientMapper();

const REACT_APP_RACE_URL = 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race';

const maritalStatusOptions = [
  {
    code: 'A',
    display: 'Anulled',
  },
  {
    code: 'D',
    display: 'Divorced',
  },
  {
    code: 'T',
    display: 'Domestic Partner',
  },
  {
    code: 'L',
    display: 'Legally Separated',
  },
  {
    code: 'M',
    display: 'Married',
  },
  {
    code: 'S',
    display: 'Single',
  },
  {
    code: 'W',
    display: 'Divorced',
  },
];

const EditMode = ({ data, onClose, configurations }: any) => {
  const [patient, { update, refresh }] = usePatient(data.id!, {
    map: PatientWrapper,
  });

  const [prevPatientData, setPrevPatientData] = useState<Patient | undefined>();

  const initPhones = data.getPhoneNumbers();
  const initEmails = data.getEmails();
  const initAddresses = data.getAddresses();
  const initAlias = data.getAlias();
  const initMS = data.getMaritalStatus();
  const initRace = data.getRace(configurations?.fhir);
  const initSO = data.getSexualOrientation();
  const initSSN = data.getSSN();

  const [phones, setPhones] = useState(
    initPhones.length > 0 ? initPhones : [{ system: 'phone', use: 'mobile' }]
  );
  const [emails, setEmails] = useState(
    initEmails.length > 0 ? initEmails : [{ system: 'email', use: 'home' }]
  );
  const [addresses, setAddresses] = useState(initAddresses.length > 0 ? initAddresses : [{}]);
  const [alias, setAlias] = useState(initAlias || { given: [], use: 'nickname' });

  const [gender, setGender] = useState(data.getGender() || '');

  const [maritalStatus, setMaritalStatus] = useState(initMS || '');

  const [race, setRace] = useState(initRace);

  const [sexualOrientation, setSexualOrientation] = useState(initSO);

  const [ssn, setSSN] = useState(initSSN || '');
  const initialStateNotificationDialogData: INotificationDialogData = {
    isOpen: false,
    title: '',
    text: '',
  };
  const [notificationDialogData, setNotificationDialogData] = useState<INotificationDialogData>(
    initialStateNotificationDialogData
  );

  const handleAddPhone = () => {
    setPhones([...phones, { system: 'phone', use: 'mobile' }]);
  };

  const handleChangePhoneValue = (value: string, index: number) => {
    const updatedPhones = [...phones];
    updatedPhones[index] = { ...updatedPhones[index], value };
    setPhones(updatedPhones);
  };

  const handleChangePhoneType = (use: string, index: number) => {
    const updatedPhones = [...phones];
    updatedPhones[index] = { ...updatedPhones[index], use };
    setPhones(updatedPhones);
  };

  const handleRemovePhone = (index: number) => {
    setPhones([...phones.slice(0, index), ...phones.slice(index + 1)]);
  };

  const handleFavoritePhone = (value: any, index: number) => {
    const updatedPhones = phones.map((e: any) => ({ ...e, rank: 2 }));
    if (!value) {
      updatedPhones[index].rank = 1;
    }
    setPhones(updatedPhones);
  };

  const handleAddEmail = () => {
    setEmails([...emails, { system: 'email', use: 'home' }]);
  };

  const handleChangeEmailValue = (value: string, index: number) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = { ...updatedEmails[index], value };
    setEmails(updatedEmails);
  };

  const handleChangeEmailType = (use: string, index: number) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = { ...updatedEmails[index], use };
    setEmails(updatedEmails);
  };

  const handleRemoveEmail = (index: number) => {
    setEmails([...emails.slice(0, index), ...emails.slice(index + 1)]);
  };

  const handleFavoriteEmail = (value: any, index: number) => {
    const updatedEmails = emails.map((e: any) => ({ ...e, rank: 2 }));
    if (!value) {
      updatedEmails[index].rank = 1;
    }
    setEmails(updatedEmails);
  };

  const handleChangeAddress = (address: any, index: number) => {
    const updatedAddresses = [...addresses];
    updatedAddresses[index] = address;
    setAddresses(updatedAddresses);
  };

  const handleAddAddress = () => {
    setAddresses([...addresses, { line: ['', ''] }]);
  };

  const handleRemoveAddress = (index: number) => {
    setAddresses([...addresses.slice(0, index), ...addresses.slice(index + 1)]);
  };

  const handleChangeAlias = (ev: any) => {
    const alias = {
      given: [ev.target.value],
      use: 'nickname',
    };
    setAlias(alias);
  };

  const handleChangeGender = (ev: any) => {
    setGender(ev.target.value);
  };

  const handleChangeMaritalStatus = (ev: any) => {
    const { code, display } = maritalStatusOptions.find(
      (option) => option.code === ev.target.value
    );
    const status = {
      coding: [
        {
          code,
          display,
          system: 'https://terminology.hl7.org/5.0.0/CodeSystem-v3-MaritalStatus.html',
        },
      ],
    };
    setMaritalStatus(status);
  };

  const handleChangeRace = (newRace: Coding) => {
    setRace(newRace);
  };

  const handleChangeSexualOrientation = (newSexualOrientation: Coding) => {
    setSexualOrientation(newSexualOrientation);
  };

  const handleChangeSSN = (ev: any) => {
    const ssn = { system: configurations?.fhir?.systemSsn, value: ev.target.value };
    setSSN(ssn);
  };

  const handleOnCloseNotificationDialog = (currentSelectedRadioOption?: string | undefined) => {
    console.log('handleOnCloseNotificationDialog: ', currentSelectedRadioOption);
    if (currentSelectedRadioOption && currentSelectedRadioOption === 'discard') {
      refresh();
    } else if (
      currentSelectedRadioOption &&
      currentSelectedRadioOption === 'override' &&
      prevPatientData
    ) {
      refresh();
      const newPatient: Patient = {
        ...prevPatientData,
      };

      newPatient.meta = { versionId: patient?.meta?.versionId };

      update(newPatient);
    }

    setNotificationDialogData(() => initialStateNotificationDialogData);
    onClose();
  };

  const handleSave = async () => {
    const producedPatient = produce(patient!, (draft) => {
      if (patient?.meta?.versionId) draft.meta = { versionId: patient?.meta?.versionId };
      draft.telecom = [
        ...normalizePhones(phones.filter((p: any) => p.hasOwnProperty('value'))),
        ...emails.filter((e: any) => e.hasOwnProperty('value')),
      ];
      if (gender) draft.gender = gender;

      draft.address = normalizeAddresses(addresses.filter((a: any) => a.hasOwnProperty('line')));

      if (alias) draft.name = [...draft.name!.filter((n: any) => n.use !== 'nickname'), alias];

      draft.maritalStatus = maritalStatus || {};
      draft.identifier = [
        ...(draft.identifier
          ? draft.identifier.filter((i: any) => i.system !== configurations?.fhir?.systemSsn)
          : []),
        ...(ssn ? [ssn] : []),
      ];
      draft.extension = [
        ...(draft.extension
          ? draft.extension.filter(
              (e: any) =>
                e?.url !== configurations?.fhir?.sexualOrientationUrl && e?.url !== REACT_APP_RACE_URL
            )
          : []),
        {
          url: configurations?.fhir?.sexualOrientationUrl,
          valueCodeableConcept: {
            coding: [sexualOrientation],
          },
        },
        {
          url: REACT_APP_RACE_URL,
          valueCodeableConcept: {
            coding: [race],
          },
        },
      ];
    });

    setPrevPatientData(() => producedPatient as Patient);

    await update(producedPatient, {
      onError: (error: any, variables, context) => {
        if (error?.error === 'meta.versionId is required for this ResourceType') {
          console.log(
            `there is an error inside mutation from PersonalDetails meta.versionId doesnt exists`
          );
        } else if (
          error?.error === 'meta.versionId doesnt match the last version on FHIR datastore'
        ) {
          console.log(error?.error);

          setNotificationDialogData(() => ({
            isOpen: true,
            title: 'Error while editing',
            text: 'The Patient has been updated by another user while you were editing it.',
            radioOptions: {
              defatulValue: 'discard',
              radioOptionsList: [
                { value: 'discard', label: 'Discard all my changes and redisplay' },
                {
                  value: 'override',
                  label: 'Apply my changes anyway (changes will be overwritten)',
                },
              ],
            },
          }));
        }
        // An error happened!
        console.log('error: ', error);
      },
    });
    onClose();
  };

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ mb: 1.5 }}
        spacing={2}
      >
        <Typography variant="h6">Personal Details</Typography>
        <Stack direction="row">
          <IconButton onClick={handleSave}>
            <Iconify icon="eva:save-fill" />
          </IconButton>
          <IconButton onClick={onClose}>
            <Iconify icon="eva:close-outline" />
          </IconButton>
        </Stack>
      </Stack>
      <Box sx={{ height: '400px', overflow: 'auto' }}>
        {phones.map((phone: any, index: number) => (
          <PhoneField
            key={`phone-${index}`}
            isLast={phones.length - 1 === index}
            onAdd={() => handleAddPhone()}
            onChangeType={(type) => handleChangePhoneType(type, index)}
            onChangeValue={(value) => handleChangePhoneValue(value, index)}
            onSelectFavorite={(event: React.MouseEvent<HTMLElement>, value: any) =>
              handleFavoritePhone(value, index)
            }
            onRemove={phones.length > 1 ? () => handleRemovePhone(index) : undefined}
            rank={phone.rank}
            type={phone.use}
            value={phone.value}
          />
        ))}
        {emails.map((email: any, index: number) => (
          <EmailField
            key={`email-${index}`}
            isLast={emails.length - 1 === index}
            onAdd={() => handleAddEmail()}
            onChangeType={(type) => handleChangeEmailType(type, index)}
            onChangeValue={(value) => handleChangeEmailValue(value, index)}
            onSelectFavorite={(event: React.MouseEvent<HTMLElement>, value: any) =>
              handleFavoriteEmail(value, index)
            }
            onRemove={phones.length > 1 ? () => handleRemoveEmail(index) : undefined}
            rank={email.rank}
            type={email.use}
            value={email.value}
          />
        ))}
        {addresses.map((addr: any, index: number) => (
          <AddressField
            key={`addr-${index}`}
            isLast={addresses.length - 1 === index}
            value={addr}
            onAdd={() => handleAddAddress()}
            onChange={(addr) => handleChangeAddress(addr, index)}
            onRemove={addresses.length > 1 ? () => handleRemoveAddress(index) : undefined}
          />
        ))}
        <TextField
          fullWidth
          label="Alias"
          margin="dense"
          size="small"
          variant="standard"
          onChange={handleChangeAlias}
          value={alias?.given?.join(' ')}
        />
        <TextField
          fullWidth
          select
          label="Marital Status"
          margin="dense"
          size="small"
          variant="standard"
          value={maritalStatus?.coding?.[0]?.code}
          onChange={handleChangeMaritalStatus}
        >
          {maritalStatusOptions.map((option) => (
            <MenuItem key={option.code} value={option.code}>
              {option.display}
            </MenuItem>
          ))}
        </TextField>
        <RaceSelector onChange={handleChangeRace} value={race} />
        <SexualOrientationSelector
          onChange={handleChangeSexualOrientation}
          value={sexualOrientation}
        />
        <TextField
          fullWidth
          select
          label="Gender"
          margin="dense"
          size="small"
          variant="standard"
          value={gender}
          onChange={handleChangeGender}
        >
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          {/* TODO: Search FHIR values for options */}
          {/* <MenuItem value="non-binary">Non-binary</MenuItem>
        <MenuItem value="transgender-male">Transgender Male</MenuItem>
        <MenuItem value="transgender-female">Transgender Female</MenuItem> */}
          <MenuItem value="other">Other</MenuItem>
          {/* <MenuItem value="non-disclose">Non-disclose</MenuItem> */}
        </TextField>
        <PatternFormat
          fullWidth
          format="###-##-####"
          label="Social Security Number"
          margin="dense"
          size="small"
          variant="standard"
          customInput={TextField}
          onChange={handleChangeSSN}
          value={ssn.value}
        />
        <NotificationDialog
          data={notificationDialogData}
          handleOnClose={handleOnCloseNotificationDialog}
        />
      </Box>
    </>
  );
};

interface DisplayModeProps {
  data: WrappedPatient;
  onClose: () => void;
  configurations: any;
}

const DisplayMode = ({ data, onClose, configurations }: DisplayModeProps) => {
  const phoneNumbers = data.getPhoneNumbers();
  const emails = data.getEmails();
  const alias = data.getAlias();
  const race = data.getRace(configurations?.fhir);
  const addresses = data.getAddressesFormatted();
  const gender = data.getGender();
  const maritalStatus = data.getMaritalStatus();
  const sexualOrientation = data.getSexualOrientation(configurations?.fhir);
  const ssn = data.getSSN(configurations?.fhir?.systemSsn);

  return (
    <>
      <Stack alignItems="center" direction="row" sx={{ mb: 1.5 }}>
        <Typography variant="h6">Personal Details</Typography>
        <IconButton onClick={onClose}>
          <Iconify icon="eva:edit-outline" />
        </IconButton>
      </Stack>
      <Box sx={{ height: '400px', overflow: 'auto' }}>
        <Grid pr={2}>
          <Grid container alignItems="baseline" item xs={12} sx={{ mb: 1.5 }}>
            <Grid item xs={2}>
              <Typography variant="caption">Phone</Typography>
            </Grid>
            <Grid container item xs direction="column">
              {phoneNumbers.map((phone: any, index: number) => (
                <Grid container item direction="row" alignItems="center" key={`phone-${index}`}>
                  <Grid item alignItems="center">
                    <PatternFormat
                      displayType="text"
                      format="###-###-####"
                      variant="subtitle2"
                      value={phone.value}
                      customInput={Typography}
                    />
                  </Grid>
                  <Grid item alignItems="center">
                    <Typography variant="subtitle2" style={{ textTransform: 'capitalize' }}>
                      &nbsp;({phone.use ?? 'Other'})
                    </Typography>
                  </Grid>
                  {phone.rank === 1 ? <Iconify icon="eva:star-fill" /> : null}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container alignItems="baseline" item xs={12} sx={{ mb: 1.5 }}>
            <Grid item xs={2}>
              <Typography variant="caption">Email</Typography>
            </Grid>
            <Grid container item xs direction="column">
              {emails.map((email: any, index: number) => (
                <Grid container item direction="row" alignItems="center" key={`email-${index}`}>
                  <Grid item alignItems="center">
                    <Typography variant="subtitle2">{email.value}</Typography>
                  </Grid>
                  <Grid item alignItems="center">
                    <Typography variant="subtitle2" style={{ textTransform: 'capitalize' }}>
                      &nbsp;({email.use})
                    </Typography>
                  </Grid>
                  {email.rank === 1 ? <Iconify icon="eva:star-fill" /> : null}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container alignItems="baseline" item xs={12} sx={{ mb: 1.5 }}>
            <Grid item xs={2}>
              <Typography variant="caption">Address</Typography>
            </Grid>
            <Grid container item xs direction="column">
              {addresses?.map((address: string, index: number) => (
                <Grid container item direction="row" key={`address-${index}`}>
                  <Grid item alignItems="center">
                    <Typography variant="subtitle2">{address}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container alignItems="baseline" item xs={12} sx={{ mb: 1.5 }}>
            <Grid item xs={2}>
              <Typography variant="caption">Alias</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">{alias?.given?.join(' ')}</Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="baseline" item xs={12} sx={{ mb: 1.5 }}>
            <Grid item xs={2}>
              <Typography variant="caption">MS.</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" style={{ textTransform: 'capitalize' }}>
                {maritalStatus?.coding?.[0]?.display}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="baseline" item xs={12} sx={{ mb: 1.5 }}>
            <Grid item xs={2}>
              <Typography variant="caption">Race</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" style={{ textTransform: 'capitalize' }}>
                {race?.display}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="baseline" item xs={12} sx={{ mb: 1.5 }}>
            <Grid item xs={2}>
              <Typography variant="caption">SO.</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" style={{ textTransform: 'capitalize' }}>
                {sexualOrientation?.display}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="baseline" item xs={12} sx={{ mb: 1.5 }}>
            <Grid item xs={2}>
              <Typography variant="caption">Gender</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" style={{ textTransform: 'capitalize' }}>
                {gender}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="baseline" item xs={12} sx={{ mb: 1.5 }}>
            <Grid item xs={2}>
              <Typography variant="caption">SSN</Typography>
            </Grid>
            <Grid item>
              <PatternFormat
                displayType="text"
                format="###-##-####"
                variant="subtitle2"
                customInput={Typography}
                value={ssn?.value}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

interface PersonalDetailsProps {
  patient: WrappedPatient;
}

const PersonalDetails = ({ patient }: PersonalDetailsProps) => {
  const { configurations } = useTenantConfigData();
  const [editMode, setEditMode] = useState(false);

  const closeEditMode = () => {
    setEditMode(false);
  };

  const openEditMode = () => {
    setEditMode(true);
  };

  return (
    <Stack>
      {editMode ? (
        <EditMode data={patient} onClose={closeEditMode} configurations={configurations}/>
      ) : (
        <DisplayMode data={patient} onClose={openEditMode} configurations={configurations} />
      )}
    </Stack>
  );
};

export default PersonalDetails;
