import {
  Box,
  Card,
  CircularProgress,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import fhirSystem from 'src/fhir/system';
import { ConcreteActivity } from 'src/sections/careflow/tasks-activities/model/Activity';
import {
  useCarePlan,
  useCodeSystem,
  usePatient,
  usePractitioner,
  useQuestionnaire,
  useQuestionnaireResponse,
  useTask,
} from 'src/@nicheaim/fhir-react';
import { CarePlan } from 'src/@nicheaim/fhir-base/mappings/CarePlan';
import { CarePlanWrapper } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import Label, { LabelColor } from 'src/components/Label';
import { shortFormat } from 'src/utils/formatTime';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import api from 'src/services/api';
import TextMaxLine from 'src/components/TextMaxLine';
import { sumFhirDuration } from 'src/utils/time';
import { sentenceCase } from 'change-case';
import { ActivityLegendRenderer } from 'src/sections/careflow/tasks-activities/components/Activities/ActivityRenderers';
import { ActivityLegends, ActivityStatus } from 'src/sections/careflow/tasks-activities/model';
import { renderDate } from 'src/sections/careflow/tasks-activities/components/Activities/utils';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

interface ActivityDetailsModalProps {
  actionBar: JSX.Element;
  activity: ConcreteActivity;
}

const ActivityDetailsModal = ({ actionBar, activity }: ActivityDetailsModalProps) => {
  const [task] = useTask(activity.taskId);
  const [contactTypesCodeSystem] = useCodeSystem('ccm-activity-contact-types');

  return (
    <Card>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        px={2}
        pt={2}
        pb={1}
        direction="row"
      >
        <Typography variant="subtitle1">Activity Details</Typography>
        <Box>{actionBar}</Box>
      </Stack>

      <Stack>
        <Stack width="100%" px={2} mb={2}>
          <Typography>
            {activity.type} - {activity.activity}
          </Typography>
          <Typography variant="body2">
            {contactTypesCodeSystem?.concept?.find(
              (concept) => concept.code === activity.contactType
            )?.display ?? '...'}
          </Typography>
        </Stack>

        <ActivityInfo activity={activity} />

        <ActivityLinkedAssessmentsTable activity={activity} />

        <ActivityLinkedCarePlansTable
          carePlanIds={
            task?.output
              ?.filter(
                (output) =>
                  output?.type?.coding?.[0].system ===
                    fhirSystem.codeSystems.ccmActivityOutputTypes.asString() &&
                  output.type.coding[0].code === 'linked-care-plan'
              )
              .map((output) => output?.valueReference?.id!)
              .filter(Boolean) ?? []
          }
        />
      </Stack>
    </Card>
  );
};

//--------------------------------------------------
// Activity Info
//--------------------------------------------------

interface ActivityInfoProps {
  activity: ConcreteActivity;
}

function ActivityInfo({ activity }: ActivityInfoProps) {
  const { configurations } = useTenantConfigData();
  const [patient] = usePatient(activity.patientFhirId, {
    map: PatientWrapper,
  });
  const [staff, { isLoading: isLoadingPractitioner }] = usePractitioner(activity.staffFhirId, {
    map: PractitionerWrapper,
    autofetch: !!activity.staffFhirId,
  });

  return (
    <Stack px={2} flex={1} direction="row" flexWrap="wrap">
      <Stack flex={1} gap={1}>
        <Stack>
          <Typography variant="subtitle2" color="textSecondary">
            Name
          </Typography>
          <Typography variant="body1">{activity.patientName}</Typography>
        </Stack>

        <Stack>
          <Typography variant="subtitle2" color="textSecondary">
            ID
          </Typography>
          <Typography variant="body1">{patient?.getMRN(configurations?.mpi?.systemMrn)?.value}</Typography>
        </Stack>

        <Stack>
          <Typography variant="subtitle2" color="textSecondary">
            Age
          </Typography>
          <Typography variant="body1">{activity.patientAge}</Typography>
        </Stack>
      </Stack>

      <Stack flex={1} gap={1}>
        <Stack>
          <Typography variant="subtitle2" color="textSecondary">
            Date/Time
          </Typography>
          <Typography variant="body1">{renderDate(activity)}</Typography>
        </Stack>

        <Stack>
          <Typography variant="subtitle2" color="textSecondary">
            Staff
          </Typography>
          <Typography>
            {isLoadingPractitioner ? (
              <CircularProgress size={16} />
            ) : (
              staff?.getFullName() ?? 'Unassigned'
            )}
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="subtitle2" color="textSecondary">
            Status
          </Typography>
          <Typography>
            {activity.status === ActivityStatus.CANCELLED
              ? new ActivityLegendRenderer({
                  ...activity,
                  legend: ActivityLegends.CANCELLED,
                }).render(true)
              : activity.status === ActivityStatus.REQUESTED
              ? new ActivityLegendRenderer({
                  ...activity,
                  legend: ActivityLegends.NEW,
                }).render(true)
              : new ActivityLegendRenderer(activity).render(true)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

//--------------------------------------------------
// Activity Linked Assessments
//--------------------------------------------------

interface ActivityLinkedAssessmentsTableProps {
  activity: ConcreteActivity;
}

function ActivityLinkedAssessmentsTable({ activity }: ActivityLinkedAssessmentsTableProps) {
  const [task] = useTask(activity.taskId, {
    autofetch: !!activity.taskId,
  });
  const assessmentIds = useMemo(
    () =>
      task?.identifier
        ?.filter(
          (identifier) =>
            identifier.system === fhirSystem.activity.completedWithAssessment.asString()
        )
        .map((identifier) => identifier.value!) ?? [],
    [task]
  );

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle2" color="textSecondary">
          Linked Assessments
        </Typography>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Assessment</TableCell>
              <TableCell>Staff</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assessmentIds.length ? (
              assessmentIds.map((assessmentId) => (
                <ActivityLinkedAssessment
                  key={assessmentId}
                  questionnaireResponseId={assessmentId}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="body2" color="textSecondary">
                    No linked assessments
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

interface ActivityLinkedAssessmentProps {
  questionnaireResponseId: string;
}

function ActivityLinkedAssessment({ questionnaireResponseId }: ActivityLinkedAssessmentProps) {
  const [questionnaireResponse] = useQuestionnaireResponse(questionnaireResponseId, {
    autofetch: !!questionnaireResponseId,
  });
  const [questionnaire] = useQuestionnaire(
    questionnaireResponse?.questionnaire?.split('/')[1] ?? '',
    {
      autofetch: !!questionnaireResponse,
    }
  );
  const [author] = usePractitioner(questionnaireResponse?.author?.reference?.split('/')[1] ?? '', {
    autofetch: !!questionnaireResponse,
    map: PractitionerWrapper,
  });

  const status = useMemo(() => {
    if (!questionnaireResponse) {
      return {
        color: 'default' as LabelColor,
        text: 'Unknown',
      };
    }

    const mapping: Record<
      'completed' | 'in-progress',
      {
        color: LabelColor;
        text: string;
      }
    > = {
      completed: {
        color: 'success',
        text: 'Completed',
      },
      'in-progress': {
        color: 'info',
        text: 'In Progress',
      },
    };

    return (
      mapping[questionnaireResponse.status as 'completed' | 'in-progress'] ?? {
        color: 'default',
        text: 'Unknown',
      }
    );
  }, [questionnaireResponse]);

  return (
    <TableRow>
      {/* Date */}
      <TableCell>{moment(questionnaireResponse?.authored).format('MMM D, YYYY')}</TableCell>

      {/* Type */}
      <TableCell></TableCell>

      {/* Title */}
      <TableCell>{questionnaire?.title}</TableCell>

      {/* Staff */}
      <TableCell>{author?.getFullName()}</TableCell>

      {/* Category */}
      <TableCell></TableCell>

      {/* Status */}
      <TableCell>
        <Label variant="ghost" color={status.color}>
          {status.text}
        </Label>
      </TableCell>
    </TableRow>
  );
}
//--------------------------------------------------
// Activity Linked Care Plans
//--------------------------------------------------

interface ActivityLinkedCarePlansTableProps {
  carePlanIds: string[];
}

function ActivityLinkedCarePlansTable({ carePlanIds }: ActivityLinkedCarePlansTableProps) {
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle2" color="textSecondary">
          Linked Care Plans
        </Typography>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Priority</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Goal</TableCell>
              <TableCell>Condition</TableCell>
              <TableCell>Goal Group</TableCell>
              <TableCell>Intervention</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Sign Off</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Term</TableCell>
              <TableCell>Appointment Alert</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {carePlanIds?.length ? (
              carePlanIds.map((id) => <ActivityLinkedCarePlan key={id} carePlanId={id} />)
            ) : (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  <Typography variant="body2" color="textSecondary">
                    No linked care plans
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

interface ActivityLinkedCarePlanProps {
  carePlanId: string;
}

function ActivityLinkedCarePlan({ carePlanId }: ActivityLinkedCarePlanProps) {
  const [carePlan] = useCarePlan(carePlanId, {
    map: CarePlanWrapper,
  });
  const { data: carePlanSettings } = useQuery(['care-plan-automation-settings'], () =>
    api.settings.system.getSystemSetting('care-plan-automation')
  );

  const author = useMemo(() => {
    if (carePlan?.author?.extension) {
      const createdByAutomation = carePlan.author.extension.find(
        (ext) => ext.url === fhirSystem.extension.CarePlan.createdByAutomation.asString()
      );
      if (createdByAutomation && createdByAutomation.valueReference) {
        return createdByAutomation.valueReference;
      }
    }
    return carePlan?.author?.display ?? '';
  }, [carePlan]);

  const status = useMemo(() => {
    if (!carePlan) {
      return {
        color: 'default' as LabelColor,
        text: 'Unknown',
      };
    }

    const mapping = {
      completed: {
        color: 'success',
        text: 'Completed',
      },
      active: {
        color: 'info',
        text: 'In Progress',
      },
      revoked: {
        color: 'error',
        text: 'Cancelled',
      },
      draft: {
        color: 'warning',
        text: 'New',
      },
    } as Record<
      CarePlan['status'],
      {
        color: LabelColor;
        text: string;
      }
    >;

    return (
      mapping[carePlan.status] ?? {
        color: 'default',
        text: 'Unknown',
      }
    );
  }, [carePlan]);

  return !carePlan ? null : (
    <TableRow>
      <TableCell>
        <TextMaxLine>{sentenceCase(carePlan._goal?.priority?.text ?? '')}</TextMaxLine>
      </TableCell>
      <TableCell>
        <TextMaxLine
          line={3}
          title={
            typeof author === 'string' ? author : `Automationn\n${author.display || 'No name'}`
          }
        >
          {typeof author === 'string' ? (
            author
          ) : (
            <Box>
              <TextMaxLine>Automation</TextMaxLine> (
              <Link href={`/settings/care-plan-automation/automations/${author.id}`}>
                {author.display || 'No name'}
              </Link>
              )
            </Box>
          )}
        </TextMaxLine>
      </TableCell>
      <TableCell>
        <TextMaxLine line={3} title={carePlan.parsed.goal}>
          {carePlan.parsed.goal}
        </TextMaxLine>
      </TableCell>
      <TableCell>
        <TextMaxLine line={3} title={carePlan.parsed.condition}>
          {carePlan.parsed.condition}
        </TextMaxLine>
      </TableCell>
      <TableCell>
        <TextMaxLine line={3} title={carePlan.parsed.goalGroup}>
          {carePlan.parsed.goalGroup}
        </TextMaxLine>
      </TableCell>
      <TableCell>
        <TextMaxLine line={3} title={carePlan.parsed.intervention}>
          {carePlan.parsed.intervention}
        </TextMaxLine>
      </TableCell>
      <TableCell>
        <Label variant="ghost" color={status.color}>
          {status.text}
        </Label>
      </TableCell>
      <TableCell>No</TableCell>
      <TableCell>
        {carePlan.period?.start ? shortFormat(moment(carePlan.period.start).toDate()) : 'N/A'}
      </TableCell>
      <TableCell>
        {carePlan.period?.end ? shortFormat(moment(carePlan.period.end).toDate()) : 'N/A'}
      </TableCell>
      <TableCell>
        {!carePlanSettings || !carePlan.period?.start || !carePlan.period?.end
          ? '...'
          : moment(carePlan.period?.end).toDate() >
            sumFhirDuration(
              moment(carePlan.period.start).toDate(),
              carePlanSettings.shortTermBefore,
              carePlanSettings.shortTermBeforeUnit.value
            )
          ? 'Long Term'
          : 'Short Term'}
      </TableCell>

      <TableCell>{carePlan.parsed.appointmentAlert ? 'Yes' : 'No'}</TableCell>
    </TableRow>
  );
}

export default ActivityDetailsModal;
