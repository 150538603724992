import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import EditableAvatar, { EditableAvatarProps } from './EditableAvatar/EditableAvatar';
import {
  deleteFile,
  extractFileNameFromURL,
  getProfileImagePath,
  uploadFile,
} from '../utils/firebaseStorage';
import useFirebaseCollectionMutation from '../hooks/useFirebaseCollectionMutation';
import { MutationActions, Person } from '../types';
import { collectionNames } from '../utils/constants';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

export interface UserProfileImageProps {
  personId: string | null;
  personProfileImage?: string | null;
  onImageUploadSuccess: VoidFunction;
}

const UserProfileImage = ({
  personId,
  personProfileImage,
  onImageUploadSuccess,
}: UserProfileImageProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { configurations } = useTenantConfigData();
  const { mutateAsync: mutatePerson } = useFirebaseCollectionMutation<Person>({
    collectionName: collectionNames.PERSONS,
    configurations: configurations?.yocare,
  });

  const deletePreviousImage = async () => {
    try {
      const previousFileName = extractFileNameFromURL(personProfileImage);
      if (!previousFileName) return;
      const imagePath = getProfileImagePath(
        personId ?? '', previousFileName, configurations?.yocare?.storageProfileImagePath
      );
      await deleteFile(imagePath);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleUploadImage: EditableAvatarProps['onUploadImage'] = async (file) => {
    try {
      setIsLoading(true);
      const extension = file?.path?.split?.('.')?.pop?.();
      const imagePath = getProfileImagePath(
        personId ?? '', `${uuidv4()}.${extension}`, configurations?.yocare?.storageProfileImagePath
      );
      const newImageUrl = await uploadFile(imagePath, file as File);
      if (!newImageUrl) throw new Error('Unable to upload image');
      await deletePreviousImage();
      await mutatePerson({
        data: { profilePicture: newImageUrl },
        action: MutationActions.MODIFY,
        id: personId as string,
      });
      onImageUploadSuccess();
      enqueueSnackbar('Image updated succesfully', {
        variant: 'success',
      });
      setIsLoading(false);
      return true;
    } catch (error) {
      console.log('Error uploading profile image', error);
      setIsLoading(false);
      enqueueSnackbar(
        'There was an issue while trying to upload the image. If the problem persist please contact support',
        {
          variant: 'error',
        }
      );
      return false;
    }
  };
  return (
    <EditableAvatar
      imageSourceUrl={personProfileImage}
      onUploadImage={handleUploadImage}
      isLoading={isLoading}
    />
  );
};
export default UserProfileImage;
