import { LoadingButton } from "@mui/lab";
import { useMemo, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { trimMultipleWhiteSpaces } from "src/utils/string";
import { dateYearFormatWithAge } from "src/utils/formatTime";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import VerifiedDecorator from "src/sections/crs/provider/VerifiedDecorator";
import { WrappedPractitioner } from "src/@nicheaim/fhir-base/wrappers/Practitioner";
import ProviderDetailPreviewDialog from "src/sections/crs/provider/new/ProviderDetailPreviewDialog";
import GeneralPractitionerPreviewDrawer from "src/sections/crs/provider/GeneralPractitionerPreviewDrawer";
import { Address, FhirResource } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";
import { Badge, Cake, ContactMail, ContactPhone, MedicalInformationOutlined, Person, PersonRemove, Place, Search } from "@mui/icons-material";
import { AssignableGeneralPractitioner, DirectoryProvider, DirectoryProviderLookUp, OnProviderUnselect } from "src/@types/crs/providerDirectory";
import { formatProviderAddress, getGenderFromProvider, getProviderPrimaryAddress, getProviderPrimaryTelecom } from "src/sections/crs/provider/helpers";
import useTenantConfigData from "src/hooks/useTenantConfigData";

export interface ProviderGridProps {
  selectedProvider: DirectoryProvider | null;
  practitioner: WrappedPractitioner | null;
  onProviderUnselect: OnProviderUnselect;
  isLoading: boolean;
  onSearchProvider: () => void;
  isIncidentDispositionDone: boolean;
}

const ProviderGrid = ({
  selectedProvider,
  onProviderUnselect,
  isLoading,
  onSearchProvider,
  isIncidentDispositionDone,
  practitioner,
}: ProviderGridProps) => {
  const { configurations } = useTenantConfigData();
  const [
    isProviderPreviewDialogOpen,
    setIsProviderPreviewDialogOpen,
  ] = useState(false);

  const [
    isPractitionerPreviewDialogOpen,
    setIsPractitionerPreviewDialogOpen,
  ] = useState(false);

  const practitionerAsGeneralPractitioner = useMemo((): AssignableGeneralPractitioner | null => {
    if (!practitioner) return null;
    return {
      resourceId: practitioner.id as string,
      resourceType: "Practitioner",
      practitioner: {
        id: practitioner?.id as string,
        fullName: practitioner.getFullName()!,
        address: practitioner.address as Address,
        isVerified: practitioner.isVerified(configurations?.provider),
        resource: practitioner as FhirResource,
      },
    };
  }, [practitioner,configurations]);

  return (
    <Box sx={{ width: "100%" }}>
      {!isIncidentDispositionDone && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: 2,
          }}
        >
          <Tooltip
            title={
              selectedProvider || practitioner
                ? "Must unselect selected provider to search for another"
                : "Search Provider"
            }
          >
            <span>
              <Button
                variant="contained"
                startIcon={<Search />}
                onClick={onSearchProvider}
                disabled={!!selectedProvider || !!practitioner}
              >
                Search Provider
              </Button>
            </span>
          </Tooltip>
        </Box>
      )}
      <Box>
        <DataGrid
          style={{ height: 270 }}
          rowHeight={160}
          density="standard"
          className="providerList pointerRow"
          rows={
            selectedProvider
              ? [selectedProvider]
              : practitioner
              ? [practitioner]
              : []
          }
          getRowId={({ uuid }) => String(new Date().getTime())}
          onRowDoubleClick={(params) => {
            if (selectedProvider) {
              setIsProviderPreviewDialogOpen(true);
              return;
            }
            if (practitioner) setIsPractitionerPreviewDialogOpen(true);
          }}
          columns={getProviderGridColumns({
            isLoading,
            onProviderUnselect,
            showActionButton: !isIncidentDispositionDone,
            isProvider: !!selectedProvider,
          })}
          isRowSelectable={() => false}
          disableColumnFilter
          disableColumnMenu
          hideFooterPagination={true}
          loading={isLoading}
        />
        <ProviderDetailPreviewDialog
          isOpen={isProviderPreviewDialogOpen}
          onClose={() => {
            setIsProviderPreviewDialogOpen(false);
          }}
          providerId={selectedProvider?.uuid ?? null}
        />
        <GeneralPractitionerPreviewDrawer
          open={isPractitionerPreviewDialogOpen}
          generalPractitioner={practitionerAsGeneralPractitioner}
          onClose={() => {
            setIsPractitionerPreviewDialogOpen(false);
          }}
        />
      </Box>
    </Box>
  );
};

export const getProviderGridColumns = ({
  isLoading,
  onProviderUnselect,
  showActionButton,
  isProvider,
}: {
  isLoading: boolean;
  onProviderUnselect: OnProviderUnselect;
  showActionButton: boolean;
  isProvider: boolean;
}): GridColDef[] => [
  {
    field: "PersonalInfo",
    headerName: "Personal Info",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      let fullName = "";
      let npi = "";
      let birthDate = "";
      let ssn = "";
      let gender = "";

      const provider = params.row as DirectoryProviderLookUp;
      const practitioner = params.row as WrappedPractitioner;
      if (isProvider) {
        const {
          first_name,
          last_name,
          middle_name,
          npi: providerNPI,
          gender: providerGender,
          ssn: providerSSN,
          dob,
        } = provider;
        gender = getGenderFromProvider(providerGender);
        npi = providerNPI;
        fullName = trimMultipleWhiteSpaces(
          `${first_name ?? ""} ${middle_name ?? ""} ${last_name ?? ""}`
        ).trim();
        ssn = providerSSN;
        birthDate = dob ?? "";
      } else {
        gender = practitioner?.gender ?? "";
        npi = practitioner.getNPI();
        fullName = practitioner.getFullName()!;
        ssn = practitioner.getSSN();
        birthDate = practitioner?.birthDate ?? "";
      }

      return (
        <Box justifyContent={"center"}>
          {!!npi && (
            <Box marginTop={0.4}>
              <Tooltip title="NPI">
                <Box display={"flex"} flexDirection={"row"}>
                  <MedicalInformationOutlined
                    sx={{ marginRight: 1 }}
                    fontSize={"small"}
                  />

                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <>
                      <Typography sx={{ marginRight: 1 }}>
                        {npi ?? ""}
                      </Typography>
                      <VerifiedDecorator isVerified={isProvider} />
                    </>
                  </Box>
                </Box>
              </Tooltip>
            </Box>
          )}
          {!!fullName && (
            <Box marginTop={0.4}>
              <Tooltip title="Provider">
                <Box display={"flex"} flexDirection={"row"}>
                  <Person sx={{ marginRight: 1 }} fontSize={"small"} />
                  <Typography
                    sx={{
                      marginRight: 1,
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {fullName}
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
          )}
          {!!gender && (
            <Box marginTop={0.4}>
              <Tooltip title="Provider's Gender">
                <span>
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {gender}
                  </Typography>
                </span>
              </Tooltip>
            </Box>
          )}
          {!!birthDate && (
            <Box marginTop={0.4}>
              <Tooltip title="Provider's Date of Birth">
                <Box display={"flex"} flexDirection={"row"}>
                  <Cake sx={{ marginRight: 1 }} fontSize={"small"} />
                  <Typography>
                    {dateYearFormatWithAge(birthDate)}
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
          )}

          {!!ssn && (
            <Box marginTop={0.4}>
              <Tooltip title="SSN">
                <Box display={"flex"} flexDirection={"row"}>
                  <Badge sx={{ marginRight: 1 }} fontSize={"small"} />
                  <Typography>{ssn}</Typography>
                </Box>
              </Tooltip>
            </Box>
          )}
        </Box>
      );
    },
  },
  {
    field: "ContactInfo",
    headerName: "Contact Info",
    sortable: false,
    flex: 1,
    renderCell: (params) => {
      let email = "";
      let phone = "";
      const provider = params.row as DirectoryProvider;
      const practitioner = params.row as WrappedPractitioner;
      if (isProvider) {
        const { telecom } = provider;
        phone = getProviderPrimaryTelecom(telecom ?? [], "phone")?.value ?? "";
        email = getProviderPrimaryTelecom(telecom ?? [], "email")?.value ?? "";
      } else {
        phone = practitioner.getPhones()?.[0]?.value ?? "";
        email = practitioner.getEmails()?.[0]?.value ?? "";
      }

      return (
        <Box justifyContent={"center"}>
          {!!email && (
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              marginTop={0.4}
            >
              <Tooltip title="Email">
                <ContactMail sx={{ marginRight: 1 }} fontSize={"small"} />
              </Tooltip>
              <Typography
                sx={{
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
              >
                {email}
              </Typography>
            </Box>
          )}
          {!!phone && (
            <Box display={"flex"} flexDirection={"row"} marginTop={0.4}>
              <Tooltip title="Phone">
                <ContactPhone sx={{ marginRight: 1 }} fontSize={"small"} />
              </Tooltip>
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  wordBreak: "break-word",
                }}
              >
                {phone}
              </Typography>
            </Box>
          )}
        </Box>
      );
    },
  },
  {
    field: "Address",
    headerName: "Address",
    sortable: false,
    flex: 1,
    renderCell: (params) => {
      let formattedAddress = "";

      const provider = params.row as DirectoryProvider;
      const practitioner = params.row as WrappedPractitioner;
      if (isProvider) {
        const { address } = provider;
        const primaryAddress = getProviderPrimaryAddress(address ?? []);
        formattedAddress = formatProviderAddress(primaryAddress);
      } else {
        formattedAddress = practitioner.getFormattedAddresses()?.[0] ?? "";
      }

      return (
        <Box justifyContent={"center"}>
          {!!formattedAddress && (
            <Box display={"flex"} flexDirection={"row"} marginTop={0.4}>
              <Tooltip title="Address">
                <Place sx={{ marginRight: 1 }} fontSize={"small"} />
              </Tooltip>
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  wordBreak: "break-word",
                }}
              >
                {formattedAddress}
              </Typography>
            </Box>
          )}
        </Box>
      );
    },
  },
  {
    field: "action",
    headerName: " ",
    align: "center",
    flex: 0.4,
    renderCell: (params) => {
      const provider = params.row as DirectoryProvider;
      if (!showActionButton) return null;

      return (
        <LoadingButton
          variant="contained"
          color="error"
          disabled={isLoading}
          startIcon={<PersonRemove />}
          loading={isLoading}
          onClick={() => {
            onProviderUnselect();
          }}
        >
          Unselect
        </LoadingButton>
      );
    },
  },
];

export default ProviderGrid;
