import { WrappedConsent } from 'src/@nicheaim/fhir-base/wrappers/Consent';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import CustomModal, { CustomModalBasicProps } from 'src/components/CustomModal';
import useLocales from 'src/hooks/useLocales';
import useObjectState from 'src/hooks/useObjectState';

import {
  ResourceWithIncludedResources,
} from 'src/sections/crs/types';
import ConsentForm from './ConsentForm';

export interface ConsentModalProps extends CustomModalBasicProps {
  patient: WrappedPatient | null;
  consentData: ResourceWithIncludedResources<WrappedConsent> | null;
  onSaveChanges: () => Promise<void>;
}

const ConsentModal = ({
  open,
  onClose,
  patient,
  consentData,
  onSaveChanges,
  ...modalProps
}: ConsentModalProps) => {
  const { i18n } = useLocales();
  const [
    {
      isDialogsOpen
    },
    updateState,
  ] = useObjectState<{isDialogsOpen: boolean;}>({isDialogsOpen: false});


  const title = !consentData
    ? i18n('patients.details.consents.addConsent', 'crs')
    : i18n('patients.details.consents.editConsent', 'crs');

  return (
    <CustomModal
      {...modalProps}
      open={open}
      title={title}
      breadcrumbs={[
        i18n('patients.details.patientProfile', 'crs'),
        i18n('patients.details.consents.title', 'crs'),
        title,
      ]}
      onSave={() => {}}
      showSaveButton={false}
      showCancelButton={false}
      onCancel={onClose as Function}
      onClose={onClose}
      containerSx={[{ pb: 0 }, isDialogsOpen ? { width: '85vw' } : {}]}
    >
      <ConsentForm 
        open={open}
        patient={patient}
        consentData={consentData}
        onClose={onClose as Function}
        onSaveChanges={onSaveChanges}
        onSelectProperties={(isDialogsOpen) => updateState({ isDialogsOpen })}
      />
    </CustomModal>
  );
};
export default ConsentModal;
