import useSmartLauncher from 'src/hooks/useSmartLauncher';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

interface IReportsProps {
  iamUserId: string;
  fhirUserId: string;
  patientId: string | null;
}

export default function ReportsIframe({ iamUserId, fhirUserId, patientId }: IReportsProps) {
  const { configurations } = useTenantConfigData();
  const { isDone, launchUrl, errorMsg } = useSmartLauncher({
    iamUserId,
    fhirUserId: fhirUserId,
    patientId,
    smartAppBaseLaunchUrl: configurations?.foresight?.reportsLaunchUrl,
  });

  if (errorMsg) {
    return <div>{errorMsg}</div>;
  }

  return isDone && launchUrl ? (
    <iframe
      scrolling="no"
      src={launchUrl}
      title="foresights"
      style={{ border: 0, minHeight: '70vh', height: '100%', width: '100%' }}
    />
  ) : (
    <div>loading</div>
  );
}
