/* eslint-disable */
import Page from 'src/components/Page';
import TaskDetails from './TaskDetails';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import useTaskServiceRequestQuestionnaire from 'src/hooks/useTaskServiceRequestQuestionnaire';
import { Tabs, Tab, Container, Card, Backdrop, CircularProgress } from '@mui/material';
import { LoadingComponent, NoResultText } from 'src/sections/crs/case/components/RelatedReferrals/components';
import useLocales from 'src/hooks/useLocales';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

export default function TaskTab() {
  
  const { themeStretch } = useSettings();
  const { id } = useParams();
  const { i18n } = useLocales();
  const { configurations } = useTenantConfigData();
  const [ devMode, setDevMode ] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [ showMeasureQuestionnaireResponses, setShowMeasureQuestionnaireResponses ] = useState(false);

  const { 
    report, 
    patient, 
    planDefinition, 
    serviceRequest,
    observations,
    updateServiceRequest,
    refreshServiceRequest,
    isLoading
  } = useTaskServiceRequestQuestionnaire(id ?? '');

  const searchURLQueryParams = () => {
    const query = new URLSearchParams(location.search);
    const getDevMode = query.get("devMode");
    const getMeasureQR = query.get("measureQR");

    if (getDevMode === "true") {
      setDevMode(true);
    }

    if (getMeasureQR === "true") {
      setShowMeasureQuestionnaireResponses(true);
    }
  };

  useEffect(() => {
    searchURLQueryParams()
  }, []);

  const handleBackdrop = (open: boolean) => {
    setOpenBackdrop(open);
  };

  return (
    <Page title={i18n('nat.details')}>
       <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          title={i18n('nat.details')}
          heading=""
          links={[
            { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
            { name: `${i18n('nat.title')}` },
            { name: `${i18n('nat.details')}` },
          ]}
        />
          {!isLoading ? (
            report?.length ? (
              <Card sx={{ mt: 2 }}>
                <Tabs
                  value={selectedTab}
                  variant={'scrollable'}
                  onChange={(_, newSelectedTab) => {
                    setSelectedTab(newSelectedTab);
                  }}
                  TabIndicatorProps={{
                    sx: {
                      bottom: 5,
                    },
                  }}
                >
                  {report?.map((item) => {
                    const taskNumber = item?.task 
                      ? item?.task?.getInternalNumber?.(configurations?.fhir?.taskInternalNumberIdentifierSystem) 
                        ?? item?.task?.getTaskNumberNAT(configurations?.fhir?.taskNATIdenitifierType) 
                        ?? ""
                      : "";
                    return (
                      <Tab
                        sx={{ textAlign: 'left' }}
                        label={`${i18n('nat.tabTitle', 'translations')} ${taskNumber}`}
                        key={`${taskNumber}`}
                      />
                    )
                  })}
                </Tabs>

                {report?.map((item, index) => (
                  <TaskDetails
                    patient={patient}
                    key={`taskTabPanel${String(index)}`}
                    task={item?.task}
                    serviceRequest={serviceRequest}
                    planDefinition={planDefinition}
                    questionnaire={item?.questionnaire}
                    questionnaireResponse={item?.questionnaireResponse}
                    questionnaireAnswerMap={item?.questionnaireAnswerMap}
                    measureReport={item?.measureReport}
                    ownerTask={item?.ownerTask}
                    requesterTask={item?.requesterTask}
                    observations={observations}
                    updateServiceRequest={updateServiceRequest}
                    refreshServiceRequest={refreshServiceRequest}
                    devMode={devMode}
                    showMeasureQuestionnaireResponses={showMeasureQuestionnaireResponses}
                    handleBackdrop={handleBackdrop}
                    index={index}
                    selectedTab={selectedTab}
                  />
                ))}
              </Card>
            ) : (
              <NoResultText text="Task detail not found" />
            )
          ) : (
            <LoadingComponent />
          )} 
      </Container>
    </Page>
  );
};
