// components
import { SettingsValueProps } from './components/settings/type';
// routes
import { enUS } from '@mui/material/locale';
import { LanguageResource } from './locales/i18n';
import { PATH_DASHBOARD } from './routes/paths';
import { getBackEndBaseUrl } from './utils/domain-utils';

export enum Modules {
  CCM = 'ccm',
  CRS = 'crs',
  ENGAGEMENT_HUB = 'engagementhub',
}

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultLang: LanguageResource = {
  label: 'MCC - Default',
  value: 'mcc',
  icon: '/assets/icons/flags/ic_flag_us.svg',
  themeLocalization: enUS,
};

export const allLangs: LanguageResource[] = [
  defaultLang,
  {
    label: 'NJ',
    value: 'nj',
    icon: '/assets/icons/flags/ic_flag_us.svg',
    themeLocalization: enUS,
  },
  {
    label: 'SBHA',
    value: 'mcc_sbha',
    icon: '/assets/icons/flags/ic_flag_us.svg',
    themeLocalization: enUS,
  },
  {
    label: 'NJ Demo',
    value: 'mcc_demo',
    icon: '/assets/icons/flags/ic_flag_us.svg',
    themeLocalization: enUS,
  },
  {
    label: 'CT',
    value: 'ct',
    icon: '/assets/icons/flags/ic_flag_us.svg',
    themeLocalization: enUS,
  }
];

// API
// ----------------------------------------------------------------------
export const BACKEND_PREFIX_ROUTE = '/api';
export const DEV_BACKEND_URL = process.env.REACT_APP_DEV_BACKEND_URL;
export const REACT_APP_SENTRY_DSN_KEY = process.env.REACT_APP_SENTRY_DSN_KEY;
export const APP_URL = process.env.REACT_APP_APP_URL;
export const SESSION_EXPIRED_ROUTE = '/session-expired';
export const SESSION_EXPIRED_URL = APP_URL + SESSION_EXPIRED_ROUTE;
export const REDIRECT_AUTHORIZE_ROUTE = '/auth/redirect-authorize-url';
export const REDIRECT_AUTHORIZE_URL = getBackEndBaseUrl() + REDIRECT_AUTHORIZE_ROUTE;

export const REACT_APP_SECTION_CRS = process.env.REACT_APP_SECTION_CRS ?? '';
export const IS_CRS = REACT_APP_SECTION_CRS.toLowerCase() === Modules.CRS;
// // ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN =
  REACT_APP_SECTION_CRS.toLowerCase() === 'crs'
    ? PATH_DASHBOARD.general.app
    : PATH_DASHBOARD.general.activities; // as '/dashboard/app'

export const config = {
  fhirTenantId: '', // do not set header until FHIR Proxy accepts it again // process.env.REACT_APP_FHIR_TENANT_ID,
  fhirApiBaseUrl: process.env.REACT_APP_FHIR_API_BASE_URL,
  fhirApiIndexedBaseUrl: process.env.REACT_APP_FHIR_INDEXED_BASE_URL
};

export const YOCARE_FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_YOCARE_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_YOCARE_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_YOCARE_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_YOCARE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_YOCARE_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_YOCARE_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_YOCARE_FIREBASE_MEASUREMENT_ID,
};

export const DEVELOPMENT_CONFIG = {
  disableTokenCheck: process.env.REACT_APP_DEVELOPMENT_DISABLE_TOKEN_CHECK === 'true',
};

export const DEBUG_CONFIG = {
  debugTokenLife: process.env.REACT_APP_DEBUG_TOKEN_LIFE === 'true',
};

export const RENDER_TEMPLATE_BACK_URL = (renderTemplate: string) => 
  getBackEndBaseUrl() + renderTemplate;

export const JASPER_FUNCTION_URL = (jasperReport: string) => 
  getBackEndBaseUrl() + jasperReport;

export const PROVIDER_CERTIFICATION_IDENTIFIER = (providerDirectoryBaseUrl: string) => ({
  type: {
    coding: [
      {
        code: 'providers-directory-certification',
        display: 'Providers Directory Certification',
        system: providerDirectoryBaseUrl,
      },
    ],
    text: 'Providers Directory Certification',
  },
});

export const PROVIDER_LICENSE_IDENTIFIER = (providerDirectoryBaseUrl: string) => ({
  type: {
    coding: [
      {
        code: 'providers-directory-license',
        display: 'Providers Directory License',
        system: providerDirectoryBaseUrl,
      },
    ],
    text: 'Providers Directory License',
  },
});