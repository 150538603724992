import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import PreviewDetailDrawer, {
  InfoRow,
  PreviewDetailDrawerProps,
  SectionInfo,
} from '../../common/PreviewDetailDrawer';
import { Option } from 'src/@types/crs/case';
import useLocales from 'src/hooks/useLocales';
import moment from 'moment';
import { ReferralStatuses } from './child-referral/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { fhirClient } from '../../../../App';
import { CircularProgress } from '@mui/material';
import { isArray } from 'lodash';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

export interface OutboundReferralDetailDrawerProps
  extends Omit<PreviewDetailDrawerProps, 'title' | 'anchor'> {
  serviceRequest?: WrappedServiceRequest | null;
}

const OutboundReferralDetailDrawer = ({
  serviceRequest,
  onCloseIconButtonClick,
  open,
  ...drawerProps
}: OutboundReferralDetailDrawerProps) => {
  const { i18n } = useLocales();
  const { configurations } = useTenantConfigData();

  const [referredFrom, setReferredFrom] = useState<any>(null);
  const [isLoadingReferredFrom, setIsLoadingReferredFrom] = useState(false);
  const [healthcareServices, setHealthcareServices] = useState<any[]>([]);
  const [isLoadingHealthcareServices, setIsLoadingHealthcareServices] = useState(false);

  const healthCareServiceIds = useMemo(
    () =>
      serviceRequest
        ?.getPerformersByReferenceType?.('HealthcareService')
        ?.reduce?.<string[]>((ids, { reference }) => {
          const id = reference?.split?.('/')?.[1];
          if (id) return [...ids, id];
          return ids;
        }, []) ?? [],
    [serviceRequest]
  );

  const reset = () => {
    setReferredFrom(null);
    setHealthcareServices([]);
    setIsLoadingHealthcareServices(false);
    setIsLoadingReferredFrom(false);
  };

  const handleClose = () => {
    reset();
    onCloseIconButtonClick();
  };

  const fetchServiceRequests = useCallback(async () => {
    if (healthCareServiceIds && healthCareServiceIds.length > 0) {
      setIsLoadingHealthcareServices(true);
      if (healthCareServiceIds && healthCareServiceIds.length > 0) {
        try {
          const response: any = await fhirClient.get(
            `HealthcareService?_id=${healthCareServiceIds.join(',')}`
          );
          if (
            response &&
            response?.entry &&
            isArray(response?.entry) &&
            response?.entry?.length > 0
          ) {
            setHealthcareServices(response?.entry?.map((x: any) => x?.resource));
          }
          setIsLoadingHealthcareServices(false);
          return response;
        } catch (error) {
          console.log(error);
          setIsLoadingHealthcareServices(false);
          setHealthcareServices([]);
        }
      } else {
        setIsLoadingHealthcareServices(false);
        return null;
      }
    }
  }, [healthCareServiceIds]);

  useEffect(() => {
    fetchServiceRequests();
  }, [fetchServiceRequests]);

  const referredFromReference = useMemo(() => {
    if (!open) {
      return null;
    }
    try {
      return {
        resourceType: serviceRequest?.requester?.reference?.split('/')?.[0] ?? null,
        resourceId: serviceRequest?.requester?.reference?.split('/')?.[1] ?? null,
      };
    } catch (error) {
      console.log(error);
      return null;
    }
  }, [serviceRequest, open]);

  const fetchReferredFrom = useCallback(async () => {
    if (
      referredFromReference &&
      referredFromReference?.resourceType &&
      referredFromReference?.resourceId
    ) {
      setIsLoadingReferredFrom(true);

      try {
        const response: any = await fhirClient.get(
          `${referredFromReference?.resourceType}/${referredFromReference?.resourceId}`
        );
        if (response && response?.id) {
          setReferredFrom(response);
        }
        setIsLoadingReferredFrom(false);
        return response;
      } catch (error) {
        console.log(error);
        setIsLoadingReferredFrom(false);
        setReferredFrom(null);
      }
    } else {
      setIsLoadingReferredFrom(false);
      setReferredFrom(null);
    }
  }, [referredFromReference]);

  useEffect(() => {
    fetchReferredFrom();
  }, [fetchReferredFrom]);

  const referredFromDisplay = useMemo(() => {
    if (referredFrom && referredFrom?.id && referredFrom?.resourceType) {
      if (
        referredFrom?.resourceType === 'Practitioner' ||
        referredFrom?.resourceType === 'Patient' ||
        referredFrom?.resourceType === 'RelatedPerson'
      ) {
        return `${referredFrom?.name?.[0]?.given?.[0]} ${referredFrom?.name?.[0]?.family}`;
      } else if (referredFrom?.resourceType === 'PractitionerRole') {
        return referredFrom?.practitioner?.display ?? null;
      } else if (referredFrom?.resourceType === 'Organization') {
        return referredFrom?.name;
      }
    }
    return null;
  }, [referredFrom]);

  // const [healthcareServices, { isLoading, isFetching, isSuccess, isError }] = useHealthcareServices(
  //   {
  //     map: HealthcareServiceWrapper,
  //     filter: {
  //       _id: healthCareServiceIds.join(','),
  //     },
  //     autofetch: !!healthCareServiceIds.length && open,
  //   }
  // );

  const serviceRequestDetails: Option<() => string | undefined>[] = [
    {
      label: `patients.details.serviceRequests.internalNumber`,
      value: () => serviceRequest?.getInternalNumber?.(configurations?.fhir?.serviceRequestInternalNumberIdenitifierSystem),
    },
    {
      label: 'patients.details.serviceRequests.occurrenceDate',
      value: () => {
        const date = moment(serviceRequest?.occurrenceDateTime ?? null);
        if (!date.isValid()) return;
        return date.format('MM/DD/YYYY');
      },
    },
    {
      label: 'patients.details.serviceRequests.status',
      value: () => {
        if (!serviceRequest?.status) return '';
        return ReferralStatuses?.[serviceRequest.status];
      },
    },
    {
      label: 'patients.details.serviceRequests.referredFrom',
      value: () => {
        if (isLoadingReferredFrom) {
          return <CircularProgress />;
        } else {
          return referredFromDisplay;
        }
      },
    },
    {
      label: 'patients.details.serviceRequests.referredTo',
      value: () => serviceRequest?.getPerformersByReferenceType?.('Organization')?.[0]?.display,
    },
    {
      label: 'patients.details.serviceRequests.service',
      value: () => {
        if (isLoadingHealthcareServices) {
          return <CircularProgress />;
        } else {
          const healthCareServiceId = serviceRequest
            ?.getPerformersByReferenceType?.('HealthcareService')?.[0]
            ?.reference?.split?.('/')?.[1];
          if (!healthCareServiceId) return;

          return healthcareServices?.find?.(({ id }) => id === healthCareServiceId)?.name;
        }
      },
    },
    {
      label: 'patients.details.serviceRequests.location',
      value: () => serviceRequest?.locationReference?.[0]?.display,
    },
    {
      label: 'patients.details.serviceRequests.assignedTo',
      value: () => serviceRequest?.getPerformersByReferenceType?.('Practitioner')?.[0]?.display,
    },
  ];

  const isLoading = useMemo(
    () => isLoadingHealthcareServices && isLoadingReferredFrom,
    [isLoadingHealthcareServices, isLoadingReferredFrom]
  );

  return (
    <PreviewDetailDrawer
      onCloseIconButtonClick={handleClose}
      shouldShowActionButton={checkAclValidation({
        acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.EDIT],
      })}
      open={open}
      isLoading={isLoading}
      title={i18n('patients.details.serviceRequests.details', 'crs')}
      anchor="right"
      {...drawerProps}
    >
      <SectionInfo title={i18n('patients.details.serviceRequests.detail', 'crs')}>
        {serviceRequestDetails.map(({ label, value }) => (
          <InfoRow key={`${label}-${value}`} label={i18n(label, 'crs')} value={value() ?? ''} />
        ))}
      </SectionInfo>
    </PreviewDetailDrawer>
  );
};

export default OutboundReferralDetailDrawer;
