import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import _ from "lodash";
import { useState, useEffect } from "react";
import { useCodeSystem } from "src/@nicheaim/fhir-react";
import useTenantConfigData from "src/hooks/useTenantConfigData";
import { CodeSystemConcept } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";
import axiosInstance from "src/utils/axios";


export type DropdownOptionType = { display: string; code: string; }

interface DiagnosisSearchDropdownProps {
  onChange: (value: any, createDiagnosisCallback: Function) => void;
  renderOption?: (props: any, option: any, { selected }: {
    selected: any;
}) => JSX.Element;
  disabled?: boolean;
  value: any;
  variant?: 'standard' | 'outlined' | 'filled';
  sx?: any;
  multiple?: boolean;
  label?: string;
}

const DiagnosisSearchDropdown = ({
  value,
  onChange,
  renderOption,
  disabled,
  variant = 'outlined',
  sx = {},
  multiple = false,
  label = 'Type to search diagnosis'
}: DiagnosisSearchDropdownProps) => {
  const { configurations } = useTenantConfigData();
  const [diagnosisCodes, { update: updateCodeSystem }] = useCodeSystem('ccm-diagnosis-codes');
  const [loadingDiagnosis, setLoadingDiagnosis] = useState<boolean>(false);
  const [searchDiagnosisValue, setSearchDiagnosisValue] = useState<string>('');
  const [diagnosis, setDiagnosis] = useState<DropdownOptionType[]>([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState<DropdownOptionType | null>(null)
  const [showDialogModal, setShowDialogModal] = useState(false);

  useEffect(() => {
    setDiagnosis(diagnosisCodes?.concept?.map((concept: CodeSystemConcept) => ({
      display: concept.display ?? '',
      code: concept.code ?? '',
      system: '',
    })) ?? []);
  }, [diagnosisCodes])

  useEffect(() => {
    if ((searchDiagnosisValue.length >= 1 || searchDiagnosisValue.length === 0) && !loadingDiagnosis) {
      const filteredOptions: DropdownOptionType[] = diagnosisCodes?.concept?.filter((concept: CodeSystemConcept) =>
        concept.display?.toLocaleLowerCase().includes(searchDiagnosisValue.toLocaleLowerCase())
      )
      .map((concept: CodeSystemConcept) => ({
        display: concept.display ?? '',
        code: concept.code ?? '',
      })) ?? [];

      if (filteredOptions.length === 0) {
        setLoadingDiagnosis(true)
        axiosInstance.get(`${configurations?.hapiIcdUrl}/$expand?_format=json&filter=${searchDiagnosisValue}`).then(({ data }) => {
          setDiagnosis(data.expansion.contains.map((i: any) => ({
            display: `${i.display}(${i.code})`,
            code: i.code,
            system: i.system,
          })));
          setLoadingDiagnosis(false);
        });
      } else {
        setDiagnosis(filteredOptions);
      }
    } else {
      if (diagnosis.length === 0) {
        setDiagnosis(diagnosisCodes?.concept?.map((concept: CodeSystemConcept) => ({
          display: concept.display ?? '',
          code: concept.code ?? '',
          system: '',
        })) ?? []);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDiagnosisValue]);

  const onChangeDiagnosisSearch = _.debounce((e) => setSearchDiagnosisValue(e.target.value), 2000)

  const closeDialogModal = () => setShowDialogModal(false);

  const importDiagnosis = async (selectedDiagnosis: DropdownOptionType) =>
    updateCodeSystem({
      ...diagnosisCodes!,
      concept: [
        ...diagnosisCodes?.concept ?? [],
        {
          code: selectedDiagnosis?.code,
          display: selectedDiagnosis?.display,
        }
      ]
    }).catch((err) => console.log(err))
  
  return (
    <>
      <Dialog open={showDialogModal} onClose={closeDialogModal}>
        <DialogTitle id="create-diagnosis-on-small-catalog-modal">
          {'The selected diagnosis come from the ICD-10 catalog'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-note-modal-description">
            Do you want to import this diagnosis to your organization's catalog?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            onChange(selectedDiagnosis, () => {})
            closeDialogModal()
          }}>
            No, go back
          </Button>
          <Button
            onClick={() => {
              onChange(selectedDiagnosis, importDiagnosis)
              closeDialogModal();
            }}
          >
            Yes, import
          </Button>
        </DialogActions>
      </Dialog>
      <Autocomplete
        multiple={multiple}
        disablePortal
        options={diagnosis}
        value={value}
        onChange={(e, val) => {
          console.log({
            val,
            c: diagnosisCodes?.concept?.find(i => i.code === val?.code)
          })
          setSelectedDiagnosis(val);
          if (val.code && !diagnosisCodes?.concept?.find(i => i.code === val?.code)) {
            setShowDialogModal(true);
          } else {
            onChange(val, importDiagnosis)
          }
        }}
        getOptionLabel={(option) => option.display}
        filterOptions={(options, state) => {
          if (state.inputValue !== '') {
            return options.filter((i) => i.display.toLowerCase().includes(state.inputValue.toLowerCase()))
          }

          return options;
        }}
        renderInput={(params) =>
          <TextField
            placeholder="Type to search diagnosis"
            variant={variant}
            {...params}
            onChange={onChangeDiagnosisSearch}
            label={label}
          />
        }
        renderOption={renderOption ?? ((props, option, { index }) => (
          <li {...props} key={option.code + index}>{option.display}</li>
        ))}
        disableCloseOnSelect={multiple}
        disabled={disabled}
        loading={loadingDiagnosis}
        loadingText="Loading from ICD-10 catalog..."
        sx={sx}
      />
    </>
  )
}

export default DiagnosisSearchDropdown;
