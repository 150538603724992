import { Button } from '@mui/material';
import { useEffect } from 'react';
import useSmartLaunch from 'src/hooks/useSmartLaunch';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

interface ProviderWindowProps {
  userId: string;
  fhirUserId: string;
  patientId: string | null;
}

const getFhirIdFromUri = (uri: string): string => {
  const fhirIdParts = uri.split('/');
  return fhirIdParts?.[1] ? fhirIdParts[1] : '';
};

function ProviderWindow({ userId, fhirUserId, patientId }: ProviderWindowProps) {
  const { configurations } = useTenantConfigData();
  const { getSmartUrl, readyToBuild } = useSmartLaunch({
    userId,
    fhirUserId: getFhirIdFromUri(fhirUserId),
    patientId,
    smartAppBaseLaunchUrl: configurations?.providerWindowLaunchUrl,
    fhirBaseUrl: configurations?.fhir?.baseUrl
  });

  const handleOpenProviderWindow = async () => {
    const url = await getSmartUrl();
    if (url) {
      window.open(url);
    }
  };

  const render = readyToBuild ? (
    <Button color="inherit" variant="outlined" size="small" onClick={handleOpenProviderWindow}>
      Provider Window
    </Button>
  ) : (
    <div>Loading...</div>
  );

  return render;
}

export default ProviderWindow;
