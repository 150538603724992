import { 
  ComplexityScore, 
  MeasureReportCustom, 
  PatientCustom, 
  QuestionnaireResponseCustom, 
  RequestCustom, 
  RequesterCustom 
} from 'src/@types/nat/report';
import axios from 'src/utils/axios';
import { urlBackNestApp } from 'src/routes/urls';
import { IApiClient } from 'src/api/clients/api_client';

export interface ResendReprocessResponse {
  status: string;
}

export interface TaskCustom {
  patient: PatientCustom;
  request: RequestCustom;
  requester: RequesterCustom;
  medical_complexity: ComplexityScore;
  social_complexity: ComplexityScore;
  service_integration_levels: ComplexityScore;
  measure_report: MeasureReportCustom[];
  questionnaire_response_list: QuestionnaireResponseCustom[];
}

export class TaskApiClient {
  apiBase: string;

  taskApiClient: IApiClient;

  constructor(taskApiClient: IApiClient) {
    this.apiBase = 'nat/task';
    this.taskApiClient = taskApiClient;
  }

  async resendTask(taskId: string) {
    try {
      const response = await this.taskApiClient.get<ResendReprocessResponse | undefined>(
        `${this.apiBase}/${taskId}/resend`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async reprocessMeasureReport(measureReportId: string) {
    try {
      const response = await this.taskApiClient.get<ResendReprocessResponse | undefined>(
        `${this.apiBase}/${measureReportId}/reprocess`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async getPrintable(body: Partial<TaskCustom>){
    const response = await axios.post<any>(
      `${urlBackNestApp}/nat/task/printable`,
      body,
      { responseType: "arraybuffer", timeout: 20000 }
    );
    return response;
  }

  async createTask(payload: any) {
    try {
      const response = await this.taskApiClient.post<any, undefined>(
        `${this.apiBase}/`, payload
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async getQuestionnaireAnswerMap(questionaireIds: string[]) {
    const response = await axios.get<any>(
      `${urlBackNestApp}/nat/task/questionnaire-answer-map/${questionaireIds}`
    );
    return response;
  }
}

export default class TaskService {
  taskApiClient: TaskApiClient;

  constructor(taskApiClient: TaskApiClient) {
    this.taskApiClient = taskApiClient;
  }

  async resendTask(taskId: string): Promise<ResendReprocessResponse | undefined> {
    const response = await this.taskApiClient.resendTask(taskId);
    if (!response) return undefined;
    return response;
  }

  async reprocessMeasureReport(measureReportId: string): Promise<ResendReprocessResponse | undefined> {
    const response = await this.taskApiClient.reprocessMeasureReport(measureReportId);
    if (!response) return undefined;
    return response;
  }

  async getPrintable(body: Partial<TaskCustom>) {
    const response = await this.taskApiClient.getPrintable(body);
    if (!response) return undefined;
    return response;
  }

  async createTask(payload: any) {
    const response = await this.taskApiClient.createTask(payload);
    if (!response) return undefined;
    return response;
  }

  async getQuestionnaireAnswerMap(questionaireIds: string[]){
    const response = await this.taskApiClient.getQuestionnaireAnswerMap(questionaireIds);
    if (!response) return undefined;
    return response;
  }
}
