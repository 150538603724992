//@ts-nocheck
import { Languages } from 'src/@types/crs/patient';
import {
  Address,
  CodeableConcept,
  Coding,
  ContactPoint,
  Extension,
  HumanName,
  Identifier,
  Patient,
  PatientGender,
  Attachment,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { formatAddress } from 'src/sections/careflow/health-record/patient-info-header/components/utils';
import { getAge, getMonthsSince } from 'src/utils/formatTime';
import { createWrapper } from '../../fhir-react/base';
import { getExtensionArrayByURLs } from 'src/utils/fhir';

const IDENTIFIER_TYPE_CODE_SYSTEM_URL = 'http://terminology.hl7.org/CodeSystem/v2-0203';
export const OMBCATEGORY_SYSTEM = 'urn:oid:2.16.840.1.113883.6.238';

export enum IdentifierCode {
  MEDICAL_RECORD_NUMBER = 'MRN',
  SOCIAL_SECURITY_NUMBER = 'SSN',
  DRIVERS_LICENSE_NUMBER = 'DL',
  PASSPORT_NUMBER = 'PPN',
  TAX_ID_NUMBER = 'TAX',
  DONOR_REGISTRATION_NUMBER = 'DR',
  SOCIAL_BENEFICIARY_IDENTIFIER = 'SB',
  MASTER_PERSON_UUID = 'MASTER_PERSON_UUID',
  MASTER_PATIENT_UUID = 'MASTER_PATIENT_UUID',
}

export const IDENTIFIER_MAP: Map<IdentifierCode, Identifier> = newIdentifierMap()
  .add(
    IdentifierCode.MEDICAL_RECORD_NUMBER,
    'Medical record number',
    process.env.REACT_APP_SYSTEM_MRN
  )
  .add(
    IdentifierCode.SOCIAL_SECURITY_NUMBER,
    'Social security number',
    'http://hl7.org/fhir/sid/us-ssn'
  )
  .add(
    IdentifierCode.DRIVERS_LICENSE_NUMBER,
    "Driver's license number",
    IDENTIFIER_TYPE_CODE_SYSTEM_URL
  )
  .add(IdentifierCode.PASSPORT_NUMBER, 'Passport number', IDENTIFIER_TYPE_CODE_SYSTEM_URL)
  .add(IdentifierCode.TAX_ID_NUMBER, 'Tax ID number', IDENTIFIER_TYPE_CODE_SYSTEM_URL)
  .add(
    IdentifierCode.DONOR_REGISTRATION_NUMBER,
    'Donor Registration Number',
    IDENTIFIER_TYPE_CODE_SYSTEM_URL
  )
  .add(
    IdentifierCode.SOCIAL_BENEFICIARY_IDENTIFIER,
    'Social Beneficiary Identifier',
    IDENTIFIER_TYPE_CODE_SYSTEM_URL
  )
  .add(
    IdentifierCode.MASTER_PERSON_UUID,
    'Master Person UUID',
    `${process.env.REACT_APP_MPI_SEARCH_URL}/persons`
  )
  .add(
    IdentifierCode.MASTER_PATIENT_UUID,
    'Master Patient UUID',
    `${process.env.REACT_APP_MPI_SEARCH_URL}/patients`
  )
  .getValue();

export const PatientWrapper = createWrapper('Patient', (patient) => ({
  getDefaultDateLocale(): string {
    return 'en-US';
  },

  getDefaultDateFormatOptions(): Intl.DateTimeFormatOptions {
    return {
      month: 'short',
      year: 'numeric',
      day: 'numeric',
    };
  },

  getMRN(systemUrl: string): Identifier | null {
    return getIdentifier(patient, IdentifierCode.MEDICAL_RECORD_NUMBER, systemUrl);
  },

  getSSN(systemUrl: string): Identifier | null {
    return getIdentifier(patient, IdentifierCode.SOCIAL_SECURITY_NUMBER, systemUrl);
  },

  getIdentifier(code: IdentifierCode, systemUrl: string) {
    return getIdentifier(patient, code, systemUrl);
  },

  getFullName(): string | null {
    let fullName = null;
    if (patient.name?.[0]?.given?.[0] && patient.name?.[0]?.family) {
      fullName = `${patient.name?.[0]?.given?.[0]} ${patient.name?.[0]?.family}`;
    }
    return fullName;
  },

  getAlias(): HumanName | null {
    return patient.name?.find((n) => n.use === 'nickname') || null;
  },

  getPrimaryPhone(): ContactPoint | null {
    let mainPhone = null;
    if (patient.telecom && patient.telecom?.length > 0) {
      const phones = patient.telecom.filter((t) => t?.system === 'phone');
      if (phones && phones?.length > 0) {
        mainPhone = phones[0];
      }
    }
    return mainPhone;
  },

  getPhoto(): Attachment | null {
    if (!patient.photo) return null;
    return patient.photo[0];
  },
  getPhoneNumbers() {
    if (!patient.telecom) return [];

    return patient.telecom.filter((t) => t?.system === 'phone') as (ContactPoint & {
      system: 'phone';
    })[];
  },

  getEmails() {
    if (!patient.telecom) return [];
    return patient.telecom.filter((t) => t?.system === 'email') as (ContactPoint & {
      system: 'email';
    })[];
  },

  getPrimaryEmail(): ContactPoint | null {
    let mainPhone = null;
    if (patient.telecom && patient.telecom?.length > 0) {
      const phones = patient.telecom.filter((t) => t?.system === 'email');
      if (phones && phones?.length > 0) {
        mainPhone = phones[0];
      }
    }
    return mainPhone;
  },

  getAddresses(): Address[] | null {
    if (!patient.address) return [];
    return patient.address;
  },

  getAddressesFormatted(): string[] | undefined {
    const addresses = this.getAddresses();
    return addresses?.map((a) => formatAddress(a));
  },

  getPrimaryAddress(): Address | null {
    let mainAddress = null;
    if (patient.address && patient.address?.length > 0) {
      mainAddress = patient.address[0];
    }
    return mainAddress;
  },

  getPrimaryAddressFormatted(): string | null {
    const mainAddress = this.getPrimaryAddress();

    if (!mainAddress) return null;

    return formatAddress(mainAddress);
  },

  getAgeInYears(): number | null {
    return patient.birthDate ? getAge(new Date(patient.birthDate)) : null;
  },

  getBirthDate(): Date | null {
    return patient.birthDate ? new Date(patient.birthDate) : null;
  },

  getBirthDateForDisplay(): string | null {
    const date = this?.getBirthDate()?.toLocaleDateString(
      this.getDefaultDateLocale(),
      this.getDefaultDateFormatOptions()
    );
    return date ? date : null;
  },

  getAgeGroup(): string | null {
    const age = this.getAgeInYears();
    let ageGroup = null;
    if (age) {
      if (age < 21) {
        ageGroup = 'young';
      }
      if (age >= 21) {
        ageGroup = 'adult';
      }
    }
    return ageGroup;
  },

  getAgeInMonths(): number | null {
    return patient.birthDate ? getMonthsSince(new Date(patient.birthDate)) : null;
  },

  getStatus(): string {
    return patient.active ? 'active' : 'inactive';
  },

  getExtensionByUrl(url: string): Extension | null {
    const ext = patient.extension ? patient.extension?.filter((e) => e?.url === url)?.[0] : null;

    return ext || null;
  },

  getRace(configurations: any): Coding | null {
    const URLS_RACE = ['ValueSet/omb-race-category', configurations?.raceUrl];
    let race = null;
    const raceExtension = getExtensionArrayByURLs(patient, URLS_RACE);

    if (raceExtension && Array.isArray(raceExtension) && raceExtension?.length > 0) {
      race = raceExtension[0];
    }

    return race;
  },

  getRaceArray(configurations: any): Coding[] | any {
    const URLS_RACE = ['ValueSet/omb-race-category', configurations?.raceUrl];
    const race = getExtensionArrayByURLs(patient, URLS_RACE);
    return race;
  },

  getEthnicity(configurations: any): Coding | null {
    const URLS_ETHNICITY = ['ValueSet/omb-ethnicity-category', configurations?.ethnicityUrl];
    let ethnicity = null;
    const ethnicityExtension = getExtensionArrayByURLs(patient, URLS_ETHNICITY);

    if (ethnicityExtension && Array.isArray(ethnicityExtension) && ethnicityExtension?.length > 0) {
      ethnicity = ethnicityExtension[0];
    }

    return ethnicity;
  },

  getEthnicityArray(configurations: any): Coding[] | any {
    const URLS_ETHNICITY = ['ValueSet/omb-ethnicity-category', configurations?.ethnicityUrl];
    const ethnicity = getExtensionArrayByURLs(patient, URLS_ETHNICITY);
    return ethnicity;
  },

  getGender(): PatientGender | null {
    return patient?.gender;
  },

  getMaritalStatus(): CodeableConcept | null {
    return patient?.maritalStatus || null;
  },

  getSexualOrientation(configurations): Coding | null {
    let sexualOrientation = null;
    const coding = configurations?.sexualOrientationUrl
      ? this.getExtensionByUrl(configurations?.sexualOrientationUrl)?.valueCodeableConcept?.coding
      : null;

    if (coding && Array.isArray(coding) && coding?.length > 0) {
      sexualOrientation = coding[0];
    }

    return sexualOrientation;
  },
  getOrganization: (): string | undefined =>
    patient?.managingOrganization?.display || patient?.managingOrganization?.reference,

  getLanguage(): Languages[] | null {
    const language = patient?.communication?.map((e) => ({
      value: e?.language?.coding?.[0].display,
      preferred: e?.preferred,
    }));
    return language ? language : null;
  },

  getInckEligible: (): boolean =>
    patient?.meta?.tag?.find((tag) => tag?.code === 'InCK-Eligible') ? true : false,

  getGenderIdentityCodeableConcept: (configurations: any): ValueSetComposeIncludeConcept | null => {
    const URLS_GENDER_IDENTITY = [
      'ValueSet/us-core-birthsex', 
      configurations?.genderIdentityExtensionDefinitionUrl, 
      configurations?.genderIdentityCodeSystemUrl
    ];
    const genderIdentityExtension = patient?.extension?.find?.(
      ({ url }) => URLS_GENDER_IDENTITY.some((e: string) => e === url)
    );
    if (!genderIdentityExtension) return null;
    const genderIdentityCoding = genderIdentityExtension?.valueCodeableConcept;

    return {
      code: genderIdentityCoding?.coding?.[0]?.code,
      display: 
        genderIdentityCoding?.coding?.[0]?.display 
        ? genderIdentityCoding.coding[0].display 
        : genderIdentityCoding?.text 
            ? genderIdentityCoding.text 
            : genderIdentityCoding?.coding?.[0]?.code
                ? genderIdentityCoding.coding[0].code.replace('-', ' ') 
                : null,
      system: genderIdentityCoding?.coding?.[0]?.system
    };
  },
  getMedicaId: (configurations): string =>
    patient?.identifier?.find?.(({ system }) => system === configurations?.medicaIdIdentifierSystemUrl)?.value ??
    '',
  getZIPCode: (): string =>
    patient?.address?.find?.(({ postalCode }) => !!postalCode)?.postalCode || '',
}));

function getIdentifier(
  patient: Patient, 
  identifierCode: IdentifierCode,
  systemUrl: string
): Identifier | null {
  let identifierType = IDENTIFIER_MAP.get(identifierCode);
  const system = systemUrl ? systemUrl : identifierType?.system;
  const code = identifierType?.type?.coding?.[0]?.code;
  if (system || code) {
    const value =
      patient.identifier?.find(
        (identifier) => identifier?.system === system || identifier?.type?.coding?.[0].code === code
      ) || null;
    return value;
  }
  return null;
}

function newIdentifierMap() {
  const entries: Map<IdentifierCode, Identifier> = new Map();
  const map = {
    add: (code: IdentifierCode, display: string, system?: string) => {
      entries.set(code, {
        system,
        type: {
          coding: [
            {
              system,
              code,
              display,
            },
          ],
          text: display,
        },
      });
      return map;
    },
    getValue: () => entries,
  };
  return map;
}

export type WrappedPatient = ReturnType<typeof PatientWrapper>;
