/* eslint-disable */
import makeStyles from "@mui/styles/makeStyles";
import KeyValueGrid from "../../crs/common/KeyValueGrid";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { QuestionnaireAnswerMap, SimplifiedAnswer, SimplifiedQuestion } from "src/@types/nat/report";
import { 
  Questionnaire,
  QuestionnaireResponse,
  QuestionnaireResponseItem, 
  QuestionnaireResponseItemAnswer 
} from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "40px",
  },
  rootExpanded: {
    background: "blue",
    flexGrow: 1,
  },
  highlight: {
    backgroundColor: "#000",
    color: "#FFF",
    paddingBottom: "8px",
    marginTop: "8px",
  },
}));

interface RequestReportResponseProps {
  devMode: boolean;
  defaultExpanded?: boolean;
  questionnaire: Questionnaire[] | undefined;
  questionnaireResponse: QuestionnaireResponse[] | undefined;
  questionnaireAnswerMap: QuestionnaireAnswerMap[] | undefined;
}

export default function RequestReportResponse({
  questionnaire,
  questionnaireResponse,
  devMode,
  defaultExpanded,
  questionnaireAnswerMap
}: RequestReportResponseProps) {
  const classes = useStyles();

  const getQuestionnaireResponseSummary = (
    questResponse: QuestionnaireResponse,
  ) => {

    const matchingQuestUuidFromUrl = (() => {
      if (questResponse?.questionnaire === undefined) return undefined;
    
      const url = questResponse?.questionnaire;
      const pathParts = url.split('/');
      const lastPart = pathParts[pathParts.length - 1];
    
      return lastPart;
    })();

    const questionnaireFromQuest = questionnaire?.find((item) => item?.id === matchingQuestUuidFromUrl);
    
    return (
      <Grid container>
        <Grid container item md={4} alignItems="center">
          <Typography>
            {questionnaireFromQuest?.title ?? ''}
          </Typography>
        </Grid>

        {devMode && (
          <Grid container item direction="row" md={12}>
            <Grid
              className={classes.highlight}
              container
              item
              md={12}
              direction="column"
            >
              <KeyValueGrid
                label="Questionnaire ID"
                value={
                  questionnaireFromQuest?.id === undefined
                    ? ""
                    : questionnaireFromQuest?.resourceType + "/" + questionnaireFromQuest?.id
                }
              />
              <KeyValueGrid
                label="Questionnaire Title"
                value={questionnaireFromQuest?.title === undefined ? "" : questionnaireFromQuest?.title}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const getNestedValues = (item: QuestionnaireResponseItem) => {
    let simplifiedQuest: SimplifiedQuestion = {
      text: item.text === undefined ? "" : item.text,
      linkId: item.linkId,
      answers: [],
      items: [],
    };

    if (item.answer) {
      item.answer.forEach((answer: QuestionnaireResponseItemAnswer) => {
        let valueRes: SimplifiedAnswer = {
          value: "",
          type: "",
          explanation: "",
          answerStyle: ""
        };

        if (answer.valueInteger !== undefined) {
          valueRes.type = "valueInteger";
          valueRes.value = String(answer.valueInteger);
        } else if (answer.valueCoding !== undefined) {
          let answerMap = questionnaireAnswerMap && questionnaireAnswerMap?.length > 0 && 
            questionnaireAnswerMap?.find((e: QuestionnaireAnswerMap) => e?.questionId === item.linkId);
          valueRes.type = "valueCoding";
          valueRes.value = String(answer.valueCoding.display);
          valueRes.code = answer.valueCoding.code;
          valueRes.display = String(answer.valueCoding.display);
          valueRes.explanation = String(answerMap ? answerMap.answerDisplay : "");
          valueRes.answerStyle = String(answerMap ? answerMap.answerStyle : "");

          if (answer.valueCoding.system) {
            valueRes.system = answer.valueCoding.system;
          }

          if (answer.valueCoding.version) {
            valueRes.system = answer.valueCoding.version;
          }
        } else if (answer.valueString !== undefined) {
          valueRes.type = "valueString";
          valueRes.value = answer.valueString;
        }

        simplifiedQuest.answers?.push(valueRes);
      });
    }

    if (item.item) {
      return (
        <Accordion defaultExpanded={defaultExpanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container>
              <Grid>{simplifiedQuest.text}</Grid>
              {devMode && (
                <Grid container item direction="row" md={12}>
                  <Grid
                    className={classes.highlight}
                    container
                    item
                    md={12}
                    direction="column"
                  >
                    <KeyValueGrid
                      label="Item Linkid"
                      value={simplifiedQuest.linkId}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <Grid>
            {item.item.map((item: QuestionnaireResponseItem) =>
              getNestedValues(item)
            )}
          </Grid>
        </Accordion>
      );
    }

    return (
      <AccordionDetails>
        <Grid container direction="row">
          <Grid item md={6}>
            <Grid container item>
              <Typography variant="body2" component="h1" paragraph>
                {simplifiedQuest.text}
              </Typography>
            </Grid>
          </Grid>

          <Grid container item direction="row" md={2}>
            <Grid container item direction="row" justifyContent="center">
              <Typography
                variant="body2"
                component="h1"
                paragraph
                style={{ color: "green" }}
              >
                <b>
                  {simplifiedQuest.answers?.map(
                    (item: SimplifiedAnswer, index: number) => {
                      let displayData = "";

                      if (index > 0) displayData += ", ";

                      return <span className={item.answerStyle}>{displayData + item.value}</span>;
                    }
                  )}
                </b>
              </Typography>
            </Grid>
          </Grid>

          <Grid container item direction="row" md={4}>
            <Grid container item direction="row" justifyContent="center">
              <Typography
                variant="body2"
                component="h1"
                paragraph
                style={{ color: "green" }}>
                  {simplifiedQuest.answers?.map(
                    (item: SimplifiedAnswer, index: number) => {
                      return <span className={item.answerStyle}>{item.explanation}</span>;
                    }
                  )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {devMode && (
          <Grid container direction="row">
            <Grid
              className={classes.highlight}
              container
              item
              md={12}
              direction="column"
            >
              <KeyValueGrid
                label="Answer valueType"
                value={
                  simplifiedQuest.answers
                    ? simplifiedQuest.answers
                        ?.map((item: SimplifiedAnswer) => item.type)
                        .join(" ")
                    : ""
                }
              />

              {simplifiedQuest?.answers?.[0]?.display !== undefined && (
                <KeyValueGrid
                  label="Answer Display"
                  value={
                    simplifiedQuest.answers
                      ? simplifiedQuest.answers
                          ?.map((item: SimplifiedAnswer) => item.display)
                          .join(" ")
                      : ""
                  }
                />
              )}

              {simplifiedQuest?.answers?.[0]?.code !== undefined && (
                <KeyValueGrid
                  label="Answer Code"
                  value={
                    simplifiedQuest.answers
                      ? simplifiedQuest.answers
                          ?.map((item: SimplifiedAnswer) => item.code)
                          .join(" ")
                      : ""
                  }
                />
              )}

              {simplifiedQuest?.answers?.[0]?.system !== undefined && (
                <KeyValueGrid
                  label="Answer System"
                  value={
                    simplifiedQuest.answers
                      ? simplifiedQuest.answers
                          ?.map((item: SimplifiedAnswer) => item.system)
                          .join(" ")
                      : ""
                  }
                />
              )}

              {simplifiedQuest?.answers?.[0]?.version !== undefined && (
                <KeyValueGrid
                  label="Answer Version"
                  value={
                    simplifiedQuest.answers
                      ? simplifiedQuest.answers
                          ?.map((item: SimplifiedAnswer) => item.version)
                          .join(" ")
                      : ""
                  }
                />
              )}

              <KeyValueGrid
                label="Answer Linkid"
                value={simplifiedQuest.linkId}
              />
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    );
  };

  const getSimplifiedValuesFromQR = (
    items: QuestionnaireResponseItem[]
  ) => {
    const res = items.map((item: QuestionnaireResponseItem) => {
      return getNestedValues(item);
    });

    return res;
  };

  const getQuestionnaireReponseDetails = (
    questResponse: QuestionnaireResponse
  ) => {
    
    const OOC = questResponse?.item?.
      find((itemOOC: QuestionnaireResponseItem) => itemOOC.answer?.[0]?.valueCoding?.code === 'OOC');
    
    const simplifiedValuesFromQR =
      questResponse.item === undefined
        ? undefined
        : OOC === undefined ? 
          getSimplifiedValuesFromQR(questResponse.item) 
            : getSimplifiedValuesFromQR([OOC]);

    return (
      <Grid>
        {devMode && (
          <Grid
            className={classes.highlight}
            container
            item
            md={12}
            direction="column"
          >
            <KeyValueGrid
              label="Questionnaire Response ID"
              value={
                questResponse.id === undefined
                  ? ""
                  : questResponse.resourceType + "/" + questResponse.id
              }
            />
            <KeyValueGrid
              label="QuestionnaireResponse.questionnaire"
              value={
                questResponse.questionnaire === undefined
                  ? ""
                  : questResponse.questionnaire
              }
            />
            <KeyValueGrid
              label="Questionnaire Response Status"
              value={questResponse.status}
            />
          </Grid>
        )}

        <Grid container flexDirection="row">
          <Grid container item md={12} paddingLeft={2}>
            <Grid item md={6}>
              <Typography variant="h6" component="h1" paragraph>
                QUESTION
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant="h6" component="h1" paragraph>
                RESPONSE
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant="h6" component="h1" paragraph>
                RESPONSE EXPLANATION
              </Typography>
            </Grid>
          </Grid>
          
          {simplifiedValuesFromQR?.map((item: JSX.Element) => (
            <Grid item md={12}>
              {item}
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  return (
    <Paper elevation={3}>
      <Grid container direction="row" justifyContent="center">
        <Grid container item md={12} style={{ padding: 16 }}>
          <Typography 
            variant="h4" 
            sx={{ m: 2 }}
          >
            Questionnaire Responses
          </Typography>
          <Grid
            container
            item
            md={12}
            flexDirection="row"
            alignItems="center"
            spacing={2}
          >
            {questionnaireResponse?.map(
              (questResponseItem: QuestionnaireResponse) => {
                return (
                  <Grid container item md={12} flexDirection="row" alignItems="center">
                    <Accordion className={classes.root} defaultExpanded={defaultExpanded}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        {getQuestionnaireResponseSummary(questResponseItem)}
                      </AccordionSummary>
                      {questResponseItem ? (
                        getQuestionnaireReponseDetails(questResponseItem)
                      ) : (
                        <></>
                      )}
                    </Accordion>
                  </Grid>
                )}
              )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
