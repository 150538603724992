import ConsentModal from '../ConsentModal';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import DataGridWithFilters from 'src/sections/crs/case/components/DataGridWithFilters/DataGridWithFilters';
import useLocales from 'src/hooks/useLocales';
import useGridFilters from 'src/hooks/useGridFilters';
import useClientGrid from 'src/hooks/useClientGrid';
import useObjectState from 'src/hooks/useObjectState';
import { ConsentWrapper, WrappedConsent } from 'src/@nicheaim/fhir-base/wrappers/Consent';
import ConsentGridFilter, { ConsentGridFilters } from './ConsentFilters';
import useGridFilterConsolidation from 'src/hooks/useGridFilterConsolidation';
import { defaultRowsPerPageOptions } from 'src/sections/crs/constants';
import { GridColDef } from '@mui/x-data-grid';
import CellRow from 'src/sections/crs/common/CellRow';
import { useCallback, useEffect, useMemo } from 'react';
import { fhirClient } from 'src/App';
import {
  Bundle,
  Consent,
  FhirResource,
  Organization,
  PractitionerRole,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import useNotiMessage from 'src/hooks/useNotiMessage';
import { ConsentPermissions, ResourceWithIncludedResources } from 'src/sections/crs/types';
import { getReferenceId, getReferenceType } from 'src/utils/fhir';
import { PermissionsProvider } from 'src/contexts/PermissionsContext';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import CustomLink from 'src/sections/crs/common/CustomLink';
import { Box, SxProps } from '@mui/material';
import moment from 'moment';
import {
  begginingOfTime,
  endOfTime,
  formatDate,
  getTimeDiff,
  isBetweenDates,
  momentDateComparator,
} from 'src/utils/dates';
import { Edit, EventAvailable, EventBusy, Lock, LockOpen, Preview } from '@mui/icons-material';
import { useValueSet } from 'src/@nicheaim/fhir-react';
import { ValueSetWrapper, WrappedValueSet } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import SeverityStatus from 'src/components/SeverityStatus';
import { cleanSearchInput } from 'src/utils/string';
import ConsentDetailPreview from './ConsentDetailPreview';
import ActionButton from 'src/components/ActionButton';
import { NestedMenuItem } from 'mui-nested-menu';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

export interface ConsentGridProps {
  patient: WrappedPatient | null;
  title?: string;
}

export interface ConsentGridState {
  isLoading: boolean;
  isConsentModalOpen: boolean;
  selectedConsent: ResourceWithIncludedResources<WrappedConsent> | null;
  consents: ResourceWithIncludedResources<WrappedConsent>[];
  filteredConsents: ResourceWithIncludedResources<WrappedConsent>[];
  isConsentDetailDrawerOpen: boolean;
}

const initialFilters: ConsentGridFilters = {
  startDate: null,
  endDate: null,
};

const ConsentGrid = ({ patient, title }: ConsentGridProps) => {
  const [
    {
      isConsentModalOpen,
      isLoading,
      consents,
      filteredConsents,
      isConsentDetailDrawerOpen,
      selectedConsent,
    },
    updateState,
  ] = useObjectState<ConsentGridState>({
    isConsentDetailDrawerOpen: false,
    isConsentModalOpen: false,
    selectedConsent: null,
    isLoading: true,
    consents: [],
    filteredConsents: [],
  });

  const { i18n } = useLocales();
  const { configurations } = useTenantConfigData();
  const consentInternalNumberIdentifierSystem = configurations?.fhir?.consentInternalNumberIdentifierSystem;

  const {
    isFilterDrawerOpen,
    onFilterDrawerOpen,
    onFilterDrawerClose,
    onSearchTextFieldChange,
    searchTextFieldValue,
    filters,
    onApplyFilters,
  } = useGridFilters<ConsentGridFilters>(initialFilters);

  const { showErrorMessage } = useNotiMessage();

  const [consentStatusVS, { isFetching: isConsentStatusLoading }] = useValueSet(
    'consent-state-codes',
    {
      map: ValueSetWrapper,
    }
  );

  const {
    filters: tempFilters,
    updateFilters,
    onClearAllFilters,
  } = useGridFilterConsolidation<ConsentGridFilters>(filters, initialFilters);

  const { sortModel, handleSortModelChange } = useClientGrid({
    initialRowsPerPage: 10,
    defaultSort: [{ field: 'date', sort: 'desc' }],
  });

  const getConsents = useCallback(async (): Promise<
    ResourceWithIncludedResources<WrappedConsent>[]
  > => {
    if (!patient?.id) return [];
    try {
      const url = `Consent?patient=${patient.id}&_include=Consent:source-reference&_include=Consent:consentor&_include=Consent:organization&_count=1000`;
      const response = await fhirClient.get<Bundle>(url);
      if (!response?.entry?.length) return [];
      let entries = response.entry.reduce<FhirResource[]>((resources, { resource }) => {
        if (!resource) return resources;
        return [...resources, resource];
      }, []);
      const consents = entries.filter(
        ({ resourceType }) => resourceType === 'Consent'
      ) as Consent[];

      const practitionerRoleRelatedResources = await getPractitionerRoleRelatedResources(consents);

      entries = [...entries, ...practitionerRoleRelatedResources];

      return consents.reduce<ResourceWithIncludedResources<WrappedConsent>[]>(
        (resources, consentUnwrapped) => {
          if (!consentUnwrapped?.id) return resources;
          const consent = ConsentWrapper(consentUnwrapped as Consent);

          const documentReference = entries.find?.(
            ({ id }) => id === getReferenceId(consent?.sourceReference?.reference)
          );

          const organizations =
            consent?.organization?.reduce?.<Organization[]>((organizations, { reference }) => {
              const organization = entries.find?.(
                ({ id }) => id === getReferenceId(reference)
              ) as Organization;
              if (!organization) return organizations;
              return [...organizations, organization];
            }, []) ?? [];

          const performers =
            consent?.performer?.reduce?.<FhirResource[]>((resources, { reference }) => {
              const resource = entries.find?.(({ id }) => id === getReferenceId(reference));
              if (!resource) return resources;
              return [...resources, resource];
            }, []) ?? [];

          const prRelatedResources = performers.reduce<FhirResource[]>((resources, resource) => {
            if (resource?.resourceType !== 'PractitionerRole') return resources;
            const practitionerRole = resource as PractitionerRole;

            const organization = entries.find(
              ({ id }) => id === getReferenceId(practitionerRole?.organization?.reference)
            );

            const practitioner = entries.find(
              ({ id }) => id === getReferenceId(practitionerRole?.practitioner?.reference)
            );
            return [
              ...resources,
              ...(organization ? [organization] : []),
              ...(practitioner ? [practitioner] : []),
            ];
          }, []);

          return [
            ...resources,
            {
              resource: consent,
              includedResources: [
                ...(documentReference ? [documentReference] : []),
                ...organizations,
                ...performers,
                ...prRelatedResources,
              ],
            },
          ];
        },
        []
      );
    } catch (error) {
      showErrorMessage(i18n('patients.details.consents.errorRetrieving', 'crs'));
      return [];
    }
  }, [patient]);

  useEffect(() => {
    const getConsentsAsync = async () => {
      updateState({ isLoading: true });
      const consents = await getConsents();
      updateState({ isLoading: false, consents: consents });
    };
    getConsentsAsync();
  }, [getConsents, updateState]);

  useEffect(() => {
    const searchInput = cleanSearchInput(searchTextFieldValue ?? '').toLowerCase();

    const filteredConsents = consents.filter(({ resource, includedResources }) => {
      const matches: boolean[] = [];
      matches.push(
        filterConsentsBySearchInput(searchInput, resource, includedResources, [
          ...(consentStatusVS ? [consentStatusVS] : []),
        ],consentInternalNumberIdentifierSystem),
        filterConsents(filters, resource)
      );

      return !matches.includes(false);
    });

    updateState({ filteredConsents });
  }, [searchTextFieldValue, filters, consents, consentStatusVS, configurations]);

  const consentStatusConcepts = consentStatusVS?.asListAll?.() ?? [];

  const handlePreviewDetails = (consentData: ResourceWithIncludedResources<WrappedConsent>) => {
    updateState({
      isConsentDetailDrawerOpen: true,
      selectedConsent: consentData,
    });
  };
  const gridColumns: GridColDef[] = [
    {
      flex: 1,
      sortable: false,
      field: 'identifier',
      headerName: i18n('patients.details.consents.consentId', 'crs'),
      renderCell: (params) => {
        const consentData = params.row as ResourceWithIncludedResources<WrappedConsent>;
        const { resource: consent } = consentData;

        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <CustomLink
              to="#"
              onClick={() => {
                handlePreviewDetails(consentData);
              }}
            >
              {consent.getInternalNumber(consentInternalNumberIdentifierSystem)}
            </CustomLink>
          </Box>
        );
      },
    },
    {
      flex: 2,
      field: 'documentTitle',
      sortable: true,
      valueGetter: (params) => {
        const { includedResources, resource: consent } =
          params.row as ResourceWithIncludedResources<WrappedConsent>;

        return consent.getDocumentTitle(includedResources);
      },
      headerName: i18n('patients.details.consents.document', 'crs'),
      renderCell: (params) => {
        const { includedResources, resource: consent } =
          params.row as ResourceWithIncludedResources<WrappedConsent>;
        return (
          <CellRow shouldTruncateText={false} title={consent.getDocumentTitle(includedResources)} />
        );
      },
    },
    {
      flex: 2,
      field: 'grantee',
      sortable: true,
      valueGetter: (params) => {
        const { includedResources, resource: consent } =
          params.row as ResourceWithIncludedResources<WrappedConsent>;

        return consent.getFirstPerformerName(includedResources);
      },
      headerName: i18n('patients.details.consents.grantee', 'crs'),
      renderCell: (params) => {
        const { includedResources, resource: consent } =
          params.row as ResourceWithIncludedResources<WrappedConsent>;
        return (
          <CellRow
            shouldTruncateText={false}
            title={consent.getFirstPerformerName(includedResources)}
          />
        );
      },
    },
    {
      flex: 2,
      field: 'controller',
      sortable: true,
      valueGetter: (params) => {
        const { includedResources, resource: consent } =
          params.row as ResourceWithIncludedResources<WrappedConsent>;

        return consent.getFirstOrganizationName(includedResources);
      },
      headerName: i18n('patients.details.consents.controller', 'crs'),
      renderCell: (params) => {
        const { includedResources, resource: consent } =
          params.row as ResourceWithIncludedResources<WrappedConsent>;

        return (
          <CellRow
            shouldTruncateText={false}
            title={consent.getFirstOrganizationName(includedResources)}
          />
        );
      },
    },

    {
      flex: 2,
      field: 'period',
      sortable: true,
      valueGetter: (params) => {
        const { resource: consent } = params.row as ResourceWithIncludedResources<WrappedConsent>;
        const start = moment(consent?.provision?.period?.start ?? null);
        const end = moment(consent?.provision?.period?.end ?? null);
        if (end.isValid()) return end;
        return start;
      },
      sortComparator: (_, __, date1, date2) => momentDateComparator(date1.value, date2.value),
      headerName: i18n('patients.details.consents.period', 'crs'),
      renderCell: (params) => {
        const { resource: consent } = params.row as ResourceWithIncludedResources<WrappedConsent>;
        const start = moment(consent?.provision?.period?.start ?? null);
        const end = moment(consent?.provision?.period?.end ?? null);
        const timeDiff =
          start.isValid() && end.isValid() ? getTimeDiff(start, end)?.diffText : null;

        const now = moment();
        let color = '#ffab00';
        if (
          (start.isSameOrBefore(now, 'date') && end.isAfter(now, 'date')) ||
          (start.isSameOrBefore(now, 'date') && !end.isValid())
        )
          color = '#00ab55';
        if (end.isValid() && end.isBefore(now, 'date')) color = '#ff5630';
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {start.isValid() && (
              <CellRow
                tooltipTitle={i18n('patients.details.consents.startDate', 'crs')}
                Icon={<EventAvailable sx={{ height: 25, width: 25 }} htmlColor={color} />}
                shouldTruncateText={false}
                title={formatDate(start)}
              />
            )}
            {end.isValid() && (
              <CellRow
                tooltipTitle={i18n('patients.details.consents.endDate', 'crs')}
                Icon={<EventBusy sx={{ height: 25, width: 25 }} htmlColor={color} />}
                shouldTruncateText={false}
                title={`${formatDate(end)}${timeDiff ? ` (${timeDiff})` : ''}`}
              />
            )}
          </Box>
        );
      },
    },
    {
      flex: 1,
      field: 'decision',
      sortable: true,
      valueGetter: (params) => {
        const { resource: consent } = params.row as ResourceWithIncludedResources<WrappedConsent>;
        return consent.getProvisionType()?.display;
      },
      headerName: i18n('patients.details.consents.decision', 'crs'),
      renderCell: (params) => {
        const { resource: consent } = params.row as ResourceWithIncludedResources<WrappedConsent>;
        const provisionType = consent.getProvisionType();
        const LockIcon = provisionType?.code
          ? provisionType.code === 'permit'
            ? LockOpen
            : Lock
          : null;
        return (
          <CellRow
            Icon={LockIcon ? <LockIcon htmlColor="#637381" sx={{ height: 25, width: 25 }} /> : null}
            shouldTruncateText={false}
            title={provisionType?.display}
          />
        );
      },
    },
    {
      flex: 2,
      field: 'type',
      sortable: true,
      valueGetter: (params) => {
        const { resource: consent } = params.row as ResourceWithIncludedResources<WrappedConsent>;
        return consent.getFirstCategory()?.display;
      },
      headerName: i18n('patients.details.consents.type', 'crs'),
      renderCell: (params) => {
        const { resource: consent } = params.row as ResourceWithIncludedResources<WrappedConsent>;

        return <CellRow shouldTruncateText={false} title={consent.getFirstCategory()?.display} />;
      },
    },
    {
      flex: 1.1,
      field: 'status',
      sortable: true,
      align: 'center',
      valueGetter: (params) => {
        const { resource: consent } = params.row as ResourceWithIncludedResources<WrappedConsent>;
        return consentStatusConcepts.find(({ code }) => code === consent.status)?.display;
      },
      headerName: i18n('patients.details.consents.status', 'crs'),
      renderCell: (params) => {
        const { resource: consent } = params.row as ResourceWithIncludedResources<WrappedConsent>;

        const statusDisplay = consentStatusConcepts.find(
          ({ code }) => code === consent.status
        )?.display;
        const severity = consent.getStatusSeverity();
        if (!statusDisplay || !severity) return null;
        return (
          <SeverityStatus
            status={{
              message: statusDisplay,
              severity,
            }}
          />
        );
      },
    },
    {
      flex: 0.3,
      field: 'action',
      sortable: false,
      align: 'center',
      headerName: ' ',
      renderCell: (params) => {
        const consentData = params.row as ResourceWithIncludedResources<WrappedConsent>;
        return (
          <ActionButton
            renderChildren={(isMenuOpen, setIsMenuOpen) => (
              <>
                <NestedMenuItem
                  sx={nestedMenuItemStyles}
                  onClick={() => {
                    handlePreviewDetails(consentData);
                    setIsMenuOpen(false);
                  }}
                  leftIcon={<Preview />}
                  rightIcon={null}
                  label={i18n('patients.details.consents.details', 'crs')}
                  parentMenuOpen={isMenuOpen}
                />
                {!!permissions.isAllowedToEdit && (
                  <NestedMenuItem
                    sx={nestedMenuItemStyles}
                    onClick={() => {
                      updateState({
                        selectedConsent: consentData,
                        isConsentModalOpen: true,
                      });
                      setIsMenuOpen(false);
                    }}
                    leftIcon={<Edit />}
                    rightIcon={null}
                    label={i18n('patients.details.consents.edit', 'crs')}
                    parentMenuOpen={isMenuOpen}
                  />
                )}
              </>
            )}
          />
        );
      },
    },
  ];

  const permissions: ConsentPermissions = useMemo(
    () => ({
      isAllowedToAdd: checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CONSENT.ADD] }),
      isAllowedToEdit: checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CONSENT.EDIT] }),
    }),
    []
  );

  const handleCloseConsentDetailDrawer = () => {
    updateState({
      selectedConsent: null,
      isConsentDetailDrawerOpen: false,
    });
  };

  return (
    <>
      <PermissionsProvider permissions={permissions}>
        <DataGridWithFilters
          getRowSpacing={() => ({ bottom: 1, top: 2 })}
          addButtonTitle={i18n('patients.details.consents.newConsent', 'crs')}
          title={title ? i18n(title, 'crs') : ''}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          onAddButtonClick={() => {
            updateState({
              isConsentModalOpen: true,
              selectedConsent: null,
            });
          }}
          density="standard"
          loading={isLoading || isConsentStatusLoading}
          rowsPerPageOptions={defaultRowsPerPageOptions}
          getRowHeight={() => 'auto'}
          onFilterDrawerOpen={onFilterDrawerOpen}
          rows={filteredConsents}
          columns={gridColumns}
          getRowId={({ resource }: ResourceWithIncludedResources<WrappedConsent>) =>
            resource.id as string
          }
          showPagination={true}
          autoHeight={true}
          onSearchTextFieldChange={onSearchTextFieldChange}
          searchTextFieldValue={searchTextFieldValue}
          isNestedGrid={false}
          Filters={<ConsentGridFilter filterValues={tempFilters} updateFilters={updateFilters} />}
          FilterDrawerProps={{
            title: i18n('patients.details.consents.consentFilters', 'crs'),
            open: isFilterDrawerOpen,
            onApplyButtonClick: () => {
              onApplyFilters(tempFilters);
            },
            onCloseIconButtonClick: onFilterDrawerClose,
            onClearAllButtonClick: onClearAllFilters,
          }}
        />
        <ConsentModal
          onSaveChanges={async () => {
            const consents = await getConsents();
            updateState({ consents });
          }}
          patient={patient}
          open={isConsentModalOpen}
          consentData={selectedConsent}
          onClose={() => {
            updateState({
              isConsentModalOpen: false,
            });
          }}
        />
        <ConsentDetailPreview
          open={isConsentDetailDrawerOpen}
          onCloseIconButtonClick={handleCloseConsentDetailDrawer}
          onClose={handleCloseConsentDetailDrawer}
          data={selectedConsent}
          consentStatusConcepts={consentStatusConcepts}
          patient={patient}
          onEdit={() => {
            updateState({
              isConsentDetailDrawerOpen: false,
              isConsentModalOpen: true,
            });
          }}
        />
      </PermissionsProvider>
    </>
  );
};

export const getPractitionerRoleRelatedResources = async (consents: Consent[]) => {
  try {
    const practitionerRoleIds = consents.reduce<string[]>((resourceIds, consent) => {
      const ids = consent?.performer
        ?.filter?.(
          ({ reference }) =>
            getReferenceType(reference) === 'PractitionerRole' && !!getReferenceId(reference)
        )
        ?.map?.(({ reference }) => getReferenceId(reference)) as string[];
      if (!ids?.length) return resourceIds;
      return [...resourceIds, ...ids];
    }, []);
    if (!practitionerRoleIds.length) return [];

    const response = await fhirClient.get<Bundle>(
      `PractitionerRole?_id=${[
        ...new Set(practitionerRoleIds),
      ].join()}&_include=PractitionerRole:organization&_include=PractitionerRole:practitioner`
    );

    return (
      response?.entry?.reduce?.<FhirResource[]>((resources, { resource }) => {
        if (!resource) return resources;
        return [...resources, resource];
      }, []) ?? []
    );
  } catch (error) {
    return [];
  }
};

export const filterConsentsBySearchInput = (
  searchInput: string,
  consent: WrappedConsent,
  includedResources: FhirResource[],
  valueSets: WrappedValueSet[],
  consentInternalNumberIdentifierSystem: string,
): boolean => {
  if (!searchInput || searchInput.length < 3) return true;
  const statusDisplay =
    valueSets
      .find(({ id }) => id === 'consent-state-codes')
      ?.asListAll?.()
      ?.find?.(({ code }) => code === consent.status)?.display ?? '';

  const searcheableValues: string[] = [
    statusDisplay,
    consent.getInternalNumber(consentInternalNumberIdentifierSystem) ?? '',
    consent.getDocumentTitle(includedResources) ?? '',
    consent.getFirstPerformerName(includedResources) ?? '',
    consent.getFirstOrganizationName(includedResources) ?? '',
    consent.getProvisionType()?.display ?? '',
    consent.getFirstCategory()?.display ?? '',
  ];

  for (const value of searcheableValues) {
    if (value.toLowerCase().includes(searchInput)) return true;
  }

  return false;
};

export const filterConsents = (filters: ConsentGridFilters, consent: WrappedConsent): boolean => {
  const { startDate, endDate } = filters;
  const start = moment(startDate?.isValid?.() ? startDate : begginingOfTime);
  const end = moment(endDate?.isValid?.() ? endDate : endOfTime);

  const consentStart = moment(
    moment(consent?.provision?.period?.start ?? null).isValid()
      ? consent?.provision?.period?.start
      : begginingOfTime
  );
  const consentEnd = moment(
    moment(consent?.provision?.period?.end ?? null).isValid()
      ? consent?.provision?.period?.end
      : endOfTime
  );
  if (!isBetweenDates(consentStart, consentEnd, start, end)) return false;

  return true;
};

const nestedMenuItemStyles: SxProps = {
  paddingX: 3,
};

export default ConsentGrid;
