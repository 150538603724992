import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { MobileDatePicker } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { useValueSet } from 'src/@nicheaim/fhir-react';
import { fToNow, parseShortFormat } from 'src/utils/formatTime';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { convertValueToValueSet } from 'src/sections/crs/common/common-utils';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import {
  ContactAttemptDto,
  ContactAttemptOutcome,
  TypeContactAttempt,
} from 'src/crs/dto/contact-attempt.dto';
import { MenuItem } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { getDefaultAssessmentsQueryParams } from 'src/sections/crs/helpers/assessments';
import useLocales from 'src/hooks/useLocales';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

type Props = {
  option: string;
  referral: any;
  open: boolean;
  lastRecord: any;
  countRecord: any;
  assessmentsLinks?: any[];
  onCancel: VoidFunction;
  handleCreateContactForm: (data: any) => Promise<any>;
  patientId?: string | null;
};

export default function ContactForm({
  option,
  referral,
  open,
  lastRecord,
  countRecord,
  assessmentsLinks,
  handleCreateContactForm,
  onCancel,
  patientId,
}: Props) {
  const user = useAuth();
  const { theme, configurations } = useTenantConfigData();
  const { enqueueSnackbar } = useSnackbar();
  const [headerTab, setHeaderTab] = React.useState(0);
  const [outcomeList] = useValueSet('ph-outcome-contact-form', { map: ValueSetWrapper });
  const [categoryForm] = useValueSet('ph-workflow-step-form', { map: ValueSetWrapper });
  const { i18n } = useLocales();

  type FormValues = {
    contactOn: Date;
    nextContactOn: Date;
    outcome: string;
    reasonNote: string;
  };

  const EventSchema = Yup.object().shape({
    contactOn: Yup.date().required('Contact On is required'),
    nextContactOn: Yup.date().required('Next Contact On is required'),
    outcome: Yup.string().required('Outcome is required'),
    reasonNote: Yup.string(),
  });

  const defaultValues = {
    contactOn: new Date(),
    nextContactOn: new Date(),
    outcome: '',
    reasonNote: '',
  };

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues,
  });

  const { control, handleSubmit, reset } = methods;

  const mapFormDataToCommunication = (data: FormValues): ContactAttemptDto => {
    const { contactOn, nextContactOn, outcome, reasonNote } = data;

    const topicCoding = convertValueToValueSet(outcome, outcomeList);
    const categoryCoding = convertValueToValueSet(option || '', categoryForm);

    let typeContact;
    if (option === 'Contact Client') {
      typeContact = TypeContactAttempt.CONTACT_CLIENT_ITEM;
    } else {
      typeContact = TypeContactAttempt.CONTACT_PCP_ITEM;
    }

    return {
      typeNote: categoryCoding?.display || '',
      reasonNote: reasonNote,
      outcome: topicCoding?.display as ContactAttemptOutcome,
      id: Number(referral.referralId),
      contactOn: new Date(contactOn).toISOString(),
      nextContactOn: new Date(nextContactOn).toISOString(),
      type: typeContact,
    };
  };

  const handleClose = () => {
    reset(defaultValues);
    onCancel();
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      const resultMap = mapFormDataToCommunication(data);
      const result = await handleCreateContactForm(resultMap);
      handleClose();
      if (!isEmpty(result)) enqueueSnackbar('Register was created.');
    } catch (e) {
      handleClose();
      enqueueSnackbar('Register was not created.', { variant: 'error' });
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setHeaderTab(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        {option}&nbsp;
        {countRecord !== undefined ? `[${countRecord}/5]` : ''}&nbsp;
        <Typography variant="body2" component="span">
          {!isEmpty(lastRecord)
            ? `Last on ${parseShortFormat(lastRecord || '')} - ${fToNow(lastRecord || '')}`
            : ''}
        </Typography>
      </DialogTitle>

      {option === 'Contact Client' && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={headerTab} onChange={handleChange} centered aria-label="">
              <Tab label={i18n('referral.details.contactAttempts.contactInfo', 'crs')} />
              <Tab label={i18n('referral.details.contactAttempts.assesmentsLinks', 'crs')} />
            </Tabs>
          </Box>
        </>
      )}

      <CustomTabPanel value={headerTab} index={0}>
        <Card sx={{ m: 2 }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Stack spacing={2} sx={{ p: 2 }}>
                  <Controller
                    name="contactOn"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <MobileDatePicker
                        {...field}
                        label={i18n('referral.details.contactAttempts.contactOn', 'crs')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="nextContactOn"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <MobileDatePicker
                        {...field}
                        label={i18n('referral.details.contactAttempts.nextContactOn', 'crs')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  />

                  <RHFSelect name="outcome" label={i18n('referral.details.contactAttempts.outcome', 'crs')}>
                    <MenuItem disabled />
                    {outcomeList?.asList().map((option: any) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.display}
                      </MenuItem>
                    ))}
                  </RHFSelect>

                  <RHFTextField name="reasonNote" label={i18n('referral.details.contactAttempts.reasonNote', 'crs')} multiline rows={4} />
                </Stack>
              </Grid>
            </Grid>

            <Stack spacing={2} alignItems="center">
              <DialogActions>
                <Box sx={{ flexGrow: 1 }} />

                <Button variant="contained" color="info" onClick={handleClose}>
                  {i18n('cancel')}
                </Button>

                <Button variant="contained" color="info" type="submit">
                  {i18n('submit')}
                </Button>
              </DialogActions>
            </Stack>
          </FormProvider>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={headerTab} index={1}>
        <Card sx={{ m: 2, p: 3 }}>
          <Stack direction="column" spacing={2} justifyContent="center" sx={{ pt: 2 }}>
            {assessmentsLinks?.map((btn) => {
              const url = `${btn.link}${getDefaultAssessmentsQueryParams(
                patientId ?? '',
                user,
                { tenantInfoName: theme?.tenantName, nodeEnv: configurations?.nodeEnv}
              )}&referralUUID=${referral?.fhirId}`;
              if (!patientId || !user) return null;
              return (
                <Link key={btn?.name} href={url} target="_blank" underline="always">
                  {btn?.label}
                </Link>
              );
            })}
          </Stack>
        </Card>
        <Stack spacing={2} alignItems="center">
          <DialogActions>
            <Box sx={{ flexGrow: 1 }} />

            <Button variant="contained" color="info" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </Stack>
      </CustomTabPanel>

      {/* <Card sx={{ m: 2 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <Controller
                  name="contactOn"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <MobileDatePicker
                      {...field}
                      label="Contact On"
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="nextContactOn"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <MobileDatePicker
                      {...field}
                      label="Next Contact On"
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />

                <RHFSelect name="outcome" label="Outcome">
                  <MenuItem disabled/>
                  {outcomeList?.asList().map((option: any) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.display}
                    </MenuItem>
                  ))}
                </RHFSelect>

                <RHFTextField name="reasonNote" label="Reason Note" multiline rows={4} />
              </Stack>
            </Grid>
          </Grid>

          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />

              <Button variant="contained" color="info" onClick={handleClose}>
                Cancel
              </Button>

              <Button variant="contained" color="info" type="submit">
                Submit
              </Button>
            </DialogActions>
          </Stack>
        </FormProvider>
      </Card> */}
    </Dialog>
  );
}
