import { useState } from 'react';
import Iconify from 'src/components/Iconify';
import { TableMoreMenu } from 'src/components/table';
import { MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

type Props = {
  row: any;
  handleEdit: any;
};

export default function ContactRow({ row, handleEdit }: Props) {
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleEditOpen = (row: any) => {
    handleCloseMenu();
    handleEdit(row);
  };

  return (
    <>
      <TableRow>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {`${row?.name?.given?.[0]} ${row?.name?.family}`}
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="caption">
              {row?.telecom?.filter((e: any) => e?.system === 'phone')?.[0]?.value}
            </Typography>
            <Typography variant="caption">
              {row?.telecom?.filter((e: any) => e?.system === 'email')?.[0]?.value}
            </Typography>
          </div>
        </TableCell>
        <TableCell align="center">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CONTACTS.EDIT] })
                ? [
                    <MenuItem key={row?.id} onClick={() => handleEditOpen(row)}>
                      <Iconify icon={'mdi:pencil'} />
                      Edit
                    </MenuItem>,
                  ]
                : null
            }
          />
        </TableCell>
      </TableRow>
    </>
  );
}
