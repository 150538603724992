import { useEffect, useMemo } from 'react';
import { TextField, Grid, Autocomplete, Dialog, Box, Typography } from '@mui/material';
import {
  BreadCrumbTitle,
  CustomModalBasicProps,
  GridItem,
  GridSection,
} from '../../../../../components/CustomModal';
import useObjectState from 'src/hooks/useObjectState';
import {
  CodeSystemConcept,
  Reference,
  ValueSetComposeIncludeConcept,
} from '../../../../../nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources/resources';
import { WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import {
  PatientWrapper,
  WrappedPatient,
} from '../../../../../@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCareTeam } from '../../../../../@nicheaim/fhir-base/wrappers/CareTeam';
import { WrappedCarePlan } from '../../../../../@nicheaim/fhir-base/wrappers/CarePlan';
import { onSuccess } from '../../../../../@types/crs/case';
import DatePickerMoment from 'src/components/DatePickerMoment';
import moment, { DurationInputArg2 } from 'moment';
import { useActivityDefinitions, usePatient, usePractitioners } from 'src/@nicheaim/fhir-react';
import {
  ActivityDefinitionWrapper,
  WrappedActivityDefinition,
} from 'src/@nicheaim/fhir-base/wrappers/ActivityDefinition';
import {
  PractitionerWrapper,
  WrappedPractitioner,
} from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import SearchMember, {
  SearchMemberProps,
  MemberResourceTypeOption,
} from '../SearchMember/SearchMember';
import { WrappedPractitionerRole } from 'src/@nicheaim/fhir-base/wrappers/PractitionerRole';
import { useSnackbar } from 'notistack';
import { getValueSetConceptValue } from 'src/sections/crs/helpers/common';
import { ActionData } from '../GoalsGrid/GoalModal';
import { WrappedGoal } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
import { createTask, updateTask } from 'src/services/api/case';
import useLocales from 'src/hooks/useLocales';
import { getDynamicValueExpressionByPath } from 'src/utils/fhir';
import { WrappedOrganization } from 'src/@nicheaim/fhir-base/wrappers/Organization';
import { WrappedRelatedPerson } from 'src/@nicheaim/fhir-base/wrappers/RelatedPerson';
import useTaskContext from 'src/hooks/useTaskContext';
import { isEmpty } from 'lodash';
import { capitalCase } from 'change-case';
import GoalInfoRibbon from '../GoalsGrid/GoalInfoRibbon';
import CancelButton from 'src/sections/crs/common/CancelButton';
import { LoadingButton } from '@mui/lab';
import useTenantConfigData from 'src/hooks/useTenantConfigData';
import SelectSearchMember from 'src/sections/crs/common/SelectSearchMember';

export interface TaskModalProps extends CustomModalBasicProps {
  patientExternal?: WrappedPatient | null;
  onSuccessfulCreation: onSuccess;
  onSuccessfulEdit: onSuccess;
  carePlan?: WrappedCarePlan | null;
  goal?: WrappedGoal | null;
  taskToEdit?: WrappedTask;
  taskBasedOn?: WrappedTask | null;
}

export type TaskSelectedMemberOption = MemberResourceTypeOption;

export type TaskType = CodeSystemConcept;

export type TaskSelectedMemberValue =
  | WrappedPractitioner
  | WrappedCareTeam
  | WrappedPractitionerRole
  | WrappedPatient
  | WrappedOrganization
  | WrappedRelatedPerson;

export interface TaskFormState {
  isLoading: boolean;
  activityDefinition: WrappedActivityDefinition | null;
  taskType: TaskType | null;
  taskStatus: TaskStatus | null;
  taskPriority: TaskPriority | null;
  taskIntent: TaskIntent | null;
  requester: TaskSelectedMemberValue | null;
  taskOwner: TaskSelectedMemberValue | null;
  requesterPerformer: TaskSelectedMemberValue | null;
  performer: TaskSelectedMemberValue | null;
  requestedStart: moment.Moment | null;
  requestedEnd: moment.Moment | null;
  requestedBy: string | null;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  description: string | null;
  comments: string | null;
}

export interface ErrorFieldState {
  taskStatus?: string | null;
  taskIntent?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  requestedStart?: string | null;
  requestedEnd?: string | null;
  description?: string | null;
}

export type TaskStatus = ValueSetComposeIncludeConcept;

export type TaskPriority = ValueSetComposeIncludeConcept;

export type TaskIntent = ValueSetComposeIncludeConcept;

export interface TaskSelectedMemberState {
  isSearchMemberOpen: boolean;
  isTaskOwnerOpen: boolean;
  inputSelected: TaskSelectedMemberOption | TaskSelectedMemberValue | null;
  selectedRequester: TaskSelectedMemberOption | TaskSelectedMemberValue | null;
  selectedTaskOwner: TaskSelectedMemberOption | TaskSelectedMemberValue | null;
  selectedRequesterPerformer: TaskSelectedMemberOption | TaskSelectedMemberValue | null;
  selectedPerformer: TaskSelectedMemberOption | TaskSelectedMemberValue | null;
}

const TaskModal = ({
  patientExternal,
  onSuccessfulCreation,
  onSuccessfulEdit,
  carePlan,
  taskToEdit,
  open,
  onClose,
  goal,
  taskBasedOn,
}: TaskModalProps) => {

  const { i18n } = useLocales();

  const { basedOn } = useTaskContext();

  const { enqueueSnackbar } = useSnackbar();

  const { componentsData, configurations } = useTenantConfigData();
  const { searchMemberOptions } = componentsData?.searchMember ?? {};

  const [
    {
      isLoading,
      activityDefinition,
      taskType,
      taskStatus,
      taskPriority,
      taskIntent,
      requester,
      startDate,
      endDate,
      description,
      comments,
      taskOwner,
      requesterPerformer,
      performer,
      requestedBy,
      requestedStart,
      requestedEnd,
    },
    updateState,
  ] = useObjectState<TaskFormState>(
    getInitialState({
      taskToEdit,
      taskTypes: [],
      taskIntents: [],
      taskPriorities: [],
      taskStatuses: [],
      activityDefinitions: [],
      configurations: null
    })
  );

  const [activityDefinitionsRecords] = useActivityDefinitions({
    map: ActivityDefinitionWrapper,
    filter: {
      status: 'active',
    },
  });

  const compareByDescription = function( a, b ) {
    if ( a.description < b.description ){
      return -1;
    }
    if ( a.description > b.description ){
      return 1;
    }
    return 0;
  };  

  const activityDefinitions = useMemo(
    () =>
      activityDefinitionsRecords.reduce<WrappedActivityDefinition[]>(
        (distinctActivityDefinitions, activityDefinition) => {
          if (distinctActivityDefinitions.find(({ title }) => title === activityDefinition.title))
            return distinctActivityDefinitions;

          return [...distinctActivityDefinitions, activityDefinition];
        },
        []
      ).sort(compareByDescription),
    [activityDefinitionsRecords]
  );
  
  const {
    valueSets: [taskTypes, taskStatuses, taskIntents, taskPriorities],
  } = useValueSetsByIdentifiers([
    'ph-task-type-codes',
    'ph-task-status',
    'ph-task-intent',
    'ph-task-priority',
  ]);

  const [patientRecord, { isFetching: isPatientLoading }] = usePatient(
    taskToEdit?.getPatientId?.(),
    {
      map: PatientWrapper,
      autofetch: !!(taskToEdit?.getPatientId?.() && !patientExternal),
    }
  );

  const patient: WrappedPatient | null = useMemo(() => {
    if (patientExternal) return patientExternal;
    if (patientRecord) return patientRecord;
    return null;
  }, [patientRecord, patientExternal]);

  const [errors, updateErrorState] = useObjectState<ErrorFieldState>(errorsInitialState);

  useEffect(() => {
    if (!open) return;
    updateState(
      getInitialState({
        taskToEdit,
        taskTypes: taskTypes?.asList?.(),
        taskIntents: taskIntents?.asList?.(),
        taskPriorities: taskPriorities?.asList?.(),
        taskStatuses: taskStatuses?.asList(),
        activityDefinitions: activityDefinitions,
        configurations: configurations?.fhir
      })
    );
    updateErrorState(errorsInitialState);
    updateSelectedMember(
      getInitialSelectedState(
        taskToEdit,
        configurations?.fhir
    ));
  }, [open, taskToEdit, taskTypes, patient, taskStatuses, taskIntents, taskPriorities, configurations]);

  const [
    {
      isSearchMemberOpen,
      isTaskOwnerOpen,
      selectedRequester,
      selectedTaskOwner,
      selectedRequesterPerformer,
      selectedPerformer,
      inputSelected
    },
    updateSelectedMember,
  ] = useObjectState<TaskSelectedMemberState>(
    getInitialSelectedState(
      taskToEdit, 
      configurations?.fhir
    ));

  const [
    practitioners,
    {
      isLoading: isPractitionersLoading,
    },
  ] = usePractitioners({
    map: PractitionerWrapper,
    pagination: {
      pageSize: 10,
    },
  });

  const handleOnSave = async () => {
    const errors: ErrorFieldState = {};
    if (!description?.trim?.()?.length) errors.description = 'Must specify a description';
    if (!taskStatus) errors.taskStatus = 'Must select a valid status';
    if (!taskIntent) errors.taskIntent = 'Must select a valid intent';
    if (startDate && endDate && endDate?.isBefore(startDate, 'date'))
      errors.startDate = "Start Date can't be after End Date";
    if (startDate && !startDate?.isValid()) errors.startDate = 'Must specify a valid date';
    if (endDate && !endDate?.isValid()) errors.endDate = 'Must specify a valid date';
    if (requestedStart && requestedEnd && requestedEnd?.isBefore(requestedStart, 'date'))
      errors.requestedStart = "Requested Start can't be after Requested End";
    if (Object.keys(errors).length) {
      updateErrorState({ ...errors });
      return;
    }

    updateErrorState(errorsInitialState);
    const isCreating = !taskToEdit?.id;
    const { actionCall, payload: taskData, successfulMessage } = getTaskPayload(isCreating);
    updateState({ isLoading: true });

    const response = !taskToEdit?.id
      ? await createTask(taskData)
      : await updateTask(taskData, taskToEdit?.id as string);
    updateState({ isLoading: false });
    if (!response) {
      enqueueSnackbar("There's been an error. Please Try Again", { variant: 'error' });
      return;
    }

    enqueueSnackbar(successfulMessage);
    actionCall?.(response);
    onClose?.({}, 'backdropClick');
  };

  const getTaskPayload = (isCreating: boolean): ActionData => ({
    payload: {
      description,
      comments,
      status: taskStatus?.code ?? null,
      intent: taskIntent?.code ?? null,
      priority: taskPriority?.code ?? null,
      taskType: taskType?.code ?? null,
      taskTypeDisplay: taskType?.display ?? null,
      startDate: startDate?.toISOString() ?? null,
      endDate: endDate?.toISOString() ?? null,
      requesterId: requester?.id ? `${requester?.resourceType}/${requester?.id}` : null,
      requesterDisplay: getOwnerDisplayLabel(requester) ?? '',
      ownerId: taskOwner?.id ? `${taskOwner?.resourceType}/${taskOwner?.id}` : null,
      ownerDisplay: getOwnerDisplayLabel(taskOwner),
      requesterPerformerId: requesterPerformer?.id
        ? `${requesterPerformer?.resourceType}/${requesterPerformer?.id}`
        : null,
      requesterPerformerDisplay: getOwnerDisplayLabel(requesterPerformer),
      performerId: performer?.id ? `${performer?.resourceType}/${performer?.id}` : null,
      performerDisplay: getOwnerDisplayLabel(performer),
      requestedStart: requestedStart?.toISOString() ?? null,
      requestedEnd: requestedEnd?.toISOString() ?? null,
      ...(isCreating
        ? {
            patientId: patient?.id,
            patientName: patient?.getFullName(),
            activityDefinitionId: activityDefinition?.id,
            carePlanId: carePlan?.id,
            goalId: goal?.id,
            basedOn: !!basedOn?.length ? basedOn : undefined,
            ...(taskBasedOn?.id && { basedOn: [{ reference: `Task/${taskBasedOn?.id}` }] }),
          }
        : {}),
    },
    actionCall: isCreating ? onSuccessfulCreation : onSuccessfulEdit,
    successfulMessage: isCreating ? 'Task Succesfully Created' : 'Task Successfully Modified',
  });

  const title = !taskToEdit
    ? `${i18n('patients.details.tasks.titleAddTasks', 'crs')}`
    : 'Edit Task';

  const updateSelection = (updateKey: string, stateKey: string, resource: any) => {
    const updateObj: any = { isSearchMemberOpen: false, isTaskOwnerOpen: false };
    updateObj[updateKey] = resource;
    updateSelectedMember(updateObj);
    updateState({ [stateKey]: resource });
  };

  const getTaskNumber = useMemo(() => 
    taskToEdit 
    ? taskToEdit?.getInternalNumber?.(configurations?.fhir?.taskInternalNumberIdentifierSystem) 
      ?? taskToEdit?.getTaskNumberNAT(configurations?.fhir?.taskNATIdenitifierType) 
      ?? ""
    : "", [taskToEdit]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={false}
      PaperProps={{
        sx: {
          width: (isSearchMemberOpen || isTaskOwnerOpen) ? '94vw' : '50vw',
          paddingX: 3
        },
      }}
    >
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} sx={{ paddingTop: 1 }}>
        <Typography variant="h6">{title}</Typography>
        <Box
          sx={{ marginLeft: 1 }}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
        >
          {['List of Tasks', title].map((breadcrumbTitle, index) => (
            <BreadCrumbTitle
              key={breadcrumbTitle}
              useSeparator={!!index}
              title={breadcrumbTitle}
            />
          ))}
        </Box>
      </Box>

      {goal && <GoalInfoRibbon goal={goal} />}
      <Grid container my={3} justifyContent={'space-between'}>
        <Grid item xs={(isSearchMemberOpen || isTaskOwnerOpen) ? 6 : 12}>
          {!!taskToEdit && (
            <GridSection mt={0}>
              <GridItem>
                <TextField
                  fullWidth
                  label={i18n('tasks.taskNo', 'crs')}
                  value={getTaskNumber}
                  type="text"
                  placeholder={i18n('tasks.taskNo', 'crs')}
                  variant="outlined"
                  disabled
                />
              </GridItem>
            </GridSection>
          )}
          <GridSection>
            <GridItem>
              <TextField
                fullWidth
                label={i18n('patients.details.tasks.organization', 'crs')}
                value={patient?.getOrganization() ?? ''}
                type="text"
                placeholder="Organization"
                variant="outlined"
                disabled
              />
            </GridItem>
            <GridItem>
              <TextField
                fullWidth
                label={i18n('patients.details.tasks.patientName', 'crs')}
                value={patient?.getFullName() ?? ''}
                type="text"
                placeholder="Patient Name"
                variant="outlined"
                disabled
              />
            </GridItem>
          </GridSection>
          <GridSection>
            {!!taskToEdit && (
              <GridItem>
                <TextField
                  fullWidth
                  label={i18n('patients.details.tasks.requestedBy', 'crs')}
                  value={requestedBy ?? ''}
                  type="text"
                  placeholder={i18n('patients.details.tasks.requestedBy', 'crs')}
                  variant="outlined"
                  disabled
                />
              </GridItem>
            )}
            <GridItem>
              <Autocomplete
                disabled={isLoading || !!taskToEdit}
                value={activityDefinition}
                fullWidth
                onChange={(_: React.SyntheticEvent, activityDefinition) => {
                  const priority = getValueSetConceptValue(
                    taskPriorities?.asList?.() ?? [],
                    activityDefinition?.priority
                  );
                  const intent = getValueSetConceptValue(
                    taskIntents?.asList?.() ?? [],
                    getDynamicValueExpressionByPath(activityDefinition?.dynamicValue, 'task.intent')
                  );

                  const status = getValueSetConceptValue(
                    taskStatuses?.asList?.() ?? [],
                    getDynamicValueExpressionByPath(activityDefinition?.dynamicValue, 'task.status')
                  );

                  const taskType = activityDefinition?.code?.coding?.[0] ?? null;
                  let taskStartDate: moment.Moment | null = null;
                  let taskEndDate: moment.Moment | null = null;
                  if (
                    activityDefinition?.timingDuration?.value &&
                    activityDefinition?.timingDuration?.unit
                  ) {
                    taskStartDate = startDate?.isValid?.() ? startDate : moment();
                    taskEndDate = taskStartDate
                      .clone()
                      .add(
                        activityDefinition?.timingDuration?.value,
                        activityDefinition?.timingDuration?.unit as DurationInputArg2
                      );
                  }
                  updateState({
                    activityDefinition,
                    ...(status ? { taskStatus: status } : {}),
                    ...(taskEndDate && taskStartDate
                      ? { endDate: taskEndDate, startDate: taskStartDate }
                      : {}),
                    ...(taskType ? { taskType: taskType as CodeSystemConcept } : {}),
                    ...(priority ? { taskPriority: priority } : {}),
                    ...(intent ? { taskIntent: intent } : {}),
                    ...(activityDefinition?.description
                      ? {
                          description:
                            activityDefinition?.description ??
                            activityDefinition?.title ??
                            activityDefinition?.name,
                        }
                      : {}),
                  });
                }}
                options={activityDefinitions}
                getOptionLabel={({ title }: WrappedActivityDefinition) => title as string}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n('patients.details.tasks.activityDefinition', 'crs')}
                    variant="outlined"
                  />
                )}
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <Autocomplete
                disabled={isLoading}
                value={taskType}
                fullWidth
                onChange={(_: React.SyntheticEvent, taskType) => {
                  updateState({ taskType });
                }}
                options={(taskTypes?.asList() ?? []) as TaskType[]}
                getOptionLabel={({ display }: TaskType) => display as string}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n('patients.details.tasks.taskType', 'crs')}
                    variant="outlined"
                  />
                )}
              />
            </GridItem>
            <GridItem>
              <Autocomplete
                disabled={isLoading}
                value={taskStatus}
                fullWidth
                onChange={(_: React.SyntheticEvent, taskStatus) => {
                  if (taskStatus) updateErrorState({ taskStatus: null });
                  updateState({ taskStatus });
                }}
                options={taskStatuses?.asList?.() ?? []}
                getOptionLabel={({ display }: TaskStatus) => display ?? ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors?.taskStatus}
                    helperText={errors?.taskStatus}
                    label={`${i18n('patients.details.tasks.status', 'crs')}*`}
                    variant="outlined"
                  />
                )}
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <Autocomplete
                disabled={isLoading}
                value={taskPriority}
                fullWidth
                onChange={(_: React.SyntheticEvent, taskPriority) => {
                  updateState({ taskPriority });
                }}
                options={taskPriorities?.asList?.() ?? []}
                getOptionLabel={({ display }: TaskPriority) => display ?? ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n('patients.details.tasks.priority', 'crs')}
                    variant="outlined"
                  />
                )}
              />
            </GridItem>
            <GridItem>
              <Autocomplete
                disabled={isLoading}
                value={taskIntent}
                fullWidth
                onChange={(_: React.SyntheticEvent, taskIntent) => {
                  if (taskIntent) updateErrorState({ taskIntent: null });
                  updateState({ taskIntent });
                }}
                options={taskIntents?.asList?.() ?? []}
                getOptionLabel={({ display }: TaskIntent) => display ?? ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors?.taskIntent}
                    helperText={errors?.taskIntent}
                    label={`${i18n('patients.details.tasks.intent', 'crs')}*`}
                    variant="outlined"
                  />
                )}
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <SelectSearchMember
                patient={patient ?? undefined}
                isLoading={isLoading || (isPatientLoading && !patient) || isPractitionersLoading}
                valueSelected={selectedRequester}
                labelText={"patients.details.tasks.requester"}
                options={practitioners ?? []}
                selectedOption={'selectedRequester'}
                stateKey={'requester'}
                updateSelectedMember={updateSelectedMember}
                updateState={updateState}
              />
            </GridItem>
            <GridItem>
              <SelectSearchMember
                patient={patient ?? undefined}
                isLoading={isLoading || (isPatientLoading && !patient)}
                valueSelected={selectedTaskOwner}
                labelText={"patients.details.tasks.taskOwner"}
                options={searchMemberOptions?.taskSelectOwner ?? []}
                selectedOption={'selectedTaskOwner'}
                stateKey={'taskOwner'}
                updateSelectedMember={updateSelectedMember}
                updateState={updateState}
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <SelectSearchMember 
                patient={patient ?? undefined}
                isLoading={isLoading || (isPatientLoading && !patient)}
                valueSelected={selectedRequesterPerformer}
                labelText={"patients.details.tasks.requesterPerformer"}
                options={searchMemberOptions?.taskSelectDefault ?? []}
                selectedOption={'selectedRequesterPerformer'}
                stateKey={'requesterPerformer'}
                updateSelectedMember={updateSelectedMember}
                updateState={updateState}
              />
            </GridItem>
            <GridItem>
              <SelectSearchMember 
                patient={patient ?? undefined}
                isLoading={isLoading || (isPatientLoading && !patient)}
                valueSelected={selectedPerformer}
                labelText={"patients.details.tasks.performer"}
                options={searchMemberOptions?.taskSelectDefault ?? []}
                selectedOption={"selectedPerformer"}
                stateKey={"performer"}
                updateSelectedMember={updateSelectedMember}
                updateState={updateState}
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <DatePickerMoment
                value={startDate}
                error={errors?.startDate}
                disabled={isLoading}
                label={i18n('patients.details.tasks.startDate', 'crs')}
                onChange={(value) => {
                  if (value?.isValid?.() || value === null) {
                    updateErrorState({
                      startDate: null,
                    });
                  }
                  updateState({
                    startDate: value,
                  });
                }}
              />
            </GridItem>
            <GridItem>
              <DatePickerMoment
                error={errors?.endDate}
                value={endDate}
                disabled={isLoading}
                label={i18n('patients.details.tasks.endDate', 'crs')}
                onChange={(value) => {
                  if (value?.isValid?.() || value === null) {
                    updateErrorState({
                      endDate: null,
                    });
                  }
                  updateState({
                    endDate: value,
                  });
                }}
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <DatePickerMoment
                value={requestedStart}
                error={errors?.requestedStart}
                disabled={isLoading}
                label={i18n('patients.details.tasks.requestedStart', 'crs')}
                onChange={(value) => {
                  if (value?.isValid?.() || value === null) {
                    updateErrorState({
                      requestedStart: null,
                    });
                  }
                  updateState({
                    requestedStart: value,
                  });
                }}
              />
            </GridItem>
            <GridItem>
              <DatePickerMoment
                error={errors?.requestedEnd}
                value={requestedEnd}
                disabled={isLoading}
                label={i18n('patients.details.tasks.requestedEnd', 'crs')}
                onChange={(value) => {
                  if (value?.isValid?.() || value === null) {
                    updateErrorState({
                      requestedEnd: null,
                    });
                  }
                  updateState({
                    requestedEnd: value,
                  });
                }}
              />
            </GridItem>
          </GridSection>
          {!!taskToEdit && (
            <GridSection>
              <GridItem>
                <DatePickerMoment
                  disabled
                  label={i18n('patients.details.tasks.authoredOn', 'crs')}
                  value={
                    moment(taskToEdit?.authoredOn).isValid()
                      ? moment(taskToEdit?.authoredOn)
                      : !taskToEdit
                      ? moment()
                      : null
                  }
                  onChange={() => {}}
                />
              </GridItem>
              <GridItem>
                <DatePickerMoment
                  disabled
                  label={'Last Modified Date'}
                  value={
                    moment(taskToEdit?.lastModified).isValid()
                      ? moment(taskToEdit?.lastModified)
                      : null
                  }
                  onChange={() => {}}
                />
              </GridItem>
            </GridSection>
          )}
          <GridSection>
            <TextField
              fullWidth
              disabled={isLoading}
              value={description ?? ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                if (value.trim().length) updateErrorState({ description: null });
                updateState({
                  description: event.target.value,
                });
              }}
              error={!!errors.description}
              helperText={errors.description}
              label={`${i18n('patients.details.tasks.description', 'crs')}*`}
              placeholder={`${i18n('patients.details.tasks.description', 'crs')}*`}
              variant="outlined"
            />
          </GridSection>
          <GridSection>
            <TextField
              fullWidth
              disabled={isLoading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateState({
                  comments: event.target.value,
                });
              }}
              value={comments ?? ''}
              label={i18n('patients.details.tasks.comments', 'crs')}
              placeholder={i18n('patients.details.tasks.comments', 'crs')}
              variant="outlined"
            />
          </GridSection>
        </Grid>
        {isSearchMemberOpen && (
          <Grid item xs={5.9}>
            <SearchMember
              patient={patient as WrappedPatient}
              onSelectResource={(resource) => {
                if (typeof inputSelected === 'string' && inputSelected === 'taskOwner') {
                  updateSelection('selectedTaskOwner', 'taskOwner', resource);
                } else if (typeof inputSelected === 'string' && inputSelected ===  'requesterPerformer') {
                  updateSelection('selectedRequesterPerformer', 'requesterPerformer', resource);
                } else if (typeof inputSelected === 'string' && inputSelected ===  'performer') {
                  updateSelection('selectedPerformer', 'performer', resource);
                }
              }}
              onClear={() => {
                if (typeof inputSelected === 'string' && inputSelected ===  'taskOwner') {
                  updateSelection('selectedRequesterPerformer', 'requesterPerformer', null);
                  updateState({ taskOwner: null });
                } else if (typeof inputSelected === 'string' && inputSelected ===  'requesterPerformer') {
                  updateSelection('selectedRequesterPerformer', 'requesterPerformer', null);
                  updateState({ requesterPerformer: null });
                } else if (typeof inputSelected === 'string' && inputSelected === 'performer') {
                  updateSelection('selectedPerformer', 'performer', null);
                }
              }}
              externalResourceType={(() => {
                const selectedOption =
                  (typeof inputSelected === 'string' && inputSelected ===  'taskOwner'
                    ? getExternalResourceType(selectedTaskOwner)
                    : undefined) ??
                  (typeof inputSelected === 'string' && inputSelected ===  'requesterPerformer'
                    ? getExternalResourceType(selectedRequesterPerformer)
                    : undefined) ??
                  (typeof inputSelected === 'string' && inputSelected ===  'performer'
                    ? getExternalResourceType(selectedPerformer)
                    : undefined);
                return selectedOption;
              })()}
            />
          </Grid>
        )}
      </Grid>

      <Box
        sx={{ paddingY: 2 }}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-end'}
      >
        <CancelButton
          disabled={!!isLoading}
          onClick={() => onClose?.({}, 'backdropClick')}
        />
        <LoadingButton
          loading={!!isLoading}
          disabled={!!isLoading }
          onClick={handleOnSave}
          variant="contained"
        >
          {i18n('saveChanges')}
        </LoadingButton>
      </Box>
    </Dialog>
  );
};

const errorsInitialState: ErrorFieldState = {
  taskStatus: null,
  taskIntent: null,
  startDate: null,
  endDate: null,
  description: null,
};

export const getResourceDataFromFhirId = (fhirId: string | undefined) => fhirId?.split('/') ?? [];
export const getTaskOwnerByReference = (
  reference: Reference | undefined
): TaskFormState['taskOwner'] => {
  let taskOwner: TaskFormState['taskOwner'] = null;
  const display = reference?.display ?? '';
  const [resourceType, taskOwnerId] = getResourceDataFromFhirId(reference?.reference);
  if (taskOwnerId) {
    switch (resourceType) {
      case 'Practitioner':
      case 'Patient':
        taskOwner = {
          id: taskOwnerId,
          resourceType,
          getFullName: () => display,
        } as WrappedPractitioner | WrappedPatient;
        break;
      case 'PractitionerRole':
        taskOwner = {
          id: taskOwnerId,
          resourceType,
          getPractitionerName: (_: WrappedPractitioner[]) => display,
        } as WrappedPractitionerRole;
        break;
      case 'CareTeam':
        taskOwner = {
          id: taskOwnerId,
          resourceType,
          name: display,
        } as WrappedCareTeam;
        break;
      case 'Organization':
        taskOwner = {
          id: taskOwnerId,
          resourceType,
          name: display,
        } as WrappedOrganization;
        break;
    }
  }
  return taskOwner;
};

export const getOwnerDisplayLabel = (option: TaskFormState['taskOwner']): string => {
  switch (option?.resourceType) {
    case 'Practitioner':
    case 'Patient':
      return option?.getFullName() as string;
    case 'PractitionerRole':
      return option?.getPractitionerName() as string;
    case 'CareTeam':
      return option?.name as string;
    case 'Organization':
      return option?.name as string;
    case 'RelatedPerson':
      return option?.getFullName() as string;
    default:
      return '';
  }
};

export const getPractitionerFromReference = (
  reference: Reference | undefined
): WrappedPractitioner | null => {
  if (!reference?.reference) return null;
  const [resourceType, practitionerId] = getResourceDataFromFhirId(reference?.reference);
  return {
    id: practitionerId,
    resourceType: resourceType as 'Practitioner',
    getFullName: () => reference?.display ?? '',
  } as WrappedPractitioner;
};

const getInitialState = ({
  taskToEdit,
  taskTypes,
  taskStatuses,
  taskIntents,
  taskPriorities,
  activityDefinitions,
  configurations
}: {
  taskToEdit: WrappedTask | undefined;
  taskTypes: TaskType[] | undefined;
  taskStatuses: TaskStatus[] | undefined;
  taskIntents: TaskIntent[] | undefined;
  taskPriorities: TaskPriority[] | undefined;
  activityDefinitions: WrappedActivityDefinition[] | null;
  configurations: any
}): TaskFormState => {
  const taskType = getValueSetConceptValue(taskTypes ?? [], taskToEdit?.getTaskType?.());
  const taskStatus = getValueSetConceptValue(taskStatuses ?? [], taskToEdit?.status);
  const taskPriority = getValueSetConceptValue(taskPriorities ?? [], taskToEdit?.priority);
  const taskIntent = getValueSetConceptValue(taskIntents ?? [], taskToEdit?.intent);
  let startDate: moment.Moment | null = moment(taskToEdit?.executionPeriod?.start ?? null);
  let endDate: moment.Moment | null = moment(taskToEdit?.executionPeriod?.end ?? null);
  if (!startDate.isValid()) startDate = !taskToEdit ? moment() : null;
  if (!endDate.isValid()) endDate = null;
  const requester = getTaskOwnerByReference(taskToEdit?.requester);
  const taskOwner = getTaskOwnerByReference(taskToEdit?.owner);
  const activityDefinition = activityDefinitions?.find(
    (e) => e?.id === taskToEdit?.instantiatesCanonical?.split('/')?.[1]
  );
  const getExtensionTask = taskToEdit?.extension?.find(
    (e) => e?.url === configurations?.taskExtension
  )?.extension;
  const requesterPerformer = getTaskOwnerByReference(
    getExtensionTask?.find((e) => e?.url === 'requesterPerformer')?.valueReference
  );
  const performer = getTaskOwnerByReference(
    getExtensionTask?.find((e) => e?.url === 'performer')?.valueReference
  );
  const requestedStart = moment(
    getExtensionTask?.find((e) => e?.url === 'requestedPeriod')?.valuePeriod?.start ?? null
  );
  const requestedEnd = moment(
    getExtensionTask?.find((e) => e?.url === 'requestedPeriod')?.valuePeriod?.end ?? null
  );
  const requestedByMetaTag =
    taskToEdit?.meta?.tag?.find((e) => e.code === 'ph-created-by')?.display ?? null;
  const requestedBy = requestedByMetaTag
    ? capitalCase(requestedByMetaTag?.split('@')[0].replace('.', ' '))
    : null;

  return {
    taskType,
    taskStatus,
    taskPriority,
    taskIntent,
    startDate,
    endDate,
    requester,
    taskOwner,
    description: taskToEdit?.description ?? null,
    comments: taskToEdit?.getComments?.() ?? null,
    isLoading: false,
    activityDefinition: activityDefinition ?? null,
    requesterPerformer,
    performer,
    requestedBy,
    requestedStart,
    requestedEnd,
  };
};

const getInitialSelectedState = (
  taskToEdit: WrappedTask | null | undefined,
  configurations: any
) => {
  const getExtensionTask = taskToEdit?.extension?.find(
    (e) => e?.url === configurations?.taskExtension
  )?.extension;
  const requesterPerformer = getTaskOwnerByReference(
    getExtensionTask?.find((e) => e?.url === 'requesterPerformer')?.valueReference
  );
  const performer = getTaskOwnerByReference(
    getExtensionTask?.find((e) => e?.url === 'performer')?.valueReference
  );
  return {
    isSearchMemberOpen: false,
    isTaskOwnerOpen: false,
    selectedRequester: taskToEdit ? getTaskOwnerByReference(taskToEdit?.requester) : null, 
    selectedTaskOwner: taskToEdit ? getTaskOwnerByReference(taskToEdit?.owner) : null,
    selectedRequesterPerformer: taskToEdit ? requesterPerformer : null,
    selectedPerformer: taskToEdit ? performer : null,
    inputSelected: null,
  };
};

export const getExternalResourceType = (
  option: any
): SearchMemberProps['externalResourceType'] | undefined => {
  if (!isEmpty(option)) {
    const selectedOption = option as TaskSelectedMemberOption;
    return selectedOption?.value as SearchMemberProps['externalResourceType'];
  }
  return undefined;
};

export default TaskModal;
