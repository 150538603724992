//@ts-nocheck
import { useEffect, useState } from 'react';
import axiosInstance from '../utils/axios';

export interface IUseSmartProps {
  smartAppBaseLaunchUrl: string | undefined;
  fhirBaseUrl: string | undefined;
  fhirUserId: string;
  userId: string;
  patientId: string | null;
}

export interface ILaunchDto {
  launch?: string;
  fhir_user?: string;
  iam_user?: string;
  patient?: string;
}

const createLaunch = async (
  userId: string,
  fhirUserId: string,
  patientId: string,
  smartLaunchUrl: string
): Promise<string | undefined> => {
  console.log('DEBUG smartLaunchUrl: ', smartLaunchUrl);
  if (smartLaunchUrl === undefined) return undefined;

  const launchBody: ILaunchDto = {
    fhir_user: fhirUserId,
    iam_user: userId,
    patient: patientId,
  };

  try {
    const response = await axiosInstance.post<ILaunchDto>(smartLaunchUrl, launchBody);
    if (response.data.launch) return response.data.launch;
  } catch (e) {
    console.log(e);
  }

  return undefined;
};

const buildSmartUrl = async (
  userId: string,
  fhirUserId: string,
  patientId: string,
  smartAppBaseLaunchUrl: string,
  fhirBaseUrl: string
) => {
  console.log('DEBUG config.fhirServerUrl: ', fhirBaseUrl);
  if (fhirBaseUrl === undefined || smartAppBaseLaunchUrl === undefined) return null;

  const launchId = await createLaunch(userId, fhirUserId, patientId, smartAppBaseLaunchUrl);

  if (launchId === undefined) return null;

  const buildedLaunch = `${smartAppBaseLaunchUrl}/?iss=${fhirBaseUrl}&launch=${launchId}`;

  return buildedLaunch;
};

function useSmartLaunch({ userId, fhirUserId, patientId, smartAppBaseLaunchUrl, fhirBaseUrl }: IUseSmartProps) {
  const [readyToBuild, setReadyToBuild] = useState<boolean>();

  useEffect(() => {
    if (!!userId && !!patientId && !!smartAppBaseLaunchUrl) {
      setReadyToBuild(true);
    }
  }, [userId, patientId, smartAppBaseLaunchUrl]);

  const getSmartUrl = async () => {
    if (readyToBuild && smartAppBaseLaunchUrl) {
      const builtUrl = await buildSmartUrl(userId, fhirUserId, patientId, smartAppBaseLaunchUrl,fhirBaseUrl);

      return builtUrl;
    }
    return null;
  };

  return { getSmartUrl, readyToBuild };
}

export default useSmartLaunch;
